import { loadModules } from 'esri-loader';
import React, { Component, Fragment } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import swal from 'sweetalert';
import { getLoggedInUser } from '../../helpers/authUtils';
import { CrudOperations } from '../../services/CRUDoperations.service';
import { UserAPI } from '../../services/users.services';
import { workorderService } from '../../services/workorder.service';
import Spinner from '../Spinner';

const __listPanelContentScroller = {
  position: 'absolute',
  top: '70px',
  left: '0',
  right: '0',
  bottom: '65px',
  overflow: 'auto',
  overflowX: 'hidden',
};

const _btnFooter = {
  backgroundColor: '#a4a3a4',
  color: '#fff',
};

class LogWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationBtnActive: false,
      User: null,
      AssignedToUID: '',
      SRCUsers: [],
      GISServices: [],
      GISServicesType: [],
      GISServicesTypes: [],
      GISAssets: [],
      GISRestServices: [],
      GISRestServicesOptions: [],
      GISProblems: [],
      GISProblemsType: [],
      GISProblemsTypes: [],
      GISResolutions: [],
      GISResolutionsType: [],
      GISResolutionsTypes: [],
      WorkAssignedToUser: null,
      WorkAssignedToUsersOptions: [],
      SelectedGISAsset: null,
      SelectedWorkType: null,
      SelectedProblemType: null,
      SelectedResolutionType: null,
      SelectedUserAssignedTo: null,
      essentialQuestions: null,
      GISUsers: [],
      WorkAssignedTo: '',
      globalID: '',
      departmentID: 0,
      selectedDepartment: null,
      workOrderType: '',
      workServiceID: 0,
      highlight: null,
      view: null,
      ismappingNewWork: false,
      NewWorkOrderGraphic: null,
      NewWorkOrderAddress: null,
      IsNewWorkAttributesVisible: false,
      isAssetBasedWork: false,
      isWorkAddressFound: false,
      isSubmittingWork: false,
      SketchModel: null,
      WorkOrderGeometry: null,
      isUILoading: true,
      workAssetDetails: '',
      sketchDisplay: 'none',
      departments: [],
      workOrderDueDate: null,
      workDescription: '',
      workTypeOptions: [],
      departmentOptions: [],
      assignedToOptions: [],
      workAssignedToUserObj: null,
      selectedWorkType: null,
      selectedProblemType: null,
      selectedResolutionType: null,
      selectedWorkAsset: null,
      IsReccurrence: 'No',
      OccurrenceNumber: 1,
      OccurrenceDelay: 'Days',
      nextOccurenceDueDate: '',
      isAddingMoreAssets: false,
      selectedGISAsset: null,
      ProblemName: '',
      ResolutionName: '',
      ProblemID: 0,
      ResolutionID: 0,
      DateRequired: null,
      DateCompleted: null,
      LocationAddress: null,
      Description: null,
      FieldNotes: null,
      AssetName: null,
      AssetID: null,
      DepartmentName: null,
   };
  }

  __getGISAssets = async () => {
    try {
      const loggedUser = getLoggedInUser();
      const __user = await UserAPI.GetUser(loggedUser.id);

      const view = workorderService.mapView();
      var curr = new Date();
      //curr.setDate(curr.getDate() + 3);
      var workOrderDueDate = curr.toISOString().substr(0, 10);

      const user_role = workorderService.GetUserRole();
      const { data } = await CrudOperations.Get('GisConfiguration');
      const departmentData = await CrudOperations.Get('Departments');
      const GISServicesTypes = await CrudOperations.Get('Services');
      const GISProblemsTypes = await CrudOperations.Get('ProblemResolutions');

      const departments = departmentData.data;
      // Get departmentID
      const userDepartment = departments.find(
        ({ name }) => name === loggedUser.department
      );

      var __gis__assets = data;
      var __gis__services = GISServicesTypes.data;
      var __gis__problems = GISProblemsTypes.data.problems;
      var __gis__resolutions = GISProblemsTypes.data.resolutions;

      if (user_role === 'supervisor') {
        __gis__assets = __gis__assets.filter(
          (s) => s.departmentID === userDepartment.departmentID
        );

        if (__gis__assets.length < 1) {
          swal(
            `There are no asset defined for department ${loggedUser.department}. Please make sure to add GIS Services before creating work order.`
          );
          this.props.renderComponent('');
          return;
        }
        __gis__services = __gis__services.filter(
          (s) =>
            s.gisService.getGISService.departmentID ===
            userDepartment.departmentID
        );
        __gis__problems = __gis__problems.filter(
          (s) =>
            s.problemAsset.getGISService.departmentID ===
            userDepartment.departmentID
        );
        __gis__resolutions = __gis__resolutions.filter(
          (s) =>
            s.resolutionAsset.getGISService.departmentID ===
            userDepartment.departmentID
        );

      }
      const GISRestServicesOptions = [];
      // console.log(__gis__assets);
      __gis__assets.map((o) => {
        GISRestServicesOptions.push({
          value: o.gisServicesConfigurationID,
          label: o.name,
          users: o.gisServicesUsersConfigurations,
        });
      });

      this.setState({
        GISRestServices: __gis__assets,
        GISRestServicesOptions,
        GISServicesTypes: __gis__services,
        GISProblemsTypes: __gis__problems,
        GISResolutionsTypes: __gis__resolutions,
        departments,
        IsReccurrence: 'No',
        view,
        workOrderDueDate,
        User: __user.data,
      });

      setTimeout(() => {
  //      this.__configureServicesSelection();
        this.__configureServicesProblemsSelection();
      }, 500);
      return Promise.resolve(this.state.view)
    } catch (error) {
      return Promise.reject(error)
    }
  };

  __configureServicesSelection = () => {  //REPLACED WITH _configureServicesProblemsSelection 12/22/2022 DMM
    const GISServices = [];
    this.state.GISServicesType.map((s) => {
      GISServices.push({
        value: s.workServiceID,
        label: s.name,
        description: s.description,
        essentialQuestions: s.essentialQuestions,
      });
    });

    this.setState({
      GISServices,
    });
  };

  __configureServicesProblemsSelection = () => {
    const GISServices = [];
    const GISProblems = [];
    const GISResolutions = [];
    this.state.GISServicesType.map((s) => {
      GISServices.push({
        value: s.workServiceID,
        label: s.name,
        description: s.description,
        essentialQuestions: s.essentialQuestions,
      });
    });

    this.state.GISProblemsType.map((s) => {
      GISProblems.push({
        value: s.problemID,
        label: s.name,
        description: s.description,
      });
    });

    this.state.GISResolutionsType.map((s) => {
      GISResolutions.push({
        value: s.resolutionID,
        label: s.name,
        description: s.description,
      });
    });

    this.setState({
      GISServices,
      GISProblems,
      GISResolutions,
    });
  };
/*
  componentDidMount() {
    this.__getGISAssets();
    setTimeout(() => {
      this.__initAddressAndSketchModelView();
      this.__initWorkLogSteps();
    }, 500);
  }
*/
  componentDidMount() {
    this.__getGISAssets()
      .then(() => this.__initAddressAndSketchModelView())
      .then(() => this.__initWorkLogSteps());
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
    // destroy the draw tool
    if (this.state.SketchModel) {
      this.state.SketchModel.destroy();
    }
  }

  OnWorkTypeSelectionChange = (evt) => {
    //console.log(evt);

    //SelectedWorkType
    this.setState({ selectedWorkType: evt });
  };

  OnProblemTypeSelectionChange = (evt) => {
    //console.log(evt);

    //SelectedWorkType
    this.setState({ selectedProblemType: evt });
  };

  OnResolutionTypeSelectionChange = (evt) => {
    //console.log(evt);

    //SelectedWorkType
    this.setState({ selectedResolutionType: evt });
  };


  OnWorkUserAssignedSelectionChange = (evt) => {
    //SelectedUserAssignedTo
//    console.log(evt);
  };

  OnAssetSelectionChange = ({ label, value }) => {
    // value here is the gisServicesConfigurationID and label the name
    const selectedGISService = this.state.GISRestServices.find(
      (s) => s.gisServicesConfigurationID === value
    );

    const WorkAssignedToUser = null,
      WorkAssignedToUsersOptions = [];
    //console.log(selectedGISService);

    // update work type selection
    var GISServicesType = this.state.GISServicesTypes;
    var GISProblemsType = this.state.GISProblemsTypes;
    var GISResolutionsType = this.state.GISResolutionsTypes;

    GISServicesType = GISServicesType.filter(
      (t) => t.gisService.gisServicesConfigurationID === value
    );

    GISProblemsType = GISProblemsType.filter(
      (t) => t.problemAsset.gisServicesConfigurationID === value
    );

    GISResolutionsType = GISResolutionsType.filter(
      (t) => t.resolutionAsset.gisServicesConfigurationID === value
    );

    // update users choices
    if (selectedGISService.gisServicesUsersConfigurations.length > 0) {
      selectedGISService.gisServicesUsersConfigurations.map((u) => {
        const __user = u.user;
        WorkAssignedToUsersOptions.push({
          value: __user.id,
          label: `${__user.firstName} ${__user.lastName} - ${u.userWorkSummary.activeWorkOrders}`,
          department: __user.department,
        });
      });

      setTimeout(() => {
        this.setState({ WorkAssignedToUsersOptions });
      }, 50);
    }

    this.setState({
      GISServicesType,
      GISProblemsType,
      GISResolutionsType,
      SelectedGISAsset: selectedGISService,
      selectedGISAsset: {
        label: selectedGISService.name,
        value: selectedGISService.gisServicesConfigurationID,
      },
    });
    setTimeout(() => {
//      this.__configureServicesSelection();
      this.__configureServicesProblemsSelection();
    }, 500);
  };

  __initWorkLogSteps = () => {         
    const { feature } = this.props;
    const GISAssets = [];
    if (feature) {
      if (feature.hasOwnProperty('gisAsset') && feature.gisAsset !== null) {
        GISAssets.push(feature.gisAsset);
        const assetDepartment = workorderService
          .GetApplicationDepartments()
          .find((d) => d.departmentID === feature.gisAsset.departmentID); 
        if (GISAssets.length > 0) {
          setTimeout(() => {
            this.setState({
              GISAssets,
              workAssetDetails:
                GISAssets.length > 0
                  ? `for asset ${GISAssets[0].facilityID}`
                  : '',
              departmentID: assetDepartment.departmentID,
            });
            this.handleWorkCreationFromURL(feature.feature.geometry);

            // set selectedAsset
            const userDefaultAsset = this.state.GISRestServices.find(
              (a) => a.name === feature.gisAsset.sourceLayer
            );
            this.setState({
              selectedGISAsset: {
                label: userDefaultAsset.name,
                value: userDefaultAsset.gisServicesConfigurationID,
              },
            });
            setTimeout(() => {
              const { label, value } = this.state.selectedGISAsset;
              this.OnAssetSelectionChange({ label, value });
            }, 200);
          }, 200);
        }
      }
    }
  };

__getCaseSensitiveAttributeName = (fieldAliases, fieldName) => {
    for (var key in fieldAliases) {  
      if (key.toUpperCase() === fieldName.toUpperCase()){
        return key;
        }
    }
    return "";
  };

  __getDepartments = () => {
    CrudOperations.Get('Departments')
      .then((res) => {
        //console.log(res);
        var departmentOptions = this.state.departmentOptions;
        res.data.map((d) => {
          departmentOptions.push({
            value: d.departmentID,
            label: d.name,
          });
        });
        this.setState({ departments: res.data, departmentOptions });
        const GISAssets = this.state.GISAssets;
        const { feature } = this.props;
        this.__initAddressAndSketchModelView();
        // set default asset
        if (feature) {
          // check for gisAsset proporty
          if (
            feature.hasOwnProperty('gisAsset') &&
            feature.gisAsset !== null
          ) {
//            console.log(feature);
            GISAssets.push(feature.gisAsset);
            const assetDepartment = this.state.departments.find(
              (d) => d.name === feature.DepartmentName
            );
            this.__GetUsersAndServicesByDepartment(assetDepartment.name);
            this.__GetProblemsByDepartment(assetDepartment.name);

            if (GISAssets.length > 0) {
              setTimeout(() => {
                this.setState({
                  GISAssets,
                  workAssetDetails:
                    GISAssets.length > 0
                      ? `for asset ${GISAssets[0].facilityID}`
                      : '',
                  departmentID: assetDepartment.departmentID,
                  selectedDepartment: {
                    value: assetDepartment.departmentID,
                    label: assetDepartment.name,
                  },
                });
                this.handleWorkCreationFromURL(feature.feature.geometry);
              }, 200);
            }
          }
        }
      })
      .catch((err) => {
        console.log('Error fetching departments :: ', err);
      });
  };

  __GetUsersAndServicesByDepartment = (department) => {
    const dep = this.state.departments.find((d) => d.name === department);
    CrudOperations.GetServicesByDepartment(dep.departmentID).then((assets) => {
      var GISServices = this.state.GISServices;
      GISServices = [];
      assets.data.map((s, index) => {
        GISServices.push({
          value: s.workServiceID,
          label: s.name,
          departmentID: s.departmentID,
          description: s.description,
          essentialQuestions: s.essentialQuestions,
        });
      });
      this.setState({
        GISServices,
        selectedDepartment: { value: dep.departmentID, label: dep.name },
        departmentID: dep.departmentID,
      });
      this.__getUsersByDepartmentWithActiveWorkTally(department);
    });
  };

  __GetProblemsByDepartment = (department) => {
    const dep = this.state.departments.find((d) => d.name === department);
    CrudOperations.GetServicesByDepartment(dep.departmentID).then((assets) => {
      var GISServices = this.state.GISServices;
      GISServices = [];
      assets.data.map((s, index) => {
        GISServices.push({
          value: s.workServiceID,
          label: s.name,
          departmentID: s.departmentID,
          description: s.description,
          essentialQuestions: s.essentialQuestions,
        });
      });
      this.setState({
        GISServices,
        selectedDepartment: { value: dep.departmentID, label: dep.name },
        departmentID: dep.departmentID,
      });
      this.__getUsersByDepartmentWithActiveWorkTally(department);
    });
  };

  __toggleCreationLoader = () => {
    const __loaders = document.getElementsByClassName('work-create-loading');
    for (let i = 0; i < __loaders.length; i++) {
      if (__loaders[i].classList.contains('work-creation-loader')) {
        __loaders[i].classList.remove('work-creation-loader');
      } else {
        __loaders[i].classList.add('work-creation-loader');
      }
    }
  };

  renderWorkAssigned() {
    if (this.state.isWorkAssigned) {
      return (
        <Fragment>
          <label className="esri-feature-form__label">Assign work to:</label>
          <Select
            className="react-select"
            classNamePrefix="react-select"
            onChange={(e) => this.__handleAssignWorkToChange(e)}
            options={this.state.GISUsers}
          ></Select>
          <small className="form-text text-muted mb-2">
            Please provide work responsible.
          </small>
        </Fragment>
      );
    }
  }

  __toggleLocationActiveStatus = () => {
    const _btn_Location = document.getElementById('btn-location-getter');
    _btn_Location.classList.toggle('map-click-location');
  };

  toggleLocationBtn = (e) => {
    e.preventDefault();
    this.__toggleLocationActiveStatus();
    setTimeout(() => {
      const _btn_Location = document.getElementById('btn-location-getter');
      if (_btn_Location.classList.contains('map-click-location')) {
        document.getElementById('mapId').style.cursor = 'crosshair';
        this.__handleMapClickedForLocation();
      }
    }, 10);
  };

  __handleNewWorkLocationAndAddress = (locationPoint) => {
    const view = workorderService.mapView();
    // show event creation loader
    this.__toggleCreationLoader();
    workorderService
      .GetAddressFromLocation(view, locationPoint)
      .then((geocoderresult) => {
        const { Address } = geocoderresult.data.address;
        this.setState({
          NewWorkOrderAddress: Address,
          IsNewWorkAttributesVisible: true,
        });
        view.popup.close();
        setTimeout(() => {
          this.__toggleCreationLoader();
        }, 25);
      });
  };

  handleWorkCreationFromURL = (locationPoint) => {
    const workLogLocationGL = this.state.view.map.findLayerById(
      'workLogLocationGL'
    );
    workLogLocationGL.removeAll();
    loadModules(['esri/Graphic']).then(([Graphic]) => {
      this.__toggleCreationLoader();
      const view = workorderService.mapView();
      workorderService.GetAddressFromLocation(view).then((geocoderresult) => {
        const { Address } = geocoderresult.data.address;

        this.setState({
          NewWorkOrderAddress: Address,
          IsNewWorkAttributesVisible: true,
          NewWorkOrderGraphic: new Graphic(view.popup.location),
        });

        var marker = {
          type: 'simple-marker',
          outline: { width: 2, color: [253, 51, 51, 1] },
          size: 25,
          color: [224, 95, 95, 0.25],
        };

        var gr = new Graphic(view.popup.location, marker);
        workLogLocationGL.add(gr);
        this.state.SketchModel.update(gr);

        view.popup.close();
        setTimeout(() => {
          this.__toggleCreationLoader();
        }, 25);
      });
    });
  };

  __handleMapClickedForLocation = () => {
    const { view } = this.state;
    const handler = view.on('click', (event) => {
      handler.remove();
      event.stopPropagation();

      if (event.mapPoint) {
        let point = event.mapPoint.clone();
        point.z = undefined;
        point.hasZ = false;

        //this.__handleNewWorkLocationAndAddress(point);
      } else {
        console.error('event.mapPoint is not defined');
      }
    });
  };

  __SelectUserConfiguredTopAsset = () => {    //THIS CODE IS NO LONGER REFERENCED - CHANGED BY DMM 10/27/2022 FOR TRYING TOO HARD!
    if (this.state.GISRestServices.length > 0) {
      // check for selected Service
      var __settingsObj = workorderService.GetUserAppSettings();
      if (__settingsObj === '' || __settingsObj === null) {
        // select top first
        const defaultAsset = this.state.GISRestServices[0];
        this.setState({
          selectedGISAsset: {
            label: defaultAsset.name,
            value: defaultAsset.gisServicesConfigurationID,
          },
        });
      } else {
        // select user top layer
        const userDefaultAsset = this.state.GISRestServices.find(
          (a) => a.gisServicesConfigurationID === __settingsObj.mostTopLayerID
        );
        this.setState({
          selectedGISAsset: {
            label: userDefaultAsset.name,
            value: userDefaultAsset.gisServicesConfigurationID,
          },
        });
      }

      setTimeout(() => {
        const { label, value } = this.state.selectedGISAsset;
        this.OnAssetSelectionChange({ label, value });
      }, 200);
    }
  };

  _renderServicesOptions = () => {
    return (
      <Fragment>
        {this.state.GISServices.map((s, index) => {
          return (
            <option key={index + 1} value={`${s.value}:${s.label}`}>
              {s.label}
            </option>
          );
        })}
      </Fragment>
    );
  };

  _renderProblemsOptions = () => {
    return (
      <Fragment>
        {this.state.GISProblems.map((s, index) => {
          return (
            <option key={index + 1} value={`${s.value}:${s.label}`}>
              {s.label}
            </option>
          );
        })}
      </Fragment>
    );
  };

  _renderResolutionsOptions = () => {
    return (
      <Fragment>
        {this.state.GISResolutions.map((s, index) => {
          return (
            <option key={index + 1} value={`${s.value}:${s.label}`}>
              {s.label}
            </option>
          );
        })}
      </Fragment>
    );
  };

  _renderUsersOptions = () => {
    return (
      <Fragment>
        {this.state.GISUsers.map((u, index) => {
          return (
            <option key={index + 1} value={`${u.value}:${u.label}`}>
              {u.label}
            </option>
          );
        })}
      </Fragment>
    );
  };

  __initAddressAndSketchModelView = () => {
    const view = workorderService.mapView();
    loadModules([
      'esri/widgets/Sketch/SketchViewModel',
      'esri/widgets/Sketch',
      'esri/widgets/Search',
      'esri/Graphic',
    ]).then(([SketchViewModel, Sketch, Search, Graphic]) => {
      const workLogLocationGL = view.map.findLayerById(
        'workLogLocationGL'
      );

      var marker = {
        type: 'simple-marker',
        outline: { width: 2, color: [253, 51, 51, 1] },
        size: 25,
        color: [224, 95, 95, 0.25],
      };

      view.when(() => {
        const sketch = new SketchViewModel({
          view,
          layer: workLogLocationGL,
          pointSymbol: marker,
          updateOnGraphicClick: true,
          snappingOptions: {
            enabled: false,
          },
        });

        sketch.on('create', (e) => {
          if (e.graphic) {
            e.graphic.symbol = marker;
            if (e.state.toLowerCase() === 'complete') {
              this.setState({
                WorkOrderGeometry: e.graphic.geometry,
                NewWorkOrderGraphic: new Graphic(e.graphic.geometry),
                sketchDisplay: 'none',
              });
              this.__handleNewWorkLocationAndAddress(
                this.state.WorkOrderGeometry
              );
              //setTimeout(() => {
                //console.log(this.state.selectedDepartment);
                /* this.__GetUsersAndServicesByDepartment(
                  this.state.selectedDepartment.label
                ); */
                // set default asset
                
/*                setTimeout(() => {  .//REMOVED BY DMM 10/27/2022
                  this.__SelectUserConfiguredTopAsset();
              }, 50);  */

            }
          }
          //sketch.create('point');
        });

        sketch.on('update', (e) => {
          this.setState({
            WorkOrderGeometry: e.graphics[0].geometry,
            NewWorkOrderGraphic: new Graphic(e.graphics[0].geometry),
          });
        });

        // search
        const locationSearch = new Search({
          view: view,
          container: 'sketch-address-search',
          allPlaceholder: 'Find work order address',
        });

        locationSearch.on('select-result', (evt) => {
          workLogLocationGL.removeAll();
          const { Address, StAddr } = evt.result.target.attributes;
          this.setState({
            WorkOrderGeometry: evt.result.target.geometry.centroid,
            NewWorkOrderAddress: StAddr,
            IsNewWorkAttributesVisible: true,
            NewWorkOrderGraphic: new Graphic(
              evt.result.target.geometry.centroid
            ),
            sketchDisplay: 'none',
          });

          var gr = new Graphic(this.state.WorkOrderGeometry, marker);
          workLogLocationGL.add(gr);
          sketch.update(gr);
        });

        setTimeout(() => {
          let sketchDisplay =
            this.state.GISAssets.length > 0 ? 'none' : 'block';
          this.setState({
            SketchModel: sketch,
            isUILoading: false,
            sketchDisplay,
          });
        }, 200);
      });

      /* const sketch = new Sketch({
        id: 'sketch-work-create',
        layer: workLogLocationGL,
        view: view,
        container: 'sketch-view-model',
        availableCreateTools: ['point'],
        creationMode: 'update',
        pointSymbol: marker,
        visibleElements: {
          selectionTools: {
            'lasso-selection': false,
            'rectangle-selection': false,
          },
          settingsMenu: false,
          undoRedoMenu: false,
        },
      }); */
    });
  };

  SetWorkType = (e) => {
    const { departmentID, label, value } = e;
    const dept = this.state.departments.find(
      (d) => d.departmentID === departmentID
    );
    this.setState({
      selectedDepartment: { value: departmentID, label: dept.name },
      selectedWorkType: e,
    });
    this.__getUsersByDepartmentWithActiveWorkTally(dept.name);
  };

  
  __getUsersByDepartmentWithActiveWorkTally = (department) => {
    UserAPI.GetUsersByDepartment(department).then((users) => {
      // populate users from same department
      let GISUsers = [];
      users.data.map((user, index) => {
        workorderService.GetUserWorkSummary(user.id).then((summary) => {
          const { totalWorkOrders, activeWorkOrders, unassignedWorkOrders } =
            summary.data;

          GISUsers.push({
            value: user.id,
            label: `${user.firstName} ${user.lastName} - ${activeWorkOrders}`,
            department: user.department,
          });
          this.setState({
            GISUsers,
          });
        });
      });
    });
  };

  SetWorkAssignedTo = (e) => {
    const { value, label } = e;
    this.setState({ workAssignedToUserObj: { value, label } });
  };
  __UpdateUsersAndServicesByDepartment = (e) => {
    //console.log(e);
    /* this.setState({
      selectedDepartment: { value: e.value, label: e.label },
      departmentID: e.value,
    }); */
    // reload users for selected department
    //this.__getUsersByDepartmentWithActiveWorkTally(e.label);
    this.__GetUsersAndServicesByDepartment(e.label);
    // reload worktypes for selected department
  };

  CancelWorkCreation = () => {
    const workLogLocationGL = this.state.view.map.findLayerById(
      'workLogLocationGL'
    );
    workLogLocationGL.removeAll();
    workorderService.SetAppInAddingAssets(false);

    this.state.view.graphics.removeAll();
    this.props.renderComponent('');
  };

  __createNewWork = () => {
    const {
      view,
      departments,
      selectedWorkType,
      selectedProblemType,
      selectedResolutionType,
      SelectedGISAsset,
      workAssignedToUserObj,
      NewWorkOrderAddress,
      workOrderDueDate,
      workDescription,
      User,
      NewWorkOrderGraphic,
    } = this.state;
    workorderService.SetAppInAddingAssets(false);

    if (NewWorkOrderAddress === '') {
      swal('Please provide required location address');
      return;
    }
    if (SelectedGISAsset === null) {
      swal('Please select an asset');
      return;
    }
    if (selectedWorkType === null) {
      swal('Please select work type');
      return;
    }
    if (workDescription === '') {
      swal('Please provide required description for this work.');
      return;
    }
    this.setState({ isSubmittingWork: true });
    const workLogLocationGL = view.map.findLayerById('workLogLocationGL');

    const __IsWorkAssigned = workAssignedToUserObj === null ? false : true;

    const __workAssignedTo = __IsWorkAssigned
      ? workAssignedToUserObj.label.split('-')[0].trim()
      : 'Unassigned';

    var selectedDate = new Date(workOrderDueDate).toGMTString();
    var __esri_date = selectedDate;

    const department = departments.find(
      (d) => d.departmentID === SelectedGISAsset.departmentID
    );

    const attributes = {
      OriginatorID: `${User.firstName} ${User.lastName}`, //`${this.state.User.id}`,
      OriginatorUID: `${User.id}`,
      WOID: 0,
      AssignedToID: `${User.firstName} ${User.lastName}`,
      AssignedToUID: `${User.id}`,
      Status: 'Completed',
      DateInitiated: workorderService.__getEpochTime(new Date().getTime()),
      DateCompleted: workorderService.__getEpochTime(
        new Date(__esri_date).getTime()
      ),
      LocationAddress: NewWorkOrderAddress,
      Description: 'Logged Completed Work',
      WorkType: selectedWorkType.label,
      FieldNotes: workDescription,
      PlannedDate: workorderService.__getEpochTime(
        new Date(__esri_date).getTime()
      ),
      Recurrence: null,
      NextDueDate: null,
      IsRecurring: 'No',
      AssetName: SelectedGISAsset.name,
      AssetID: SelectedGISAsset.gisServicesConfigurationID,
      ProblemName: selectedProblemType === null ? '' : selectedProblemType.label,
      ResolutionName: selectedResolutionType === null ? '' : selectedResolutionType.label,
      ProblemID: selectedProblemType === null ? 0 : selectedProblemType.value,
      ResolutionID: selectedResolutionType === null ? 0 : selectedResolutionType.value,
      IsWorkAssigned: 1,
      workServiceID: selectedWorkType.value,
      DepartmentID: department.departmentID,
      DepartmentName: department.name,
    };

    let featureGraphique = NewWorkOrderGraphic;
    featureGraphique.attributes = attributes;

    const edits = {
      addFeatures: [featureGraphique],
    };
    workorderService.ApplyWorkOrderEdits(edits).then((res) => {
      if (res.addFeatureResults.length > 0) {
        //[2] Add created work to backend server
        var workOrderQuestionAnswers = [];

/*        if (selectedWorkType.essentialQuestions.length > 0) {
          for (
            let index = 0;
            index < selectedWorkType.essentialQuestions.length;
            index++
          ) {
            const question = selectedWorkType.essentialQuestions[index];
            workOrderQuestionAnswers.push({
              workServiceID: selectedWorkType.value,
              essentialQuestionID: question.essentialQuestionID,
              answer: 'Details required',
            });
          }
        }  */

        const _workOrderObj = {
          globalID: res.addFeatureResults[0].globalId,
          workCreatedBy: attributes.OriginatorID,
          originatorUID: attributes.OriginatorUID,
          workAssignedTo: attributes.AssignedToID,
          assignedToUID: attributes.AssignedToUID,
          status: attributes.Status,
          DateCompleted: new Date(workOrderDueDate),
          LocationAddress: attributes.LocationAddress,
          Description: attributes.Description,
          workOrderType: attributes.WorkType,
          FieldNotes: attributes.FieldNotes,
          plannedDate: new Date(workOrderDueDate),
          recurrence: null,
          nextDueDate: new Date(),
          isRecurring: attributes.IsRecurring,
          AssetName: attributes.AssetName,
          AssetID: attributes.AssetID,
          ProblemName: selectedProblemType === null ? '' : selectedProblemType.label,
          ResolutionName: selectedResolutionType === null ? '' : selectedResolutionType.label,
          ProblemID: selectedProblemType === null ? 0 : selectedProblemType.value,
          ResolutionID: selectedResolutionType === null ? 0 : selectedResolutionType.value,
          isWorkAssigned: false,
          DepartmentName: attributes.DepartmentName,
          departmentID: department.departmentID,
          workServiceID: selectedWorkType.value,
          workOrderGISAsset: {
            gisServicesConfigurationID:
              SelectedGISAsset.gisServicesConfigurationID,
          },
          gisAssets: this.state.GISAssets,
          workOrderQuestionAnswers: workOrderQuestionAnswers,
        };


        workorderService
          .CreateServerWorkOrder(_workOrderObj)
          .then(async (serverResponse) => {
            // update the feature class with woid id
            // [1] query the new created feature using the globalID => res.addFeatureResults[0].globalId
            const __newly_created_feature =
              await workorderService.SelectFeatureByGlobalID(
                res.addFeatureResults[0].globalId
              );
            let __new__feature = __newly_created_feature.features[0];

            // update the work orderID with ID from SQL Server => serverResponse.data.workOrderID
            Object.keys(__new__feature.attributes).map((fieldName) => {
              switch (fieldName) {
                case 'WOID':
                  __new__feature.attributes[fieldName] =
                    serverResponse.data.workOrderID;
                  break;
              }
            });
            // make the new GIS feature object
            const __update__feature__class = {
              updateFeatures: [__new__feature],
            };

            // call the update attributes
            workorderService
              .ApplyWorkOrderEdits(__update__feature__class)
              .then((res) => {
                workLogLocationGL.removeAll();
                this.state.view.graphics.removeAll();
                this.props.renderComponent('');
              });
          })
          .catch((err) =>
            console.log('Error creating server work order :: ', err)
          );
      }
    });
  };

  handleAddWorkLocationOnMap = () => {
    var IslocationBtnActive = this.state.locationBtnActive;
    this.setState({ locationBtnActive: !IslocationBtnActive });

    setTimeout(() => {
      if (this.state.locationBtnActive) {
        this.state.SketchModel.create('point');
      } else {
        this.state.SketchModel.cancel();
      }
    }, 20);
  };

  render() {
    return (
      <Fragment>
        <Card style={{ height: '100%' }}>
          <CardHeader style={{ padding: '0' }}>
            <div className="flex-row">
              <div
                className="box box--start"
                style={{ textAlign: 'left', fontSize: '1.6rem' }}
              >
                {this.state.IsNewWorkAttributesVisible && (
                  <>
                    <button
                      type="button"
                      id="btn-sumbit-work-completion"
                      className="btn btn-success mr-2 ml-2"
                      disabled={this.state.isSubmittingWork}
                      style={{ marginTop: '-5px' }}
                      onClick={this.__createNewWork}
                    >
                      {this.state.isSubmittingWork && (
                        <Spinner
                          className="spinner-border-sm"
                          tag="span"
                          color="white"
                        />
                      )}
                      {!this.state.isSubmittingWork && (
                        <i
                          className="uil uil-check"
                          style={{ fontSize: '1.2rem' }}
                        ></i>
                      )}
                    </button>
                    <UncontrolledTooltip
                      placement="top"
                      target="btn-sumbit-work-completion"
                    >
                      Log Work
                    </UncontrolledTooltip>
                  </>
                )}
                <span>Log</span>
              </div>
              <div className="box box--end">
                <button
                  type="button"
                  id="btn-cancel-work"
                  className="btn btn-danger"
                  style={{ marginRight: '-60px' }}
                  onClick={this.CancelWorkCreation}
                >
                  <i
                    className="uil uil-multiply"
                    style={{ fontSize: '1.2rem' }}
                  ></i>
                </button>
                <UncontrolledTooltip placement="top" target="btn-cancel-work">
                  Cancel
                </UncontrolledTooltip>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div
              id="layerInfoContentDisplay"
              style={__listPanelContentScroller}
            >
              <div style={{ margin: '5px 10px' }}>
                {this.state.isUILoading && (
                  <div style={{ textAlign: 'center', marginTop: '45px' }}>
                    <Spinner className="m-2" color={'info'} size={'sm'} />
                  </div>
                )}

                <div style={{ display: this.state.sketchDisplay }}>
                  <h4>Work order location</h4>
                  <div className="row">
                    <div className="col col-lg-2">
                      <div>
                        <button
                          className={`btn btn-lg btn-light mb-2 ${
                            this.state.locationBtnActive
                              ? 'work-location-btn-active'
                              : ''
                          }`}
                          id="workLocationBtn"
                          type="button"
                          title="Draw point on map"
                          onClick={() => this.handleAddWorkLocationOnMap()}
                        >
                          <i className="dripicons-location"></i>
                        </button>
                      </div>
                      {/* <div id="sketch-view-model"></div> */}
                    </div>
                    <div className="col-md-auto">
                      <span className="text-muted">
                        Click on the location icon and then click on the desired
                        location on the map...
                      </span>
                    </div>
                  </div>
                  <p className="mt-2 mb-2 text-muted">...or enter address</p>
                  <div id="sketch-address-search"></div>
                </div>

                {this.state.IsNewWorkAttributesVisible && (
                  <Fragment>
                    <div id="attributeArea">
                      <div className="esri-feature-form__form-header">
                        <p
                          className="esri-feature-form__description-text"
                          style={{ fontSize: '1rem' }}
                        >
                          Please provide work information{' '}
                          {this.state.workAssetDetails}
                        </p>
                        {this.state.isAddingMoreAssets && (
                          <div>
                            <span
                              className="text-info"
                              style={{ fontSize: '0.8rem' }}
                            >
                              add assets from the map and they will be displayed
                              below:
                            </span>
                            <div>
                              <ol>
                                {this.state.GISAssets.map((asset, index) => (
                                  <li key={index}>
                                    {asset.facilityID} -{' '}
                                    {asset.assetName.split('|')[0]}
                                  </li>
                                ))}
                              </ol>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* Work order form */}
                      <form
                        id="create-work-order"
                        className="g-3 needs-validation"
                        noValidate
                      >
                        <div className="mb-2">
                          <label htmlFor="inputAddress" className="form-label">
                            Address
                          </label>
                          <input
                            type="text"
                            value={this.state.NewWorkOrderAddress}
/*                            onChange={(e) =>
                              this.setState({
                                NewWorkOrderAddress: e.target.value,
                              })
                            } */
                            className="form-control"
                            id="inputAddress"
                            disabled
                          />
                        </div>

                        <div className="mb-2">
                          <label htmlFor="inputDepartment" className="form-label">
                            Asset
                          </label>
                          <input
                            type="text"
                            value={this.state.selectedGISAsset.label}
                            className="form-control"
                            id="inputDepartment"
                            disabled
                          />
                        </div>

                        <div className="mb-2">
                          <label htmlFor="inputWorkType" className="form-label">
                            Work Type
                          </label>
                          <Select
                            id="inputWorkType"
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={(e) => this.OnWorkTypeSelectionChange(e)}
                            options={this.state.GISServices}
                          ></Select>
                          <div className="invalid-feedback">
                            Please select work type
                          </div>
                        </div>

                        <div className="mb-3">
                          <label htmlFor="inputDueDate" className="form-label">
                            Date Completed
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="inputDueDate"
                            value={this.state.workOrderDueDate}
                            onChange={(e) =>
                              this.setState({
                                workOrderDueDate: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="mb-2">
                          <label
                            htmlFor="inputWorkDescription"
                            className="form-label"
                          >
                            Field Notes
                          </label>
                          <textarea
                            className="form-control"
                            id="inputWorkDescription"
                            rows="3"
                            value={this.state.workDescription}
                            onChange={(e) =>
                              this.setState({ workDescription: e.target.value })
                            }
                            required
                          ></textarea>
                          <div className="invalid-feedback">
                            Please provide required field notes.
                          </div>
                        </div>

                        <div className="mb-2">
                          <label htmlFor="inputProblemType" className="form-label">
                            Problem Type
                          </label>
                          <Select
                            id="inputProblemType"
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={(e) => this.OnProblemTypeSelectionChange(e)}
                            options={this.state.GISProblems}
                          ></Select>
                          <div className="invalid-feedback">
                            Please select Problem type
                          </div>
                        </div>

                        <div className="mb-2">
                          <label htmlFor="inputResolutionType" className="form-label">
                            Resolution Type
                          </label>
                          <Select
                            id="inputResolutionType"
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={(e) => this.OnResolutionTypeSelectionChange(e)}
                            options={this.state.GISResolutions}
                          ></Select>
                          <div className="invalid-feedback">
                            Please select Resolution type
                          </div>
                        </div>
                      </form>
                    </div>
                  </Fragment>
                )}

                {/* {this.renderNewWorkOrderUI()} */}
              </div>
            </div>
          </CardBody>
          <CardFooter style={_btnFooter}>
            You are in the process of logging completed work
          </CardFooter>
        </Card>
      </Fragment>
    );
  }
}

export default LogWork;
