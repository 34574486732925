import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  UncontrolledCarousel,
  UncontrolledTooltip,
} from 'reactstrap';
import swal from 'sweetalert';
import notFoundImg from '../../../assets/images/file-searching.svg';
import { GlobalFormatter } from '../../../services/CRUDoperations.service';
import { workorderService } from '../../../services/workorder.service';
import WorkDetails from '../Details/WorkDetails';
import './../wo.details.css';

const __stylePanelHeader = {
  position: 'relative',
  height: '70px',
  backgroundColor: '#313a46',
};

const __listPanelContentScroller = {
  position: 'absolute',
  top: '70px',
  left: '0',
  right: '0',
  bottom: '65px',
  overflow: 'auto',
};

const __workOrders_total = {
  position: 'absolute',
  top: '15px',
  left: '15px',
  fontSize: '1.8rem',
  fontWeight: '400',
  color: '#afadad',
};

const _btnFooter = {
  backgroundColor: '#a4a3a4',
};

class WorkOrderOfficeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      activeTab: '1',
      isReclassifyingWork: false,
      workStatus: this.props.workorder.attributes.Status,
      unassignedWorkStatus: 'Red',
      isClaimingWork: false,
      selectedWork: this.props.workorder,
      GISAssets: [],
      GISResources: [],
      essentialQuestions: [],
      GISAttachments: [],
      workOrderID: 0,
      isDeleteEnabled: false,  //enable / disable "DELETE" for the application
    };
    this.toggle = this.toggle.bind(this);
    this.openModalWithHeaderClass = this.openModalWithHeaderClass.bind(this);
    this.openModalWithBodyClass = this.openModalWithBodyClass.bind(this);
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  openModalWithHeaderClass = (className) => {
    this.setState({ headerClassName: className });
    this.toggle();
  };
  openModalWithBodyClass = (className) => {
    this.setState({ headerClassName: className, bodyClass: className });
    this.toggle();
  };
  componentDidMount() {
//    this.state.GISAssets.length=0;
//    this.state.GISResources.length=0; 
    const { geometry, attributes } = this.state.selectedWork;
    // get Work order details
    this.setWorkDeleteAvailability(attributes.DateInitiated);
    workorderService
      .GetManagedOrderDetails(attributes.GlobalID)
      .then((results) => {
        if (results.data === '') {
        } else {
          const {
            gisAssets,
            workOrderResources,
            workServiceID,
            workOrderQuestionAnswers,
            workOrderID,
          } = results.data;

          this.setState({
            GISAssets: gisAssets,
            GISResources: workOrderResources,
            essentialQuestions: workOrderQuestionAnswers,
            workOrderID,
          });
        }
      })
      .catch((err) => console.log(`Error fetching order details :: ${err}`));

    workorderService
      .GetWorkOrderAttachments(attributes.OBJECTID)
      .then((resp) => {
        if (resp.data.attachmentInfos.length > 0) {
          let GISAttachments = [];
          const _attachments = resp.data.attachmentInfos;
          _attachments.map((attachment, index) => {
            GISAttachments.push({
              src: `${process.env.REACT_APP_WOC_BASE_SERVICE}FeatureServer/0/${attributes.OBJECTID}/attachments/${attachment.id}`,
              altText: '', //`Slide ${index}`,
              caption: '', //attachment.contentType,
              header: '', //attachment.name,
              key: index,
            });
          });

          this.setState({ GISAttachments });
        }
      })
      .catch((err) => console.log('Error fetching attachments :: ', err));

    // const view = workorderService.mapView();
    // workorderService.ZoomToSelectedWork(geometry, view);
    // workorderService.__flushSelectedWork(geometry, view);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  setWorkDeleteAvailability = (initiatedDate) => {
    this.setState({
      isDeleteEnabled:
        workorderService.IsDeleteWorkOptionAvailable(initiatedDate),
    });
  };

  __getHumanDate = (_d) => {
    return new Date(_d).toLocaleDateString();
  };

  __getWorkStatus = () => {
    const { workStatus } = this.state;
    return workorderService.renderStatus(workStatus);
  };

  __getResourcesTotalCost = () => {
    let cost = 0;
    this.state.GISResources.map((r) => {
//      cost = cost + r.unit * r.resource.rate;  // DMM 12/9/2022
      cost = cost + r.unit * r.rate;
    });

    return GlobalFormatter.FormatUSD(cost);
  };

  _renderAssets() {
    if (this.state.GISAssets.length > 0) {
      return (
        <div className="mb-2 p-2">
          <ul className="list-group">
            <li
              className="list-group-item active"
              style={{
                backgroundColor: '#6f7188 !important',
                borderColor: '#6f7188 !important',
              }}
            >
              <i className="dripicons-network-3 me-1"></i> GIS Assets{' '}
              <span style={{ float: 'right' }}>
                [ {this.state.GISAssets.length} ]
              </span>
            </li>
            {this.state.GISAssets.map((a, index) => (
              <li key={index} className="list-group-item">
                <p className="mb-0 pb-0">{a.assetName}</p>
                <p className="mb-0 pb-0">{a.facilityID}</p>
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <img src={notFoundImg} height="90" alt="" className="mt-4" />
          <h4 className="text-uppercase text-danger mt-3">
            No GIS Assets found
          </h4>
          <p className="text-muted mt-3">
            There are no gis assets linked to this work order.
          </p>
        </div>
      );
    }
  }
  _renderResources() {
    if (this.state.GISResources.length > 0) {
      return (
        <div style={{ overflowX: 'hidden' }}>
          <table className="mb-0 mt-2 table table-sm">
            <thead>
              <tr>
                <th style={{ minWidth: '200px' }}>Resource</th>
                <th>Rate</th>
                <th>Quantity</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {this.state.GISResources.map((r, index) => (
                <tr key={index}>
{/*                  <td>{r.resource.name}</td>
                  <td>{r.resource.rate}</td>
                  <td>{r.unit}</td>
              <td>{GlobalFormatter.FormatUSD(r.resource.rate * r.unit)}</td>  DMM 12/9/2022 */}
                  <td>{r.name}</td>
                  <td>{r.rate}</td>
                  <td>{r.unit}</td>
                  <td>{GlobalFormatter.FormatUSD(r.rate * r.unit)}</td>
                </tr>
              ))}
              <tr
                className="bg-primary text-white"
                style={{ fontWeight: 'bold' }}
              >
                <td>Total:</td>
                <td colSpan="3" className="text-right">
                  {this.__getResourcesTotalCost()}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <img src={notFoundImg} height="90" alt="" className="mt-4" />
          <h4 className="text-uppercase text-danger mt-3">
            No Resources found
          </h4>
          <p className="text-muted mt-3">
            There are no work resources linked to this work order.
          </p>
        </div>
      );
    }
  }
  _renderAttachments() {
    switch (this.state.GISAttachments.length) {
      case 0:
        return (
          <>
            <div className="text-center">
              <img src={notFoundImg} height="90" alt="" className="mt-4" />
              <h4 className="text-uppercase text-danger mt-3">
                No Attachments found
              </h4>
              <p className="text-muted mt-3">
                There are no attachments linked to this work order.
              </p>
            </div>
          </>
        );
        break;

      default:
        return (
          <>
            <div className="text-center" style={{ margin: '10px 1px' }}>
              <UncontrolledCarousel
                items={this.state.GISAttachments}
                indicators={this.state.GISAttachments.length < 2 ? false : true}
                controls={this.state.GISAttachments.length < 2 ? false : true}
              />
            </div>
          </>
        );
        break;
    }
  }

  _renderEssentialQuestions() {
    if (this.state.essentialQuestions.length > 0) {
      return (
        <Fragment>
          <ul className="conversation-list mt-2">
            {this.state.essentialQuestions.map((q, index) => (
              <Fragment key={index}>
                <li className="clearfix">
                  <div className="chat-avatar"></div>
                  <div className="conversation-text">
                    <div className="ctext-wrap">
                      <i>Question {index + 1}</i>
                      <p>{q.essentialQuestion.question}</p>
                    </div>
                  </div>
                </li>
                <li className="clearfix odd">
                  <div className="chat-avatar"></div>
                  <div className="conversation-text">
                    <div className="ctext-wrap">
                      <i>Answer {index + 1}</i>
                      <p>{q.answer}</p>
                    </div>
                  </div>
                </li>
              </Fragment>
            ))}
          </ul>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="text-center">
            <img src={notFoundImg} height="90" alt="" className="mt-4" />
            <h4 className="text-uppercase text-danger mt-3">
              No Questions found
            </h4>
            <p className="text-muted mt-3">
              There are no Essential Details linked to this work order.
            </p>
          </div>
        </Fragment>
      );
    }
  }

  __returnToWorkList = () => {
    var selectedWorkID = new URLSearchParams(window.location.search).get('id');
    if (selectedWorkID) {
      // remove url params
      this.props.history.push('/apps/workorders');
    }
    this.props.renderComponent('');
  };

  __deleteWorkOrder = (feature) => {
    swal({
      text: 'Are you sure to delete this work?',
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then((action) => {
      if (action) {
        //[1] delete feature from service
        const edits = {
          deleteFeatures: [feature],
        };

        workorderService.ApplyWorkOrderEdits(edits).then((result) => {
          //console.log(result);
          //[2] delete record from backend
          if (result.deleteFeatureResults.length > 0) {
            workorderService
              .DeleteWorkOrder(this.state.workOrderID)
              .then((response) => {
                //console.log(response);
                swal('Work order deleted successfully');
                this.__returnToWorkList();
              });
          }
        });
      }
    });
  };

  render() {
    const { attributes } = this.props.workorder;
    return (
      <Fragment>
        <Card style={{ height: '100%' }}>
          <CardHeader style={{ padding: '0' }}>
            <div className="flex-row">
              <div className="box box--start">ID: {attributes.WOID}</div>
              <div className="box box--end text-right">
                {this.state.isDeleteEnabled && (
                  <>
                    <button
                      type="button"
                      id="btn-delete-work"
                      className="btn btn-danger btn-lg mr-2"
                      onClick={() =>
                        this.__deleteWorkOrder(this.props.workorder)
                      }
                    >
                      <i className="dripicons-trash"></i>
                    </button>
                    <UncontrolledTooltip
                      placement="top"
                      target="btn-delete-work"
                    >
                      Delete work
                    </UncontrolledTooltip>
                  </>
                )}
                {this.props.workorder.attributes.Status !== 'Completed' && (
                  <>
                    <button
                      type="button"
                      id="btn-update-work"
                      className="btn btn-warning btn-lg"
                      onClick={() =>
                        this.props.renderComponent(
                          'FieldWorkUpdate',
                          {
                            feature: this.props.workorder,
                            isClaimedWork: false,
                          },
                          this.props.user
                        )
                      }
                    >
                      <i className="dripicons-pencil"></i>
                    </button>
                    <UncontrolledTooltip
                      placement="top"
                      target="btn-update-work"
                    >
                      Update work
                    </UncontrolledTooltip>
                  </>
                )}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div
              id="layerInfoContentDisplay"
              style={__listPanelContentScroller}
            >
              <WorkDetails
                workorder={this.props.workorder}
                goBack={this.__returnToWorkList}
              />
            </div>
          </CardBody>
          <CardFooter style={_btnFooter}>
            {/* <Button color={'primary'}>Complete</Button>
                        <Button color={'info'}>Follow up</Button> */}
            <Button
              color={'secondary'}
              onClick={() => this.__returnToWorkList()}
            >
              Return
            </Button>
          </CardFooter>
        </Card>
      </Fragment>
    );
  }
}

export default withRouter(WorkOrderOfficeDetails);
