import React from 'react';
import { Link } from 'react-router-dom';
import notFoundImg from '../../../assets/images/file-searching.svg';

const NoVideo = () => {
    return (
        <>
            <div className="text-center">
                <img src={notFoundImg} height="90" alt="" className="mt-4" />
                {/* <h3 className="text-error mt-4">404</h3> */}
                <h4 className="text-uppercase text-danger mt-3">
                    No CCTV found
                </h4>
                <p className="text-muted mt-3">
                    There are no CCTV at this time.
                </p>
                <div className="cctv-anoucement text-danger text-left">You may activate the sewer main from the utility toggle, click on a main to identify it then
                    click on "CCTV video" button. If videos are available for the selected main, they will display here </div>

                <Link className="btn btn-info mt-3" to="/">
                    <i className="mdi mdi-reply"></i> Return Home
                </Link>
            </div>
        </>
    )
}

export default NoVideo;
