import React, { Component, Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { getLoggedInUser } from '../../../helpers/authUtils';
import { CrudOperations } from '../../../services/CRUDoperations.service';
import { workorderService } from '../../../services/workorder.service';
import PageTitle from '../../PageTitle';
import WoProblems from '../../Shared/WoProblems';
import WoResolutions from '../../Shared/WoResolutions';
import Spinner from '../../Spinner';

const Entity = 'ProblemResolutions';

class ProblemResolutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Department: '',
      resources: [],
      departments: [],
      Problems: [],
      Resolutions: [],
      isLoading: true,
      newModal: false,
      existingModal: false,
      name: '',
      description: '',
      rate: 0,
      resourceID: 0,
      departmentID: 0,
      GISAssets: [],
      assetsOptions: [],
    };
    this.toggleNewModal = this.toggleNewModal.bind(this);
    this.toggleExistingModal = this.toggleExistingModal.bind(this);
  }

  /**
   * Toggles the new department modal
   */
  toggleNewModal = () => {
    this.setState((prevState) => ({
      newModal: !prevState.newModal,
    }));
  };

  toggleExistingModal = () => {
    this.setState((prevState) => ({
      existingModal: !prevState.existingModal,
    }));
  };

  __getGISAssets = async (department) => {
    const user_role = workorderService.GetUserRole();
    const { data } = await CrudOperations.Get('GisConfiguration');
    var __gis__assets = data;
    if (user_role === 'supervisor') {
      __gis__assets = __gis__assets.filter(
        (s) => s.departmentID === department.departmentID
      );
    }
    // console.log(__gis__assets);

    const { assetsOptions } = this.state;
    if (__gis__assets.length > 0) {
      __gis__assets.map((s) => {
        assetsOptions.push({
          value: s.gisServicesConfigurationID,
          label: s.name,
        });
      });
    }

    this.setState({ GISAssets: __gis__assets, assetsOptions });
  };

  componentDidMount() {
    const loggedUser = getLoggedInUser();
    CrudOperations.Get('Departments').then((res) => {
      const departmentIndex = res.data.findIndex(
        (d) => d.name === loggedUser.department
      );
      if (departmentIndex > -1) {
        const department = res.data[departmentIndex];
        this.__getGISAssets(department);
        this.setState({
          departments: res.data,
          Department: loggedUser.department,
          departmentID: department.departmentID,
        });
        this.__getProblemResolutions(department.departmentID);
      }
    });
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  __getProblemResolutions = (departmentID) => {
    const user_role = workorderService.GetUserRole();

    switch (user_role) {
      case 'manager':
        CrudOperations.Get(Entity)
          .then((res) => {
            const { problems, resolutions } = res.data;
            this.setState({
              Problems: problems,
              Resolutions: resolutions,
              isLoading: false,
            });
          })
          .catch((err) => {
            console.log(
              'Error fetching problems resolutions for manager :: ',
              err
            );
          });
        break;

      default:
        CrudOperations.GetProblemResolutionsByDepartment(departmentID)
          .then((res) => {
            const { problems, resolutions } = res.data;
            this.setState({
              Problems: problems,
              Resolutions: resolutions,
              isLoading: false,
            });
          })
          .catch((err) =>
            console.log('Error, enable to fetch problems resolution :: ', err)
          );
        break;
    }
  };

  newModel = () => {
    this.setState({
      newModal: true,
    });
  };

  EditResource = (resObj) => {
    const { resourceID, departmentID, name, rate } = resObj;

    this.setState({
      resourceID,
      departmentID,
      name,
      rate,
      existingModal: true,
    });
  };

  _renderDepartmentOptions() {
    return (
      <Fragment>
        {this.state.departments.map((d, index) => {
          return (
            <option
              key={`dept-${d.departmentID}`}
              value={d.departmentID}
            >
              {d.name}
            </option>  
        );
        })}
      </Fragment>
    );
  }

  __getDepartmentName = (departmentID) => {
    const deptIndex = this.state.departments.findIndex(
      (d) => d.departmentID === departmentID
    );
    if (deptIndex > -1) {
      let departmentName = this.state.departments[deptIndex].name;
      return departmentName;
    } else {
      return '';
    }
  };

  departmentFormatter = (resourceID, resourceObj) => {
    return this.__getDepartmentName(resourceObj.departmentID);
  };

  __renderProblemResolutions() {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      return (
        <>
          <Row>
            <Col lg={6}>
              <WoProblems
                problems={this.state.Problems}
                departments={this.state.departments}
                Department={this.state.Department}
                departmentID={this.state.departmentID}
                userRole={workorderService.GetUserRole()}
                GISAssets={this.state.GISAssets}
                assetsOptions={this.state.assetsOptions}
                __getProblemResolutions={this.__getProblemResolutions}
              />
            </Col>

            <Col lg={6}>
              <WoResolutions
                resolutions={this.state.Resolutions}
                departments={this.state.departments}
                Department={this.state.Department}
                departmentID={this.state.departmentID}
                userRole={workorderService.GetUserRole()}
                GISAssets={this.state.GISAssets}
                assetsOptions={this.state.assetsOptions}
                __getProblemResolutions={this.__getProblemResolutions}
              />
            </Col>
          </Row>

          {/* {{this.state.assetsOptions.length < 1 && (
            <div className="text-center">
              <img src={notFoundImg} height="90" alt="" className="mt-4" />
              <h4 className="text-uppercase text-danger mt-3">
                No Data available
              </h4>
              <p className="text-muted mt-3">
                There are no GIS Assets configured at this time.
              </p>
            </div>
          )}} */}
        </>
      );
    }
  }

  render() {
    return (
      <Fragment>
        <div>
          <div className="main-content-wrapper-scrollable">
            <div style={{ clear: 'both' }}></div>
            <div>
              <PageTitle
                breadCrumbItems={[
                  { label: 'Map', path: '/apps/workorders' },
                  { label: 'Problems & Resolutions', active: true },
                ]}
                title={'Problems - Resolutions Panel'}
              />
              {/* {this.state.Department && (
                <p className="text-primary" style={{ marginTop: '-15px' }}>
                  Showing Problems and Resolutions for{' '}
                  <u>
                    <b>{this.state.Department}</b>
                  </u>{' '}
                  department
                </p>
              )} */}

              <div>{this.__renderProblemResolutions()}</div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ProblemResolutions;
