import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

class FooterList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //console.log(this.props);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    return (
      <Fragment>
        <Link
          to={'/apps/reports/tbl'}
          id="list-to-table"
          className="btn btn-primary btn-md"
        >
          <i className="uil-grid"></i>{' '}
          <span className="d-none d-lg-inline">Table</span>
        </Link>
        <UncontrolledTooltip placement="top" target="list-to-table">
          View Work Order List
        </UncontrolledTooltip>
        <button
          id="btn-zoom-to-list"
          className="btn btn-info btn-md ml-1"
          onClick={this.props.zoomToList}
        >
          <i className="dripicons-zoom-in"></i>{' '}
          <span className="d-none d-lg-inline">List</span>
        </button>
        <UncontrolledTooltip placement="top" target="btn-zoom-to-list">
          Zoom to List
        </UncontrolledTooltip>
        <button
          id="btn-print-batch"
          className="btn btn-secondary btn-md ml-1"
          onClick={this.props.printWorkOrdersInBatch}
        >
          <i className="dripicons-print"></i>{' '}
          <span className="d-none d-lg-inline">Batch</span>
        </button>
        <UncontrolledTooltip placement="top" target="btn-print-batch">
          Print List as batch
        </UncontrolledTooltip>
{/*        <button
          id="tooltipRefreshList"
          className="btn btn-success btn-md ml-1"
          onClick={this.props.showSelected}
          disabled={this.props.btnListDisabled}
        >
          <i className="uil uil-square"></i>
        </button>
        <UncontrolledTooltip placement="top" target="tooltipRefreshList">
          Show my selection list
        </UncontrolledTooltip> */}
      </Fragment>
    );
  }
}
export default FooterList;
