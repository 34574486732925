import React from 'react';
import { Card, CardBody, 
    TabContent, TabPane, Nav, 
    NavItem, NavLink
} from 'reactstrap';

const HydrantFlushing = ({ facilityIDs, renderInspectionComponent }) => {
    const backBtnStyle = {
        fontSize:'1.5rem',
        color:'#737df3',
        cursor:'pointer'
    }

    return (
        <>
            <Card>
                <CardBody>
                    <h4 className="header-title mb-2">
                        <i className='dripicons-reply' 
                            style={backBtnStyle} 
                            onClick={() => renderInspectionComponent('WOInspection')}
                            title='Return'
                            >
                        </i> Hydrant Flushing Report</h4>
                    

                  <div className='mt-3'>
                      Coming soon...
                  </div>

                </CardBody>
            </Card>
        </>
    )
}

export default HydrantFlushing
