import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import { CrudOperations } from '../../services/CRUDoperations.service';
import { workorderService } from '../../services/workorder.service';
import InspectionDisplay from './Display/InspectionDisplay';

const Entity = 'GISInspections';

class InspectionsManagement extends Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading: true,
            inspections: [],
            GISAssets: [],
            userRole: ''
        }
    }

    fetchGIS_Services = async () => {
        const __gisServices = await CrudOperations.Get('GisConfiguration');
        this.setState({
          GISAssets: __gisServices.data
        });
      };

    fetchInspections_Data = async () => {
        const { data } = await CrudOperations.Get(Entity);
        this.setState({
            inspections: data,
            isLoading: false
        })
    }
    componentDidMount() {
        const user_role = workorderService.GetUserRole();
        if(user_role === 'manager' || workorderService.IsAdmin()) {
            this.fetchGIS_Services();
            this.fetchInspections_Data();
        } else{
            //redirect to home page

        }

        setTimeout(() => {
            const footer = document.getElementById('footer');
            if (footer) {
              footer.classList.add('hide-footer');
            }
          }, 300);
    }
    componentWillUnmount() {
        const footer = document.getElementById('footer');
        footer.classList.remove('hide-footer');
        this.setState = (state, callback) => {
            return;
        }
    }

    changeFeatureStatus = async (isEnabled, inspection) => {
        inspection.isEnabled = isEnabled;
        const {data} = await CrudOperations.Update(Entity, inspection);
        this.fetchInspections_Data();
    }

    onInspectionAssetsUpdate = async(inspectionLayers, inspection) => {
        inspection.inspectionLayers = inspectionLayers;
        const {data} = await CrudOperations.Update(Entity, inspection);
        this.fetchInspections_Data();
    }

    render() {
        return (
            <div>
                <PageTitle
                    breadCrumbItems={[
                    { label: 'Map', path: '/apps/workorders' },
                    { label: 'Inspections', active: true },
                    ]}
                    title={'GIS Inspections Configuration'}
                />

                <InspectionDisplay 
                    inspections={ this.state.inspections } 
                    gisServices={ this.state.GISAssets }
                    changeFeatureStatus={this.changeFeatureStatus} 
                    onInspectionAssetsUpdate={this.onInspectionAssetsUpdate}
                    />
                    
            </div>
        )
    }
}

export default InspectionsManagement;
