// @flow
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CustomInput, FormGroup, Label } from 'reactstrap';
import swal from 'sweetalert';
import * as layoutConstants from '../constants/layout';
import { getLoggedInUser } from '../helpers/authUtils';
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  hideRightSidebar,
} from '../redux/actions';
import { UserAPI } from '../services/users.services';
import {
  AppThemeService,
  workorderService,
} from '../services/workorder.service';

type ThemeCustomizerProps = {
  layoutType: string,
  layoutWidth: string,
  leftSideBarTheme: string,
  leftSideBarType: string,
  hideRightSidebar: PropTypes.func,
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
};

type ThemeCustomizerState = {
  isHorizontalLayout?: boolean,
  isDetachedLayout?: boolean,
  isBoxed?: boolean,
  isSidebarScrollable?: boolean,
  isCondensed?: boolean,
  isLight?: boolean,
  isDark?: boolean,
};

class ThemeCustomizer extends Component<
  ThemeCustomizerProps,
  ThemeCustomizerState
> {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.changeLayout = this.changeLayout.bind(this);
    this.changeWidthMode = this.changeWidthMode.bind(this);
    this.changeTheme = this.changeTheme.bind(this);
    this.changeLeftSiderbarType = this.changeLeftSiderbarType.bind(this);

    this.state = {
      isHorizontalLayout: false,
      isDetachedLayout: false,
      isBoxed: false,
      isSidebarScrollable: false,
      isCondensed: false,
      isLight: false,
      isDark: false,
      notificationType: 'Email',
      userSettings: null,
    };
  }

  /**
   * Handle the notification settings
   *
   */
  handleNotificationTypeChange = (evt) => {
    this.setState({
      notificationType: evt.target.value,
      userRole: '',
    });
  };

  /**
   *  component did mount hook
   */
  componentDidMount = () => {
    this._loadStateFromProps();
    const _user = getLoggedInUser();
    UserAPI.GetUser(_user.id).then((user) => {
      setTimeout(() => {
        var __settingsObj = workorderService.GetUserAppSettings();
        const user_role = workorderService.GetUserRole();
        this.setState({
          userRole: user_role,
          notificationType:
            __settingsObj === null ? 'Email' : __settingsObj.notificationType,
          userSettings: __settingsObj,
        });
        this.getUserTheme();
      }, 50);
    });
  };

  /**
   * component did update hook
   */
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps !== this.props) {
      this._loadStateFromProps();
    }
  };

  __saveNotificationPreference = () => {
    const _user = getLoggedInUser();
    const { notificationType } = this.state;

    const _preference = {
      UserID: _user.id,
      Preference: notificationType,
    };

    // save
    UserAPI.UpdateNotificationPreference(_preference)
      .then((res) => {
        var __settingsObj = workorderService.GetUserAppSettings();

        if (__settingsObj === null) {
          const __userSettingObj = {
            userID: _user.id,
            themeName: this.state.isDark
              ? 'LEFT_SIDEBAR_THEME_DARK'
              : 'LEFT_SIDEBAR_THEME_LIGHT',
            notificationType: notificationType,
            mostTopLayerID: 0,
          };
          workorderService.SaveUserSettings(__userSettingObj);
        } else {
          __settingsObj.notificationType = notificationType;
          workorderService.SaveUserSettings(__settingsObj);
        }

        //swal('Notification preference saved successfully');
        this.props.hideRightSidebar();
      })
      .catch((err) => {
        swal('Notification preference not saved.');
        console.log('Error updating notification :: ', err);
      });
  };

  /**
   * Handles the close
   */
  handleClose = (e) => {
    e.preventDefault();
    this.props.hideRightSidebar();
  };

  /**
   * change state based on props changes
   */
  _loadStateFromProps() {
    this.setState({
      isHorizontalLayout:
        this.props.layoutType === layoutConstants.LAYOUT_HORIZONTAL,
      isDetachedLayout:
        this.props.layoutType === layoutConstants.LAYOUT_DETACHED,
      isBoxed: this.props.layoutWidth === layoutConstants.LAYOUT_WIDTH_BOXED,
      isSidebarScrollable:
        this.props.leftSideBarType ===
        layoutConstants.LEFT_SIDEBAR_TYPE_SCROLLABLE,
      isCondensed:
        this.props.leftSideBarType ===
        layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED,
      isLight:
        this.props.leftSideBarTheme ===
        layoutConstants.LEFT_SIDEBAR_THEME_LIGHT,
      isDark:
        this.props.leftSideBarTheme === layoutConstants.LEFT_SIDEBAR_THEME_DARK,
    });
  }

  /**
   * On layout change
   */
  changeLayout = (e) => {
    var layout = e.currentTarget.value;
    switch (layout) {
      case 'horizontal':
        this.setState({
          isHorizontalLayout: !this.state.isHorizontalLayout,
          isCondensed: false,
          isDetachedLayout: false,
        });
        this.props.changeLayout(layoutConstants.LAYOUT_HORIZONTAL);
        break;
      case 'detached':
        this.setState({
          isDetachedLayout: !this.state.isDetachedLayout,
          isHorizontalLayout: false,
          isBoxed: false,
        });
        this.props.changeLayout(layoutConstants.LAYOUT_DETACHED);
        break;
      default:
        this.setState({
          isHorizontalLayout: false,
          isCondensed: false,
          isDetachedLayout: false,
        });
        this.props.changeLayout(layoutConstants.LAYOUT_VERTICAL);
        break;
    }
  };

  /**
   * Change the width mode
   */
  changeWidthMode = (e) => {
    var mode = e.currentTarget.value;
    switch (mode) {
      case 'boxed':
        this.setState({ isBoxed: true });
        this.props.changeLayoutWidth(layoutConstants.LAYOUT_WIDTH_BOXED);
        break;
      default:
        this.setState({ isBoxed: false });
        this.props.changeLayoutWidth(layoutConstants.LAYOUT_WIDTH_FLUID);
        break;
    }
  };

  /**
   * Changes the theme
   */
  applyTheme = (e) => {
    if (e.target.checked) {
      this.setState({ isDark: true });
      this.props.changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DARK);
      AppThemeService.SetMapDarkMode();
    } else {
      this.setState({ isDark: false });
      this.props.changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_LIGHT);
      AppThemeService.SetMapLightMode();
    }
  };
  changeTheme = (e) => {
    //console.log(e);
    var theme = e.currentTarget.value;
    switch (theme) {
      case 'light':
        this.setState({ isLight: true });
        this.props.changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_LIGHT);
        AppThemeService.SetMapLightMode();
        break;
      case 'dark':
        this.setState({ isLight: false, isDark: true });
        this.props.changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DARK);
        AppThemeService.SetMapDarkMode();
        break;
      default:
        this.setState({ isLight: false, isDark: false });
        this.props.changeSidebarTheme(
          layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT
        );
        break;
    }
  };

  setUserTheme = () => {
    const _user = getLoggedInUser();
    var __settingsObj = workorderService.GetUserAppSettings();
    //console.log(__settingsObj);
    if (__settingsObj === '' || __settingsObj === null) {
      const __userSettingObj = {
        userID: _user.id,
        themeName: this.state.isDark
          ? 'LEFT_SIDEBAR_THEME_DARK'
          : 'LEFT_SIDEBAR_THEME_LIGHT',
        notificationType: this.state.notificationType,
        mostTopLayerID: 0,
      };
      workorderService.SaveUserSettings(__userSettingObj);
    } else {
      __settingsObj.themeName = this.state.isDark
        ? 'LEFT_SIDEBAR_THEME_DARK'
        : 'LEFT_SIDEBAR_THEME_LIGHT';
      workorderService.SaveUserSettings(__settingsObj);
    }
    this.props.hideRightSidebar();
  };
  getUserTheme = () => {
    const { userSettings } = this.state;
    if (userSettings) {
      switch (userSettings.themeName) {
        case 'LEFT_SIDEBAR_THEME_LIGHT':
          this.setState({ isDark: false });
          this.props.changeSidebarTheme(
            layoutConstants.LEFT_SIDEBAR_THEME_LIGHT
          );
          AppThemeService.SetMapLightMode();
          break;

        default:
          this.setState({ isDark: true });
          this.props.changeSidebarTheme(
            layoutConstants.LEFT_SIDEBAR_THEME_DARK
          );
          AppThemeService.SetMapDarkMode();
          break;
      }
    }
  };
  __saveTheme = () => {
    this.setUserTheme();
  };

  /**
   * Change the type
   */
  changeLeftSiderbarType = (e) => {
    var type = e.currentTarget.value;
    switch (type) {
      case 'condensed':
        this.setState({
          isCondensed: true,
          isSidebarScrollable: false,
          isLight: false,
          isDark: false,
        });
        this.props.changeSidebarType(
          layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED
        );
        break;
      case 'scrollable':
        this.setState({ isCondensed: false, isSidebarScrollable: true });
        this.props.changeSidebarType(
          layoutConstants.LEFT_SIDEBAR_TYPE_SCROLLABLE
        );
        break;
      default:
        this.setState({ isCondensed: false, isSidebarScrollable: false });
        this.props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_FIXED);
        break;
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="mt-2 p-2">
          <div className="alert alert-primary" role="alert">
            <strong>Customize your layout theme</strong>
          </div>
        </div>

        <hr className="mb-0" />
        <h5 className="pl-3">Color Theme</h5>

        <div className="pl-3">
          <div className="pt-2">
            {/* <div className="custom-control custom-switch mb-1">
                            <input
                                type="radio"
                                className="custom-control-input"
                                name="theme"
                                value="default"
                                id="default-theme-check"
                                checked={!this.state.isLight && !this.state.isDark}
                                onChange={this.changeTheme}
                                disabled={this.state.isDetachedLayout || this.state.isHorizontalLayout}
                            />
                            <label className="custom-control-label" htmlFor="default-theme-check">
                                Default
                            </label>
                        </div> */}

            {/* <div className="custom-control custom-switch mb-1">
              <input
                type="radio"
                className="custom-control-input"
                name="theme"
                value="light"
                id="light-theme-check"
                onChange={this.changeTheme}
                checked={this.state.isLight}
                disabled={
                  this.state.isDetachedLayout || this.state.isHorizontalLayout
                }
              />
              <label
                className="custom-control-label"
                htmlFor="light-theme-check"
              >
                Light
              </label>
            </div> */}

            <CustomInput
              type="switch"
              id="dark-theme-check"
              name="theme"
              checked={this.state.isDark}
              label="Base/Alternate"
              onChange={this.applyTheme}
            />

            <div className="text-center">
              <button
                className="btn btn-primary mt-2"
                onClick={this.__saveTheme}
              >
                Save theme
              </button>
            </div>
          </div>
        </div>

{/*  CHANGED BY DMM 10/28/2022 - EMAIL IS THE ONLY CHOICE!  ERRORS IN SRC_API FOR TEXT
-       <hr className="mb-0" />
        <h5 className="pl-3">Notifications</h5>

        <div className="pl-3">
          <div className="pt-2">
            <FormGroup>
              <Label for="exampleCheckbox">
                How would you like to be notified when work is assigned to you?
              </Label>
              <div>
                <CustomInput
                  type="radio"
                  id="email"
                  //name="customRadio"
                  value="Email"
                  label="Email"
                  checked={this.state.notificationType === 'Email'}
                  onChange={this.handleNotificationTypeChange}
                />
                <CustomInput
                  type="radio"
                  id="text"
                  value="TextMessage"
                  label="Text Message"
                  checked={this.state.notificationType === 'TextMessage'}
                  onChange={this.handleNotificationTypeChange}
                />
                <CustomInput
                  type="radio"
                  id="EmailAndText"
                  value="EmailAndText"
                  label="Both"
                  checked={this.state.notificationType === 'EmailAndText'}
                  onChange={this.handleNotificationTypeChange}
                />
              </div>
            </FormGroup>
            <button
              className="btn btn-primary"
              onClick={this.__saveNotificationPreference}
            >
              Save notification preference
            </button>
          </div>
        </div>
*/}

        <hr className="mb-0" />
        <h5 className="text-center">Set most top asset </h5>

        <div className="text-center">
          <div className="pt-2">
            <div id="topAssetSelector"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showRightSidebar: state.Layout.showRightSidebar,
    layoutType: state.Layout.layoutType,
    layoutWidth: state.Layout.layoutWidth,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    leftSideBarType: state.Layout.leftSideBarType,
  };
};
export default connect(mapStateToProps, {
  hideRightSidebar,
  changeLayout,
  changeLayoutWidth,
  changeSidebarType,
  changeSidebarTheme,
})(ThemeCustomizer);
