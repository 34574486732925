import { loadModules } from 'esri-loader';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button, Card,
  CardBody, Col, CustomInput, Input, Label, Row
} from 'reactstrap';

  const request_options = {
    query: {
      f: 'json',
    },
    responseType: 'json',
  };
 
const InspectionDisplay = ( { inspections, gisServices, changeFeatureStatus, onInspectionAssetsUpdate } ) => {
  
  const { config } = useSelector((state) => state.AppConfig);
  const proxyUrl = config?.proxyServer.proxyUrl;
  const urlPrefix = config?.proxyServer.urlPrefix;
  
  const [featureClassOptions, setfeatureClassOptions] = useState([]);
  const [selectedLayerID, setSelectedLayerID] = useState();
  const [selectedFeatureClass, setSelectedFeatureClass] = useState();

    const getServiceById = () => {
      return gisServices.find((s) => parseInt(s.gisServicesConfigurationID) === parseInt(selectedLayerID));
    }

    const __addInspectionLayer = async(inspection) => {
      let inspectionLayers = inspection.inspectionLayers;
      const _service = getServiceById();
      const inspectionLayer = {
          //inspectionLayerID: 0,
          inspectionID: inspection.inspectionID,
          layerName: _service.name,
          featureClass: selectedFeatureClass
        };
      
        inspectionLayers.push(inspectionLayer);
      onInspectionAssetsUpdate(inspectionLayers, inspection);
    }

    const __fetch_feature_classes = (serviceUrl) => {
      return loadModules([
        'esri/request',
        'esri/core/urlUtils'
      ]).then(async([esriRequest, urlUtils]) => {

        

        urlUtils.addProxyRule({
          proxyUrl,
          urlPrefix
        });

        const _subLayers = [];
        const __layerInfo = await esriRequest(
          serviceUrl,
          request_options
        );
        //console.log(__layerInfo);
        const { layers } = __layerInfo.data;
        layers.map((l) => {
          if(l.parentLayerId > -1) {
            _subLayers.push(l.name);
          }
        })

        return _subLayers;
      })
    }

    const __populateFeatureClass = async(serviceID) => {
      setfeatureClassOptions([]);
      const service = gisServices.find((s) => parseInt(s.gisServicesConfigurationID) === parseInt(serviceID));
      //console.log(service);
      if(service) {
        const result = await __fetch_feature_classes(service.serviceLink);
        setfeatureClassOptions(result);
        setSelectedLayerID(serviceID);
      }
    }

    return (
        <div>

            <Row className="mb-2">
                {inspections.map((inspection, idx) => {
                    const {inspectionName, description, workType, isEnabled, thumbnail, inspectionLayers} = inspection;
                return (
                    <Col md={6} xl={3} key={idx}>
                        <Card className="d-block">
                                <React.Fragment>
                                <img className="card-img-top" src={`${window.location.origin}/assets/images/inspections/${thumbnail}`} alt="" />
                                </React.Fragment>

                                <CardBody className="position-relative">
                                    <h4 className="mt-0 text-title">
                                    {inspectionName}
                                    </h4>
                                    <p className="text-muted font-13 mb-3">
                                        {description}
                                    </p>
                                    <Label>GIS Assets</Label>
                                    <div style={{maxHeight: '100px'}}>
                                      <ul>
                                        {
                                          inspectionLayers.map((a, idx) => (
                                            <li key={idx}>{a.featureClass}</li>
                                          ))
                                        }
                                      </ul>
                                    </div>

                                    <React.Fragment>
                                        <div className="mt-3 mb-2 font-weight-bold">
                                        Is Enabled <span className="float-right">
                                        <CustomInput
                                            type="switch"
                                            id={`exampleCustomSwitch-${idx}`}
                                            name={`customSwitch-${idx}`}
                                            checked={isEnabled}
                                            onChange={(e) => changeFeatureStatus(e.target.checked, inspection)}
                                        />
                                        </span>
                                        </div>
                                        <div>
                                        <Row>
                                          <Col lg={6}>
                                              <Label for="selectLayer">Select Layer</Label>
                                              <Input 
                                                type="select" id="selectLayer"
                                                onChange={(evt) => __populateFeatureClass(evt.target.value)}
                                                >
                                                  <option value={0}>Select layer...</option>
                                                 {
                                                    gisServices.map((s, idx) => (
                                                      <option key={idx} value={s.gisServicesConfigurationID}>{s.name}</option>
                                                    ))
                                                 }
                                              </Input>
                                          </Col>
                                          <Col lg={6}>
                                              <Label for="selectFeatureClass">Select Feature</Label>
                                              <Input type="select" 
                                                id="selectFeatureClass"
                                                onChange={(e) => setSelectedFeatureClass(e.target.value)}
                                              >
                                                  <option value={''}>Select...</option>
                                                  {
                                                    featureClassOptions.map((fc, idx) => (
                                                      <option key={idx} value={fc}>{fc}</option>
                                                    ))
                                                  }
                                              </Input>
                                          </Col>
                                        </Row>
                                        <Button color="primary" 
                                          type="button" 
                                          className='mt-2'
                                          onClick={() => __addInspectionLayer(inspection)}
                                          >
                                            Add
                                        </Button>
                                        </div>
                                    </React.Fragment>
                                </CardBody>
                            </Card>
                    </Col>
                );
                })}
            </Row>
        </div>
    )
}

export default InspectionDisplay
