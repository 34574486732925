import { AvForm } from 'availity-reactstrap-validation';
import classnames from 'classnames';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledCarousel,
} from 'reactstrap';
import swal from 'sweetalert';
import notFoundImg from '../../../assets/images/file-searching.svg';
import { getLoggedInUser } from '../../../helpers/authUtils';
import {
  CrudOperations,
  GlobalFormatter,
} from '../../../services/CRUDoperations.service';
import { mapGraphics } from '../../../services/Graphics/graphics.service';
import { UserAPI } from '../../../services/users.services';
import { workorderService } from '../../../services/workorder.service';
import CCTV from '../../../utils/Inspections/CCTV/CCTV';
import HydrantFlowTest from '../../../utils/Inspections/HydrantFlowTest/HydrantFlowTest';
import HydrantFlushing from '../../../utils/Inspections/HydrantFlushing/HydrantFlushing';
import ManholeInspection from '../../../utils/Inspections/ManholeInspection/ManholeInspection';
import ValveExercising from '../../../utils/Inspections/ValveExercising/ValveExercising';
import './../wo.details.css';
import WOInspection from './Inspections/WOInspection';
import RecurrenceUpdate from './RecurrenceUpdate/RecurrenceUpdate';

class WorkDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      activeTab: '1',
      isReclassifyingWork: false,
      isChangingAssignedTo: false,
      workStatus: this.props.workorder.attributes.Status,
      workAssignedTo:
        this.props.workorder.attributes.AssignedToID === ''
          ? 'Unassigned'
          : this.props.workorder.attributes.AssignedToID,
      unassignedWorkStatus: 'Red',
      isClaimingWork: false,
      selectedWork: this.props.workorder,
      GISAssets: [],
      GISResources: [],
      essentialQuestions: [],
      GISAttachments: [],
      GISUsers: [],
      applicationUsers: [],
      DepartmentsUsers: [],
      departments: [],
      loggedInUser: null,
      workAssignedToUserObj: null,
      reccurenceNber: 0,
      reccurenceDelay: '',
      activeAsset: '',
      IsZoomingToExtent: false,
      IsZoomingToAsset: false,
      IsUnassigningWork: false,
      IsWorkAssigned: true,
      selectedWorkAsset: null,
      enabledInspections: [],
      activeInspectionComponent: 'WOInspection',
      activeInspectionProps: null,
      workOrderID: 0,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleWorkChangeModal = this.toggleWorkChangeModal.bind(this);
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  toggleWorkChangeModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  fetchInspections_Data = async () => {
    const enabledInspections = [];
    const { data } = await CrudOperations.Get('GISInspections');
    //console.log(data);
    data.map((f) => {
      if (f.isEnabled) {
        enabledInspections.push(f);
      }
    });
    setTimeout(() => {
      this.setState({ enabledInspections });
    }, 20);
    return;  /* DMM 7/20/22 */
  };

  componentDidMount() {
    this.state.GISAssets.length=0;
    this.state.GISResources.length=0; 
    this.fetchInspections_Data();
    this.fetchOrderDetails();
  }

  fetchOrderDetails = async () => {
    const loggedUser = getLoggedInUser();
    //console.log(loggedUser);
    const userData = await UserAPI.GetUser(loggedUser.id);
    const { data } = await CrudOperations.Get('GisConfiguration');
    const { geometry, attributes } = this.state.selectedWork;

    var __gis__assets = data;
    /* __gis__assets = __gis__assets.filter(
      (s) => s.departmentID === userDepartment.departmentID
    ); */

    const selectedWorkAsset = __gis__assets.find(
      (a) => a.name === attributes.AssetName
    );

    var workAssignedToUserObj = { value: '', label: '' };

    if (attributes.AssignedToUID !== '') {
      const workAssignedToUser =
        selectedWorkAsset.gisServicesUsersConfigurations.find(
          (u) => u.userID === attributes.AssignedToUID
        );
      if (workAssignedToUser) {
        workAssignedToUserObj.value = workAssignedToUser.user.id;
        workAssignedToUserObj.label = `${workAssignedToUser.user.firstName} ${workAssignedToUser.user.lastName} - ${workAssignedToUser.userWorkSummary.activeWorkOrders}`;
      }
    }

    this.setState({
      IsWorkAssigned: attributes.Status === 'Unassigned' ? false : true,
      workStatus: attributes.Status,
      workAssignedTo: attributes.AssignedToID,
      selectedWorkAsset,
      workAssignedToUserObj,
      loggedInUser: userData.data,
    });

    if (attributes.IsRecurring === 'Yes') {
      let recurrenceArr = attributes.Recurrence.split(' ');
      let reccurenceDelay = recurrenceArr[recurrenceArr.length - 1];
      let reccurenceNber = recurrenceArr[recurrenceArr.length - 2];

      this.setState({
        reccurenceNber,
        reccurenceDelay,
      });
    }
    if (attributes.Status === 'Archived') {
      let detail_content = document.getElementById('layerInfoContentDisplay');
      if (detail_content)
        detail_content.classList.add('completed-work-detail-bg');
    }
    if (attributes.Status === 'Completed') {
      let detail_content = document.getElementById('layerInfoContentDisplay');
      if (detail_content)
        detail_content.classList.add('completed-work-detail-bg');
    }
    switch (workorderService.GetUserRole()) {
      case 'supervisor':
        /* workorderService
          .GetConnectedUserAndAssets(loggedUser.id)
          .then((userObj) => {
            const {
              applicationUser,
              applicationUsers,
              departments,
              resources,
              workServices,
            } = userObj.data;

            this.setState({
              loggedInUser: applicationUser,
              applicationUsers,
              DepartmentsUsers: applicationUsers,
            });
            this.__getUsersByDepartmentWithActiveWorkTally(
              loggedUser.department
            );
          }); */
        break;

      case 'manager':
        /* workorderService
          .GetConnectedUserAndAssets(loggedUser.id)
          .then((resp) => {
            const { applicationUser, applicationUsers } = resp.data;
            this.setState({ loggedInUser: applicationUser, applicationUsers });
            this.__getDepartments();
            this.__getUsersByDepartmentWithActiveWorkTally(
              attributes.DepartmentName
            );
          }); */
        break;
      default: /* field user */
        break;
    }

    //console.log(this.props);

    // get Work order details
    workorderService
      .GetManagedOrderDetails(attributes.GlobalID)
      .then((results) => {
        if (results.data === '') {
        } else {
          const {
            workOrderID,
            gisAssets,
            workOrderResources,
            workOrderQuestionAnswers,
          } = results.data;

          this.setState({
            workOrderID,
            GISAssets: gisAssets,
            GISResources: workOrderResources,
            essentialQuestions: workOrderQuestionAnswers,
          });
          workorderService.SetAppDetailMode(true);
        }
      })
      .catch((err) => console.log(`Error fetching order details :: ${err}`));

    workorderService
      .GetWorkOrderAttachments(attributes.OBJECTID)
      .then((resp) => {
        //console.log(resp);
        if (resp.data.attachmentInfos.length > 0) {
          let GISAttachments = [];
          const _attachments = resp.data.attachmentInfos;
//          const { proxyUrl } = this.props.config.proxyUrl;  // undefined proxy reference - needs help
          _attachments.map((attachment, index) => {
            GISAttachments.push({
//              src: `${proxyUrl}?${process.env.REACT_APP_WOC_BASE_SERVICE}FeatureServer/0/${attributes.OBJECTID}/attachments/${attachment.id}`,
              src: `${process.env.REACT_APP_WOC_BASE_SERVICE}FeatureServer/0/${attributes.OBJECTID}/attachments/${attachment.id}`,
              altText: '', //`Slide ${index}`,
              caption: '', //attachment.contentType,
              header: '', //attachment.name,
              key: index,
            });
          });

          this.setState({ GISAttachments });
        }
      })
      .catch((err) => console.log('Error fetching attachments :: ', err));
  };

  componentDidUpdate() {
    //this.fetchOrderDetails();
  }

  componentWillUnmount() {
    workorderService.SetAppDetailMode(false);
    document
      .getElementById('layerInfoContentDisplay')
      .classList.remove('completed-work-detail-bg');
    this.setState = (state, callback) => {
      return;
    };
  }

  __GetUsersByDepartment = (department) => {
    UserAPI.GetUsersByDepartment(department).then((users) => {
      let GISUsers = this.state.GISUsers;
      GISUsers = [];
      users.data.map((user, index) => {
        GISUsers.push({
          value: user.id,
          label: user.firstName + ' ' + user.lastName,
          department: user.department,
        });
      });
      this.setState({ GISUsers });
      return;
    });
  };

  /* __getUsersByDepartmentWithActiveWorkTally = (department) => {
    UserAPI.GetUsersByDepartment(department).then((users) => {
      // populate users from same department
      let GISUsers = [];
      users.data.map((user, index) => {
        workorderService.GetUserWorkSummary(user.id).then((summary) => {
          const { totalWorkOrders, activeWorkOrders, unassignedWorkOrders } =
            summary.data;

          GISUsers.push({
            value: user.id,
            label: `${user.firstName} ${user.lastName} - ${activeWorkOrders}`,
            department: user.department,
          });
        });
      });
      setTimeout(() => {
        // get work assigned to
        const assignedToUser = GISUsers.find(
          (u) => u.value === this.props.workorder.attributes.AssignedToUID
        );
        if (assignedToUser) {
          const { value, label } = assignedToUser;
          this.setState({
            workAssignedToUserObj: { value, label },
          });
        }
        this.setState({
          GISUsers,
        });
      }, 200);
    });
  }; */

  __getDepartments = () => {
    CrudOperations.Get('Departments')
      .then((res) => {
        this.setState({ departments: res.data });
        //console.log(this.state.departments);
      })
      .catch((err) => {
        console.log('Error fetching departments :: ', err);
      });
  };

  __getHumanDate = (_d) => {
    return new Date(_d).toLocaleDateString();
  };

  __getWorkStatus = () => {
    const { workStatus } = this.state;
    return workorderService.renderStatus(workStatus);
  };

  __getResourcesTotalCost = () => {
    let cost = 0;
    this.state.GISResources.map((r) => {
//      cost = cost + r.unit * r.resource.rate;  // DMM 12/9/2022
      cost = cost + r.unit * r.rate;
    });
    return GlobalFormatter.FormatUSD(cost);
  };

  __zoomToAssetsExtent = () => {
    this.setState({ IsZoomingToExtent: true });
    mapGraphics
      .highlightAssetAndZoomTo(workorderService.mapView(), this.state.GISAssets)
      .then((features) => this.__goToAssets(features));
  };
  __zoomToAsset = (asset) => {
    this.setState({ activeAsset: asset.facilityID, IsZoomingToAsset: true });
    mapGraphics
      .highlightAssetAndZoomTo(workorderService.mapView(), [asset])
      .then((features) => this.__goToAssets(features));
  };

  __goToAssets = (features) => {
    this.setState({ IsZoomingToAsset: false, IsZoomingToExtent: false });
/*    workorderService
      .mapView()
//      .goTo(features)  // DMM 12/2/2022
      .then(() => {
        this.setState({ IsZoomingToAsset: false, IsZoomingToExtent: false });
      }); */
  };

  _renderAssets() {
    if (this.state.GISAssets.length > 0) {
      return (
        <div className="mb-2 p-2">
          <ul className="list-group">
            <li
              className="list-group-item active"
              style={{
                backgroundColor: '#6f7188 !important',
                borderColor: '#6f7188 !important',
              }}
            >
              <i className="dripicons-network-3 me-1"></i> GIS Assets{' '}
              {/* {this.state.GISAssets.length > 1 && (
                <>
                  <span className="ml-3" id="zoomToExtentBtn">
                    <Button
                      color="link"
                      className="text-white"
                      onClick={this.__zoomToAssetsExtent}
                    >
                      <i className="dripicons-zoom-in"></i>
                    </Button>
                    {this.state.IsZoomingToExtent && (
                      <span
                        role="status"
                        className="spinner-grow text-warning"
                        style={{
                          width: '1rem',
                          height: '1rem',
                        }}
                      ></span>
                    )}
                  </span>
                  <UncontrolledTooltip placement="top" target="zoomToExtentBtn">
                    Zoom to Extent
                  </UncontrolledTooltip>
                </>
              )} */}
              <span style={{ float: 'right' }}>
                [ {this.state.GISAssets.length} ]
              </span>
            </li>
            {this.state.GISAssets.map((a, index) => (
              <li
                key={index}
                className={`list-group-item ${
                  this.state.activeAsset === a.facilityID ? 'active-asset' : ''
                }`}
              >
                <span style={{ float: 'right' }}>
                  {a.facilityID === this.state.activeAsset &&
                    this.state.IsZoomingToAsset && (
                      <span
                        role="status"
                        className="spinner-grow text-warning"
                        style={{
                          width: '1rem',
                          height: '1rem',
                        }}
                      ></span>
                    )}
                  <Button color="link" onClick={() => this.__zoomToAsset(a)}>
                    <i className="dripicons-brush"></i>
                  </Button>
                </span>
                <p className="mb-0 pb-0">{a.assetName}</p>
                <p className="mb-0 pb-0">{a.facilityID}</p>
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <img src={notFoundImg} height="90" alt="" className="mt-4" />
          <h4 className="text-uppercase text-danger mt-3">
            No GIS Assets found
          </h4>
          <p className="text-muted mt-3">
            There are no gis assets linked to this work order.
          </p>
        </div>
      );
    }
  }
  _renderResources() {
    if (this.state.GISResources.length > 0) {
      return (
        <div style={{ overflowX: 'hidden' }}>
          <table className="mb-0 mt-2 table table-sm">
            <thead>
              <tr>
                <th style={{ minWidth: '200px' }}>Resource</th>
                <th>Rate</th>
                <th>Qty</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {this.state.GISResources.map((r, index) => (
                <tr key={index}>
                  <td>{r.name}</td>
                  <td>{r.rate}</td>
                  <td>{r.unit}</td>
                  <td>{GlobalFormatter.FormatUSD(r.rate * r.unit)}</td>
{/*                  <td>{r.resource.name}</td>
                  <td>{r.resource.rate}</td>
                  <td>{r.unit}</td>
              <td>{GlobalFormatter.FormatUSD(r.resource.rate * r.unit)}</td>  DMM 12/9/2022 */}
                </tr>
              ))}
              <tr
                className="bg-primary text-white"
                style={{ fontWeight: 'bold' }}
              >
                <td>Total:</td>
                <td colSpan="3" className="text-right">
                  {this.__getResourcesTotalCost()}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <img src={notFoundImg} height="90" alt="" className="mt-4" />
          <h4 className="text-uppercase text-danger mt-3">
            No Resources found
          </h4>
          <p className="text-muted mt-3">
            There are no work resources linked to this work order.
          </p>
        </div>
      );
    }
  }
  _renderAttachments() {
    switch (this.state.GISAttachments.length) {
      case 0:
        return (
          <>
            <div className="text-center">
              <img src={notFoundImg} height="90" alt="" className="mt-4" />
              <h4 className="text-uppercase text-danger mt-3">
                No Attachments found
              </h4>
              <p className="text-muted mt-3">
                There are no attachments linked to this work order.
              </p>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="text-center" style={{ margin: '10px 1px' }}>
              <UncontrolledCarousel
                items={this.state.GISAttachments}
                indicators={this.state.GISAttachments.length < 2 ? false : true}
                controls={this.state.GISAttachments.length < 2 ? false : true}
              />
            </div>
          </>
        );
    }
  }

  _renderEssentialQuestions() {
    if (this.state.essentialQuestions.length > 0) {
      return (
        <Fragment>
          <ul className="conversation-list mt-2">
            {this.state.essentialQuestions.map((q, index) => (
              <Fragment key={index}>
                <li className="clearfix">
                  <div className="chat-avatar"></div>
                  <div className="conversation-text">
                    <div className="ctext-wrap">
                      <i>Question {index + 1}</i>
                      <p>{q.essentialQuestion.question}</p>
                    </div>
                  </div>
                </li>
                <li className="clearfix odd">
                  <div className="chat-avatar"></div>
                  <div className="conversation-text">
                    <div className="ctext-wrap">
                      <i>Answer {index + 1}</i>
                      <p>{q.answer}</p>
                    </div>
                  </div>
                </li>
              </Fragment>
            ))}
          </ul>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="text-center">
            <img src={notFoundImg} height="90" alt="" className="mt-4" />
            <h4 className="text-uppercase text-danger mt-3">
              No Details found
            </h4>
            <p className="text-muted mt-3">
              There are no Essential Details linked to this work order.
            </p>
          </div>
        </Fragment>
      );
    }
  }

  SetWorkAssignedTo = (e) => {
    const { value, label } = e;
    this.setState({ workAssignedToUserObj: { value, label } });
  };

  __renderWorkAssigned = () => {
/*    const { attributes } = this.state.selectedWork;  DMM 7/20/22 */
    const __usersOptions = [];
    if (this.state.selectedWorkAsset) {
      this.state.selectedWorkAsset.gisServicesUsersConfigurations.map((u) => {
        __usersOptions.push({
          value: u.user.id,
          label: `${u.user.firstName} ${u.user.lastName} - ${u.userWorkSummary.activeWorkOrders}`,
        });
        return;  /* DMM 7/20/22 */
      });
    }
    return (
      <Fragment>
        <div className="mb-2">
          <label htmlFor="inputWorkAssignedTo" className="form-label">
            Assign Work To
          </label>
          <Select
            id="inputWorkAssignedTo"
            className="react-select"
            classNamePrefix="react-select"
            value={this.state.workAssignedToUserObj}
            onChange={(e) => this.SetWorkAssignedTo(e)}
            options={__usersOptions}
            ariadescribedby="assignedToHelp"
          ></Select>
          <div id="assignedToHelp" className="form-text text-danger">
            If unselected, this work will be unassigned.
          </div>
        </div>
      </Fragment>
    );
  };

  __renderButtonChangeWorkAssignedTo = () => {
    const { attributes } = this.state.selectedWork;
    //console.log(attributes);
    if (
      workorderService.GetUserRole() === 'supervisor' ||
      (workorderService.GetUserRole() === 'manager' &&
        attributes.Status !== 'Completed')
    ) {
      return (
        <button
          className="btn btn-secondary btn-sm"
          style={{ position: 'absolute', top: '0', right: '0' }}
          onClick={this.toggleWorkChangeModal}
        >
          Change
        </button>
      );
    }
  };

  changeWorkAssignedTo = (event, values) => {
    const { workAssignedToUserObj } = this.state;
    if (workAssignedToUserObj === null) {
      swal('Please select a user');
      return;
    }

    this.setState({ isChangingAssignedTo: true });

    /**
     * [1] update feature service, update properties:
     * Status: to Assigned
     * AssignedToID
     * AssignedToUID
     */

    const feature = this.props.workorder;

    Object.keys(feature.attributes).forEach((field, index) => {
      switch (field) {
        case 'Status':
          feature.attributes[field] = 'Assigned';
          break;
        case 'AssignedToUID':
          feature.attributes[field] = `${workAssignedToUserObj.value}`;
          break;
        case 'AssignedToID':
          feature.attributes[field] = `${workAssignedToUserObj.label
            .split('-')[0]
            .trim()}`;
          break;
        case 'IsWorkAssigned':
          feature.attributes[field] = 1;
          break;
        default:
          break;
      }
    });

    // apply changes
    const edits = {
      updateFeatures: [feature],
    };

    workorderService.ApplyWorkOrderEdits(edits).then((result) => {  // 1st - changes applied to feature class
      if (result.updateFeatureResults.length > 0) {
        // related work records updates
        // update related records
        const __workOrderObj = {
          globalID: feature.attributes.GlobalID,
          workAssignedTo: feature.attributes.AssignedToID,
          assignedToUID: feature.attributes.AssignedToUID,
          status: feature.attributes.Status,
          sender: `${this.state.loggedInUser.firstName} ${this.state.loggedInUser.lastName}`,
        };
        workorderService
          .UpdateWorkorderAssigned(__workOrderObj)  //2nd - changes applied to database
          .then((updateDataResponse) => {
            //workorderService.RefreshWorkOrders();
            this.setState({
              isChangingAssignedTo: false,
              workStatus: 'Assigned',
              workAssignedTo: __workOrderObj.workAssignedTo,
              IsWorkAssigned: true,
            });
            this.toggleWorkChangeModal();
            swal(
              `Work order reassigned successfully. \n \n ${__workOrderObj.workAssignedTo} has been notified`
            );
          })
          .catch((error) => {
            swal('There was an error updating related records.');
            console.log(`Error updating related data :: ${error}`);
            this.setState({ isChangingAssignedTo: false });
          });
      } else {
        console.log(
          'There were some errors updating the Feature Service. Please try again'
        );
        this.setState({ isChangingAssignedTo: false });
      }
    });
  };

  __isRecurringUpdate = (isrecurring) => {
    var isShowing = false;
    const user_role = workorderService.GetUserRole();
    if (isrecurring === 'Yes') {
      switch (user_role) {
        case 'supervisor':
        case 'manager':
          isShowing = true;
          break;
      }
    }
    return isShowing;
  };

  unassignWork = () => {
    swal({
      text: 'Are you sure you want to unassign this work?',
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then((action) => {
      if (action) {
        //console.log('Unassigning work...');
        const { workorder } = this.props;
        //console.log(workorder);
        //const __previous__assignedUID = attributes.AssignedToUID;

        this.setState({ IsUnassigningWork: true });

        Object.keys(workorder.attributes).forEach((fieldName) => {
          switch (fieldName) {
            case 'AssignedToID':
              workorder.attributes[fieldName] = 'Unassigned';
              break;
            case 'AssignedToUID':
              workorder.attributes[fieldName] = '';
              break;
            case 'Status':
              workorder.attributes[fieldName] = 'Unassigned';
              break;
            case 'IsWorkAssigned':
              workorder.attributes[fieldName] = '0';
              break;
            default:
              break;
          }
        });

        // apply changes
        const edits = {
          updateFeatures: [workorder],
        };

        //console.log(edits);
        workorderService.ApplyWorkOrderEdits(edits).then((result) => {
          if (result.updateFeatureResults.length > 0) {
            const __workOrderObj = {
              globalID: workorder.attributes.GlobalID,
              assignedToID: workorder.attributes.AssignedToID,  // fixed on 12/8/2022
              assignedToUID: workorder.attributes.AssignedToUID,
              status: workorder.attributes.Status,
              sender: `${this.state.loggedInUser.firstName} ${this.state.loggedInUser.lastName}`,
            };
            workorderService
              .UpdateWorkorderAssigned(__workOrderObj)
              .then((res) => {
                this.setState({
                  IsUnassigningWork: false,
                  IsWorkAssigned: false,
                  workStatus: 'Unassigned',
                  workAssignedTo: 'Unassigned',
                });
                swal(`Work order unassigned successfully.`);
                this.toggleWorkChangeModal();
              });
          }
        });
      }
    });
  };

  __setInspectionStateComponent = (
    compoment
    //props = null
  ) => {
    this.setState({
      activeInspectionComponent: compoment,
      //activeInspectionProps: props
    });
  };

  __renderInspectionComponent = () => {
    if (this.state.GISAssets.length > 0) {
      switch (this.state.activeInspectionComponent) {
        case 'CCTV':
          return (
            <CCTV
              facilityIDs={this.state.GISAssets}
              reportBaseUrl={''}
              workorderid={this.state.workOrderID}
              renderInspectionComponent={this.__setInspectionStateComponent}
            />
          );
        case 'Hydrant Flow Test':
          return (
            <HydrantFlowTest
              facilityIDs={this.state.GISAssets}
              workorderid={this.state.workOrderID}
              renderInspectionComponent={this.__setInspectionStateComponent}
            />
          );
        case 'Hydrant Flushing':
          return (
            <HydrantFlushing
              facilityIDs={this.state.GISAssets}
              workorderid={this.state.workOrderID}
              renderInspectionComponent={this.__setInspectionStateComponent}
            />
          );
        case 'Manhole Inspection':
          return (
            <ManholeInspection
              facilityIDs={this.state.GISAssets}
              workorderid={this.state.workOrderID}
              renderInspectionComponent={this.__setInspectionStateComponent}
            />
          );
        case 'Valve Exercising':
          return (
            <ValveExercising
              facilityIDs={this.state.GISAssets}
              workorderid={this.state.workOrderID}
              renderInspectionComponent={this.__setInspectionStateComponent}
            />
          );
        default:
          return (
            <WOInspection
              inspectionFeatures={this.state.enabledInspections}
              workorder={this.props.workorder}
              assets={this.state.GISAssets}
              renderInspectionComponent={this.__setInspectionStateComponent}
            />
          );
      }
    } else {
      return (
        <div className="text-center">
          <h4 className="text-uppercase text-danger mt-3">No Assets found </h4>
          <p className="text-muted mt-3">
            There are no assets associated with this work order at this time.
          </p>
        </div>
      );
    }
  };

  render() {
    const { attributes } = this.props.workorder;
    return (
      <Fragment>
        <Nav tabs className="nav-pills bg-nav-pills nav-justified">
          <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={(e) => {
                this.toggle('1');
              }}
            >
              <i
                className={classnames(
                  'mdi mdi-alert-circle-outline',
                  'd-lg-none',
                  'd-block',
                  'mr-1'
                )}
              ></i>
              <span className="d-none d-lg-block">Info</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => {
                this.toggle('2');
              }}
            >
              <i
                className={classnames(
                  'mdi mdi-alpha-a-box',
                  'd-lg-none',
                  'd-block',
                  'mr-1'
                )}
              ></i>
              <span className="d-none d-lg-block">Assets</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => {
                this.toggle('3');
              }}
            >
              <i
                className={classnames(
                  'mdi mdi-alpha-r-box',
                  'd-lg-none',
                  'd-block',
                  'mr-1'
                )}
              ></i>
              <span className="d-none d-lg-block">Resources</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => {
                this.toggle('4');
              }}
            >
              <i
                className={classnames(
                  'mdi mdi-file-image',
                  'd-lg-none',
                  'd-block',
                  'mr-1'
                )}
              ></i>
              <span className="d-none d-lg-block">Attachments</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: this.state.activeTab === '5' })}
              onClick={() => {
                this.toggle('5');
              }}
            >
              <i
                className={classnames(
                  'mdi mdi-help-box',
                  'd-lg-none',
                  'd-block',
                  'mr-1'
                )}
              ></i>
              <span className="d-none d-lg-block"> Details</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: this.state.activeTab === '6' })}
              onClick={() => {
                this.toggle('6');
              }}
            >
              <i
                className={classnames(
                  'mdi mdi-help-box',
                  'd-lg-none',
                  'd-block',
                  'mr-1'
                )}
              ></i>
              <span className="d-none d-lg-block"> Inspections</span>
            </NavLink>
                </NavItem>
          <NavItem style={{ paddingTop: '0.5rem' }}>
            <Link
              to={`/print/${attributes.GlobalID}`}
              className={classnames({ active: this.state.activeTab === '7' })}
            >
              <i
                className={classnames(
                  'mdi mdi-settings-outline',
                  'd-lg-none',
                  'd-sm-none',
                  'd-block',
                  'mr-1'
                )}
              ></i>
              <span className="d-none d-lg-block">
                <i className="dripicons-print"></i> Print
              </span>
            </Link>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div style={{ margin: '5px 10px' }}>
              <h5 className="text-secondary mt-0">
                GIS Asset :{' '}
                <span className="text-primary">{attributes.AssetName}</span>
              </h5>
              <h5 className="text-secondary mt-0">Work order type</h5>
              <p className="text-primary">{attributes.WorkType}</p>
              <h5 className="text-secondary mt-0">Department</h5>
              <p className="text-primary">{attributes.DepartmentName}</p>
              <h5 className="text-secondary mt-0">Work assigned by:</h5>
              <p className="text-primary">{attributes.OriginatorID}</p>
              <div style={{ position: 'relative' }}>
                <h5 className="text-secondary mt-0">Work assigned to:</h5>
                <p className="text-primary">{this.state.workAssignedTo}</p>
                {this.__renderButtonChangeWorkAssignedTo()}
              </div>

              <h5 className="text-secondary mt-0">
                Status:
                <span style={{ float: 'right' }}>{this.__getWorkStatus()}</span>
              </h5>
              {attributes.Status === 'Completed' && (
                <h5 className="text-secondary mt-0">
                  Date Completed:
                  <span style={{ float: 'right' }}>
                    {new Date(attributes.DateCompleted).toLocaleDateString()}
                  </span>
                </h5>
              )}
              <h5 className="text-secondary mt-0">Due Date</h5>
              <p
                className={workorderService.GetDueDateTextColor(
                  attributes.PlannedDate,
                  attributes.Status
                )}
              >
                {new Date(attributes.PlannedDate).toISOString().substr(0,10)}
              </p>
              <h5 className="text-secondary mt-0">Location:</h5>
              <p className="text-primary">
                {attributes.LocationAddress}{' '}
                <span style={{ float: 'right', fontSize: '1.2rem' }}>
                  <a
                    href={`https://maps.google.com/?q=${attributes.LocationAddress},REYNOLDSBURG,OH`}
                    target="_blank"
                  >
                    <i className="uil uil-map-pin-alt"></i>
                  </a>
                </span>
              </p>
              <h5 className="text-secondary mt-0">Description:</h5>
              <p className="text-primary">{attributes.Description}</p>
              <h5 className="text-secondary mt-0">Problem:</h5>
              <p className="text-primary">{attributes.ProblemName}</p>
              <h5 className="text-secondary mt-0">Resolution:</h5>
              <p className="text-primary">{attributes.ResolutionName}</p>
              <h5 className="text-secondary mt-0">Field notes:</h5>
              <p className="text-primary">
                {attributes.FieldNotes ? attributes.FieldNotes : 'Not provided'}
              </p>

              <h5 className="text-secondary mt-0">
                Recurring Work:
                <span style={{ float: 'right' }}>{attributes.IsRecurring}</span>
              </h5>
              {attributes.IsRecurring === 'Yes' && (
                <div style={{ position: 'relative' }}>
                  <h5 className="text-secondary mt-0">Recurrence:</h5>
                  <Row style={{ margin: '0' }}>
                    <Col lg={8} className="text-left p-0">
                      <p className="text-primary">{attributes.Recurrence}</p>
                    </Col>
                    <Col lg={4} className="text-right p-0">
                      {this.__isRecurringUpdate(attributes.IsRecurring) && (
                        <>
                          <RecurrenceUpdate
                            feature={this.props.workorder}
                            reccurenceNber={this.state.reccurenceNber}
                            reccurenceDelay={this.state.reccurenceDelay}
                            goBack={this.props.goBack}
                          />
                        </>
                      )}
                    </Col>
                  </Row>

                  <h5 className="text-secondary mt-0">
                    Next Due Date:
                    <span style={{ float: 'right' }}>
                      {attributes.NextDueDate}
                    </span>
                  </h5>
                </div>
              )}
            </div>
          </TabPane>
          <TabPane tabId="2">{this._renderAssets()}</TabPane>
          <TabPane tabId="3">{this._renderResources()}</TabPane>
          <TabPane tabId="4">{this._renderAttachments()}</TabPane>
          <TabPane tabId="5">{this._renderEssentialQuestions()}</TabPane>
          <TabPane tabId="6">{this.__renderInspectionComponent()}</TabPane>   *
        </TabContent>

        <Modal isOpen={this.state.modal} toggle={this.toggleWorkChangeModal}>
          <ModalHeader toggle={this.toggleWorkChangeModal}>
            Change Work Order Responsibility
          </ModalHeader>
          <ModalBody>
            <AvForm onValidSubmit={this.changeWorkAssignedTo}>
              {this.__renderWorkAssigned()}
              <div className="text-center m-3 mb-2">
                <Button
                  color="light"
                  type="button"
                  className="mr-2"
                  onClick={this.toggleWorkChangeModal}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={this.state.isChangingAssignedTo}
                  type="submit"
                >
                  {this.state.isChangingAssignedTo
                    ? 'Submitting...'
                    : 'Submit and Notify User'}
                </Button>

                {this.state.IsWorkAssigned && (
                  <Button
                    color="danger"
                    disabled={this.state.IsUnassigningWork}
                    type="button"
                    className="ml-2"
                    onClick={this.unassignWork}
                  >
                    {this.state.IsUnassigningWork
                      ? 'Submitting...'
                      : 'Unassign Work'}
                  </Button>
                )}
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.AppConfig.config,
  };
};
export default connect(mapStateToProps)(WorkDetails);
