import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import DepartmentManagement from '../components/workorders/BackOffice/DepartmentManagement';
import PrintWorkOrder from '../components/workorders/BackOffice/PrintWorkOrder';
import ResourcesManagement from '../components/workorders/BackOffice/ResourcesManagement';
import ServicesManagement from '../components/workorders/BackOffice/ServicesManagement';
import SrcSearch from '../components/workorders/BackOffice/SrcSearch';
import UserProfile from '../components/User/UserProfile';
import UsersManagements from '../components/workorders/BackOffice/UsersManagements';
import WorkOrders from '../components/workorders/WorkOrders';
import WorkOrdersTable from '../components/workorders/WorkOrdersTable';

import {
  isUserAuthenticated,
  getLoggedInUser,
  isUserInSupervisorRole,
} from '../helpers/authUtils';
import ResetPassword from '../pages/auth/ResetPassword';
import AccountCreateSuccess from '../pages/auth/AccountCreateSuccess';
import AccountUnauthorized from '../pages/auth/AccountUnauthorized';
import SrcTerms from '../components/TermsPolicy/Terms';
import ProblemResolutions from '../components/workorders/BackOffice/ProblemResolutions';
import Problems from '../components/workorders/BackOffice/Problems';
import Resolutions from '../components/workorders/BackOffice/Resolutions';
import ProblemResolutionCost from '../components/Reports/ProblemResolutionCost';
import PrintBatchWorkOrders from '../components/Shared/PrintBatchWorkOrders';
import ResourceUtilization from '../components/Reports/ResourceUtilization';
import Documentation from '../components/Documentation/Documentation';
import Faq from '../components/Documentation/Faq';
import WorkOrdersRecurrence from '../components/Reports/Recurrence/WorkOrdersRecurrence';
import GISAssetsConfiguration from '../pages/GISAssetsConfiguration/GISAssetsConfiguration';
import InspectionsManagement from '../pages/InspectionsManagement/InspectionsManagement';
import AssetWorkHistory from '../pages/AssetWorkHistory/AssetWorkHistory';
import ResourceInventoryCritical from '../components/Reports/ResourceInventoryCritical';
import SignWorkHistory from '../pages/SignWorkHistory/SignWorkHistory';
import SignAnnualReplacements from '../pages/SignAnnualReplacements/SignAnnualReplacements';
import SignPurchaseReport from '../pages/SignPurchaseReport/SignPurchaseReport';


// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));

const WorkOrderDashboard = React.lazy(() => import('../components/Dashboard'));

// pages
const ErrorPageNotFound = React.lazy(() =>
  import('../pages/error/PageNotFound')
);
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserAuthenticated()) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: '/account/login', state: { from: props.location } }}
          />
        );
      }

      const loggedInUser = getLoggedInUser();
      // check if route is restricted by role
      if (roles && roles.indexOf(loggedInUser.role) === -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />;
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to="/apps/workorders" />,
  route: PrivateRoute,
};
const printRoute = {
  path: '/print/:globalid',
  exact: true,
  component: PrintWorkOrder,
  route: PrivateRoute,
};
const batchPrintRoute = {
  path: '/batch/print',
  exact: true,
  component: PrintBatchWorkOrders,
  route: PrivateRoute,
};
const searchRoute = {
  path: '/search/:q',
  exact: true,
  component: SrcSearch,
  route: PrivateRoute,
};
const userProfileRoute = {
  path: '/user/profile',
  exact: true,
  component: UserProfile,
  route: PrivateRoute,
};
const srcTermsRoute = {
  path: '/src/terms',
  exact: true,
  component: SrcTerms,
  route: PrivateRoute,
};
const GISServicesConfigRoute = {
  path: '/gis/services/configuration',
  exact: true,
  component: GISAssetsConfiguration,
  route: PrivateRoute,
};
const GISInspectionsConfigRoute = {
  path: '/gis/inspections/configuration',
  exact: true,
  component: InspectionsManagement,
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Dashboard',
  route: PrivateRoute,
  roles: ['Admin', 'supervisor'],
  icon: 'dripicons-home',
  component: WorkOrderDashboard,
  header: 'Analytics',
};

const workOrdersAppRoutes = {
  path: '/apps/workorders',
  name: 'Map',
  route: PrivateRoute,
  roles: ['Admin', 'supervisor'],
  icon: 'dripicons-crosshair',
  component: WorkOrders,
  header: 'WORK ORDERS',
};
const workOrdersTableRoute = {
  path: '/apps/reports/tbl',
  name: 'Listing',
  route: PrivateRoute,
  roles: ['Admin', 'supervisor'],
  icon: 'dripicons-list',
  component: WorkOrdersTable,
};

const docsAppRoutes = {
  path: '/apps/docs',
  name: 'Documentation',
  route: PrivateRoute,
  roles: ['Users'],
  icon: 'dripicons-question',
  children: [
/*    {
      path: '/apps/docs/help',
      name: 'User Guide',
      component: Documentation,
      route: PrivateRoute,
    },  */  // DMM 12/12/2022
    {
      path: '/apps/docs/faq',
      name: 'FAQ',
      component: Faq,
      route: PrivateRoute,
    },
  ],
};
const appRoutes = [workOrdersAppRoutes];

const pageRoutes = {
  path: '/system',
  name: 'Configuration',
  icon: 'dripicons-document-edit',
  header: 'System',
  children: [
    {
      path: '/system/services',
      name: 'Work Types',
      icon: 'uil-server-network-alt',
      component: ServicesManagement,
      route: PrivateRoute,
    },
    {
      path: '/system/resources',
      name: 'Resources',
      icon: 'uil-cell',
      component: ResourcesManagement,
      route: PrivateRoute,
    },
    {
    path: '/system/problemresolutions',
    name: 'Problem Resolutions',
    icon: 'uil-lightbulb',
    component: ProblemResolutions,
    route: PrivateRoute,
    },
    {
      path: '/system/users',
      name: 'Users',
      icon: 'uil-users-alt',
      component: UsersManagements,
      route: PrivateRoute,
    },
    {
      path: '/system/assets/configuration',
      name: 'Assets',
      icon: 'uil-game-structure',
      component: GISAssetsConfiguration,
      route: PrivateRoute,
    },
    {
      path: '/system/departments',
      name: 'Departments',
      icon: 'uil-folder-network',
      component: DepartmentManagement,
      route: PrivateRoute,
    },
    {
      path: '/system/inspections/configuration',
      name: 'Inspections',
      icon: 'uil-sliders-v-alt',
      component: InspectionsManagement,
      route: PrivateRoute,
    },
  ],
};
const reportRoutes = {
  path: '/report',
  name: 'Reports',
  icon: 'dripicons-to-do',
  children: [
    {
      path: '/report/problem-resolution/cost',
      name: 'Problem/Resolution Cost',
      icon: 'uil-money-withdrawal',
      component: ProblemResolutionCost,
      route: PrivateRoute,
    },
    {
      path: '/report/resource-utilization',
      name: 'Resource Utilization',
      icon: 'uil-dollar-sign-alt',
      component: ResourceUtilization,
      route: PrivateRoute,
    },
    {
      path: '/report/recurrence',
      name: 'Work Recurrence',
      icon: 'dripicons-alarm',
      component: WorkOrdersRecurrence,
      route: PrivateRoute,
    },
    {
      path: '/report/asset-work-history',
      name: 'Asset Work History',
      icon: 'dripicons-clock',
      component: AssetWorkHistory,
      route: PrivateRoute,
    },
    {
      path: '/report/inventory-critical',
      name: 'Critical Resource Inventory',
      icon: 'dripicons-flag',
      component: ResourceInventoryCritical,
      route: PrivateRoute,
    },
  ],
};

const assetmanagementSignsRoutes = {
  path: '/assetmanagement/signs',
  name: 'Signs',
  icon: 'uil-notes',
  header: 'Asset Management',
  children: [
    {
      path: '/assetmanagement/signhistory',
      name: 'Detailed History',
      icon: 'dripicons-message',
      component: SignWorkHistory,
      route: PrivateRoute,
    },
    {
      path: '/assetmanagement/signreplacements',
      name: 'Annual Replacements',
      icon: 'dripicons-network-2',
      component: SignAnnualReplacements,
      route: PrivateRoute,
    },  
/*    {
      path: '/assetmanagement/signpurchasereport',
      name: 'Annual Purchases',
      icon: 'dripicons-network-2',
      component: SignPurchaseReport,
      route: PrivateRoute,
    },  */
/*    {
      path: '/assetmanagement/signreporting',
      name: 'Advanced Reporting',
      icon: 'dripicons-network-3',
      component: WorkOrdersRecurrence,
      route: PrivateRoute,
    }, */
  ],
};
/*
const assetmanagementWastewaterRoutes = {
  path: '/assetmanagement/wastewater',
  name: 'Wastewater',
  icon: 'uil-notes',
  children: [
    {
      path: '/assetmanagement/wastewater1',
      name: 'Wastewater Management 1',
      icon: 'dripicons-message',
      component: ProblemResolutionCost,
      route: PrivateRoute,
    },
    {
      path: '/assetmanagement/wastewater2',
      name: 'Wastewater Management 2',
      icon: 'dripicons-network-2',
      component: ResourceUtilization,
      route: PrivateRoute,
    },
    {
      path: '/assetmanagement/wastewater3',
      name: 'Wastewater Management 3',
      icon: 'dripicons-network-3',
      component: WorkOrdersRecurrence,
      route: PrivateRoute,
    },
  ],
};

const assetmanagementStormwaterRoutes = {
  path: '/assetmanagement/stormwater',
  name: 'Stormwater',
  icon: 'uil-notes',
  children: [
    {
      path: '/assetmanagement/stormwater1',
      name: 'Stormwater Management 1',
      icon: 'dripicons-message',
      component: ProblemResolutionCost,
      route: PrivateRoute,
    },
    {
      path: '/assetmanagement/stormwater2',
      name: 'Stormwater Management 2',
      icon: 'dripicons-network-2',
      component: ResourceUtilization,
      route: PrivateRoute,
    },
    {
      path: '/assetmanagement/stormwater3',
      name: 'Stormwater Management 3',
      icon: 'dripicons-network-3',
      component: WorkOrdersRecurrence,
      route: PrivateRoute,
    },
  ],
};
*/

// auth
const authRoutes = {
  path: '/account',
  name: 'Auth',
  children: [
    {
      path: '/account/login',
      name: 'Login',
      component: Login,
      route: Route,
    },
    {
      path: '/account/logout',
      name: 'Logout',
      component: Logout,
      route: Route,
    },
    {
      path: '/account/register',
      name: 'Register',
      component: Register,
      route: Route,
    },
    {
      path: '/account/confirm',
      name: 'Confirm',
      component: Confirm,
      route: Route,
    },
    {
      path: '/account/create/success',
      name: 'AccountCreateSuccess',
      component: AccountCreateSuccess,
      route: Route,
    },
    {
      path: '/account/unauthorised',
      name: 'AccountUnauthorized',
      component: AccountUnauthorized,
      route: Route,
    },
    {
      path: '/account/forget-password',
      name: 'Forget Password',
      component: ForgetPassword,
      route: Route,
    },
    {
      path: '/account/reset-pw',
      name: 'Reset Password',
      component: ResetPassword,
      route: Route,
    },
  ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const SupRoutes = [
  dashboardRoutes,
  rootRoute,
  printRoute,
  batchPrintRoute,
  searchRoute,
  userProfileRoute,
  srcTermsRoute,
  ...appRoutes,
  authRoutes,
  workOrdersTableRoute,
  reportRoutes,
  docsAppRoutes,
  assetmanagementSignsRoutes,
//  assetmanagementWastewaterRoutes,
//  assetmanagementStormwaterRoutes,
  pageRoutes,
];

const authSupProtectedRoutes = [
  dashboardRoutes,
  ...appRoutes,
  workOrdersTableRoute,
  reportRoutes,
  docsAppRoutes,
  assetmanagementSignsRoutes,
//  assetmanagementWastewaterRoutes,
//  assetmanagementStormwaterRoutes,
  pageRoutes,
  printRoute,
  batchPrintRoute,
  searchRoute,
  userProfileRoute,
  srcTermsRoute,
];

const allRoutes = SupRoutes;
const authProtectedRoutes = authSupProtectedRoutes;

const allFlattenRoutes = flattenRoutes(SupRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
