import React, { Component, Fragment} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit';
import { Card, CardBody, Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import { getLoggedInUser } from '../../helpers/authUtils';
import { GlobalFormatter } from '../../services/CRUDoperations.service';
import { workorderService } from '../../services/workorder.service';
import NoDataAvailable from '../../components/Shared/NoDataAvailable';
import Spinner from '../../components/Spinner';
import swal from 'sweetalert';
//import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

class SignAnnualReplacements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: `${new Date().getFullYear()}`,
      isLoading: true,
      years: [2021,2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2024],
      annualWork:[],
    };
  }

  componentDidMount() {
//    this.__getAnnualReport(this.state.selectedYear);
    this.__getAnnualReport(2021);
    setTimeout(() => {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('hide-footer');
      }
    }, 300);

/*  DMM 12/30/22 - CAN'T GET YEARS FROM FEATURES - HARD-CODED ABOVE.
    workorderService.GetAnnualYears().then((res) => {
      const annualYears = res;
      this.setState({
        years: annualYears,
      });  
    });
*/    

  }
  componentWillUnmount() {
    const footer = document.getElementById('footer');
    footer.classList.remove('hide-footer');
    this.setState = (state, callback) => {
      return;
    };
  }

  __getAttachmentUrl2 = async (objectID) => {
    try{
        const resp = await workorderService.GetFeatureAttachments(objectID)
        if (resp.data.attachmentInfos.length > 0) {
          const _attachments = resp.data.attachmentInfos;
          const src = _attachments[0].id;
          const url = 'https://gis.reynoldsburg.gov/arcgis/rest/services/WorkOrderCenter/WOC_Signs/FeatureServer/1/'+ objectID + '/attachments/'+src;
          return url;
        }
        else return 'https://via.placeholder.com/150'
    }
    catch(err){
        console.log('Error fetching attachments :: ', err)
        return 'https://via.placeholder.com/150'
    }
  }

  __getAttachmentUrl = (objectID) => {
    workorderService.GetFeatureAttachments(objectID).then((resp) => {
        if (resp.data.attachmentInfos.length > 0) {
          const _attachments = resp.data.attachmentInfos;
          const src = _attachments[0].id;
          const url = 'https://gis.reynoldsburg.gov/arcgis/rest/services/WorkOrderCenter/WOC_Signs/FeatureServer/1/'+ objectID + '/attachments/'+src;
          return url;
        };
      });
  };

  __getAnnualReport = (year) => {
    this.setState({ isLoading: true });

    workorderService.GetAnnualWork('YRREPLACE', year).then((res) => {
      const annualWork = res.features

      this.setState({
        selectedYear: year,
        annualWork,
        isLoading: false,
      });  
    });
  };
 
  __createWorkOrder = () => {
    swal("Hello");
    swal({
      text: `You are about to create a new work order.  It will include all of the Signs presently listed in the table below.  Please note, associated Signs will be projected out for replacement when you do this.  Are you sure you want to create?`,
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then((action) => {
      if (action) {
        swal("You asked for it!")
      } else {
        swal("Good idea!")
      };
    });
  };

  generatereport = (evt) => {
    this.__getAnnualReport(evt.target.value);
  };



  __renderReportTable = () => {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      switch (this.state.annualWork.length) {
        case 0:
          return (
            <Fragment>
              <NoDataAvailable />
            </Fragment>
          );

        default:
          const TableWithSearch = () => {
            const { SearchBar } = Search;
            const { ExportCSVButton } = CSVExport;
            const defaultSorted = [
              {
                dataField: 'attributes.STREET',
                order: 'asc'
              },
              {
                dataField: 'attributes.TYPE',
                order: 'asc'
              },
            ];

            const columns = [
              {
                dataField: 'attributes.FACILITYID',
                text: 'Facility ID',
                sort: true,
                footer: '',
                footerStyle: (column, colIndex) => {
                  return {
                    border: 'none',
                  };
                },
              },
              {
                dataField: 'attributes.STREET',
                text: 'Street',
                filter: textFilter(),
                sort: true,
                footer: '',
                footerStyle: (column, colIndex) => {
                  return {
                    border: 'none',
                  };
                },
              },
              {
                dataField: 'attributes.TYPE',
                text: 'Type',
                filter: textFilter(),
                sort: true,
                footer: '',
                footerStyle: (column, colIndex) => {
                  return {
                    border: 'none',
                  };
                },
              },
              {
                dataField: 'attributes.CODE',
                text: 'Code',
                filter: textFilter(),
                sort: true,
                footer: '',
                footerStyle: (column, colIndex) => {
                  return {
                    border: 'none',
                  };
                },
              },
              {
                dataField: 'attributes.SIZE',
                text: 'Size',
                filter: textFilter(),
                sort: true,
                footer: '',
                footerStyle: (column, colIndex) => {
                  return {
                    border: 'none',
                  };
                },
              },
              {
                dataField: 'attributes.BACKCOLORS',
                text: 'Background',
                filter: textFilter(),
                sort: true,
                footer: '',
                footerStyle: (column, colIndex) => {
                  return {
                    border: 'none',
                  };
                },
              },
              {
                dataField: 'attributes.DIRECTION',
                text: 'Dir',
                filter: textFilter(),
                sort: true,
                footer: '',
                footerStyle: (column, colIndex) => {
                  return {
                    border: 'none',
                  };
                },
              },
              {
                dataField: 'attributes.LOCATION',
                text: 'Location',
                filter: textFilter(),
                sort: true,
                footer: '',
                footerStyle: (column, colIndex) => {
                  return {
                    border: 'none',
                  };
                },
              },
              {
                dataField: 'image',
                text: 'Image',
                formatter: (cell, row) => (
                  <img src={row.attributes.URL} alt="Sign Image" height="50px" key={row.attributes.OBJECTID}/>
                ),
              },
            ];


            return (
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="page-title-box">
                        <div className="page-title-right">
                          <form className="form-inline">
                            <div className="form-group">
                              <Input
                                type="select"
                                name="select"
                                id="exampleSelect2"
                                className="custom-select"
                                value={this.state.selectedYear}
                                onChange={this.generatereport}
                              >
                                {this.state.years.map((y, index) => (
                                  <option key={index} value={y}>
                                    {y}
                                  </option>
                                ))}
                              </Input>
                            </div>
                          </form>
                        </div>
                        <h1 className="page-title">
                          Annual Sign Replacement Program
                        </h1>
                        {/* <h4 className="page-title">Dashboard</h4> */}
                      </div>
                    </Col>
                  </Row>
                  <p className="text-muted font-14 mb-4">
                    Filter listing / Export report data in CSV format / Create new work orders
                  </p>

                  <ToolkitProvider
                    bootstrap4
                    keyField="attributes.FACILITYID"
                    data={this.state.annualWork}
                    columns={columns}
                    search
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                  >
                    {(props) => (
                      <React.Fragment>
                        <Row>
                          <Col>
                            <SearchBar {...props.searchProps} />
                          </Col>
                          <Col>
                            <h4 className="page-title">
                            Selected Year: {this.state.selectedYear} / Signs: {this.state.annualWork.length}
                            </h4>
                          </Col>
{/*                          <Col>
                            <button
                              id="btn-create-new"
                              className="btn btn-info btn-md ml-1"
                              onClick={this.__createWorkOrder}
                              >
                                <i className="dripicons-plus"></i>
                              </button>
                              <UncontrolledTooltip placement="top" target="btn-create-new">
                                Create Work Order
                              </UncontrolledTooltip>
                    </Col>  */}
                          <Col className = "text-right">
                            <ExportCSVButton
                                {...props.csvProps}
                                className="btn btn-primary"
                              >
                                Export CSV
                              </ExportCSVButton>
                          </Col>
                        </Row>

                        <BootstrapTable
                          {...props.baseProps}
                          bordered={true}
                          defaultSorted={defaultSorted}
                          paging={false}
                          wrapperClasses="table-responsive table-sm"
                          filter={ filterFactory() }
                          striped={true}
                          hover={true}
                          keyField="attributes.FACILITYID"
                        />
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            );
          };

          return <TableWithSearch />;
      }
    }
  };
  render() {
    return (
      <Fragment>
        {this.__renderReportTable()}
        {/* {this.state.typesBreakdowns.length > 0 && (
          <Card>
            <CardHeader>
              <h4 className="header-title mt-2">Resources Types Breakdown</h4>
            </CardHeader>
            <CardBody>
              <ResourceTypesBreakDown
                typesBreakdowns={this.state.typesBreakdowns}
              />
            </CardBody>
          </Card>
        )} */}
      </Fragment>
    );
  }
}
export default SignAnnualReplacements;
