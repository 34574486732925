// @flow
import classNames from 'classnames';
import MetisMenu from 'metismenujs/dist/metismenujs';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getLoggedInUser } from '../helpers/authUtils';
import { changeActiveMenuFromLocation, initMenu } from '../redux/actions';
import { workorderService } from '../services/workorder.service';
import { WorkOrderFilter } from '../utils/filters/filter';

var userType;

const MenuItemWithChildren = ({
  item,
  linkClassNames,
  subMenuClassNames,
  activatedMenuItemIds,
}) => {
  return (
    <li
      className={classNames('side-nav-item', {
        'mm-active': activatedMenuItemIds.indexOf(item.id) >= 0,
        active: activatedMenuItemIds.indexOf(item.id) >= 0,
      })}
    >
      <Link
        to="#"
        className={classNames('has-arrow', 'side-sub-nav-link', linkClassNames)}
        aria-expanded={activatedMenuItemIds.indexOf(item.id) >= 0}
      >
        {item.icon && <i className={item.icon}></i>}
        {item.badge && (
          <span className={`badge badge-${item.badge.variant} float-right`}>
            {item.badge.text}
          </span>
        )}
        <span> {item.name} </span>
      </Link>

      <ul
        className={classNames(subMenuClassNames, 'mm-collapse', {
          'mm-collapsed mm-show': activatedMenuItemIds.indexOf(item.id) >= 0,
        })}
        aria-expanded={activatedMenuItemIds.indexOf(item.id) >= 0}
      >
        {item.children.map((child, i) => {
          return (
            <React.Fragment key={i}>
              {child.children ? (
                <MenuItemWithChildren
                  item={child}
                  linkClassNames=""
                  activatedMenuItemIds={activatedMenuItemIds}
                  subMenuClassNames="side-nav-third-level"
                />
              ) : (
                <MenuItem
                  item={child}
                  className={classNames({
                    'mm-active': activatedMenuItemIds.indexOf(child.id) >= 0,
                  })}
                  linkClassName=""
                />
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </li>
  );
};

const MenuItem = ({ item, className, linkClassName }) => {
  return (
    <li className={classNames('side-nav-item', className)}>
      <MenuItemLink item={item} className={linkClassName} />
    </li>
  );
};

const MenuItemLink = ({ item, className }) => {
  return (
    <Link
      to={item.path}
      className={classNames(
        'side-nav-link-ref',
        'side-sub-nav-link',
        className
      )}
    >
      {item.icon && <i className={item.icon}></i>}
      {item.badge && (
        <span className={`badge badge-${item.badge.variant} float-right`}>
          {item.badge.text}
        </span>
      )}
      <span> {item.name} </span>
    </Link>
  );
};

/**
 * Renders the application menu
 */
type Props = {
  initMenu: PropTypes.func,
  config: PropTypes.object,
  changeActiveMenuFromLocation: PropTypes.func,
  menu: PropTypes.object,
  mode: string,
  history: PropTypes.object,
};
class AppMenu extends Component<Props> {
  menuRef = null;
  openedMenuItems = [];

  static defaultProps = {
    mode: 'vertical',
  };

  componentDidMount = () => {
    const user = getLoggedInUser();
    const fieldCrew = user.roles.findIndex(
      (r) => r.id === 'e217ad31-13c9-452e-a800-d2c04215396c'
    );
    const supervisor = user.roles.findIndex(
      (r) => r.id === '017bf016-611d-4ddd-b5d8-f59a998c31f1'
    );
    const manager = user.roles.findIndex(
      (r) => r.id === 'ef05fa07-09f7-4789-8ab6-f89e3c02a8f1'
    );
    const admin = user.roles.findIndex(
      (r) => r.id === 'd4463c2c-ae7e-4ea6-86cc-7e24a1d2bbd5'
    );
    if (fieldCrew > -1) {
      userType = 'field';
    }
    if (supervisor > -1) {
      userType = 'supervisor';
    }
    if (manager > -1) {
      userType = 'manager';
    }
    if (admin > -1) {
      userType = 'admin';
    }
    workorderService.SetUserRole(userType);
    WorkOrderFilter.SetUserRole(userType);
    if (!this.props.menu.menuItems) this.props.initMenu();
    else this.initMenu();

    this.props.history.listen((location, action) => {
      // hide menus in mobile
      if (document.body) {
        document.body.classList.remove('sidebar-enable');
      }

      if (this.menuRef && this.openedMenuItems && this.openedMenuItems.length) {
        for (const el of this.openedMenuItems) {
          this.menuRef.hide(el);
        }
        this.openedMenuItems = [];
      }
      this.props.changeActiveMenuFromLocation();
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      !prevProps.menu.menuItems ||
      (prevProps.menu.menuItems &&
        prevProps.menu.menuItems !== this.props.menu.menuItems)
    ) {
      this.initMenu();
    }

    if (!this.props.menu.activatedMenuItemIds) {
      this.props.changeActiveMenuFromLocation();
    }
  };

  initMenu() {
    if (this.props.mode === 'horizontal') {
      const menuRef = new MetisMenu('#menu-bar').on(
        'shown.metisMenu',
        (event) => {
          this.openedMenuItems.push(event.detail.shownElement);
          const menuClick = (e) => {
            if (!event.target.contains(e.target)) {
              menuRef.hide(event.detail.shownElement);
              window.removeEventListener('click', menuClick);
            }
          };
          window.addEventListener('click', menuClick);
        }
      );
      this.menuRef = menuRef;
    } else {
      this.menuRef = new MetisMenu('#menu-bar');
    }
  }

  __renderMenuPerUsers() {
    switch (userType) {
      case 'manager':
        break;

      default:
        break;
    }
  }

  render() {
    const isHorizontal = this.props.mode === 'horizontal';
    var activatedKeys = isHorizontal
      ? []
      : this.props.menu
      ? this.props.menu.activatedMenuItemIds
        ? this.props.menu.activatedMenuItemIds
        : []
      : [] || [];

    /**
     * Horizontal layout - We are controlling how many menu items can be displayed in it
     */
    let menuItems =
      this.props.menu && this.props.menu.menuItems
        ? this.props.menu.menuItems
        : [];

    const defaultDisplayedItems = window.screen.width > 1366 ? 7 : 5;
    if (isHorizontal && menuItems.length > defaultDisplayedItems) {
      const displayedItems = menuItems.slice(0, defaultDisplayedItems);
      const otherItems = {
        id: menuItems.length + 1,
        path: '/',
        name: 'More',
        icon: 'uil-ellipsis-h',
        children: menuItems.slice(7, menuItems.length),
      };
      menuItems = [...displayedItems, otherItems];
    }

    switch (userType) {
      case 'field':
        menuItems = menuItems.filter(
          (m) =>
            m.path !== '/dashboard' &&
            m.path !== '/report' &&
            m.path !== '/system' &&
            m.path !== '/assetmanagement/signs' // DMM 12/23/2022 - filter out asset management
//            m.path !== '/assetmanagement/wastewater' &&
//            m.path !== '/assetmanagement/stormwater'
        );
        break;
      case 'supervisor':
        menuItems = menuItems.filter(
          (m) => m.path !== '/dashboard' //&& m.path !== '/report'
        );
        menuItems.map((m) => {
          switch (m.path) {
            case '/system':
              m.children = m.children.filter(
                (c) =>
                  c.path !== '/system/departments' &&
                  c.path !== '/system/users' &&
                  c.path !== '/system/assets/configuration' &&
                  c.path !== '/system/inspections/configuration'
              );
              break;
          }
        });
        break;
      case 'manager':
        menuItems.map((m) => {
          switch (m.path) {
            case '/system':
              m.children = m.children.filter(
                (c) => c.path !== '/system/inspections/configuration'
              );
              break;
          }
        });
        break;
      case 'admin':
        break;
    }

    return (
      <React.Fragment>
        <div className={classNames({ 'topbar-nav': isHorizontal })}>
          {this.props.menu && menuItems && menuItems.length ? (
            <ul className="metismenu side-nav" id="menu-bar">
              {menuItems.map((item, i) => {
                //console.log(item.path);
                switch (item.path) {
                  case '/print/:globalid':
                  case '/batch/print':
                  case '/search/:q':
                  case '/user/profile':
                  case '/src/terms':
                  case '/gis/services/configuration':
                    break;
                  default:
                    return (
                      <React.Fragment key={item.id}>
                        <React.Fragment>
                          {item.header && !isHorizontal && (
                            <li
                              className="side-nav-title side-nav-item"
                              key={i + '-el'}
                            >
                              {item.header}
                            </li>
                          )}

                          {item.children ? (
                            <MenuItemWithChildren
                              item={item}
                              subMenuClassNames="side-nav-second-level"
                              activatedMenuItemIds={activatedKeys}
                              linkClassNames="side-nav-link"
                            />
                          ) : (
                            <MenuItem
                              item={item}
                              className={classNames({
                                'mm-active':
                                  activatedKeys.indexOf(item.id) >= 0,
                              })}
                              linkClassName="side-nav-link"
                            />
                          )}
                        </React.Fragment>
                      </React.Fragment>
                    );
                    break;
                }
              })}
            </ul>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menu: state.AppMenu,
    config: state.AppConfig.config,
  };
};
export const appConfiguration = {
  config: mapStateToProps.config,
};
export default withRouter(
  connect(mapStateToProps, {
    initMenu,
    changeActiveMenuFromLocation,
  })(AppMenu)
);
