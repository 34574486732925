import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit';
import { Card, CardBody, Col, Input, Progress, Row } from 'reactstrap';
import { getLoggedInUser } from '../../helpers/authUtils';
import { GlobalFormatter } from '../../services/CRUDoperations.service';
import { workorderService } from '../../services/workorder.service';
import NoDataAvailable from '../Shared/NoDataAvailable';
import Spinner from '../Spinner';

class ResourceUtilization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: `${new Date().getFullYear()}`,
      isLoading: true,
      years: [],
      problemResolutions: [],
      typesBreakdowns: [],
    };
  }

  componentDidMount() {
    this.__getCostReport(this.state.selectedYear);
    setTimeout(() => {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('hide-footer');
      }
    }, 300);
  }
  componentWillUnmount() {
    const footer = document.getElementById('footer');
    footer.classList.remove('hide-footer');
    this.setState = (state, callback) => {
      return;
    };
  }
  __getCostReport = (year) => {
    this.setState({ isLoading: true });
    workorderService.GetProblemResolutionReport(year).then((res) => {
      let { years, problemResolutions, typesBreakdowns } = res.data;
      const loggedUser = getLoggedInUser();
      const userRole = workorderService.GetUserRole();
      if (userRole === 'supervisor') {
        typesBreakdowns = typesBreakdowns.filter(
          (r) => r.department === loggedUser.department
        );
      }
      this.setState({
        selectedYear: year,
        years,
        problemResolutions,
        typesBreakdowns,
        isLoading: false,
      });
    });
  };

  generatereport = (evt) => {
    this.__getCostReport(evt.target.value);
  };

  __getTally = (arr) => {
    if (arr.length > 0) {
      let total = arr.reduce((acc, item) => {
        return acc + item;
      });
      return GlobalFormatter.FormatUSD(total);
    } else {
      return '$0.00';
    }
  };

  __getUsage = (val) => {
    const usageNber = parseInt(val);
    return (usageNber / this.state.typesBreakdowns.length) * 100;
  };

  getProgressColor = (val) => {
    const usageNber = parseInt(val);
    const totalUsage = (usageNber / this.state.typesBreakdowns.length) * 100;
    if (totalUsage >= 50) {
      return 'success';
    }
    if (totalUsage < 50) {
      if (totalUsage >= 20) {
        return 'primary';
      } else {
        return 'danger';
      }
    }
  };

  __renderReportTable = () => {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      switch (this.state.typesBreakdowns.length) {
        case 0:
          return (
            <Fragment>
              <NoDataAvailable />
            </Fragment>
          );

        default:
          const TableWithSearch = () => {
            const { SearchBar } = Search;
            const { ExportCSVButton } = CSVExport;
            const defaultSorted = [
              {
                dataField: 'Resource',
                order: 'asc',
              },
            ];

            const columns = [
              {
                dataField: 'resource',
                text: 'Resource',
                sort: true,
                footer: '',
                footerStyle: (column, colIndex) => {
                  return {
                    border: 'none',
                  };
                },
              },
              {
                dataField: 'asset',
                text: 'Asset',
                sort: true,
                footer: '',
                footerStyle: (column, colIndex) => {
                  return {
                    border: 'none',
                  };
                },
              },
              {
                dataField: 'type',
                text: 'Resource Type',
                sort: true,
                footer: '',
                footerStyle: (column, colIndex) => {
                  return {
                    border: 'none',
                  };
                },
              },
              {
                dataField: 'usage',
                text: 'Usage',
                sort: true,
                formatter: (cell, row, index) => {
                  return (
                    <div className="progress-w-percent mb-0">
                      <span className="progress-value">{cell} </span>
                      <Progress
                        value={this.__getUsage(cell)}
                        className="progress-sm"
                        color={this.getProgressColor(cell)}
                      />
                    </div>
                  );
                },
                footer: '',
                footerStyle: (column, colIndex) => {
                  return {
                    border: 'none',
                  };
                },
              },
              {
                dataField: 'cost',
                text: 'Cost',
                sort: true,
                formatter: (cell) => {
                  return GlobalFormatter.FormatUSD(cell);
                },
                footer: (columnData) => this.__getTally(columnData),
                footerStyle: (column, colIndex) => {
                  return {
                    backgroundColor: '#d6d8da',
                  };
                },
              },
            ];

            return (
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="page-title-box">
                        <div className="page-title-right">
                          <form className="form-inline">
                            <div className="form-group">
                              <Input
                                type="select"
                                name="select"
                                id="exampleSelect2"
                                className="custom-select"
                                value={this.state.selectedYear}
                                onChange={this.generatereport}
                              >
                                {this.state.years.map((y, index) => (
                                  <option key={index} value={y}>
                                    {y}
                                  </option>
                                ))}
                              </Input>
                            </div>
                          </form>
                        </div>
                        <h1 className="page-title">
                          Yearly Resource Utilization Report
                        </h1>
                      </div>
                    </Col>
                  </Row>
                  <p className="text-muted font-14 mb-4">
                    Filter or export report data in CSV format
                  </p>

                  <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={this.state.typesBreakdowns}
                    columns={columns}
                    search
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                  >
                    {(props) => (
                      <React.Fragment>
                        <Row>
                          <Col>
                            <SearchBar {...props.searchProps} />
                          </Col>
                          <Col className="text-right">
                            <ExportCSVButton
                              {...props.csvProps}
                              className="btn btn-primary"
                            >
                              Export CSV
                            </ExportCSVButton>
                          </Col>
                        </Row>

                        <BootstrapTable
                          {...props.baseProps}
                          bordered={true}
                          defaultSorted={defaultSorted}
                          paging={false} // {paginationFactory({ sizePerPage: 10 })}
                          wrapperClasses="table-responsive table-sm"
                          striped={true}
                          hover={true}
                          keyField="id"
                        />
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            );
          };

          return <TableWithSearch />;
      }
    }
  };
  render() {
    return <Fragment>{this.__renderReportTable()}</Fragment>;
  }
}
export default ResourceUtilization;
