import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import logo from '../../assets/images/auth-logo.png';
import emailImg from '../../assets/images/mail_sent.svg';
import { isUserAuthenticated } from '../../helpers/authUtils';

class AccountCreateSuccess extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Redirect to root
   */
  renderRedirectToRoot = () => {
    const isAuthTokenValid = isUserAuthenticated();
    if (isAuthTokenValid) {
      return <Redirect to="/" />;
    }
  };

  render() {
    const isAuthTokenValid = isUserAuthenticated();
    return (
      <React.Fragment>
        {this.renderRedirectToRoot()}

        {(this._isMounted || !isAuthTokenValid) && (
          <div className="account-pages mt-5 mb-5">
            <Container>
              <Row className="justify-content-center">
                <Col lg={5}>
                  <Card>
                    <div className="card-header pt-4 pb-4 text-center bg-primary">
                      <a href="/">
                        <span>
                          <img src={logo} alt="" height="60" />
                        </span>
                      </a>
                    </div>

                    <CardBody className="p-4 position-relative">
                      <div className="text-center m-auto">
                        <img src={emailImg} alt="" height="64" />

                        <h4 className="text-dark-50 text-center mt-4 font-weight-bold">
                          Account created successfully
                        </h4>
                        <p className="text-muted mb-4">
                          A email has been sent. Please check for an email from
                          Reynoldsburg WOC and click on the included link to confirm
                          your account.
                        </p>

                        <p className="text-center">
                          <Link className="btn btn-primary" to="/account/login">
                            Back to Login
                          </Link>
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect()(AccountCreateSuccess);
