import {
  AvField, AvForm
} from 'availity-reactstrap-validation';
import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  CSVExport, Search
} from 'react-bootstrap-table2-toolkit';
import {
  Button, Card,
  CardBody, Col, Row
} from 'reactstrap';
import PageTitle from '../../components/PageTitle';
import { workorderService } from '../../services/workorder.service';

const SignWorkHistory = () => {
    const footer = document.getElementById('footer');
    if (footer) {
      footer.classList.add('hide-footer');
    }
    const [workHistory, setWorkHistory] = useState([]);
    const [workFeature, setWorkFeature] = useState([]);
    const [isFetchingHistoryData, setIsFetchingHistoryData] = useState(false);
    const [isSearchComplete, setIsSearchComplete] = useState(false);

    const __findWorkHistory = async(event, values) => {
        // call the back end api for work history by facilityID
        setIsSearchComplete(false);
        setIsFetchingHistoryData(true);
        const { facilityID } = values;
        const { features } = await workorderService.GetFeature('FACILITYID', facilityID);
        const { data } = await workorderService.GetWorkOrderHistory(facilityID);
        setWorkFeature(features);
        setWorkHistory(data);
        setIsFetchingHistoryData(false);
        setIsSearchComplete(true);
    }

    const __getGlobalID = (woid, row, status) => {
        if (status === 'Archived') {
          return (
            <> {woid} </>
          );
        } else {
        return (
          <>
            <a href={`/apps/workorders/?id=${row.workOrder.globalID}`}>
              {woid}
            </a>
          </>
        );
        }
      };

    const columns = [
        {
          dataField: 'workOrder.workOrderID',
          text: 'WOID',
          formatter: (cell, row) => {
            return __getGlobalID(cell, row, row.workOrder.status);
          },
        },
        {
          dataField: 'workOrder.workOrderType',
          text: 'Work Type',
          sort: true,
        },
        {
          dataField: 'workOrder.problemName',
          text: 'Problem',
          sort: true,
        },
        {
          dataField: 'workOrder.description',
          text: 'Work Description',
          sort: true,
        },
        {
          dataField: 'workOrder.fieldNotes',
          text: 'Field Notes',
          sort: true,
        },
        {
          dataField: 'workOrder.resolutionName',
          text: 'Resolution',
          sort: true,
        },
        {
          dataField: 'workOrder.plannedDate',
          text: 'Completed / Due',
          formatter: (cell, row) => {
            return workorderService.GetDueDateFormatted(
              cell,
              row.workOrder.status,
              row.workOrder.isRecurring
            );
          },
          sort: true,
          csvFormatter: (cell) => {
            return workorderService.__getHumanDateAndTime(cell);
          },
        },
        {
          dataField: 'workOrder.status',
          text: 'Status',
          formatter: (cell, row, index) => {
            return (
              <>
                <small style={{ textAlign: 'center', color: '#040202' }}>
                  {workorderService.renderStatus(cell)}
                </small>
              </>
            );
          },
          sort: true,
        }
      ];

      const defaultSorted = [
        {
          dataField: 'id',
          order: 'asc',
        },
      ];
  
      const { SearchBar } = Search;
      const { ExportCSVButton } = CSVExport;

    return (
        <>
            <div>
                <PageTitle
                    breadCrumbItems={[
                    { label: 'Map', path: '/apps/workorders' },
                    { label: 'Sign History', active: true },
                    ]}
                    title={'Sign Work History'}
                />
            </div>

            {/** Input field for requesting FacilityID */}
            <AvForm className="p-2" onValidSubmit={__findWorkHistory}>
                <AvField name="facilityID" label="Enter Facility ID" type="text" required />
 
                <Button color="primary"
                  disabled={isFetchingHistoryData} 
                  type="submit"
                  >
                    {isFetchingHistoryData && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        ></span> Finding...
                      </>
                    )}
                    {!isFetchingHistoryData && (
                      <>Find</>
                    )}
                 
                </Button>
            </AvForm>

            {
                workFeature.length > 0 && workHistory.length > 0 && (
                    <>
                        <Card>
                            <CardBody>
{/*                            <h4 className="header-title">Search or Export {workFeature[0].attributes.LOCATION}</h4>
                            <p className="text-muted font-14 mb-4">
                                Search or export work order history data in a CSV format
                </p>  */}
{/*                              <a
                                  id={`wo-${workFeature[0].attributes.GlobalID}`}
                                  href="#"
                                  className="list-group-item list-group-item-action workorder-item pl-4"
                                  style={{ padding: '0 1.25rem' }}
                                  key={idx}
                                  onClick={(e) => this.__ZoomToWorkOrder(wo, e)}
                >  */}
{/*                                  <CustomInput
                                    type="checkbox"
                                    id={wo.attributes.WOID}
                                    className="wo-batch-toggle-item"
                                    onClick={(evt) =>
                                      this.__toggleBatchPrintItem(evt.target.checked, wo)
                                    }
                                  />  */}
{/*                                  <div className="d-flex w-100 justify-content-between">
                                    <h6 className="mb-0">Due Date:</h6>
                                    <small className="mt-1" style={{ fontWeight: 'bold' }}>
                                      {wo.attributes.IsRecurring === 'Yes' && (
                                        <>
                                          <i
                                            className="uil uil-sync"
                                            style={{ fontSize: '0.9rem' }}
                                          ></i>{' '}
                                        </>
                                      )}

                                      {workorderService.GetDueDateFormatted(
                                        wo.attributes.PlannedDate,
                                        wo.attributes.Status
                                      )}
                                    </small>
                                      </div>  */}
                                                  
                                      <Row>
                                        <Col xl={4}>
                                          <h5 className="mb-0">TYPE: {workFeature[0].attributes.TYPE}</h5>
                                          <h5 className="mb-0">STREET: {workFeature[0].attributes.STREET} - DIRECTION:{' '}
                                                {workFeature[0].attributes.DIRECTION}{' '}</h5>
                                          <h5 className="mb-0">LOCATION: {workFeature[0].attributes.LOCATION}</h5>
                                        </Col>
                                        <Col xl={4}>
                                          <h5 className="mb-0">CODE: {workFeature[0].attributes.CODE}</h5>
                                          <h5 className="mb-0">SIZE: {workFeature[0].attributes.SIZE}</h5>
                                          <h5 className="mb-0">REPLACEMENT: {workFeature[0].attributes.YRREPLACE}</h5>
                                        </Col>
                                        <Col xl={4}>
                                          <img src={workFeature[0].attributes.URL} alt="Sign Image" height="70px"/>
                                        </Col>
                                      </Row>
{/*                                </a>  */}

                            <ToolkitProvider
                                bootstrap4
                                keyField="workOrder.workOrderID"
                                data={workHistory}
                                columns={columns}
                                search
                                exportCSV={{ onlyExportFiltered: false, exportAll: true }}
                            >
                                {(props) => (
                                <React.Fragment>
                                    <Row>
                                    <Col>
                                        <SearchBar {...props.searchProps} />
                                    </Col>
                                    <Col className="text-right">
                                        <ExportCSVButton
                                        {...props.csvProps}
                                        className="btn btn-primary"
                                        >
                                        Export CSV
                                        </ExportCSVButton>
                                    </Col>
                                </Row>  
                                <div>
                                    <BootstrapTable
                                    {...props.baseProps}
                                    bordered={true}
                                    sort={{dataField:'workOrder.workOrderID',order:'desc'}}
                                    defaultSorted={defaultSorted}
                                    keyField="workOrder.workOrderID"
                                    paging={false}
                                    wrapperClasses="table-responsive"
                                    />
                                    </div>
                                </React.Fragment>
                                )}
                            </ToolkitProvider>
                            </CardBody>
                        </Card>
                    </>
                )
            }

            {isSearchComplete && workFeature.length < 1 && (
              <div className="text-center">
              <p className="text-danger mt-3">
                No Sign found with that ID.
              </p>
            </div>
            )}

            {isSearchComplete && workHistory.length < 1 && (
              <div className="text-center">
                <p className="text-danger mt-3">
                  There is no work history data available for that asset at this time.
                </p>
              </div>
            )}


        </>
    )
}

export default SignWorkHistory;
