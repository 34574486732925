import { AvField, AvForm } from 'availity-reactstrap-validation';
import classnames from 'classnames';
import React, { Component, Fragment } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';
import swal from 'sweetalert';
import notFoundImg from '../../assets/images/file-searching.svg';
import ImageUrl from '../../assets/images/work-orders/no-image-available.png';
import {
  CrudOperations,
  GlobalFormatter,
} from '../../services/CRUDoperations.service';
import { mapGraphics } from '../../services/Graphics/graphics.service';
import { workorderService } from '../../services/workorder.service';
import Spinner from '../Spinner';
import './wo.details.css';


const __listPanelContentScroller = {
  position: 'absolute',
  top: '70px',
  left: '0',
  right: '0',
  bottom: '65px',
  overflow: 'auto',
};

const __workOrders_total = {
  position: 'absolute',
  top: '15px',
  left: '15px',
  fontSize: '1.8rem',
  fontWeight: '400',
  color: '#afadad',
};

const _btnFooter = {
  backgroundColor: '#a4a3a4',
  color: '#fff',
};

export default class WorkFieldUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      departmentID: 0,
      activeTabResourceSelection: '1',
      problemResolutionModal: false,
      workOrderEditForm: null,
      featureLayer: null,
      editFeature: null,
      highlight: null,
      GISAssets: [],
      GISResources: [],
      workOrderQuestionAnswers: [],
      Resources: [],
      WorkOrderDetails: null,
      AddedWorkResources: [],
      SelectedWorkResource: [],
      view: null,
      AttachmentUrl: ImageUrl,
      AttachmentFile: null,
      workAttachmentName: '',
      isSubmittingWorkEdits: false,
      SelectedResourceObj: {
        name: '',
        rate: 0,
        qty: 1,
        cost: 0,
        resourceID: 0,
        departmentID: 0,
      },
      IsResourceSelected: false,
      problems: [],
      resolutions: [],
//      workOrderProblemResolutions: [],  // removed when changed from multi to single storage
      InputCustomResourceName: '',
      InputCustomResourceRate: '',
      InputCustomResourceQty: 1,
      InputCustomResourceType: 'Equipment',
      activeAsset: '',
      IsZoomingToExtent: false,
      IsZoomingToAsset: false,
      isComplete: false,
      RequiredAsset: false,
      RequiredResource: false,
    };
    this.attachmentChanged = this.attachmentChanged.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleProblemResolutionModal =
      this.toggleProblemResolutionModal.bind(this);
  }

  toggleResourcesSelectionTab = (tab) => {
    if (this.state.activeTabResourceSelection !== tab) {
      this.setState({
        activeTabResourceSelection: tab,
      });
    }
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      if (parseInt(tab) === 2) {
        workorderService.SetAppInAddingAssets(true);
      } else {
        workorderService.SetAppInAddingAssets(false);
      }
    }
  };

  toggleProblemResolutionModal = () => {
    this.setState((prevState) => ({
      problemResolutionModal: !prevState.problemResolutionModal,
    }));
  };

  attachmentChanged = (e) => {
    const file = e.target.files[0];
    if (file) {
      var mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.ImageUrl = reader.result;
        this.setState({
          AttachmentUrl: reader.result,
          AttachmentFile: file,
          workAttachmentName: file.name,
        });
      };
    }
  };

  CancelWorkCreation = () => {
    this.state.GISAssets.length=0;
    this.state.Resources.length=0;
//    const workCreateLocationGL = this.state.view.map.findLayerById(
//      'workCreateLocationGL'
//    );
//    workCreateLocationGL.removeAll();
//    workorderService.SetAppInAddingAssets(false);
//    this.state.view.graphics.removeAll();
    this.props.renderComponent('');
  };

  componentDidMount() {
    this.state.GISAssets.length=0;
    this.state.GISResources.length=0; 

    const { attributes } = this.props.workorder.feature;
    //console.log(attributes);
    // get Work order details
    workorderService
      .GetManagedOrderDetails(attributes.GlobalID)
      .then((results) => {
        if (results.data === '') {
        } else {
          workorderService
            //.GetResourcesByDepartment(results.data.departmentID) //GetResourcesByGISAsset
            .GetResourcesByGISAsset(attributes.AssetID) //GetResourcesByGISAsset
            .then((resources) => {
              // set options for selecting resources
              // console.log(resources);
              const Resources = this.state.Resources;

              if (resources.data.length > 0) {
                resources.data.map((r) => {
                  const { resourceID, rate, resourceAsset, name, type } = r;
                  Resources.push({
                    resourceID,
                    rate,
                    value: resourceID, // will be the resource id
                    label: `${name} | ${type}`,
                    resourceAsset,
                  });
                });
              }

/*              CrudOperations.GetProblemResolutionsByDepartment(
                results.data.departmentID
              )  */
              CrudOperations.GetProblemResolutionsByAsset(
                attributes.AssetID
              )
                .then((problemsRes) => {
                  const { problems, resolutions } = problemsRes.data;

                  const {
                    gisAssets,
                    workOrderResources,
                    workOrderQuestionAnswers,
                  } = results.data;

                  this.setState({
                    WorkOrderDetails: results.data,
                    GISAssets: gisAssets,
                    GISResources: workOrderResources,
                    Resources: Resources,
                    workOrderQuestionAnswers,
                    problems,
                    resolutions,
                    departmentID: results.data.departmentID,
                  });
                  workorderService.SetAppEditMode(true);
                })
                .catch((err) =>
                  console.log(
                    'Error, unable to fetch problems resolutions :: ',
                    err
                  )
                );
            });
        }
      })
      .catch((err) => console.log(`Error fetching order details :: ${err}`));

    //console.log(this.props);
    this.__initEditor();
  }
  componentWillUnmount() {
    workorderService.SetAppEditMode(false);
    workorderService.SetAppInAddingAssets(false);
    this.setState = (state, callback) => {
      return;
    };
  }

  deleteWorkAsset = (asset) => {
    // remove item from collection
    const GISAssets = this.state.GISAssets;
    const _index = GISAssets.findIndex(
      (a) => a.facilityID === asset.facilityID
    );
    if (_index > -1) {
      // remove obj
      GISAssets.splice(_index, 1);
      this.setState({ GISAssets });
      console.log("here")
    }
  };

  __getCaseSensitiveAttributeName = (fieldAliases, fieldName) => {
    for (var key in fieldAliases) {  
      if (key.toUpperCase() === fieldName.toUpperCase()){
        return key;
        }
    }
    return "";
  };

  __initEditor = () => {
    const view = workorderService.mapView();
    view.popup.on('trigger-action', (evt) => {
      if (evt.action.id === 'add-work-asset-action') {
        const { selectedFeature } = view.popup;
        var mySensitiveField = this.__getCaseSensitiveAttributeName(selectedFeature.attributes, "facilityid");
        const layer_url = selectedFeature.sourceLayer.url;
        const __source__layer = layer_url.split('MapServer')[0];
        let _gisServicesConfigurationID = 0;
        let _gisServicesDepartmentID = 0;
        const __appLayers = workorderService.GetApplicationLayers();

        const __service = __appLayers.find(
          (s) => s.serviceLink === `${__source__layer}MapServer`
        );
        _gisServicesConfigurationID = parseInt(
          __service.gisServicesConfigurationID
        );
        _gisServicesDepartmentID = parseInt(
          __service.departmentID
        );

        let __TitleAndServiceName = view.popup.title.split('|');
        const __gisAsset = {
          assetName: __TitleAndServiceName[0].trim(),
          facilityID: selectedFeature.attributes[mySensitiveField],
          gisServicesConfigurationID: _gisServicesConfigurationID,  // DMM 12/6/2022
//          workOrderID: this.props.workorder.feature.attributes.WOID,  // DMM 12/6/2022
//          gisAssetID: 0,  // 12/6/2022
//          sourceLayer: __TitleAndServiceName[1].trim(),  // DMM 12/8/2022
//          departmentID: _gisServicesDepartmentID,  // DMM 12/8/2022
          };

        this.addAsset(__gisAsset);
        view.popup.close();
      }
    });

    const { geometry } = this.props.workorder.feature;
    workorderService.__flushSelectedWork(geometry, view);
  };

  addAsset = (asset) => {
    const gisAssets = this.state.GISAssets;
    const _indexExist = gisAssets.findIndex(
      (a) => a.facilityID === asset.facilityID
    );
    if (_indexExist > -1) {
      swal('This asset has been already added');
    } else {
      gisAssets.push(asset);
      this.setState({ gisAssets });
    }
  };

  OnResourceSelect = (e) => {
    //console.log(e);
    var SelectedResourceObj = this.state.SelectedResourceObj;
    const { resourceID, resourceAsset, rate, label } = e;

    SelectedResourceObj = {
      name: label.split('|')[0],
      type: label.split('|')[1],
      rate,
      qty: 1,
      cost: rate,
      resourceID,
      resourceAsset,
    };

    this.setState({ SelectedResourceObj, IsResourceSelected: true });
  };
  
  __renderSubmitButton() {
    if (this.state.workOrderQuestionAnswers.length < 1) {
      return (
        <button
          className="btn btn-primary"
          disabled={this.state.isSubmittingWorkEdits}
          onClick={this.__submitWorkEdits}
        >
          {this.state.isSubmittingWorkEdits ? 'Submitting...' : 'Complete Work'}
        </button>
      );
    }
  }

  __getResourcesTotalCost = () => {
    let cost = 0;
    this.state.GISResources.map((r) => {
//      cost = cost + r.unit * r.resource.rate;  // DMM 12/9/2022
      cost = cost + r.unit * r.rate;
    });

    return GlobalFormatter.FormatUSD(cost);
  };

  RemoveFromAddedresources = (resource) => {
    if (resource) {
      const GISResources = this.state.GISResources;
      const index = GISResources.findIndex(
        (r) => r.resourceID === resource.resourceID
      );
      if (index > -1) {
        GISResources.splice(index, 1);
        this.setState({ GISResources });
      }
    }
  };

  __updateSelectedResourceObjCost = (qty) => {
    const SelectedResourceObj = this.state.SelectedResourceObj;
    SelectedResourceObj.qty = qty;
    const cost = qty * SelectedResourceObj.rate;
    SelectedResourceObj.cost = cost;
    this.setState({ SelectedResourceObj });
  };

  __addToWorkResources = (e) => {
    e.preventDefault();
    const GISResources = this.state.GISResources;
    const { name, rate, qty, cost, type, resourceID } =
      this.state.SelectedResourceObj;

    // check for duplicate
    const existIdx = GISResources.findIndex(
      (r) =>
/*        r.resource.name.trim().toUpperCase() === name.trim().toUpperCase() &&
        r.resource.type.trim().toUpperCase() === type.trim().toUpperCase()  */  // DMM 12/9/2022
        r.name.trim().toUpperCase() === name.trim().toUpperCase() &&
        r.type.trim().toUpperCase() === type.trim().toUpperCase()
    );

    if (existIdx > -1) {
      swal('This resource already exist in the list');
      return;
    }

    GISResources.push({
      resourceID,
      unit: qty,
      cost,
      rate,
      name: name.trim(),
      type: type.trim(),
/*      resource: {
        resourceID,
        name,
        type,
        rate,
        resourceAsset: this.state.SelectedResourceObj.resourceAsset,
      },  */    // DMM 12/9/2022
    });
    this.setState({ GISResources, IsResourceSelected: false });
  };

  __zoomToAssetsExtent = () => {
    this.setState({ IsZoomingToExtent: true });
    mapGraphics
      .highlightAssetAndZoomTo(workorderService.mapView(), this.state.GISAssets)
      .then((features) => this.__goToAssets(features));
  };
  
  __zoomToAsset = (asset) => {
    this.setState({ activeAsset: asset.facilityID, IsZoomingToAsset: true });
    mapGraphics
      .highlightAssetAndZoomTo(workorderService.mapView(), [asset])
      .then((features) => this.__goToAssets(features));
  };

  __goToAssets = (features) => {
    this.setState({ IsZoomingToAsset: false, IsZoomingToExtent: false });
/*    workorderService
      .mapView()
      .goTo(features)  // DMM 12/8/2022
      .then(() => {
        this.setState({ IsZoomingToAsset: false, IsZoomingToExtent: false });
      }); */
  };

  _renderAssets() {
    return (
      <Fragment>
        <h5 id="gis-assets-add-ui">Provide assets if any</h5>
        <p className="text-muted mb-2">
          Turn the utility on and select a desired asset to be added to this
          work then click on <b>"Add Work Asset"</b>
        </p>
        <div className="mb-2">
          <ul className="list-group">
            <li className="list-group-item active">
              <i className="dripicons-network-3 me-1"></i> GIS Assets{' '}
              <span style={{ float: 'right' }}>
                [ {this.state.GISAssets.length} ]
              </span>
            </li>
            {this.state.GISAssets.map((a, index) => (
              <li
                key={index}
                className={`list-group-item ${
                  this.state.activeAsset === a.facilityID ? 'active-asset' : ''
                }`}
              >
                <span style={{ float: 'right' }}>
                  {a.facilityID === this.state.activeAsset &&
                    this.state.IsZoomingToAsset && (
                      <span
                        role="status"
                        className="spinner-grow text-warning"
                        style={{
                          width: '1rem',
                          height: '1rem',
                        }}
                      ></span>
                    )}
                  <Button
                    title="Zoom To"
                    color="link"
                    onClick={() => this.__zoomToAsset(a)}
                  >
                    <i className="dripicons-brush"></i>
                  </Button>

                  <Button
                    title="Delete"
                    color="link"
                    className="text-danger"
                    onClick={() => this.deleteWorkAsset(a)}
                  >
                    <i className="uil uil-times-circle"></i>
                  </Button>
                </span>
                <p className="mb-0 pb-0">{a.assetName}</p>
                <p className="mb-0 pb-0">{a.facilityID}</p>
              </li>
            ))}
          </ul>
        </div>

        <ul className="list-inline wizard mb-0 mt-2">
          <li className="previous list-inline-item">
            <Button onClick={() => this.toggle('1')} color="info">
              <i className="dripicons-chevron-left"></i>
            </Button>
          </li>
          <li className="next list-inline-item float-right">
            <Button onClick={() => this.toggle('3')} color="info">
              <i className="dripicons-chevron-right"></i>
            </Button>
          </li>
        </ul>
      </Fragment>
    );
  }

  beforeSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
  };

  __addCustomResource = () => {
    if (this.state.InputCustomResourceName === '') {
      swal('Please provide the resource name');
      return;
    }

    if (this.state.InputCustomResourceRate === '') {
      swal('Please provide the resource rate');
      return;
    }
    const GISResources = this.state.GISResources;
    const { attributes } = this.props.workorder.feature;

    // check for duplicate
    const existIdx = GISResources.findIndex(
      (r) =>
        r.resource.name.trim().toUpperCase() ===
          this.state.InputCustomResourceName.trim().toUpperCase() &&
        r.resource.type.trim().toUpperCase() ===
          this.state.InputCustomResourceType.trim().toUpperCase()
    );

    if (existIdx > -1) {
      swal('This resource already exist in the list');
      return;
    }

    //const { name, rate, quantity, type } = values;
    GISResources.push({
      resourceID: 0,
      unit: this.state.InputCustomResourceQty,
      cost: this.state.InputCustomResourceRate * this.state.InputCustomResourceQty, //rate * quantity,
      rate: this.state.InputCustomResourceRate,  //rate needed for storage too!  12/1/2022
      resource: {
        //resourceID: 0,
        //departmentID: this.state.departmentID,
        type: this.state.InputCustomResourceType,
        name: this.state.InputCustomResourceName,
        rate: this.state.InputCustomResourceRate,
        resourceAsset: {
          //resourceAssetID: 0,
          //resourceID: 0,
          gisServicesConfigurationID: attributes.AssetID,
        },
      },
    });
    this.setState({ GISResources });
  };

  _renderResources() {
    const { name, rate, qty, cost } = this.state.SelectedResourceObj;
    return (
      <Fragment>
        <h5>Provide resources if any</h5>
        <p className="text-muted mb-2">
          Select a desired resource and provide the quantity for the total cost.
        </p>

        {/* Resources components */}

        <div className="mb-2">
          <Alert color="primary" className="p-0">
            <Nav tabs className="nav nav-pills bg-nav-pills nav-justified">
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames('rounded-0', {
                    active: this.state.activeTabResourceSelection === '1',
                  })}
                  onClick={() => {
                    this.toggleResourcesSelectionTab('1');
                  }}
                >
                  <span className="d-none d-lg-block">Select Resource</span>
                </NavLink>
              </NavItem>
{/*              <NavItem>
                <NavLink
                  href="#"
                  className={classnames('rounded-0', {
                    active: this.state.activeTabResourceSelection === '2',
                  })}
                  onClick={() => {
                    this.toggleResourcesSelectionTab('2');
                  }}
                >
                  <span className="d-none d-lg-block">Or Add Custom</span>
                </NavLink>
                </NavItem>  [2022-13] DMM 7/12/2022*/}
            </Nav>
            <TabContent activeTab={this.state.activeTabResourceSelection}>
              <TabPane tabId="1" className="p-2">
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  onChange={(e) => this.OnResourceSelect(e)}
                  options={this.state.Resources}
                ></Select>
              </TabPane>
              <TabPane tabId="2" className="p-2 pl-2">
                <Row className="p-0 pl-2">
                  <Col md={8} className="p-0">
                    <label htmlFor="inputResource" className="form-label">
                      Resource name
                    </label>
                    <input
                      type="text"
                      value={this.state.InputCustomResourceName}
                      onChange={(e) =>
                        this.setState({
                          InputCustomResourceName: e.target.value,
                        })
                      }
                      className="form-control"
                      id="inputResource"
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <label htmlFor="inputRate" className="form-label">
                      Rate
                    </label>
                    <input
                      type="text"
                      value={this.state.InputCustomResourceRate}
                      placeholder={'0.00'}
                      onChange={(e) =>
                        this.setState({
                          InputCustomResourceRate: e.target.value,
                        })
                      }
                      className="form-control"
                      id="inputRate"
                      required
                    />
                  </Col>
                </Row>

                <Row className="p-0 pl-2 mt-2">
                  <Col md={3} className="p-0" style={{ position: 'relative' }}>
                    <small
                      style={{
                        position: 'absolute',
                        top: '3px',
                        left: '26px',
                      }}
                    >
                      (Hours)
                    </small>
                    <label htmlFor="inputQty" className="form-label">
                      Quantity
                    </label>
                    <input
                      type="number"
                      value={this.state.InputCustomResourceQty}
                      onChange={(e) =>
                        this.setState({
                          InputCustomResourceQty: e.target.value,
                        })
                      }
                      className="form-control"
                      id="inputQty"
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <label htmlFor="inputresourceType" className="form-label">
                      Type
                    </label>
                    <select
                      className="form-control"
                      id="inputresourceType"
                      value={this.state.InputCustomResourceType}
                      onChange={(e) =>
                        this.setState({
                          InputCustomResourceType: e.target.value,
                        })
                      }
                    >
                      <option value="Equipment">Equipment</option>
                      <option value="Material">Material</option>
                      <option value="Labor">Labor</option>
                      <option value="Other">Other</option>
                    </select>
                  </Col>
                  <Col md={3} className="p-0" style={{ marginTop: '29px' }}>
                    <Button
                      color="primary"
                      type="button"
                      onClick={this.__addCustomResource}
                    >
                      <i className="uil uil-plus"></i> Add
                    </Button>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Alert>
          <div
            className="table-responsive"
            style={{
              display: this.state.IsResourceSelected ? 'block' : 'none',
            }}
          >
            <h6 className="font-14">{name}</h6>
            <table className="table-sm table-centered mb-0 font-14 table">
              <thead className="thead-light">
                <tr>
                  <th>{rate}</th>
                  <th style={{ width: '30%' }}>
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      value={qty}
                      min={1}
                      onChange={(v) =>
                        this.__updateSelectedResourceObjCost(v.target.value)
                      }
                    />
                  </th>
                  <th>{GlobalFormatter.FormatUSD(cost)}</th>
                  <th>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={this.__addToWorkResources}
                    >
                      <i className="uil uil-plus"></i>
                    </button>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <table className="mb-0 mt-2 table table-sm">
            <thead>
              <tr>
                <th>Resource</th>
                <th>Rate</th>
                <th>Quantity</th>
                <th>Cost</th>
                <th>
                  <i
                    className="dripicons-trash btn-resource-delete"
                    title={'Delete all'}
                    onClick={() =>
                      this.setState({
                        GISResources: [],
                      })
                    }
                  ></i>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.GISResources.map((r, index) => (
                <tr key={index}>
                  <td>
{/*                  {r.resource.name} | {r.resource.type}  DMM 12/9/2022 */} 
                    {r.name} | {r.type}
                  </td>
{/*                  <td>{r.resource.rate}</td>  DMM 12/9/2022 */}
                  <td>{r.rate}</td>
                  <td>{r.unit}</td>
{/*                  <td>{GlobalFormatter.FormatUSD(r.resource.rate * r.unit)}</td>  DMM 12/9/2022 */}
                  <td>{GlobalFormatter.FormatUSD(r.rate * r.unit)}</td>
                  <td>
                    <i
                      className="uil uil-times-square btn-resource-delete"
                      title="Remove"
                      onClick={() => this.RemoveFromAddedresources(r)}
                    ></i>
                  </td>
                </tr>
              ))}
              <tr
                className="bg-primary text-white"
                style={{ fontWeight: 'bold' }}
              >
                <td>Total:</td>
                <td colSpan="3" className="text-right">
                  {this.__getResourcesTotalCost()}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr />

        <ul className="list-inline wizard mb-0">
          <li className="previous list-inline-item">
            <Button onClick={() => this.toggle('2')} color="info">
              <i className="dripicons-chevron-left"></i>
            </Button>
          </li>
          <li className="next list-inline-item float-right">
            <Button onClick={() => this.toggle('4')} color="info">
              <i className="dripicons-chevron-right"></i>
            </Button>
          </li>
        </ul>
      </Fragment>
    );
  }

  _renderAttachments() {
    return (
      <Fragment>
        <h5>Add attachment if needed</h5>
        <p className="text-muted mb-2">Capture photo from camera</p>

        {/*Attachments wrapper*/}
        <div className="attachmentInfo">
          <table className="table table-striped">
            <tbody>
              <tr>
                <td colSpan="2" style={{ position: 'relative' }}>
                  <div className="item">
                    <img
                      id="img-new-photo-posted"
                      src={this.state.AttachmentUrl}
                      alt="photo"
                      width="100%"
                      style={{ maxHeight: '300px' }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="custom-file form-control-sm">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="Attachments"
                      onChange={(evt) => this.attachmentChanged(evt)}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="Attachments"
                      aria-describedby="Attachments"
                    >
                      {this.state.workAttachmentName}
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />

        <ul className="list-inline wizard mb-0">
          <li className="previous list-inline-item">
            <Button onClick={() => this.toggle('3')} color="info">
              <i className="dripicons-chevron-left"></i>
            </Button>
          </li>
          <li className="next list-inline-item float-right">
            <Button onClick={() => this.toggle('5')} color="info">
              <i className="dripicons-chevron-right"></i>
            </Button>
          </li>
        </ul>
      </Fragment>
    );
  }
  _renderEssentialQuestions() {
    if (this.state.workOrderQuestionAnswers.length > 0) {
      return (
        <Fragment>
          {this.state.workOrderQuestionAnswers.map((q, index) => (
            <div key={index} className="mb-2 mt-2">
              <label>{`Question ${index + 1}`}</label>
              <p className="m-0 mb-1 text-danger" name={`question-${index}`}>
                {q.essentialQuestion.question}
              </p>
              <AvField
                name={`answer-${index}`}
                label={`Answer ${index + 1}`}
                type="textarea"
                value={q.answer}
                required
              />
            </div>
          ))}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="text-center">
            <img src={notFoundImg} height="90" alt="" className="mt-4" />
            <h4 className="text-uppercase text-danger mt-3">
              No Details found
            </h4>
            <p className="text-muted mt-3">
              There are no Essential Details linked to this work order.
            </p>
          </div>
        </Fragment>
      );
    }
  }

  /**
   * Update work order
   * @param {*} values
   * @returns
   */

  updateWorkInfo = (event, values) => {

    const { isComplete } = this.state;
    const workOrderQuestionAnswers = this.state.workOrderQuestionAnswers;
    const QuestionsNotAnswered = [];
    if (values !== null) {
      if (values.fieldNotes === '' && isComplete) {
        swal(`Please provide required field notes`);
        this.toggle('1');
        return;
      }
      if (workOrderQuestionAnswers.length > 0) {
        for (let index = 0; index < workOrderQuestionAnswers.length; index++) {
          const workorder = workOrderQuestionAnswers[index];
          const response = values[`answer-${index}`].trim();
          workOrderQuestionAnswers[index].answer = response;
          if (
            response === '' ||
            response.indexOf('Details required') > -1
          ) {
            QuestionsNotAnswered.push(workorder.essentialQuestion.question);
          }
        }
      }

      if (QuestionsNotAnswered.length > 0 && isComplete) {
        let _questions = '';
        for (let q = 0; q < QuestionsNotAnswered.length; q++) {
          _questions = _questions + `${q + 1}. ${QuestionsNotAnswered[q]} \n`;
        }
        swal(
          `Please provide required details for the following: \n \n ${_questions}`
        );
        this.toggle('5');
        return;
      }

      if (this.state.GISAssets.length === 0 && isComplete) {  
        if (this.__getPRAsset(values.problemID,values.resolutionID) === "Yes") {
          swal(
          `Must add ASSETS before completion... \n Required for the selected Problem / Resolution`
          );
          this.toggle('2');
          return;
        }
      } 

      if (this.state.GISResources.length === 0 && isComplete) {  
        if (this.__getPRResource(values.problemID,values.resolutionID) === "Yes") {
          swal(
          `Must add RESOURCES before completion... \n Required for the selected Problem / Resolution`
          );
          this.toggle('3');
          return;
        }
      }

      // confirmation
  
      swal({
        text: isComplete
          ? 'Are you sure to mark this work as complete?'
          : 'Are you sure to update this work? The Work will not be completed',
        closeOnClickOutside: false,
        buttons: { cancel: 'No', confirm: 'Yes' },
        dangerMode: true,
      }).then((action) => {
        if (action) {
          this.setState({
            isSubmittingWorkEdits: true,
            workOrderQuestionAnswers,
          });

//          console.log(this.props.user);
          let feature = this.props.workorder.feature;
//          const { firstName, lastName } = this.props.user;  /* ERROR HERE DMM */

          Object.keys(feature.attributes).forEach((fieldName) => {
            switch (fieldName) {
              case 'LocationAddress':
                feature.attributes[fieldName] = values.address;
                break;
              case 'FieldNotes':
                feature.attributes[fieldName] = values.fieldNotes;
                break;
              case 'Status':
                if (isComplete) {
                  feature.attributes[fieldName] = 'Completed';
                }
                break;
/*              case 'AssignedToID':  //BAD CODE!  ASSIGNED TO DEREK MAIR IF UNASSIGNED?
                if (feature.attributes[fieldName] === 'Unassigned') {
                  feature.attributes[fieldName] = 'Derek Mair'; //`${firstName} ${lastName}`;
                }
                break;  */
              case 'DateCompleted':
                if (isComplete) {
                  feature.attributes[fieldName] =
                    workorderService.__getEpochTime(new Date().getTime());
                }
                break;
              case 'ProblemName':
                if (values.problemID == 'Select'){
                  values.problemID = '';
                }
                if (values.problemID !== '') {
                  feature.attributes[fieldName] = this.__getProblemName(values.problemID);
                } else {
                  feature.attributes[fieldName] = '';
                } 
                break;
              case 'ResolutionName':
                if (values.resolutionID == 'Select'){
                  values.resolutionID = '';
                }
                if (values.resolutionID !== '') {
                  feature.attributes[fieldName] = this.__getResolutionName(values.resolutionID);
                } else {
                  feature.attributes[fieldName] = '';
                } 
                break;
              case 'ProblemID':
                if (values.problemID == 'Select'){
                  values.problemID = '';
                }
                if (values.problemID !== '') {
                  feature.attributes[fieldName] = values.problemID;
                } else {
                  feature.attributes[fieldName] = 0;
                } 
                break;
              case 'ResolutionID':
                if (values.resolutionID == 'Select'){
                  values.resolutionID = '';
                }
                if (values.resolutionID !== '') {
                  feature.attributes[fieldName] = values.resolutionID;
                } else {
                  feature.attributes[fieldName] = 0;
                } 
                break;
              case 'Description':
                if (values.description !== '') {
                  feature.attributes[fieldName] = values.description;
                } else {
                  feature.attributes[fieldName] = '';
                } 
                break;
              case 'PlannedDate':
                if (values.duedate !== '') {
                  var selectedDate = new Date(values.duedate).toGMTString();
                  var __esri_date = selectedDate;
                  feature.attributes[fieldName] = workorderService.__getEpochTime(new Date(__esri_date).getTime());
                } else {
                  feature.attributes[fieldName] = '1/1/1970';
                } 
                break;
              default:
                break;
            }
          });

          // apply changes
          const edits = {
            updateFeatures: [feature],
          };
          workorderService.ApplyWorkOrderEdits(edits).then((result) => {
            if (result.updateFeatureResults.length > 0) {
              workorderService.RefreshWorkOrders();
              // Add attachments if any
              if (this.state.AttachmentFile) {
                workorderService
                  .AddAttachmentToWorkOrder(
                    this.state.AttachmentFile,
                    feature.attributes.OBJECTID
                  )
                  .then((attachmentResults) => {
                    //console.log(' :: SUCCESS :: ', attachmentResults);
                  })
                  .catch((attachmentError) => {
                    console.log(
                      '==============================================='
                    );
                    console.error(
                      '[ addAttachment ] FAILURE: ',
                      attachmentError
                    );
                  });
              }

              // related work records updates
              // update related records
              const __workOrderObj = {
                workOrderID: this.state.WorkOrderDetails.workOrderID,  // ID-able
                globalID: this.state.WorkOrderDetails.globalID,  // ID-able
                plannedDate: new Date(values.duedate),  // editable
                problemName: feature.attributes.ProblemName,  // editable
                resolutionName: feature.attributes.ResolutionName, // editable
                problemID: feature.attributes.ProblemID,  // editable
                resolutionID: feature.attributes.ResolutionID, // editable
                dateCompleted: feature.attributes.DateCompleted // changed
                  ? new Date(feature.attributes.DateCompleted)
                  : new Date("1/1/1970"),
                locationAddress: feature.attributes.LocationAddress,  // editable
                description: feature.attributes.Description,  // editable
                fieldNotes: feature.attributes.FieldNotes,  // editable
                status: feature.attributes.Status,  // changed

//                workOrderType: this.state.WorkOrderDetails.workOrderType,
//                workServiceID: this.state.WorkOrderDetails.workServiceID,
//                dateRequired: feature.attributes.DateRequired, // added when found missing vs. feature and database compare
//                assetName: feature.attributes.AssetName,
//                assetID: feature.attributes.AssetID,
//                departmentName: feature.attributes.DepartmentName,
//                isRecurring: feature.attributes.IsRecurring, // added when found missing vs. feature and database compare
//                originatorUID: feature.attributes.OriginatorUID, // added when found missing vs. feature and database compare
//                workCreatedBy: feature.attributes.OriginatorID, // added when found missing vs. feature and database compare
//                nextDueDate: feature.attributes.NextDueDate, // added when found missing vs. feature and database compare
//                workAssignedTo: feature.attributes.AssignedToID,
//                assignedToUID: feature.attributes.AssignedToUID,
//                isWorkAssigned: feature.attributes.IsWorkAssigned,
//                departmentID: this.state.WorkOrderDetails.departmentID,
//                recurrence: feature.attributes.Recurrence,  // added when found missing vs. feature and database compare
//                dateCreated: feature.attributes.DateInitiated,  // added when found missing vs. feature and database compare

                gisAssets: this.state.GISAssets,  // editable
                workOrderResources: this.state.GISResources,  // editable
                workOrderQuestionAnswers: this.state.workOrderQuestionAnswers,  // editable
//                workOrderProblemResolutions: this.state.workOrderProblemResolutions,  // removed when changed from multi to single storage

              };
              //console.log(__workOrderObj);
              workorderService
                .UpdateWorkRelatedDetails(__workOrderObj)
                .then((updateDataResponse) => {
                  // Recreate similar work order if Recurrence enabled
//                  this.state.GISAssets.length=0;  //DMM 12/22/2022 - allows assets to repeat with recurring work order
                  this.state.GISResources.length=0;
                  if (feature.attributes.IsRecurring === 'Yes' && isComplete) {
                    this.__CreateRecurrentNextWorkOrder(feature);
                  } else {
                    this.state.GISAssets.length=0;  //DMM 12/22/2022
                    this.props.renderComponent('');
                  }
                })
                .catch((error) => {
                  swal('There was an error updating related records.');
                  console.log(`Error updating related data :: ${error}`);
                });
            } else {
              console.log(
                'There were some errors updating the Feature Service. Please try again'
              );
            }
        });
        }
      });
    }
  };

  __handleBtnUpdateClick = () => {
    this.setState({ isComplete: false });
    setTimeout(() => {
      document.getElementById('btn-complete-work').click();
    }, 20);
  };

  __CreateRecurrentNextWorkOrder = (feature) => {
    let recurrenceArr = feature.attributes.Recurrence.split(' ');
    let recurrenceDelay = recurrenceArr[recurrenceArr.length - 1];
    let reccurenceNber = recurrenceArr[recurrenceArr.length - 2];

    const _nextDueDate = workorderService.__getNextDueDate(
      reccurenceNber,
      feature.attributes.NextDueDate,
      recurrenceDelay
    );

    Object.keys(feature.attributes).forEach((fieldName) => {
      switch (fieldName) {
        case 'DateInitiated':
          feature.attributes[fieldName] = workorderService.__getEpochTime(
            new Date().getTime()
          );
          break;
        case 'PlannedDate':
          feature.attributes[fieldName] = workorderService.__getEpochTime(
            new Date(feature.attributes.NextDueDate).getTime()
          );
          break;
        case 'FieldNotes':
          feature.attributes[fieldName] = null;
          break;
        case 'Status':
          if (feature.attributes.IsWorkAssigned == 1) {
            feature.attributes[fieldName] = 'Assigned'
          } else {
            feature.attributes[fieldName] = 'Unassigned'
          };
          break;
        case 'NextDueDate':
          feature.attributes[fieldName] = _nextDueDate;
          break;
        case 'DateCompleted':
//          feature.attributes[fieldName] = new Date("1/1/1970");
          feature.attributes[fieldName] =  workorderService.__getEpochTime('1/1/1970');
          break;

        default:
          break;
      }
    });

    const edits = {
      addFeatures: [feature],
    };

    workorderService.ApplyWorkOrderEdits(edits).then((res) => {
      if (res.addFeatureResults.length > 0) {
        var __gis__assets = [];

        if (this.state.GISAssets.length > 0) {
          this.state.GISAssets.map((asset) => {
            __gis__assets.push({
              assetName: asset.assetName,
              facilityID: asset.facilityID,
              gisServicesConfigurationID: asset.gisServicesConfigurationID
            });
          });
        }

        const _nextWorkOrderObj = {
          globalID: res.addFeatureResults[0].globalId,
          assignedToUID: feature.attributes.AssignedToUID,
          departmentID: feature.attributes.DepartmentID,
          workOrderType: feature.attributes.WorkType,
          status: feature.attributes.Status,
          workServiceID: feature.attributes.WorkServiceID,
          workAssignedTo: feature.attributes.AssignedToID,
          isWorkAssigned: feature.attributes.IsWorkAssigned === 0 ? false : true, // MATCHES ASSIGNMENT FROM PRIOR WORK ORDER
          workCreatedBy: feature.attributes.OriginatorID,
          originatorUID: feature.attributes.OriginatorUID,
          problemName: feature.attributes.ProblemName,
          resolutionName: feature.attributes.ResolutionName,
          problemID: feature.attributes.ProblemID,
          resolutionID: feature.attributes.ResolutionID,
          plannedDate: new Date(feature.attributes.PlannedDate),
          isRecurring: feature.attributes.IsRecurring,
          recurrence: feature.attributes.Recurrence,
          nextDueDate: new Date(_nextDueDate),
          gisAssets: __gis__assets,
          LocationAddress: feature.attributes.LocationAddress,
          Description: feature.attributes.Description,
          AssetName: feature.attributes.AssetName,
          AssetID: feature.attributes.AssetID,
          DepartmentName: feature.attributes.DepartmentName,
          xcoord: feature.attributes.XCOORD,
          ycoord: feature.attributes.YCOORD,
          workOrderGISAsset: {
            gisServicesConfigurationID:
              feature.attributes.AssetID,
          },
          workOrderQuestionAnswers: [],
        };

        // NEW CODE FROM DMM TO ADD NEW DB AND GIS WORK ORDER WITH MATCHING WOID AND SIMILAR TO PRIOR (FROM RECURRENCE)
        workorderService
          .CreateServerWorkOrder(_nextWorkOrderObj)
          .then(async (serverResponse) => {
            // update the feature class with woid id
            // [1] query the new created feature using the globalID => res.addFeatureResults[0].globalId
            const __newly_created_feature =
              await workorderService.SelectFeatureByGlobalID(
                res.addFeatureResults[0].globalId
              );
            let __new__feature = __newly_created_feature.features[0];

            // update the work orderID with ID from SQL Server => serverResponse.data.workOrderID
            Object.keys(__new__feature.attributes).map((fieldName) => {
              switch (fieldName) {
                case 'WOID':
                  __new__feature.attributes[fieldName] =
                    serverResponse.data.workOrderID;
                  break;
              }
            });
            // make the new GIS feature object
            const __update__feature__class = {
              updateFeatures: [__new__feature],
            };

            // call the update attributes
            workorderService
              .ApplyWorkOrderEdits(__update__feature__class)
              .then((res) => {
//                workCreateLocationGL.removeAll();
//                this.state.view.graphics.removeAll();
                this.state.GISAssets.length=0;  //DMM 12/22/2022
                this.props.renderComponent('');
              });  
          })
          .catch((err) =>
            console.log('Error creating server work order :: ', err)
          );

          // REMOVED CODE WHEN RECURRENCE WAS IMPLEMENTED CORRECTLY ON 11/4/2022 DMM
/*        workorderService
          .CreateServerWorkOrder(_nextWorkOrderObj)
          .then((resp) => {
            swal(
              `Thank you! As a reminder, the next work is due on ${_nextDueDate}`
            );
            this.props.renderComponent('');
          })
          .catch((err) =>
            console.log('ERROR Creating recurrence Service :: ', err)
          );  */
      }
    });
  };

  handleAddProblemResolutions = (event, values) => {  //disabled in form - code not reachable
    const { problemID, resolutionID } = values;
    if (problemID === 'Select' || resolutionID === 'Select') {
      swal('Please select a problem and the solution applied');
      return;
    }
    const workOrderProblemResolutions = this.state.workOrderProblemResolutions;
    const workOrderProblemResolution = {
      workOrderID: this.state.WorkOrderDetails.workOrderID,
      problemID,
      resolutionID,
    };
    workOrderProblemResolutions.push(workOrderProblemResolution);
    this.setState({
      workOrderProblemResolutions,
      problemResolutionModal: false,
    });
  };

  _renderProblemOptions() {
    return (
      <Fragment>
        {this.state.problems.map((p, index) => {
          return (
            <option key={index} value={p.problemID}>
              {p.name}
            </option>
          );
        })}
      </Fragment>
    );
  }
  _renderResolutionOptions() {
    return (
      <Fragment>
        {this.state.resolutions.map((r, index) => {
          return (
            <option key={index} value={r.resolutionID}>
              {r.name}
            </option>
          );
        })}
      </Fragment>
    );
  }

/*  removeProblem = (p) => {
    const workOrderProblemResolutions = this.state.workOrderProblemResolutions;
    let index = workOrderProblemResolutions.findIndex(
      (pr) => parseInt(pr.problemID) === parseInt(p.problemID)
    );
    if (index > -1) {
      workOrderProblemResolutions.splice(index, 1);
      this.setState({
        workOrderProblemResolutions,
      });
    }
  };  */  //removed when problem and resolution went from multi to single storage

  __getPRAsset = (problemID,resolutionID) => {
    const p = this.state.problems.find(
      (p) => p.problemID === parseInt(problemID)
    );
    const r = this.state.resolutions.find(
      (r) => r.resolutionID === parseInt(resolutionID)
    );
    if (p !== undefined) {
      if (p.requireAsset === "Yes"){
      return "Yes";
      }
    };
    if (r !== undefined) {
      if (r.requireAsset === "Yes") {
        return "Yes";
      }
    };
    return "No";
  }
  
  __getPRResource = (problemID,resolutionID) => {
    const p = this.state.problems.find(
      (p) => p.problemID === parseInt(problemID)
    );
    const r = this.state.resolutions.find(
      (r) => r.resolutionID === parseInt(resolutionID)
    );
    if (p !== undefined) {
      if (p.requireResource === "Yes"){
      return "Yes";
      }
    };
    if (r !== undefined) {
      if (r.requireResource === "Yes") {
        return "Yes";
      }
    };
    return "No";
  }
  
  __getProblemName = (problemID) => {
    const p = this.state.problems.find(
      (p) => p.problemID === parseInt(problemID)
    );
    return p.name;
  };
  __getResolutionName = (resolutionID) => {
    const r = this.state.resolutions.find(
      (r) => r.resolutionID === parseInt(resolutionID)
    );
    return r.name;
  };

  validateFormAndSubmit = () => {
    this.setState({ isComplete: true });
    setTimeout(() => {
      document.getElementById('btn-complete-work').click();
    }, 20);
  };

  render() {
    const { attributes } = this.props.workorder.feature;
    return (
      <Fragment>
        <Card style={{ height: '100%' }}>
          <CardHeader style={{ padding: '0' }}>
            <div className="flex-row">
              <div
                className="box box--start"
                style={{ textAlign: 'left', fontSize: '1.6rem' }}
              >
                <button
                  type="button"
                  id="btn-sumbit-work-completion"
                  className="btn btn-success mr-2"
                  disabled={this.state.isSubmittingWorkEdits}
                  style={{ marginTop: '-5px' }}
                  onClick={this.validateFormAndSubmit}
                >
                  {this.state.isSubmittingWorkEdits && (
                    <Spinner
                      className="spinner-border-sm"
                      tag="span"
                      color="white"
                    />
                  )}
                  {!this.state.isSubmittingWorkEdits && (
                    <i
                      className="uil uil-check"
                      style={{ fontSize: '1.2rem' }}
                    ></i>
                  )}
                </button>
                <UncontrolledTooltip
                  placement="top"
                  target="btn-sumbit-work-completion"
                >
                  Complete work
                </UncontrolledTooltip>
                <span>ID: {attributes.WOID}</span>
              </div>
              <div className="box box--end">
                <button
                  type="button"
                  id="btn-update-work"
                  className="btn btn-info"
                  disabled={this.state.isSubmittingWorkEdits}
                  onClick={() => this.__handleBtnUpdateClick()}
                >
                  {this.state.isSubmittingWorkEdits && (
                    <Spinner
                      className="spinner-border-sm"
                      tag="span"
                      color="white"
                    />
                  )}
                  {!this.state.isSubmittingWorkEdits && (
                    <i
                      className="uil uil-check-square"
                      style={{ fontSize: '1.2rem' }}
                    ></i>
                  )}
                </button>
                <UncontrolledTooltip placement="top" target="btn-update-work">
                  Update work info
                </UncontrolledTooltip>
                <button
                  type="button"
                  id="btn-cancel-work"
                  className="btn btn-danger ml-2"
                  style={{ marginRight: '-10px' }}
                  onClick={this.CancelWorkCreation}
//                  onClick={() => this.props.renderComponent('')}
                >
                  <i
                    className="uil uil-multiply"
                    style={{ fontSize: '1.2rem' }}
                  ></i>
                </button>
                <UncontrolledTooltip placement="top" target="btn-cancel-work">
                  Cancel
                </UncontrolledTooltip>
              </div>
            </div>
          </CardHeader>

          <CardBody>
            <div
              id="layerInfoContentDisplay"
              style={__listPanelContentScroller}
            >
              <Nav tabs className="nav-bordered">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: this.state.activeTab === '1',
                    })}
                    onClick={(e) => {
                      this.toggle('1');
                    }}
                  >
                    <i
                      className={classnames(
                        'mdi mdi-alert-circle-outline',
                        'd-lg-none',
                        'd-block',
                        'mr-1'
                      )}
                    ></i>
                    <span className="d-none d-lg-block">Info</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: this.state.activeTab === '2',
                    })}
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    <i
                      className={classnames(
                        'mdi mdi-alpha-a-box',
                        'd-lg-none',
                        'd-block',
                        'mr-1'
                      )}
                    ></i>
                    <span className="d-none d-lg-block">Assets</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: this.state.activeTab === '3',
                    })}
                    onClick={() => {
                      this.toggle('3');
                    }}
                  >
                    <i
                      className={classnames(
                        'mdi mdi-alpha-r-box',
                        'd-lg-none',
                        'd-block',
                        'mr-1'
                      )}
                    ></i>
                    <span className="d-none d-lg-block">Resources</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: this.state.activeTab === '4',
                    })}
                    onClick={() => {
                      this.toggle('4');
                    }}
                  >
                    <i
                      className={classnames(
                        'mdi mdi-file-image',
                        'd-lg-none',
                        'd-block',
                        'mr-1'
                      )}
                    ></i>
                    <span className="d-none d-lg-block">Attachments</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: this.state.activeTab === '5',
                    })}
                    onClick={() => {
                      this.toggle('5');
                    }}
                  >
                    <i
                      className={classnames(
                        'mdi mdi-help-box',
                        'd-lg-none',
                        'd-block',
                        'mr-1'
                      )}
                    ></i>
                    <span className="d-none d-lg-block">ED</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <div style={{ padding: '20px 15px' }}>
                <AvForm className="" onValidSubmit={this.updateWorkInfo}>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={'1'}>
                      {/*Edit GIS data form*/}

                      <AvField
                        name="workType"
                        label="Work Type/Service"
                        type="text"
                        value={attributes.WorkType}
                        disabled
                      />
                      <AvField
                        name="address"
                        label="Location Address"
                        type="text"
                        value={attributes.LocationAddress}
                        required
                      />
                      <AvField
                        name="description"
                        label="Work Description"
                        type="textarea"
                        value={attributes.Description}
                        rows={5}
                      />

                      <AvField
                        name="duedate"
                        label="Due Date"
                        format= "MM/DD/YYYY"
                        type="date"
                        value = {new Date(attributes.PlannedDate).toISOString().split('T')[0]}  //here is where we set the display date originally
                      />

                      <AvField
                        name="problemID"
                        label="Select Problem"
                        type="select"
                        value={attributes.ProblemID}
                        className="custom-select"
                      >
                        <option>Select</option>
                        {this._renderProblemOptions()}
                      </AvField>

                      <AvField
                        name="resolutionID"
                        label="Select Resolution"
                        type="select"
                        value={attributes.ResolutionID}
                        className="custom-select"
                      >
                        <option>Select</option>
                        {this._renderResolutionOptions()}
                      </AvField>

                      {/* <div className="problems-resolutions-wrapper">
                        {this.state.workOrderProblemResolutions.map(
                          (p, index) => (
                            <Card
                              key={index}
                              className="mb-1 shadow-none border"
                            >
                              <div className="p-1">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <div className="avatar-sm">
                                      <span className="avatar-title rounded">
                                        {index + 1}.
                                      </span>
                                    </div>
                                  </Col>
                                  <Col className="pl-0">
                                    <Link
                                      to="#"
                                      className="text-danger font-weight-bold"
                                    >
                                      {this.__getProblemName(p.problemID)}
                                    </Link>
                                    <p className="mb-0 text-success">
                                      {this.__getResolutionName(p.resolutionID)}
                                    </p>
                                  </Col>
                                  <Col className="col-auto">
                                    <Link
                                      to="#"
                                      id="btn-Delete"
                                      onClick={() => this.removeProblem(p)}
                                      className="btn btn-link text-danger btn-lg p-0"
                                    >
                                      <i className="uil uil-multiply"></i>
                                    </Link>
                                    <UncontrolledTooltip
                                      placement="right"
                                      target="btn-Delete"
                                    >
                                      Delete
                                    </UncontrolledTooltip>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        )}
                      </div>
                      <Button
                        color="success"
                        className="btn-rounded mb-2"
                        onClick={this.toggleProblemResolutionModal}
                        disabled={
                          this.state.workOrderProblemResolutions.length === 1
                        }
                      >
                        <i className="mdi mdi-plus"></i> Add Problems/Resolutions
                      </Button>  */}
                      <AvField
                        name="fieldNotes"
                        label="Field Notes"
                        type="textarea"
                        value={attributes.FieldNotes}
                        rows={4}
                      />
                      <small
                        className="form-text text-danger"
                        style={{ marginTop: '-15px', marginBottom: '10px' }}
                      >
                        please provide required field notes.
                      </small>

                      <ul className="list-inline wizard mb-0">
                        <li className="next list-inline-item float-right">
                          <Button onClick={() => this.toggle('2')} color="info">
                            <i className="dripicons-chevron-right"></i>
                          </Button>
                        </li>
                      </ul>
                    </TabPane>
                    <TabPane tabId="2">{this._renderAssets()}</TabPane>
                    <TabPane tabId="3">{this._renderResources()}</TabPane>
                    <TabPane tabId="4">{this._renderAttachments()}</TabPane>
                    <TabPane tabId="5">
                      <h5>Essential Details</h5>

                      {this._renderEssentialQuestions()}

                      {/* <div className="text-center mt-3">
                      {this.__renderSubmitButton()}
                    </div> */}
                      <div className="mt-3">
                        <Button
                          onClick={() => this.toggle('4')}
                          color="info"
                          className="mr-2"
                        >
                          <i className="dripicons-chevron-left"></i>
                        </Button>
                        <Button
                          id="btn-complete-work"
                          style={{ visibility: 'hidden' }}
                          color="primary"
                          type="submit"
                          disabled={this.state.isSubmittingWorkEdits}
                        >
                          {this.state.isSubmittingWorkEdits
                            ? 'Submitting...'
                            : 'Complete Work'}
                        </Button>
                      </div>
                    </TabPane>
                  </TabContent>
                </AvForm>
              </div>
            </div>
          </CardBody>
          <CardFooter style={_btnFooter}>
            You are in the process of completing assigned work
          </CardFooter>
        </Card>

        <Modal
          isOpen={this.state.problemResolutionModal}
          toggle={this.toggleProblemResolutionModal}
          /* size="lg" */
        >
          <ModalHeader toggle={this.toggleProblemResolutionModal}>
            Adding Problem Resolution
          </ModalHeader>
          <ModalBody>
            <AvForm
              className="p-2"
              onValidSubmit={this.handleAddProblemResolutions}
            >
              <AvField
                name="problemID"
                label="Select Problem"
                type="select"
                required
                className="custom-select"
              >
                <option>Select</option>
                {this._renderProblemOptions()}
              </AvField>
              <AvField
                name="resolutionID"
                label="Select Resolution"
                type="select"
                required
                className="custom-select"
              >
                <option>Select</option>
                {this._renderResolutionOptions()}
              </AvField>

              <div className="text-right">
                <Button
                  color="light"
                  type="button"
                  className="mr-1"
                  onClick={this.toggleProblemResolutionModal}
                >
                  Cancel
                </Button>
                <Button color="success" type="submit">
                  Add
                </Button>
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}
