// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  FORGET_PASSWORD,
  RESET_PASSWORD,
} from './constants';

import {
  loginUserSuccess,
  loginUserFailed,
  registerUserSuccess,
  registerUserFailed,
  forgetPasswordSuccess,
  forgetPasswordFailed,
  resetPasswordSuccess,
  resetPasswordFailed,
} from './actions';
import { UserAPI } from '../../services/users.services';
import { workorderService } from '../../services/workorder.service';
import { CrudOperations } from '../../services/CRUDoperations.service';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
  let cookies = new Cookies();
  if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
  else cookies.remove('user', { path: '/' });
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password, history } }) {
  const __accountObj = {
    Email: username,
    Password: password,
    UserId: '',
    Code: '',
    IsEmailSent: false,
    IsPasswordReset: false,
    RememberMe: true,
  };

  try {
    const result = yield UserAPI.SignIn(__accountObj);
    if (result.data.errors.length > 0) {
      yield put(loginUserFailed(result.data.errors[0].description));
      setSession(null);
    } else {
      const _user = {
        id: result.data.id,
        department: result.data.department,
        username: username,
        token: result.data.id,
        role: 'Admin',
        roles: result.data.roles,
        notificationType: result.data.preferredContact,
      };

      const roleIndex = result.data.roles.findIndex(
        (r) =>
          r.id === 'e217ad31-13c9-452e-a800-d2c04215396c' ||
          r.id === '017bf016-611d-4ddd-b5d8-f59a998c31f1' ||
          r.id === 'ef05fa07-09f7-4789-8ab6-f89e3c02a8f1'
      );

      const departments = yield CrudOperations.Get('Departments');

      const deptIndex = departments.data.findIndex(
        (dept) => dept.name === _user.department
      );
      if (roleIndex > -1 && deptIndex > -1) {
        setSession(_user);
        const managerRoleIndex = result.data.roles.findIndex(
          (r) => r.id === 'ef05fa07-09f7-4789-8ab6-f89e3c02a8f1'
        );

        if (managerRoleIndex > -1) {
          setTimeout(() => {
            window.location.href = `/dashboard`;
            //yield put(push('/dashboard'));
          }, 10);
        }

        yield put(loginUserSuccess(username));
      } else {
        yield put(loginUserFailed('not authorize'));
        setSession(null);
      }
    }
  } catch (error) {
    yield put(loginUserFailed('Error fetching data from server'));
    setSession(null);
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
  try {
    setSession(null);
    yield call(() => {
      history.push('/account/login');
    });
  } catch (error) {}
}

/**
 * Register the user
 * payload: user Object type
 */
function* register({ payload: newUserObj }) {
  try {
    const result = yield UserAPI.CreateUser(newUserObj);
    if (result.data.errors.length > 0) {
      let message = '';
      for (let index = 0; index < result.data.errors.length; index++) {
        const error = result.data.errors[index];
        message = message + ' ' + error.description;
      }
      yield put(registerUserFailed(message));
    } else {
      yield put(registerUserSuccess('Account created successfully'));
    }
  } catch (error) {
    console.log('Error :: ', error);
    yield put(registerUserFailed('Error creating new user'));
  }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
  let isEmailSent = false;
  try {
    const response = yield UserAPI.ForgotPassword({ email: username });
    isEmailSent = response.data;
    if (response.data.errors.length > 0) {
      yield put(forgetPasswordFailed('Error fetching data from server'));
    } else {
      yield put(
        forgetPasswordSuccess(
          "If this email exist in our system, you'll get an email shortly with instructions to reset password"
        )
      );
    }
  } catch (error) {
    yield put(
      forgetPasswordSuccess(
        "If this email exist in our system, you'll get an email shortly with instructions to reset password"
      )
    );
  }
}
function* resetPassword({ payload: { email, password, code } }) {
  try {
    const result = yield UserAPI.ResetPassword({ email, password, code });
    if (result.data.errors.length > 0) {
      yield put(resetPasswordFailed(result.data.errors[0].description));
    } else {
      yield put(resetPasswordSuccess('Password updated successfully'));
    }
  } catch (error) {
    yield put(resetPasswordFailed('Error Fetching data from the server'));
  }
}

export function* watchLoginUser(): any {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser(): any {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword(): any {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}
export function* watchResetPassword(): any {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

function* authSaga(): any {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword),
    fork(watchResetPassword),
  ]);
}

export default authSaga;
