import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody, CardFooter, CardHeader
} from 'reactstrap';
import Spinner from '../Spinner';
import CCTVItem from './CCTVITEM/CCTVItem';
import NoVideo from './NoVideo/NoVideo';

const __stylePanelHeader = {
    position: 'relative',
    height: '70px',
    backgroundColor: '#313a46',
  };
  
  const __listPanelContentScroller = {
    position: 'absolute',
    top: '70px',
    left: '0',
    right: '0',
    bottom: '65px',
    overflow: 'auto',
  };

const btnOfficeWrapper = {
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
  };
  
  const _btnFooter = {
    backgroundColor: '#a4a3a4',
    textAlign: 'center',
    padding: '0.75rem 0',
  };

class CCTV extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
          videos: []
        };
      }

    componentDidMount () {
//        console.log(this.props);
        const {facilityID} = this.props.facilityID;
        this.__loadVideos(facilityID);
    }

    componentWillUnmount () {
        this.setState = (state, callback) => {
            return;
          };
    }

    __loadVideos = (facilityID) => {
        // https://video.vidyard.com/watch/BLXgYCDGfwU62vdMWybNVJ
        // https://www.googleapis.com/drive/v3/files/1Lv9AIz27Bd58-EEFKcDEDFxWFF-u5VC1?alt=media&key=AIzaSyDnc--gGwzinkHljJ1fYt6yJ2hTAyUUQWI
        if(facilityID === ''){
            this.setState({isLoading: false});
        }
        else{
            const some_videos = this.state.videos;
            setTimeout(() => {
                for (let index = 0; index < 5; index++) {
                    some_videos.push({url: 'https://video.vidyard.com/watch/BLXgYCDGfwU62vdMWybNVJ'});
                }
                this.setState({isLoading: false, videos: some_videos});
            }, 2000);
        }
    }

    __renderComponent () {
        if (this.state.isLoading) {
            return (
              <div style={{ textAlign: 'center', marginTop: '45px' }}>
                <Spinner className="m-2" color={'info'} size={'sm'} />
              </div>
            );
        }
        else {
            if(this.state.videos.length > 0) {
                return (
                    <>
                        <div className="video-player-wrapper">
                            {
                                this.state.videos.map((v, i) => (
                                    <CCTVItem key={i} video={v} className="cctv-item-style" />
                                ))
                            }  
                        </div>   
                    </>
                )
            }
            else {
                return (
                    <NoVideo />
                )
            }
        }
    }

    render() {
        return (
            <>
                <Card style={{ height: '100%' }}>
                    <CardHeader style={__stylePanelHeader}>
                        <div style={btnOfficeWrapper}>
                            <div className="cctv-title">
                            CCTV
                            </div>
                            <div className="workOrders-total" style={{ marginTop: '15px' }}>
                            {this.state.videos.length}
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div
                            id="layerInfoContentDisplay"
                            style={__listPanelContentScroller}
                        >
                            <div>{this.__renderComponent()}</div>
                        </div>
                    </CardBody>
                    <CardFooter style={_btnFooter}>
                        {
                            this.state.videos.length > 0 && (
                                <Link className="btn btn-info" to="/">
                                    <i className="mdi mdi-reply"></i> Return Home
                                </Link> 
                            )
                        }
                    </CardFooter>
                </Card>
            </>
        )
    }
}

export default CCTV;
