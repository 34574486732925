import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  CustomInput,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  getLoggedInUser,
  isUserInSupervisorRole,
} from '../../helpers/authUtils';
import { CrudOperations } from '../../services/CRUDoperations.service';
import { UserAPI } from '../../services/users.services';
import { workorderService } from '../../services/workorder.service';
import { WorkOrderFilter } from '../../utils/filters/filter';
import FooterList from '../Shared/FooterList';
import Spinner from '../Spinner';
import NoWorkOrders from './NoWorkOrders';

const __stylePanelHeader = {
  position: 'relative',
  height: '70px',
  backgroundColor: '#313a46',
};

const __listPanelContentScroller = {
  position: 'absolute',
  top: '70px',
  left: '0',
  right: '0',
  bottom: '65px',
  overflow: 'auto',
};

const __togglerToolbar = {
  position: 'relative',
  height: '45px',
  backgroundColor: '#f5f5f5',
  padding: '5px',
  textAlign: 'left',
  borderBottom: '1px solid #ddd',
};

const __workOrders_total = {
  float: 'right',
  marginTop: '8px',
  marginRight: '5px',
  fontSize: '1.8rem',
  fontWeight: '400',
  color: '#afadad',
};

const btnFieldEditorWrapper = {
  position: 'absolute',
  left: '0',
  right: '0',
  top: '8px',
};
const btnOfficeWrapper = {
  position: 'absolute',
  left: '0',
  right: '0',
  top: '0',
};

const _btnFooter = {
  backgroundColor: '#a4a3a4',
  textAlign: 'center',
  padding: '0.75rem 0',
};

class WorkOrdersList extends Component {  constructor(props) {
    super(props);
    this.state = {
      User: null,
      userAssets: [],
      workorders: [],
      departments: [],
      workTypes: [],
      workListUsers: [],
      isLoading: true,
      sortText: 'Oldest', // Newest
      sortIcon: 'dripicons-arrow-thin-up', //  dripicons-arrow-thin-up
      toggleCompleteText: 'Show Completed', // Hide Completed
      toggleCompleteIcon: 'mdi mdi-eye-outline', // mdi mdi-eye-off-outline
      completedWorkToolTip: 'Show completed on Map',
      isFieldCrew: isUserInSupervisorRole() ? false : true,
      selectedDepartment: '',
      selectedWorkType: '',
      selectedUser: '',
      selectedAsset: '',
      workAssets: [],
      printBatchItems: [],
      allWorkOrders: [],
      isOverdueWork: false,
      isDueThisWeek: false,
    };
  }

  componentDidMount() {
    //console.log({ REACT_APP_WOC_API_URL: process.env.REACT_APP_WOC_API_URL });

    // get work orders from services
    const loggedUser = getLoggedInUser();
    UserAPI.GetUser(loggedUser.id).then((user) => {
      this.setState({
        User: user.data,
        selectedDepartment: user.data.department,
      });
      UserAPI.SetLoggedInUser(this.state.User);
      this.__getActiveWorkOrders();
      this.__getDepartments();
    });
  }

  __getDepartments = () => {
    CrudOperations.Get('Departments')
      .then((res) => {
        this.setState({ departments: res.data });
        //console.log(this.state.departments);
      })
      .catch((err) => {
        console.log('Error fetching departments :: ', err);
      });
  };

  __getActiveWorkOrders = () => {
    //console.log(department);
    workorderService
//      .GetFeature('FACILITYID','SSR0815')
      .GetWorkOrders(this.props.config)
      .then(async (res) => {  //all work orders in the system
        let notCompletedWorkArray = [];
        let isOverdueWork = this.state.isOverdueWork;
        let isDueThisWeek = this.state.isDueThisWeek;
        const view = workorderService.mapView();
//        if (view !== null && view !== undefined) {
          workorderService.__clearFlushSelectedWork(view);
//        }
        const user_role = workorderService.GetUserRole();
        //console.log(user_role);

        // user assets
        const loggeduser = getLoggedInUser();
        const { data } = await CrudOperations.GetByID(
          'GisConfiguration/GetGisServicesByUserID',
          loggeduser.id
        );

        WorkOrderFilter.SetUserAssets(data);

        // filter map active work order per user
//        const userType = workorderService.GetUserRole();
        const loggedUser = getLoggedInUser();
        /* WorkOrderFilter.FilterCompletedWork(
        this.state.User.department,
        this.state.selectedAsset,
        userType,
        loggedUser.id
      ); */

        //const todaysDate = new Date().getTime();
        res.features.map((f) => {  //FILTER WORKORDERS BY USER ROLE FOR LISTING
          switch (f.attributes.Status.toUpperCase()) {
            case 'COMPLETED':
              break;
            case 'ARCHIVED':  // 9/29/2023 DMM ARCHIVE
              break;
            default:
              switch (user_role) {
                case 'manager':
                case 'admin':
                  notCompletedWorkArray.push(f);
                  break;
                case 'supervisor':
                  if (f.attributes.DepartmentName === loggedUser.department) {
                    notCompletedWorkArray.push(f);
                  }
                  break;
                case 'field':
                  // only load services assigned to me
                  // only load unassigned in services I am part of
                  //console.log(f.attributes.AssetName);
/*                  if (data.length > 0) {
                    data.map((s, idx) => {
                      if (s.name === f.attributes.AssetName) {
                        notCompletedWorkArray.push(f);
                      }
                    });
                  }  */

/*                  if ((f.attributes.AssignedToUID === loggedUser.id)  || ((f.attributes.Status === 'Unassigned') && (f.attributes.DepartmentName === loggedUser.department))) {
                     notCompletedWorkArray.push(f);
                  } */

                  if (f.attributes.AssignedToUID === loggedUser.id) {
                    notCompletedWorkArray.push(f);
                  } else {
                    if (f.attributes.Status === 'Unassigned') {
                      if (data.length > 0) {
                        data.map((s, idx) => {
                          if (s.name === f.attributes.AssetName) {
                        notCompletedWorkArray.push(f);
                        }
                      });
                    }
                  }
                 } 

                  break;
              }
              break;
          }
        });

//         console.log(notCompletedWorkArray);

        // get work types
        this.__getWorkTypesList(notCompletedWorkArray);

        // get Asset names
        this.__getWorkAssetsList(notCompletedWorkArray);

        this.__getWorkListUsers(notCompletedWorkArray);

        // Sort work orders from newest to oldest // default
//        notCompletedWorkArray = this.sortDescending(notCompletedWorkArray);  //
        notCompletedWorkArray = this.sortAscending(notCompletedWorkArray);
        setTimeout(() => {
          this.setState({
            workorders: notCompletedWorkArray,
            userAssets: data,
            isOverdueWork,
            isDueThisWeek,
            isLoading: false,
          });
          workorderService.SetListWorkOrders(this.state.workorders);  //the filtered work orders based on user and unassigned in DEPT
          var selectedWorkID = new URLSearchParams(window.location.search).get(
            'id'
          );
          if (selectedWorkID) {
            const _link = document.getElementById(`wo-${selectedWorkID}`);
            if (_link) {
              _link.click();
              _link.scrollIntoView({
                behavior: 'smooth',
                //block: "end",
                //inline: "nearest"
              });
            } else {
              // this is a completed work
              // get the selected work
//              this.__showCompletedWorkDetails(selectedWorkID);  //changed 10/3/2023 DMM for archived
              this.__showWorkOrderDetailsBasedOnStatus(selectedWorkID);
            }
            // console.log(this.state.workorders);
          } else {
//            this.zoomToList();  //DMM 12/6/2022 - stopped default behavior regarding zoom
          }
        }, 3000);
      })
      .catch((err) => console.log('Error :: ', err));
  };

  __showWorkOrderDetailsBasedOnStatus = (globalID) => {
    setTimeout(() => {
      workorderService.GetManagedOrderDetails(globalID)
        .then(workOrder => {
          
          if (workOrder.data.status === "Archived") {
            // Archived Work Order Logic
            this.__viewArchiveDetails(workOrder.data);
            
          } else {
            // Completed Work Order Logic
            const _view = workorderService.mapView();
            const workOrdersFlayer = _view.map.findLayerById('workOrders');
            const query = workOrdersFlayer.createQuery();
            query.where = `GlobalID = '${globalID}'`;
            query.outFields = ['*'];
            query.returnGeometry = true;
            
            workOrdersFlayer.queryFeatures(query).then((res) => {
              if (res.features.length > 0) {
                this.__viewWorkOrderDetails(res.features[0]);
                // Zoom and highlight the completed work
                const { geometry } = res.features[0];
                workorderService.GoToSelectedWork(geometry, _view);
                workorderService.__flushSelectedWork(geometry, _view);
              }
            });
          }
        })
        .catch(error => {
          console.error('There was a problem retrieving order details:', error.message);
        });
    }, 2000);
  };
  
  // Presently ignored / replaced with __showWorkOrderDetailsBasedOnStatus
  __showCompletedWorkDetails = (globalID) => {
    setTimeout(() => {
      const showCompletedWorkBtn = document.getElementById(
        'tooltipCompletedWork'
      );
      const _view = workorderService.mapView();
      const workOrdersFlayer = _view.map.findLayerById('workOrders');
      const query = workOrdersFlayer.createQuery();
      query.where = `GlobalID = '${globalID}'`;
      query.outFields = ['*'];
      query.returnGeometry = true;
      workOrdersFlayer.queryFeatures(query).then((res) => {
        if (res.features.length > 0) {
          this.__viewWorkOrderDetails(res.features[0]);
          // Zoom and highlight the completed work
          const { geometry } = res.features[0];
          workorderService.GoToSelectedWork(geometry, _view);
          workorderService.__flushSelectedWork(geometry, _view);
        }
      });
    }, 2000);
  };

  // Presently ignored / replaced with __showWorkOrderDetailsBasedOnStatus
  __showArchivedWorkDetails = (globalID) => {
    setTimeout(() => {
      workorderService.GetManagedOrderDetails(globalID)
      .then(workOrder => {
        this.__viewWorkOrderDetails(workOrder);
      })
      .catch(error => {
        console.error('There was a problem retrieving order details:', error.message);
      });
    }, 2000);
  };

 /*
  __showCompletedWorkDetails = (globalID) => {
    setTimeout(() => {
      const apiUrl = `https://localhost:44308/api/WorkOrders/GetByGlobalID/${globalID}`;

      fetch(apiUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(workOrder => {
          this.__viewWorkOrderDetails(workOrder);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error.message);
        });
    }, 2000);
  };
*/

  
  __getWorkTypesList = (orders) => {
    let workTypes = [];
    orders.map((r) => {
      workTypes.push(r.attributes.WorkType);
    });
    workTypes = [...new Set(workTypes)].sort();

    this.setState({ workTypes });
  };
  __getWorkAssetsList = (orders) => {
    let workAssets = [];
    orders.map((r) => {
      workAssets.push(r.attributes.AssetName);
    });
    workAssets = [...new Set(workAssets)].sort();

    this.setState({ workAssets });
  };
  __getWorkListUsers = (orders) => {
    let workListUsers = [];
    orders.map((r) => {
      if (r.attributes.AssignedToID === '') {
      } else {
        workListUsers.push(r.attributes.AssignedToID);
      }
    });
    workListUsers = [...new Set(workListUsers)].sort();

    this.setState({ workListUsers });
  };

  __setWorkTypes = (department) => {
    let workTypes = [];
    this.state.workorders.map((w) => {
      if (department === '') {
        workTypes.push(w.attributes.WorkType);
      } else {
        if (w.attributes.DepartmentName === department) { 
          workTypes.push(w.attributes.WorkType);
        }
      }
    });
    workTypes = [...new Set(workTypes)].sort();
    this.setState({ workTypes });
  };

  sortDescending = (arr) => {
    arr = arr.sort((a, b) => {
      return b.attributes.PlannedDate - a.attributes.PlannedDate;
    });

    return arr;
  };

  sortAscending = (arr) => {
    return this.sortDescending(arr).reverse();
  };

  __getHumanDate = (_d) => {
    return new Date(_d).toLocaleDateString();
  };

  renderStatus(status) {
    switch (status) {
      case 'Unassigned':
        return (
          <span className="badge bg-danger rounded-pill work-status-text-color">
            {status}
          </span>
        );
        break;
      case 'Assigned':
        return (
          <span className="badge bg-warning rounded-pill work-status-text-color">
            {status}
          </span>
        );
        break;
      case 'Mine':
        return (
          <span className="badge bg-secondary rounded-pill work-status-text-color">
            {status}
          </span>
        );
        break;

      default:
        return <></>;
        break;
    }
  }

  resetOrdersHilight = () => {
    //const items = document.querySelectorAll('workorder-item');
    const elements = document.getElementsByClassName('workorder-item');
    for (let i = 0; i < elements.length; i++) {
      const btn = elements[i].children[elements[i].children.length - 1];

      if (btn.classList.contains('btn-work-order-detail-hidden')) {
      } else {
        btn.classList.add('btn-work-order-detail-hidden');
      }
      elements[i].classList.remove('active-work-order');
    }
  };

  __ZoomToWorkOrder = (workorder, evt) => {
    let __LinkTag = evt.target,
      _detailsBtn;
    switch (evt.target.tagName) {
      case 'A':
        _detailsBtn = evt.target.children[evt.target.children.length - 1];
        break;
      default:
        // get a element
        __LinkTag = evt.target.parentNode.closest('a');
        _detailsBtn = __LinkTag.children[__LinkTag.children.length - 1];
        break;
    }

    const view = workorderService.mapView();
    this.resetOrdersHilight();

    if (evt.target.tagName === 'INPUT' || evt.target.tagName === 'LABEL') {
      workorderService.__flushSelectedWork(workorder.geometry, view);
    } else {
      workorderService.GoToSelectedWork(workorder.geometry, view);
      workorderService.__flushSelectedWork(workorder.geometry, view);
      __LinkTag.classList.add('active-work-order');
      _detailsBtn.classList.remove('btn-work-order-detail-hidden');
    }
  };

/*
  __viewWorkOrderDetails = (workOrder) => {
    let _work_details_option;
  
    if (workOrder.status === "Archived") {
      // Define the logic or path for when the status is "Archived"
      _work_details_option = 'WorkOrderOfficeArchivedDetails'; // Replace with the appropriate option for archived work orders
    } else {
      _work_details_option =
        workorderService.GetUserRole() === 'field'
          ? 'WorkOrderDetails'
          : 'WorkOrderOfficeDetails';
    }
  
    this.props.renderComponent(
      _work_details_option,
      workOrder,
      this.state.User
    );
  };
  */

  __viewArchiveDetails = (workOrder) => {
  
    let _work_details_option ='WorkOrderOfficeArchiveDetails' ;
    
    this.props.renderComponent(
      _work_details_option,
      workOrder,
      this.state.User
    );
  };

  __viewWorkOrderDetails = (workOrder) => {
    const _work_details_option =
      workorderService.GetUserRole() === 'field'
        ? 'WorkOrderDetails'
        : 'WorkOrderOfficeDetails';

    this.props.renderComponent(
      _work_details_option,
      workOrder,
      this.state.User
    );
  }; 

  __toggleBatchPrintItem = (isChecked, workorder) => {
    const printBatchItems = this.state.printBatchItems;
    if (isChecked) {
      printBatchItems.push(workorder);
    } else {
      // remove item
      const itemIndex = printBatchItems.findIndex(
        (w) => w.attributes.GlobalID === workorder.attributes.GlobalID
      );
      if (itemIndex > -1) {
        printBatchItems.splice(itemIndex, 1);
      }
    }
    this.setState({ printBatchItems });
  };

  __renderComponent() {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      if (this.state.workorders.length > 0) {
        const user_role = workorderService.GetUserRole();
        const isSupervisorOrField =
          user_role === 'supervisor' || user_role === 'field';

        return (
          <Fragment>
            <Row className="m-1">
              <Col md={6} className="p-0">
                <Input
                  type="select"
                  name="select"
                  id="department-list"
                  className="custom-select"
                  value={this.state.selectedAsset}
                  onChange={this.__filterWorkOrdersByAsset}
                >
                  <option value="">All Assets</option>
                  {this.state.workAssets.map((a, index) => (
                    <option key={index} value={a}>
                      {a}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col md={6} className="pr-0">
                <Input
                  type="select"
                  name="select"
                  id="worktype-list"
                  className="custom-select"
                  value={this.state.selectedWorkType}
                  onChange={(evt) =>
                    this.__filterWorkOrdersByType(evt, user_role)
                  }
                >
                  <option value="">All Types</option>
                  {this.state.workTypes.map((t, index) => (
                    <option key={index} value={t}>
                      {t}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            {user_role === 'manager' ||
              (workorderService.IsAdmin() && (
                <div className="form-group mt-1 ml-2 mr-2">
                  <Row className="mt-2">
                    <Col md={12}>
                      <Input
                        type="select"
                        name="select"
                        id="user-list"
                        className="custom-select"
                        value={this.state.selectedUser}
                        onChange={(evt) =>
                          this.__filterWorkOrdersByUser(evt, user_role)
                        }
                      >
                        <option value="">All Users</option>
                        {this.state.workListUsers.map((u, index) => (
                          <option key={index} value={u}>
                            {u}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </div>
              ))}

{/*            {isSupervisorOrField && (  */}
              <div className="form-group mt-1 ml-2 mr-2">
                {(user_role === 'supervisor' || user_role === 'manager') && (
                  <Row className="mt-2">
                    <Col md={12}>
                      <Input
                        type="select"
                        name="select"
                        id="user-list"
                        className="custom-select"
                        value={this.state.selectedUser}
                        onChange={(evt) =>
                          this.__filterWorkOrdersByUser(evt, user_role)
                        }
                      >
                        <option value="">All Users</option>
                        {this.state.workListUsers.map((u, index) => (
                          <option key={index} value={u}>
                            {u}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                )}
              </div>
{/*            )}  */}

            {/* <p className="work-list-group-header">Overdue work</p> */}
            <div className="list-group">
              {this.state.workorders
                .filter((w) =>
                  w.attributes.AssetName.includes(this.state.selectedAsset)
                )
                .filter((t) =>
                  t.attributes.WorkType.includes(this.state.selectedWorkType)
                )
                .filter((u) =>
                  u.attributes.AssignedToID.includes(this.state.selectedUser)
                )
                .map((wo, idx) => (
                  <a
                    id={`wo-${wo.attributes.GlobalID}`}
                    href="#"
                    className="list-group-item list-group-item-action workorder-item pl-4"
                    style={{ padding: '0 1.25rem' }}
                    key={idx}
                    onClick={(e) => this.__ZoomToWorkOrder(wo, e)}
                  >
                    <CustomInput
                      type="checkbox"
                      id={wo.attributes.WOID}
                      className="wo-batch-toggle-item"
                      onClick={(evt) =>
                        this.__toggleBatchPrintItem(evt.target.checked, wo)
                      }
                    />
                    <div className="d-flex w-100 justify-content-between">
                      <h6 className="mb-0">Due Date:</h6>
                      <small className="mt-1" style={{ fontWeight: 'bold' }}>
                        {wo.attributes.IsRecurring === 'Yes' && (
                          <>
                            <i
                              className="uil uil-sync"
                              style={{ fontSize: '0.9rem' }}
                            ></i>{' '}
                          </>
                        )}

                        {workorderService.GetDueDateFormatted(
                          wo.attributes.PlannedDate,
                          wo.attributes.Status
                        )}
                      </small>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-0">{wo.attributes.WorkType}</h5>
                      <small className="text-muted mt-1">
                        {new Date(
                          wo.attributes.DateInitiated
                        ).toLocaleDateString()}
                      </small>
                    </div>
                    <small className="text-muted">
                      Asset: <b>{wo.attributes.AssetName}</b> - ID:{' '}
                      {wo.attributes.WOID}{' '}
                    </small>
                    <br />
                    <small className="text-muted">
                      Assigned To:{' '}
                      <b>
                        {' '}
                        {wo.attributes.AssignedToID === '' ||
                        wo.attributes.AssignedToID === null
                          ? 'Unassigned'
                          : wo.attributes.AssignedToID}{' '}
                      </b>
                    </small>
                    <br />
                    <small className="text-muted">
                      Location: {wo.attributes.LocationAddress}
                    </small>
                    <small style={{ float: 'right', color: '#040202' }}>
                      {this.renderStatus(wo.attributes.Status)}
                    </small>

                    <button
                      className="btn btn-info btn-sm btn-work-order-detail-hidden"
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '53px',
                      }}
                      onClick={() => this.__viewWorkOrderDetails(wo)}
                    >
                      <i className="dripicons-information"></i> Details
                    </button>
                  </a>
                ))}
            </div>
          </Fragment>
        );
      } else {
        return <NoWorkOrders />;
      }
    }
  }

  __createNewWorkOrder = () => {
    this.props.renderComponent('CreateWorkOrder');
    // ReactDOM.render(<CreateWorkOrder />, document.getElementById('right-content-wrapper'))
  };

  sortList = () => {
    let sortText, sortIcon;
    switch (this.state.sortText) {
      case 'Newest':
        sortText = 'Oldest';
        //sortIcon = 'dripicons-arrow-thin-up';
        sortIcon = 'dripicons-arrow-thin-up';
        if (this.state.workorders.length > 0) {
          let workorders = this.sortAscending(this.state.workorders);
          this.setState({ workorders });
        }
        break;

      case 'Oldest':
        sortText = 'Newest';
        sortIcon = 'dripicons-arrow-thin-down';
        if (this.state.workorders.length > 0) {
          let workorders = this.sortDescending(this.state.workorders);
          this.setState({ workorders });
        }
        break;
    }

    this.reorderItemsBatchChecked();
    this.setState({ sortText, sortIcon });
  };

  reorderItemsBatchChecked = () => {
    if (this.state.printBatchItems.length > 0) {
      this.uncheckListSelection();
      setTimeout(() => {
        this.uncheckListSelection();
      }, 20);
    }
  };

  uncheckListSelection = () => {
    const elements = document.getElementsByClassName('wo-batch-toggle-item');
    for (let i = 0; i < elements.length; i++) {
      const parentDiv = elements[i];
      const checkbox = parentDiv.children[0];

      this.state.printBatchItems.map((w) => {
        if (w.attributes.OBJECTID === parseInt(checkbox.id)) {
          //checkbox.click();
          checkbox.checked = !checkbox.checked;
        }
      });
    }
  };

  toggleCompleteWorkVisibilityFieldUser = () => {
    const loggedUser = getLoggedInUser();
    switch (this.state.toggleCompleteText) {
      case 'Show Completed':
        this.setState({
          toggleCompleteText: 'Hide Completed',
          toggleCompleteIcon: 'mdi mdi-eye-off-outline',
          completedWorkToolTip: 'Hide completed on Map',
        });
        /* WorkOrderFilter.FilterUserWorkAndShowCompletedFieldUser(
          this.state.selectedDepartment,
          this.state.selectedAsset,
          this.state.selectedWorkType,
          loggedUser.id
        ); */
        setTimeout(() => {
          this.__FilterWorkOrders();
        }, 100);
        break;

      case 'Hide Completed':
        this.setState({
          toggleCompleteText: 'Show Completed',
          toggleCompleteIcon: 'mdi mdi-eye-outline',
          completedWorkToolTip: 'Show completed on Map',
        });
        /* WorkOrderFilter.FilterUserWorkAndHideCompletedFieldUser(
          this.state.selectedDepartment,
          this.state.selectedAsset,
          this.state.selectedWorkType,
          loggedUser.id
        ); */
        setTimeout(() => {
          this.__FilterWorkOrders();
        }, 100);
        break;
    }
  };

  toggleCompleteWorkVisibility = (userRole) => {
    switch (this.state.toggleCompleteText) {
      case 'Show Completed':
        this.setState({
          toggleCompleteText: 'Hide Completed',
          toggleCompleteIcon: 'mdi mdi-eye-off-outline',
          completedWorkToolTip: 'Hide completed on Map',
        });
        WorkOrderFilter.FilterUserWorkAndShowCompleted(
          this.state.selectedDepartment,
          this.state.selectedAsset,
          this.state.selectedWorkType,
          this.state.selectedUser,
          userRole
        );
        break;

      case 'Hide Completed':
        this.setState({
          toggleCompleteText: 'Show Completed',
          toggleCompleteIcon: 'mdi mdi-eye-outline',
          completedWorkToolTip: 'Show completed on Map',
        });
        WorkOrderFilter.FilterUserWorkAndHideCompleted(
          this.state.selectedDepartment,
          this.state.selectedAsset,
          this.state.selectedWorkType,
          this.state.selectedUser,
          userRole
        );
        break;
    }
  };

  zoomToList = () => {
    // console.log(this.state.workorders);
    const view = workorderService.mapView();
    view.goTo(this.state.workorders).catch((err) => console.log(err));   // Zoom to the current list of work orders
    workorderService.__clearFlushSelectedWork(view);

    if (this.state.printBatchItems.length > 0) {
      this.showSelected();
    }
  };

  __FilterWorkOrders = () => {
    const __filteredArray = this.state.workorders
      .filter((w) => w.attributes.AssetName.includes(this.state.selectedAsset))
      .filter((t) =>
        t.attributes.WorkType.includes(this.state.selectedWorkType)
      )
      .filter((u) =>
        u.attributes.AssignedToID.includes(this.state.selectedUser)
      );
    WorkOrderFilter.FilterMapWorkOrders(
      this.state.selectedDepartment,
      this.state.selectedAsset,
      this.state.selectedWorkType,
      this.state.selectedUser,
      __filteredArray,
      this.state.toggleCompleteText === 'Hide Completed' ? false : true
    );
  };

  refreshList = () => {
    this.setState({ isLoading: true });
    this.resetOrdersHilight();
    this.__getActiveWorkOrders();
  };

  printWorkOrdersInBatch = () => {
    if (this.state.printBatchItems.length > 0) {
      // set selected work orders batch
      workorderService.SetWorkOrdersBatchList(this.state.printBatchItems);
      WorkOrderFilter.SetWorkOrdersBatchList(this.state.printBatchItems);
    } else {
      // set filtered work
      const __filteredWork = [];
      this.state.workorders
        .filter((w) =>
          w.attributes.AssetName.includes(this.state.selectedAsset)
        )
        .filter((t) =>
          t.attributes.WorkType.includes(this.state.selectedWorkType)
        )
        .map((fw) => {
          __filteredWork.push(fw);
        });
      workorderService.SetWorkOrdersBatchList(__filteredWork);
      WorkOrderFilter.SetWorkOrdersBatchList(__filteredWork);
    }

    this.props.history.push('/batch/print');
  };

  __filterWorkOrdersByAsset = (evt) => {
    //this.__setWorkTypes(evt.target.value);
    this.uncheckListSelection();

    this.setState({
      //selectedDepartment: evt.target.value,
      selectedAsset: evt.target.value,
      printBatchItems: [],
    });

    setTimeout(() => {
      this.__FilterWorkOrders();
      this.toggleWorkListBackGroundSelection();
    }, 200);
  };

  __filterWorkOrdersByType = (evt, role) => {
    this.setState({
      selectedWorkType: evt.target.value,
      printBatchItems: [],
    });
    setTimeout(() => {
      const __filteredArray = this.state.workorders
        .filter((w) =>
          w.attributes.AssetName.includes(this.state.selectedAsset)
        )
        .filter((t) =>
          t.attributes.WorkType.includes(this.state.selectedWorkType)
        )
        .filter((u) =>
          u.attributes.AssignedToID.includes(this.state.selectedUser)
        );
      const loggedUser = getLoggedInUser();
      this.uncheckListSelection();

      WorkOrderFilter.FilterMapWorkOrders(
        this.state.selectedDepartment,
        this.state.selectedAsset,
        this.state.selectedWorkType,
        this.state.selectedUser,
        __filteredArray,
        this.state.toggleCompleteText === 'Hide Completed' ? false : true
      );

      /* WorkOrderFilter.FilterMapWorkOrderByType(
        this.state.selectedWorkType,
        this.state.selectedAsset,
        role,
        loggedUser.id,
        __filteredArray,
        this.state.selectedUser
      ); */

      this.toggleWorkListBackGroundSelection();
    }, 200);
  };

  __filterWorkOrdersByUser = (evt, role) => {
    this.setState({
      selectedUser: evt.target.value,
      printBatchItems: [],
    });
    setTimeout(() => {
      const __filteredArray = this.state.workorders
        .filter((w) =>
          w.attributes.AssetName.includes(this.state.selectedAsset)
        )
        .filter((t) =>
          t.attributes.WorkType.includes(this.state.selectedWorkType)
        )
        .filter((u) =>
          u.attributes.AssignedToID.includes(this.state.selectedUser)
        );
      const loggedUser = getLoggedInUser();
      this.uncheckListSelection();

      WorkOrderFilter.FilterMapWorkOrders(
        this.state.selectedDepartment,
        this.state.selectedAsset,
        this.state.selectedWorkType,
        this.state.selectedUser,
        __filteredArray,
        this.state.toggleCompleteText === 'Hide Completed' ? false : true
      );

      /* WorkOrderFilter.FilterMapWorkOrderByUser(
        this.state.selectedUser,
        this.state.selectedDepartment,
        role,
        loggedUser.id,
        __filteredArray
      ); */

      this.toggleWorkListBackGroundSelection();
    }, 200);
  };

  showSelected = () => {
    const view = workorderService.mapView();
    const workorders = this.state.workorders;
    const allWorkOrders = this.state.allWorkOrders;
    if (this.state.allWorkOrders.length === 0) {
      this.setState({
        workorders: this.state.printBatchItems,
        allWorkOrders: workorders,
      });
      this.reorderItemsBatchChecked();
      this.toggleWorkListBackGroundSelection(true);
      workorderService.SetWorkOrdersBatchList(this.state.printBatchItems);
      WorkOrderFilter.SetWorkOrdersBatchList(this.state.printBatchItems);
      setTimeout(() => {
        WorkOrderFilter.__filterWorkOrdersSelection();

        view.goTo(this.state.workorders).catch((err) => {
          console.log(err);
        });
      }, 500);
    } else {
      this.setState({
        workorders: allWorkOrders,
        allWorkOrders: [],
        printBatchItems: [],
      });
      this.uncheckListSelection();
      this.toggleWorkListBackGroundSelection();
      setTimeout(() => {
        view.goTo(this.state.workorders).catch((err) => {
          console.log(err);
        });
      }, 500);
    }
    setTimeout(() => {
      // remove selection filter
      // workorderService.__removeSelectionFilter();
      const loggedUser = getLoggedInUser();
      WorkOrderFilter.FilterMapWorkOrderByType(
        this.state.selectedWorkType,
        this.state.selectedDepartment,
        workorderService.GetUserRole(),
        loggedUser.id,
        this.state.workorders
      );
      // filter Types
      this.__setWorkTypes(this.state.selectedDepartment);
      workorderService.__clearFlushSelectedWork(view);
      //this.zoomToList();
    }, 20);
  };

  toggleWorkListBackGroundSelection = (isAdd = false) => {
    const listContentDiv = document.getElementById('layerInfoContentDisplay');
    isAdd
      ? listContentDiv.classList.add('list-selected-bg')
      : listContentDiv.classList.remove('list-selected-bg');
  };

  renderListComponent() {
    const userRole = workorderService.GetUserRole();
    switch (userRole) {
      case 'field': // the user is from the field crew role
        return (
          <Card style={{ height: '100%' }}>
            <CardHeader style={__stylePanelHeader}>
              <div style={btnFieldEditorWrapper}>
                <button
                  id="tooltipSorting"
                  className="btn btn-success btn-sm ml-2 mt-2"
                  onClick={this.sortList}
                >
                  {/* {this.state.sortText} */}Sort{' '}
                  <i className={this.state.sortIcon}></i>
                </button>
                <UncontrolledTooltip placement="top" target="tooltipSorting">
                  Sort by Due Date
                </UncontrolledTooltip>
                <button
                  id="tooltipCompletedWork"
                  className="btn btn-info btn-sm ml-2 mt-2"
                  onClick={this.toggleCompleteWorkVisibilityFieldUser}
                >
                  <i className={this.state.toggleCompleteIcon}></i>{' '}
                  {/* {this.state.toggleCompleteText} */}
                </button>
                <UncontrolledTooltip
                  placement="top"
                  target="tooltipCompletedWork"
                >
                  {this.state.completedWorkToolTip}
                </UncontrolledTooltip>
                <div className="workOrders-total">
                  0
                  {
                    this.state.workorders
                      .filter((w) =>
                        w.attributes.AssetName.includes(
                          this.state.selectedAsset
                        )
                      )
                      .filter((t) =>
                        t.attributes.WorkType.includes(
                          this.state.selectedWorkType
                        )
                      ).length
                  }
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div
                id="layerInfoContentDisplay"
                style={__listPanelContentScroller}
              >
                <div>{this.__renderComponent()}</div>
              </div>
            </CardBody>
            <CardFooter style={_btnFooter}>
              <FooterList
                zoomToList={this.zoomToList}
                printWorkOrdersInBatch={this.printWorkOrdersInBatch}
                showSelected={this.showSelected}
                btnListDisabled={this.state.printBatchItems.length === 0}
              />
            </CardFooter>
          </Card>
        );
        break;

      default:  //user types other than "Field User"
        return (
          <Card style={{ height: '100%' }}>
            <CardHeader style={__stylePanelHeader}>
              <div style={btnOfficeWrapper}>
                <button
                  id="btn-create-new"
                  className="btn btn-success btn-lg ml-2 mt-2"
                  onClick={this.__createNewWorkOrder}
                >
                  <i className="dripicons-plus"></i>
                </button>
                <UncontrolledTooltip placement="top" target="btn-create-new">
                  Create New Work Order
                </UncontrolledTooltip>
                <button
                  id="tooltipSorting"
                  className="btn btn-success btn-sm ml-2 mt-2"
                  onClick={this.sortList}
                >
                  {/* {this.state.sortText} */}Sort{' '}
                  <i className={this.state.sortIcon}></i>
                </button>
                <UncontrolledTooltip placement="top" target="tooltipSorting">
                  Sort by Due Date
                </UncontrolledTooltip>
                <button
                  id="tooltipCompletedWork"
                  className="btn btn-info btn-sm ml-2 mt-2"
                  onClick={() => this.toggleCompleteWorkVisibility(userRole)}
                >
                  <i className={this.state.toggleCompleteIcon}></i>{' '}
                </button>
                <UncontrolledTooltip
                  placement="top"
                  target="tooltipCompletedWork"
                >
                  {this.state.completedWorkToolTip}
                </UncontrolledTooltip>
                <div className="workOrders-total" style={{ marginTop: '15px' }}>
                  0
                  {
                    this.state.workorders
                      .filter((w) =>
                        w.attributes.AssetName.includes(
                          this.state.selectedAsset
                        )
                      )
                      .filter((t) =>
                        t.attributes.WorkType.includes(
                          this.state.selectedWorkType
                        )
                      )
                      .filter((u) =>
                        u.attributes.AssignedToID.includes(
                          this.state.selectedUser
                        )
                      ).length
                  }
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div
                id="layerInfoContentDisplay"
                style={__listPanelContentScroller}
              >
                <div>{this.__renderComponent()}</div>
              </div>
            </CardBody>
            <CardFooter style={_btnFooter}>
              <FooterList
                zoomToList={this.zoomToList}
                printWorkOrdersInBatch={this.printWorkOrdersInBatch}
                showSelected={this.showSelected}
                btnListDisabled={this.state.printBatchItems.length === 0}
              />
            </CardFooter>
          </Card>
        );
        break;
    }
  }

  render() {
    return <Fragment>{this.renderListComponent()}</Fragment>;
  }
}
const mapStateToProps = (state) => {
  return {
    config: state.AppConfig.config,
  };
};
export default withRouter(connect(mapStateToProps)(WorkOrdersList));
