import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import swal from 'sweetalert';
import { getLoggedInUser } from '../../helpers/authUtils';
import { UserAPI } from '../../services/users.services';
import { workorderService } from '../../services/workorder.service';
import WorkDetails from './Details/WorkDetails';
import './wo.details.css';

const __listPanelContentScroller = {
  position: 'absolute',
  top: '70px',
  left: '0',
  right: '0',
  bottom: '65px',
  overflow: 'auto',
};

const _btnFooter = {
  backgroundColor: '#a4a3a4',
};

class WorkOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      User: null,
      modal: false,
      isReclassifyingWork: false,
      workStatus: this.props.workorder.attributes.Status,
      unassignedWorkStatus: 'Unassigned',
      isClaimingWork: false,
      isWorkAssigned: false,
    };
    this.toggle = this.toggle.bind(this);
    this.openModalWithHeaderClass = this.openModalWithHeaderClass.bind(this);
    this.openModalWithBodyClass = this.openModalWithBodyClass.bind(this);
  }

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  openModalWithHeaderClass = (className) => {
    this.setState({ headerClassName: className });
    this.toggle();
  };
  openModalWithBodyClass = (className) => {
    this.setState({ headerClassName: className, bodyClass: className });
    this.toggle();
  };
  componentDidMount() {
//    this.state.GISAssets.length=0;
//    this.state.GISResources.length=0; 
    const loggedUser = getLoggedInUser();
    UserAPI.GetUser(loggedUser.id).then((user) => {
      const view = workorderService.mapView();
      this.setState({
        User: user.data,
        isWorkAssigned:
          this.props.workorder.attributes.Status === 'Assigned' ? true : false,
      });
    });
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  __getHumanDate = (_d) => {
    return new Date(_d).toLocaleDateString();
  };

  __getWorkStatus = () => {
    const { workStatus } = this.state;
    return workorderService.renderStatus(workStatus);
  };

  reclassifyWorkOrder = () => {
    swal({
      text: `Are you sure you want to release this work order?`,
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then((action) => {
      if (action) {
        // reverse the status back to Unassigned and return to list
        this.toggle();
      }
    });
  };

  reclassifyWork = () => {
    let __reason = document.getElementById('reason').value;
    if (__reason.trim().length < 1) {
      swal('Please provide a reason');
    } else {
      this.setState({ isReclassifyingWork: true });
      // append the reason to the work field note
      const feature = this.props.workorder;
      Object.keys(feature.attributes).map((fieldName, index) => {
        switch (fieldName.toUpperCase()) {
          case 'STATUS':
            feature.attributes[fieldName] = 'Unassigned';
            break;
          case 'ASSIGNEDTOID':
            feature.attributes[fieldName] = 'Unassigned';
            break;
          case 'ASSIGNEDTOUID':
            feature.attributes[fieldName] = '';
            break;
          case 'FIELDNOTES':
            feature.attributes[fieldName] =
              feature.attributes[fieldName] === null
                ? `Reclassified reason- ${__reason.trim()}`
                : `${
                    feature.attributes[fieldName]
                  } | Reclassified reason: ${__reason.trim()}`;
            break;
        }
      });
      // reverse the status back to Unassigned and return to list
      const edits = {
        updateFeatures: [feature],
      };
      workorderService.reclassifyWork(edits).then((res) => {
        if (res.updateFeatureResults.length > 0) {
          workorderService.RefreshWorkOrders();
          swal('Work released successfully');
          this.props.renderComponent('');
        } else {
          this.setState({ isReclassifyingWork: false });
          swal('Something went wrong. Please try again');
        }
      });
    }
  };

  ClaimUnassignedWork = () => {
    // Change status automatically
    this.setState({ isClaimingWork: true });
    const feature = this.props.workorder;

    Object.keys(feature.attributes).forEach((fieldName) => {
      switch (fieldName.toUpperCase()) {
        case 'STATUS':
          feature.attributes[fieldName] = 'Assigned';
          break;

        case 'ASSIGNEDTOID':
          feature.attributes[
            fieldName
          ] = `${this.state.User.firstName} ${this.state.User.lastName}`;
          break;
        case 'ASSIGNEDTOUID':
          feature.attributes[fieldName] = this.state.User.id;
          break;
      }
    });
    const edits = {
      updateFeatures: [feature],
    };

    workorderService.ClaimUnassignedWork(edits).then((res) => {
      if (res.updateFeatureResults.length > 0) {
        //console.log('Updated feature :: ', res.updateFeatureResults[0]);
        workorderService.RefreshWorkOrders();
        this.props.renderComponent('FieldWorkUpdate', {
          feature: feature,
          isClaimedWork: true,
        });
      } else {
        swal('There was an issue claiming this work. Please try again later.');
      }
      this.setState({ isClaimingWork: false });
    });
  };

  __renderOptionalButton() {
    switch (this.props.workorder.attributes.Status) {
      case 'Unassigned':
        return (
          <button
            type="button"
            className="btn btn-danger"
            disabled={this.state.isClaimingWork}
            onClick={this.ClaimUnassignedWork}
          >
            {this.state.isClaimingWork ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                {' Claiming...'}
              </>
            ) : (
              'Claim'
            )}
          </button>
        );
        break;
      case 'Completed':
        return <></>;
        break;

      default:
        return (
          <>
            <button
              type="button"
              className="btn btn-warning btn-lg"
              onClick={() =>
                this.props.renderComponent(
                  'FieldWorkUpdate',
                  {
                    feature: this.props.workorder,
                    isClaimedWork: false,
                  },
                  this.props.user
                )
              }
            >
              <i className="dripicons-pencil"></i>
            </button>
          </>
        );
        break;
    }
  }

  __returnToWorkList = () => {
    var selectedWorkID = new URLSearchParams(window.location.search).get('id');
    if (selectedWorkID) {
      // remove url params
      this.props.history.push('/apps/workorders');
    }
    this.props.renderComponent('');
  };

  render() {
    const { attributes } = this.props.workorder;
    return (
      <Fragment>
        <Card style={{ height: '100%' }}>
          <CardHeader style={{ padding: '0' }}>
            <div className="flex-row">
              <div className="box box--start">ID: {attributes.WOID}</div>
              <div
                className="box box--end text-right"
                style={{ width: '150px' }}
              >
                {this.__renderOptionalButton()}
              </div>
            </div>
          </CardHeader>

          <CardBody>
            <div
              id="layerInfoContentDisplay"
              style={__listPanelContentScroller}
            >
              <WorkDetails
                workorder={this.props.workorder}
                goBack={this.__returnToWorkList}
              />
              <hr />
              <div className="text-center"></div>
            </div>
          </CardBody>
          <CardFooter style={_btnFooter}>
            {/* <Button color={'primary'}>Complete</Button>
                        <Button color={'info'}>Follow up</Button> */}
            <Button
              color={'secondary'}
              onClick={() => this.__returnToWorkList()}
            >
              Return
            </Button>
            {this.state.isWorkAssigned && (
              <Button
                color={'danger'}
                onClick={this.reclassifyWorkOrder}
                className="ml-3"
              >
                Release Work Order
              </Button>
            )}
          </CardFooter>
        </Card>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Release Work Order</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="reason">Field Notes</label>
              <textarea
                className="form-control"
                id="reason"
                rows="4"
              ></textarea>
              <span className="help-block">
                <small>A reason is required.</small>
              </span>
            </div>
            <div className="form-group text-center">
              <button
                className="btn btn-primary"
                type="button"
                disabled={this.state.isReclassifyingWork}
                onClick={this.reclassifyWork}
              >
                {this.state.isReclassifyingWork
                  ? 'Releasing...'
                  : 'Complete the Release'}
              </button>
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}
export default withRouter(WorkOrderDetails);
