import { call, put, takeLatest } from 'redux-saga/effects';
import { SET_CONFIG, CONFIG_FETCH } from './constants';

import { getAppConfig } from './request';

function* fetchConfig() {
  const config = yield getAppConfig();
  // create and yield a dispatch Effect
  yield put({ type: SET_CONFIG, config });
}

export default fetchConfig;
