import React, { useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import { CrudOperations } from '../../../services/CRUDoperations.service';
import ReportRenderer from '../ReportRenderer/ReportRenderer';

const ManholeInspection = ({ workorderid, renderInspectionComponent }) => {
    const [CCTVvideos, setVideos] = useState([]);
    const [CCTVPhotos, setCCTVPhotos] = useState([]);
    const [CCTVReportFiles, setFiles] = useState([]);

    const __fetch__report = async() => {
        const { data } = await CrudOperations.GetByID('GISInspections/GetInspectionReports', workorderid);
        const __reports_files = data.filter((d) => d.reportType === "REPORT");
        const __photo_files = data.filter((d) => d.reportType === "PHOTO");
        const __video_files = data.filter((d) => d.reportType === "VIDEO");

        setTimeout(() => {
            setCCTVPhotos(__photo_files);
            setFiles(__reports_files);
            setVideos(__video_files);
        }, 20);
    }

    useEffect(() => {
        __fetch__report();
    }, [])

    const backBtnStyle = {
        fontSize:'1.5rem',
        color:'#737df3',
        cursor:'pointer'
    }

    return (
        <>
            <Card>
                <CardBody>
                    <h4 className="header-title mb-2">
                        <i className='dripicons-reply' 
                            style={backBtnStyle} 
                            onClick={() => renderInspectionComponent('WOInspection')}
                            title='Return'
                            >
                        </i> Manhole Inspection Report</h4>
                    
                        <ReportRenderer 
                            videos={CCTVvideos}
                            photos={CCTVPhotos}
                            reports={CCTVReportFiles} 
                        />

                </CardBody>
            </Card>
        </>
    )
}

export default ManholeInspection
