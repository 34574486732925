
import {
    AvField, AvForm
} from 'availity-reactstrap-validation';
import axios from 'axios';
import _debounce from 'lodash.debounce';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
    Button, Card,
    CardBody,
    CardHeader, Col, Row, UncontrolledAlert
} from 'reactstrap';
import swal from 'sweetalert';
import notFoundImg from '../../assets/images/file-searching.svg';
import PageTitle from '../../components/PageTitle';
import Spinner from '../../components/Spinner';
import { CrudOperations } from '../../services/CRUDoperations.service';
import { workorderService } from '../../services/workorder.service';
import imgSymbology from './images/layer_symbology.PNG';

const Entity = 'GisConfiguration';

class GISAssetsConfiguration extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            userRole: '',
            isLoadingServices: false,
            ServicesOptions: [],
            Roles: [],
            GISServices: [],
            departments: [],
            isSaving: false
        }
    }

    componentDidMount() {
        // check for super super admin
        const user_role = workorderService.GetUserRole();
        if(user_role === 'manager' || workorderService.IsAdmin()) {
            this.setState({ userRole: user_role, isLoadingServices: true });
            this.fetchGIS_Services();
        }

        setTimeout(() => {
            const footer = document.getElementById('footer');
            if (footer) {
              footer.classList.add('hide-footer');
            }
          }, 300);
    }
    componentWillUnmount() {
        const footer = document.getElementById('footer');
        footer.classList.remove('hide-footer');
        this.setState = (state, callback) => {
            return;
          };
    }

    fetchGIS_Services = async () => {
        const __gisServices = await CrudOperations.Get(Entity);
        const __departments = await CrudOperations.Get('Departments');

        //console.log(__gisServices);

        this.setState({
            GISServices: __gisServices.data,
            departments: __departments.data,
            isLoadingServices: false
        })
    }

    
    addService = (evt) => {
        //console.log(evt);
        let GISServices = this.state.GISServices;
        GISServices.push({gisServicesConfigurationID: 0});

        this.setState({GISServices});
    }

    RemoveService = (evt, idx, config) => {  // DELETE FOR ASSETS HAS BEEN DISABLED FROM THE INTERFACE DMM 12/12/2022 TOO CRITICAL AND ENMESHED
        evt.preventDefault();
        const GISServices = this.state.GISServices;
        if(parseInt(config.gisServicesConfigurationID) > 0) {
            swal({
                text: `Are you sure to delete this asset?`,
                closeOnClickOutside: false,
                buttons: { cancel: 'NO', confirm: 'YES' },
                dangerMode: true,
              }).then(async(action) => {
                  if(action){
                      await CrudOperations.Delete('GisConfiguration', config.gisServicesConfigurationID);

                      GISServices.splice(idx, 1);
                      this.setState({ GISServices });
                  }
              })
        } else{
            GISServices.splice(idx, 1);
            this.setState({ GISServices });
        }
    }  

    saveServiceUsers = ( selectedService, selectedUsers ) => {
        //console.log(selectedService);
        //console.log(selectedUsers);
        const serviceConfig = this.state.GISServices.find((s) => s.gisServicesConfigurationID === selectedService.value);

        let __usersGroup = [];

        selectedUsers.map((u) => {
            __usersGroup.push(
                {
                    userID: u.value,
                    gisServicesConfigurationID: serviceConfig.gisServicesConfigurationID
                }
            )
        })

        const __serviceUpdateObj = {
            gisServicesConfigurationID: serviceConfig.gisServicesConfigurationID,
            name: serviceConfig.name,
            serviceLink: serviceConfig.serviceLink,
            gisServicesUsersConfigurations: __usersGroup
          };

        CrudOperations.Update(Entity, __serviceUpdateObj)
            .then((res) => {
                this.fetchGIS_Services();
            })
            .catch((err) => console.log('Error updating GIS Config :: ', err));
    }

    _renderDepartmentOptions() {
        return (
          <>
            {this.state.departments.map((d, index) => {
              return (
                <option
                  key={index}
                  value={d.departmentID}
                >
                  {d.name}
                </option>
              );
            })}
          </>
        );
      }

      handleGIS_Service = (evt, values) => {
          //console.log(values);
          if(this.state.GISServices.length > 0) {
            this.setState({isSaving: true});
            const __bulkAssets = [];
            for (let index = 0; index < this.state.GISServices.length; index++) {
                const __configID = `config-${index}`;
                const __assetName = `name-${index}`;
                const __assetURL = `serviceURL-${index}`;
                const __assetDepartmentID = `departmentID-${index}`;
 
                const __configObj = {
                    gisServicesConfigurationID: values[__configID] === "" ? 0 : parseInt(values[__configID]),
                    name: values[__assetName],
                    serviceLink: values[__assetURL],
                    departmentID: parseInt(values[__assetDepartmentID]),
                    gisServicesUsersConfigurations: []
                };

                __bulkAssets.push(__configObj);
            }

            //console.log(__bulkAssets);

            CrudOperations.Create('GisConfiguration/CreateGisAssetsInBulkAsync', __bulkAssets)
                .then((res) => {
                    this.fetchGIS_Services();
                    swal('Assets saved successfully!');
                    this.setState({isSaving: false});
                })
                .catch((err) => console.log("Error saving assets on server :: ", err));

          }
      }

      __setGISURLValidity = async(url) => {
          if(url.indexOf("MapServer") > -1) {
            const { data } = await axios.get(`${url}?f=json`);
//            console.log(data);   CHANGED 10/28/2022 DMM
            console.log("Service Type :: ",data.type);
            
            return true;
          } else{
              return false;
          }
      }
    
      validateGISRestUrl = _debounce(async(value, ctx, input, cb) => {
        if(value === "") {}
        else{
//            console.log(value);  CHANGED 10/28/2022 DMM
            const __isValidRestUrl = await this.__setGISURLValidity(value); // returns true or false
//            console.log(__isValidRestUrl);  CHANGED 10/28/2022 DMM
            cb(__isValidRestUrl);
        }
      }, 300);

    __renderComponent() {
        if(this.state.userRole === '') {
            return (
                <div className="text-center">
                    <img src={notFoundImg} height="90" alt="" className="mt-4" />
                    <h3 className="text-error mt-4">Not Authorized</h3>
                    <h4 className="text-uppercase text-danger mt-3">
                        You are not authorized to edit work order assets
                    </h4>
                    

                    <Link className="btn btn-info mt-3" to="/">
                        <i className="mdi mdi-reply"></i> Return Home
                    </Link>
                </div>
            )
        }
        else{
            switch (this.state.isLoadingServices) {
                case true:
                    return (
                        <div style={{ textAlign: 'center', marginTop: '65px' }}>
                            <Spinner className="m-2" color={'info'} size={'sm'} />
                        </div>
                    )
            
                default:

                    return (
                        <>
                            <UncontrolledAlert color={'info'}>
                                The asset must be a <strong> ArcGIS MapService</strong> and sublayers must have a "FACILITYID" field.  
                                <p>For better rendering of the service, you must allow "per request order and symbology" checked</p>
                                <img src={imgSymbology} />
                                <p>
                                    Ex: <br />
                                    <u>Name</u>: Signs <br />
                                    <u>Service URL</u>: https://domain/arcgis/rest/services/streets/MapServer <br />
                                    <u>Department</u>: Streets
                                </p>
                            </UncontrolledAlert>

                            <Card className="mb-0">
                                    <CardHeader className="pt-3 pb-3">
                                        <h5 className="m-0">
                                            Assets  -  {this.state.GISServices.length}{' '}
                                        </h5>
                                    </CardHeader>
                                    <CardBody>
                                        <AvForm className="p-2" onValidSubmit={this.handleGIS_Service}>
                            
                                            {
                                                this.state.GISServices.map((s, idx) => (
                                                    <Row key={idx}>
                                                        <AvField  
                                                                name={`config-${idx}`} 
                                                                value={s.gisServicesConfigurationID}
                                                                style={{display:'none'}}
                                                                />
                                                        <Col md={3}>
                                                            <AvField
                                                                name={`name-${idx}`}
                                                                label="Name"
                                                                type="text"
                                                                value={s.name}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <AvField
                                                                name={`serviceURL-${idx}`}
                                                                label="Service URL"
                                                                type="text"
                                                                value={s.serviceLink}
                                                                /* validate={{async: this.validateGISRestUrl}} */
                                                                required
                                                            />

                                                        </Col>
                                                        <Col md={3}>
                                                            <AvField
                                                                name={`departmentID-${idx}`}
                                                                label="Department"
                                                                type="select"
                                                                value={s.departmentID}
                                                                required
                                                                className="custom-select"
                                                                disabled={parseInt(s.gisServicesConfigurationID) > 0 ? true : false}
                                                            >
                                                                <option value={0}>Select</option>
                                                                {this._renderDepartmentOptions()}
                                                            </AvField>

                                                        </Col>
{/*                                                        <Col md={1} style={{padding:'0', marginTop:'29px'}}>
                                                            <button className="btn btn-danger"
                                                                onClick={(evt)=> this.RemoveService(evt, idx, s)}
                                                            >
                                                                X
                                                            </button>
                                                </Col>  REMOVED ABILITY TO DELETE ASSETS ON DMM 12/12/2022 - TOO CRITICAL AND ENMESHED */}
                                                    </Row>
                                                ))
                                            }

                                            <button type="button" 
                                                className="btn btn-info mt-2 btn-lg"
                                                onClick={this.addService}
                                                >
                                                Add Asset
                                            </button>
                                            
                                            

                                            {
                                                this.state.GISServices.length > 0 && (
                                                    <div className="mt-3">
                                                        <Button 
                                                            color="primary" 
                                                            disabled={this.state.isSaving}
                                                            type="submit"
                                                            >
                                                            {
                                                                this.state.isSaving && (
                                                                    <>
                                                                        <span
                                                                            className="spinner-border spinner-border-sm me-1"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                            ></span>
                                                                            {' Saving...'}
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                !this.state.isSaving && (
                                                                    <>
                                                                        Save
                                                                    </>
                                                                )
                                                            }
                                                        </Button>
                                                    </div>
                                                )
                                            }
                                        </AvForm>
                                    </CardBody>
                                 
                                </Card>

                        </>
                    )
            }
        }
    }
    
    render() {
        return (
            <div>
                <PageTitle
                    breadCrumbItems={[
                    { label: 'Map', path: '/apps/workorders' },
                    { label: 'Assets', active: true },
                    ]}
                    title={'GIS Assets Configuration'}
                />
               {
                   this.__renderComponent()
               }

            </div>
        )
    }
}

export default withRouter(GISAssetsConfiguration);

