// @flow
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
} from './constants';

type AuthAction = { type: string, payload: {} | string };

export const loginUser = (username: string, password: string): AuthAction => ({
  type: LOGIN_USER,
  payload: { username, password },
});

export const loginUserSuccess = (user: string): AuthAction => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserFailed = (error: string): AuthAction => ({
  type: LOGIN_USER_FAILED,
  payload: error,
});

export const registerUser = (newUserObj: {}): AuthAction => ({
  type: REGISTER_USER,
  payload: newUserObj,
});

export const registerUserSuccess = (
  userRegistrationStatus: string
): AuthAction => ({
  type: REGISTER_USER_SUCCESS,
  payload: userRegistrationStatus,
});

export const registerUserFailed = (error: string): AuthAction => ({
  type: REGISTER_USER_FAILED,
  payload: error,
});

export const logoutUser = (history: any): AuthAction => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const forgetPassword = (username: string): AuthAction => ({
  type: FORGET_PASSWORD,
  payload: { username },
});

export const forgetPasswordSuccess = (
  passwordResetStatus: string
): AuthAction => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error: string): AuthAction => ({
  type: FORGET_PASSWORD_FAILED,
  payload: error,
});

export const resetPassword = (
  email: String,
  password: string,
  code: String
): AuthAction => ({
  type: RESET_PASSWORD,
  payload: { email, password, code },
});
export const resetPasswordSuccess = (error: string): AuthAction => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: error,
});
export const resetPasswordFailed = (error: string): AuthAction => ({
  type: RESET_PASSWORD_FAILED,
  payload: error,
});
