// DID NOT FIND THIS MODULE ACCESSED IN THE WOC CURRENT AS OF 8/3/2022
import { loadModules } from 'esri-loader';
import { workorderService } from '../workorder.service';

const options = {
  query: {
    f: 'json',
  },
  responseType: 'json',
};

export const mapGraphics = {
  getSymbol: (featureType) => {
    var symbol;

    switch (featureType) {
      case 'polyline':
        symbol = {
          type: 'simple-line',
          style: 'dash',
          width: 4,
          color: [245, 251, 50, 1],
        };
        break;
      case 'polygon':
        symbol = {
          type: 'simple-fill',
          outline: { width: 2, color: [245, 251, 50, 1] },
          color: [242, 255, 92, 0.25],
        };
        break;

      default:
        // point default
        symbol = {
          type: 'simple-marker',
          outline: { width: 1.25, color: [245, 251, 50, 1] },
          size: 22,
          color: [242, 255, 92, 0.25],
        };
        break;
    }

    return symbol;
  },
  __GetLayerURLAndIDs: (layerURL, layerName) => {
    return loadModules(['esri/request']).then(([esriRequest]) => {
      var IDs = [];
      return esriRequest(layerURL, options).then((response) => {
        //console.log(response.data);
        const { layers } = response.data;
        const l = layers.find((layer) => layer.name === layerName);
        IDs.push(l.id);
        return { IDs: IDs, layerURL: layerURL };
      });
    });
  },
  highlightAssetsAndZoomToExtent: (view, assets) => {
    const _gLayer = view.map.findLayerById('assetHighlighterGLayer');
    _gLayer.graphics.removeAll();
    mapGraphics.__doFindMultipleAssets(assets);
  },
  
  highlightAssetAndZoomTo: async (view, assets) => {
    const _gLayer = view.map.findLayerById('assetHighlighterGLayer');
    _gLayer.graphics.removeAll();

    const FindTask = [];

    for (let index = 0; index < assets.length; index++) {
      const asset = assets[index];

      const { IDs, layerURL } = await mapGraphics.__GetLayerURLAndIDs(
        asset.getGISService.serviceLink,
        asset.assetName
      );
      const task = await mapGraphics.__getSingleTask(
        asset.facilityID,
        IDs,
        layerURL,
        view
      );
      //console.log(task);
      FindTask.push(task.results[0]);
    }
    //console.log(FindTask);
    return mapGraphics.__zoomTo(FindTask, view);
  },

  __getSingleTask: (fid, IDs, serviceUrl, view) => {
    return loadModules([
      'esri/tasks/FindTask',
//      'esri/tasks/support/FindParameters',
      'esri/rest/support/FindParameters',
    ]).then(([FindTask, FindParameters]) => {
      const find = new FindTask({
        url: serviceUrl,
      });
      // Set parameters to only query the Counties layer by name
      const params = new FindParameters({
        layerIds: IDs,
        searchFields: ['FACILITYID'],
        searchText: fid,
        returnGeometry: true,
        outSpatialReference: view.spatialReference,
      });

      return find.execute(params);
    });
  },

  __doFindMultipleAssets: (assets) => {
    let layerName = assets[0].assetName;
//    console.log(layerName);  CHANGED 10/28/2022 DMM
    let __whereClause = 'FACILITYID in (';
    assets.map((a) => {
      __whereClause += `'${a.facilityID}',`;
    });

    __whereClause = __whereClause.slice(0, -1); // remove last comma
    __whereClause = __whereClause + ')';

//    console.log(__whereClause);
    // perform query
    loadModules(['esri/tasks/QueryTask', 'esri/tasks/support/Query']).then(
      ([QueryTask, Query]) => {
        let queryTask = new QueryTask({
          url: 'https://gis.doverohio.com/arcgis/rest/services/Managed/Utilities/FeatureServer',
        });
        let query = new Query();
        query.returnGeometry = true;
        query.outFields = ['*'];
        query.where = 'POP &gt; 1000000'; // Return all cities with a population greater than 1 million

        // When resolved, returns features and graphics that satisfy the query.
        queryTask.execute(query).then(function (results) {
//          console.log(results.features);  changed by DMM 10/31/2022
        });
      }
    );
  },
  __doFindAsset: (fid, IDs, serviceUrl, view, _gLayer) => {
    loadModules([
      'esri/tasks/FindTask',
//      'esri/tasks/support/FindParameters',
      'esri/rest/support/FindParameters',
      'esri/Graphic',
    ]).then(([FindTask, FindParameters, Graphic]) => {
      const find = new FindTask({
        url: serviceUrl,
      });
      // Set parameters to only query the Counties layer by name
      const params = new FindParameters({
        layerIds: IDs,
        searchFields: ['FACILITYID'],
        searchText: fid,
        returnGeometry: true,
        outSpatialReference: view.spatialReference,
      });
      find
        .execute(params)
        .then((res) => {
//          console.log(res);  CHANGED 10/28/2022 DMM
          var features = [];
          res.results.map((response) => {
            const { feature } = response;
            const gr = new Graphic(
              feature.geometry,
              mapGraphics.getSymbol(feature.geometry.type)
            );
            features.push(gr);
            _gLayer.add(gr);
          });
          // call zoom
          mapGraphics.__zoomTo(features, view);
        })
        .catch((err) => console.log('ERROR :: ', err));
    });
  },

  __zoomTo: (TaskFeatures, view) => {
    return loadModules(['esri/Graphic']).then(([Graphic]) => {
      const _gLayer = view.map.findLayerById('assetHighlighterGLayer');
      _gLayer.graphics.removeAll();
      var features = [];
      TaskFeatures.map((response) => {
        const { feature } = response;
        const gr = new Graphic(
          feature.geometry,
          mapGraphics.getSymbol(feature.geometry.type)
        );
        features.push(gr);
        _gLayer.add(gr);
      });

      return features;
      /* setTimeout(() => {
        view.goTo(features);

      }, 20); */
    });
  },
};
