/**
 * Performs CRUD operations from SRC backend service
 *
 */

import Axios from 'axios';

// SRC API http request config
export const _src_backend_baseurl = Axios.create({
  baseURL: process.env.REACT_APP_WOC_API_URL, //'https://localhost:44308/api', //'https://demogis.emht.com/srcapi/api', //
});

export const CrudOperations = {
  Create: (Entity, EntityObj) => {
    return _src_backend_baseurl.post(`/${Entity}`, EntityObj);
  },
  Post: (Entity, EntityObj) => {
    return _src_backend_baseurl.post(`/${Entity}`, EntityObj);
  },
  Get: (Entity) => {
    return _src_backend_baseurl.get(`/${Entity}`);
  },
  GetByID: (Entity, EntityObjID) => {
    return _src_backend_baseurl.get(`/${Entity}/${EntityObjID}`);
  },
  Delete: (Entity, EntityObjID) => {
    return _src_backend_baseurl.delete(`/${Entity}/${EntityObjID}`);
  },
  Update: (Entity, EntityObj) => {
    return _src_backend_baseurl.put(`/${Entity}`, EntityObj);
  },
  VerifyItemUsedForDeletion: (id, entity) => {
    return _src_backend_baseurl.get(
      `WorkOrders/VerifyItemUsed/${id}/${entity}`
    );
  },
  GetResourcesByDepartment: (departmentID) => {
    return _src_backend_baseurl.get(
      `resources/GetResourcesByDepartment/${departmentID}`
    );
  },
  GetServicesByDepartment: (departmentID) => {
    return _src_backend_baseurl.get(
      `services/GetServicesByDepartment/${departmentID}`
    );
  },
  GetProblemResolutions: () => {
    return _src_backend_baseurl.get(`/ProblemResolutions`);
  },
  GetProblemResolutionsByDepartment: (departmentID) => {
    return _src_backend_baseurl.get(
      `/ProblemResolutions/GetProblemResolutionsByDepartment/${departmentID}`
    );
  },
  GetProblemResolutionsByAsset: (assetID) => {
    return _src_backend_baseurl.get(
      `/ProblemResolutions/GetProblemResolutionsByAsset/${assetID}`
    );
  },
};

export const CurrencyFormater = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const GlobalFormatter = {
  FormatUSD: (price) => {
    return CurrencyFormater.format(price);
  },
};
