import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import { CustomInput, UncontrolledCollapse } from 'reactstrap';
import { CrudOperations } from '../../services/CRUDoperations.service';
import { workorderService } from '../../services/workorder.service';
import Spinner from '../Spinner';
import TopLayerSelector from '../utils/TopLayerSelector/TopLayerSelector';

class UtilityToggler extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      Water: 'Water',
      Storm: 'Storm',
      Sanitary: 'Sanitary',
      Electric: 'Electric',
      Telecommunication: 'Telecommunication',
      User: null,
      departments: [],
      userServices: [],
      userTopService: null,
      userSettings: null,
    };
  }
  componentDidMount() {
    this.__setUserServices();
    setTimeout(() => {
      CrudOperations.GetByID('UserSettings', this.props.user.id).then(
        async (res) => {
          // console.log(res);
          let __assets = [];
          if (res.data === '' || res.data === null) {
            //
            if (this.state.userServices.length > 0) {
              this.setState({
                userTopService: this.state.userServices[0],
                userSettings: null,
                isLoading: false,
              });
            } else {
              // get services from department for Supervisor and all for Manager
              const userType = workorderService.GetUserRole();
              //console.log(userType);
              switch (userType) {
                case 'manager':
                  // load assets for department
                  __assets = this.props.WOCAssets;
                  break;
                case 'supervisor':
                  // load assets for department
                  break;
                case 'field':
                  // load assets for user
                  /* console.log(this.props.WOCAssets);
                  __assets = this.props.WOCAssets.filter((a) =>
                    a.gisServicesUsersConfigurations.filter(
                      (s) => s.userID === this.props.user.id
                    )
                  );
                  console.log(__assets); */
                  break;
              }
              if (__assets.length > 0) {
                //console.log('Alright... :: ', this.props.WOCAssets);
                this.setState({
                  userTopService: __assets[0],
                  userServices: __assets,
                  userSettings: null,
                  isLoading: false,
                });
              }
              //console.log(this.props.user);
              setTimeout(() => {
                this.__setUserServices();
              }, 100);
            }
          } else {
            //console.log(this.state.userServices);
            if (this.state.userServices.length > 0) {
              const selectedService = this.state.userServices.find(
                (s) =>
                  s.gisServicesConfigurationID ===
                  parseInt(res.data.mostTopLayerID)
              );
              this.setState({
                userTopService: selectedService
                  ? selectedService
                  : this.state.userServices[0],
                userSettings: res.data,
                isLoading: false,
              });
            }
          }
          this.setState({ isLoading: false });
        }
      );
    }, 200);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  onSelectionChangeSave = (id) => {
    const { userTopService, userServices } = this.state;
    const selectedService = userServices.find(
      (s) => s.gisServicesConfigurationID === parseInt(id)
    );
    this.setState({ userTopService: selectedService });
    //this.__renderLayersToggler();
  };

  __setUserServices = () => {
    const { userServices } = this.state;
    this.props.WOCAssets.map((s) => {
      if (s.gisServicesUsersConfigurations.length > 0) {
        const _user_services = s.gisServicesUsersConfigurations.find(
          (c) => c.userID === this.props.user.id
        );
        if (_user_services) {
          userServices.push(s);
        }
      }
    });
    setTimeout(() => {
      //console.log(userServices);
      this.setState({
        userServices,
        isLoading: false,
      });

      ReactDOM.render(
        <TopLayerSelector
          layers={userServices}
          onSelectionChangeSave={this.onSelectionChangeSave}
          isDisabled={userServices.length < 2}
          selectedOption={
            this.state.userTopService === null
              ? 0
              : this.state.userTopService.gisServicesConfigurationID
          }
          userSettings={this.state.userSettings}
          userID={this.props.user.id}
        />,
        document.getElementById('topAssetSelector')
      );
    }, 500);
  };

  __renderLayersToggler() {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      return (
        <Fragment>
          <div>
            {this.__render_user_choice_topLayer()}
            {this.__renderOtherLayers()}
          </div>
        </Fragment>
      );
    }
  }

  __toggleLayerVisibility = (layerName, isChecked) => {
    const __layerId = layerName.replace(/ +/g, '');
    const mapview = this.props.mapview;
    const layer = mapview.map.layers.items.filter((l) => {
      return l.id === __layerId.toLowerCase().trim();
    });
    /// layer root visibility
    layer[0].visible = isChecked;
    layer[0].sublayers.items.map((subL) => {
      subL.visible = isChecked;
    });
  };

  __render_user_choice_topLayer() {
    if (this.state.userServices.length > 0) {
      return (
        <CustomInput
          type="switch"
          id={this.state.userTopService.gisServicesConfigurationID}
          name="customSwitch"
          label={this.state.userTopService.name}
          onClick={(e) =>
            this.__toggleLayerVisibility(
              this.state.userTopService.name,
              e.target.checked
            )
          }
        />
      );
    }
  }
  __renderOtherLayers() {
    if (this.props.WOCAssets.length > 0) {
      var OtherLayers = this.props.WOCAssets;
      if (this.state.userTopService) {
        OtherLayers = OtherLayers.filter(
          (l) =>
            l.gisServicesConfigurationID !==
            this.state.userTopService.gisServicesConfigurationID
        );
      }

      return (
        <div id="accordion">
          <NavLink
            to={''}
            className="custom-accordion-title d-block pt-2 pb-2"
            id="groups3"
            href="#"
          >
            {this.state.userServices.length > 0 ? 'More ' : 'Layers '}
            <span className="float-right">
              <i className="mdi mdi-chevron-right font-18 accordion-arrow"></i>
            </span>
          </NavLink>
          <UncontrolledCollapse toggler="#groups3">
            {OtherLayers.map((s, idx) => (
              <CustomInput
                key={idx}
                type="switch"
                id={s.gisServicesConfigurationID}
                name={`customSwitch${idx}`}
                label={s.name}
                onClick={(e) =>
                  this.__toggleLayerVisibility(s.name, e.target.checked)
                }
              />
            ))}
          </UncontrolledCollapse>
        </div>
      );
    }
  }

  render() {
    return (
      <Fragment>
        <div
          className="btn-group-vertical"
          style={{ padding: '8px', backgroundColor: '#fff' }}
        >
          {this.__renderLayersToggler()}
        </div>
      </Fragment>
    );
  }
}

export default UtilityToggler;
