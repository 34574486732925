export function configureFakeBackend() {
  let users = [
    {
      id: 1,
      username: 'gisreviewer@emht.com',
      password: '#GIS1sAwesome!',
      firstName: 'Test',
      lastName: 'User',
      role: 'Admin',
    },
  ];
  let realFetch = fetch;
  fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      // wrap in timeout to simulate server api call
      setTimeout(() => {
        // authenticate
        if (url.endsWith('/users/authenticate') && opts.method === 'POST') {
          // get parameters from post request
          let params = JSON.parse(opts.body);

          // find if any user matches login credentials
          let filteredUsers = users.filter((user) => {
            return (
              user.username === params.username &&
              user.password === params.password
            );
          });

          if (filteredUsers.length) {
            // if login details are valid return user details and fake jwt token
            let user = filteredUsers[0];
            let responseJson = {
              id: user.id,
              username: user.username,
              firstName: user.firstName,
              lastName: user.lastName,
              role: 'Admin',
              token:
                'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI',
            };
            resolve({ ok: true, json: () => responseJson });
          } else {
            // else return error
            reject('Invalid login attempt.');
          }
          return;
        }

        // register
        if (url.endsWith('/users/register') && opts.method === 'POST') {
          // get parameters from post request
          let params = JSON.parse(opts.body);

          // add new users
          let { firstName, lastName } = params.fullname.split(' ');
          let newUser = {
            id: users.length + 1,
            username: firstName,
            password: params.password,
            firstName: firstName,
            lastName: lastName,
            role: 'Admin',
          };
          users.push({ newUser });

          let responseJson = {
            id: newUser.id,
            username: newUser.username,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            role: newUser.role,
            token:
              'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI',
          };
          resolve({ ok: true, json: () => responseJson });
          return;
        }

        // forget password
        if (url.endsWith('/users/password-reset') && opts.method === 'POST') {
          // get parameters from post request
          let params = JSON.parse(opts.body);

          // find if any user matches login credentials
          let filteredUsers = users.filter((user) => {
            return user.username === params.username;
          });

          if (filteredUsers.length) {
            let responseJson = {
              message:
                "We've sent you a link to reset password to your registered email.",
            };
            resolve({ ok: true, json: () => responseJson });
          } else {
            // else return error
            reject(
              'Sorry, we could not find any registered user with entered username'
            );
          }
          return;
        }

        // get users
        if (url.endsWith('/users') && opts.method === 'GET') {
          // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
          if (
            opts.headers &&
            opts.headers.Authorization ===
              'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI'
          ) {
            resolve({ ok: true, json: () => users });
          } else {
            // return 401 not authorised if token is null or invalid
            reject('Unauthorised');
          }

          return;
        }

        //console.log(url);
        //console.log(opts);

        if (
          url.endsWith('/applyEdits') ||
          url.endsWith('/addAttachment?f=json')
        ) {
          realFetch(url, opts).then((response) => resolve(response));
          return;
        }
        //realFetch(url, opts).then((response) => resolve(response));
        realFetch(url).then((response) => resolve(response));
      }, 500);
    });
  };
}
