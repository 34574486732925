import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { getLoggedInUser } from '../../helpers/authUtils';
import { workorderService } from '../../services/workorder.service';
import PageTitle from '../PageTitle';
import Spinner from '../Spinner';
import NoWorkOrders from './NoWorkOrders';

class WorkOrdersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workorders: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    // load work orders
    const loggedUser = getLoggedInUser();
    if (this.props.config) {
      workorderService.GetWorkOrders(this.props.config).then((res) => {
        var features = res.features;
        if (features.length > 0) {
          const userRole = workorderService.GetUserRole();
          switch (userRole) {
            case 'field':  //fix 11/1/2022 DMM
/*              features = features.filter(
                (f) => f.attributes.DepartmentName === loggedUser.department
              );  */
              features = features.filter(
                (f) =>
                  (f.attributes.AssignedToUID === loggedUser.id)
              ); 
              setTimeout(() => {
                document.getElementById('btnLeftPanelToggle').click();
              }, 100);
              break;
            case 'supervisor':
              features = features.filter(
                (f) => f.attributes.DepartmentName === loggedUser.department
              );
              break;
            default:
              break;
          }
        }
        this.setState({
          workorders: features,
          isLoading: false,
        });
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  __getHumanDate = (_d) => {
    return new Date(_d).toLocaleDateString();
  };
  __getGlobalID = (WOID) => {
    const wo = this.state.workorders.find(
      (w) => w.attributes.WOID === WOID
    );
    return (
      <>
        <a href={`/apps/workorders/?id=${wo.attributes.GlobalID}`}>
          {wo.attributes.WOID}
        </a>
      </>
    );
  };

  renderStatus(status) {
    switch (status) {
      case 'Unassigned':
        return (
          <span className="badge bg-danger rounded-pill work-status-text-color">
            {status}
          </span>
        );
        break;
      case 'Assigned':
        return (
          <span className="badge bg-warning rounded-pill work-status-text-color">
            {status}
          </span>
        );
        break;
      case 'Mine':
        return (
          <span className="badge bg-secondary rounded-pill work-status-text-color">
            {status}
          </span>
        );
        break;

      case 'Completed':
        return (
          <span className="badge bg-success rounded-pill work-status-text-color">
            {status}
          </span>
        );
        break;
    }
  }

  __renderComponent() {
    const columns = [
      {
        dataField: 'attributes.WOID',
        text: 'ID',
        formatter: (cell) => {
          return this.__getGlobalID(cell);
        },  
        sort: true,
      },
      {
        dataField: 'attributes.WorkType',
        text: 'Work Type',
        sort: true,
      },
      {
        dataField: 'attributes.DepartmentName',
        text: 'Department',
        sort: true,
      },
      {
        dataField: 'attributes.LocationAddress',
        text: 'Address',
        sort: true,
      },
      {
        dataField: 'attributes.OriginatorID',
        text: 'Originator',
        sort: true,
      },
      {
        dataField: 'attributes.AssignedToID',
        text: 'Assigned To',
        sort: true,
      },
      {
        dataField: 'attributes.PlannedDate',
        text: 'Due Date',
        formatter: (cell, row) => {
          return workorderService.GetDueDateFormatted(
            cell,
            row.attributes.Status,
            row.attributes.IsRecurring
          );
        },
        sort: true,
        csvFormatter: (cell) => {
          return workorderService.__getHumanDateAndTime(cell);
        },
      },
      {
        dataField: 'attributes.Status',
        text: 'Status',
        formatter: (cell, row, index) => {
          return (
            <>
              <small style={{ textAlign: 'center', color: '#040202' }}>
                {this.renderStatus(cell)}
              </small>
            </>
          );
        },
        sort: true,
      },
      {
        dataField: 'attributes.DateCompleted',
        text: 'Date Completed',
        formatter: (cell) => {
          return workorderService.__getHumanDateAndTime(cell);
        },
        csvFormatter: (cell) => {
          return workorderService.__getHumanDateAndTime(cell);
        },
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: 'attributes.WOID',
        order: 'desc',  // Updated from 'asc' to 'desc' 9/18/2023 by DMM
      },
    ];

    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      if (this.state.workorders.length > 0) {
        return (
          <Card>
            <CardBody>
              <h4 className="header-title">Search or Export</h4>
              <p className="text-muted font-14 mb-4">
                Search or export work orders data in a CSV format
              </p>

              <ToolkitProvider
                bootstrap4
                keyField="attributes.WOID"
                data={this.state.workorders}
                columns={columns}
                search
                exportCSV={{ onlyExportFiltered: false, exportAll: true }}
              >
                {(props) => (
                  <React.Fragment>
                    <Row>
                      <Col>
                        <SearchBar {...props.searchProps} />
                      </Col>
                      <Col className="text-right">
                        <ExportCSVButton
                          {...props.csvProps}
                          className="btn btn-primary"
                        >
                          Export CSV
                        </ExportCSVButton>
                      </Col>
                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      bordered={false}
                      defaultSorted={defaultSorted}
                      keyField="attributes.WOID"
                      paging={false} // {paginationFactory({ sizePerPage: 10 })}
                      wrapperClasses="table-responsive"
                    />
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        );
      } else {
        return <NoWorkOrders />;
      }
    }
  }

  render() {
    return (
      <Fragment>
        <div>
          <div className="main-content-wrapper-scrollable">
            <div style={{ clear: 'both' }}></div>
            <div>
              <PageTitle
                breadCrumbItems={[
                  { label: 'Map', path: '/apps/workorders' },
                  { label: 'Work orders list', active: true },
                ]}
                title={'Work Orders List'}
              />
              {this.__renderComponent()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    config: state.AppConfig.config,
  };
};
export default connect(mapStateToProps)(WorkOrdersTable);
