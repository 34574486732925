import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { Component, Fragment } from 'react';
import InputMask from 'react-input-mask';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import swal from 'sweetalert';
import notFoundImg from '../../assets/images/file-searching.svg';
import profileImg from '../../assets/images/users/noprofile.png';
import { getLoggedInUser } from '../../helpers/authUtils';
import { CrudOperations } from '../../services/CRUDoperations.service';
import { UserAPI } from '../../services/users.services';
import { workorderService } from '../../services/workorder.service';
import PageTitle from '../PageTitle';
import Spinner from '../Spinner';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      departments: [],
      GISServices: [],
      UserAssets: [],
      isLoading: true,
      isUpdatingProfile: false,
      user: null,
      totalWorkOrders: 0,
      activeWorkOrders: 0,
      unassignedWorkOrders: 0,
    };
    this.toggleProfileChangeModal = this.toggleProfileChangeModal.bind(this);
  }

  toggleProfileChangeModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  fetchGIS_Services = async () => {
    const { data } = await CrudOperations.Get('GisConfiguration');

    const { UserAssets } = this.state;
    const loggedUser = getLoggedInUser();

    if (data.length > 0) {
      data.map((s, idx) => {
        if (s.gisServicesUsersConfigurations.length > 0) {
          const __service = s.gisServicesUsersConfigurations.find(
            (a) => a.userID === loggedUser.id
          );
          if (__service) {
            UserAssets.push(s);
          }
        }
      });
    }

    this.setState({
      GISServices: data,
      UserAssets,
    });
  };

  componentDidMount() {
    // get departments
    this.fetchGIS_Services();
    CrudOperations.Get('Departments').then((res) => {
      this.setState({ departments: res.data });
    });
    // get user info
    const loggedUser = getLoggedInUser();
    this.getUserInfo(loggedUser.id);

    setTimeout(() => {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('hide-footer');
      }
    }, 300);
  }
  componentWillUnmount() {
    const footer = document.getElementById('footer');
    footer.classList.remove('hide-footer');
    this.setState = (state, callback) => {
      return;
    };
  }

  getUserInfo = (userID) => {
    UserAPI.GetUser(userID)
      .then((res) => {
        workorderService.GetUserWorkSummary(userID).then((summary) => {
          const { totalWorkOrders, activeWorkOrders, unassignedWorkOrders } =
            summary.data;
          this.setState({
            user: res.data,
            totalWorkOrders,
            activeWorkOrders,
            unassignedWorkOrders,
            isLoading: false,
          });
        });
      })
      .catch((err) => {
        console.log('Error fetching user :: ', err);
        swal('Error fetching user');
      });
  };

  __renderUserAssets = () => {
    return (
      <>
        {this.state.UserAssets.map((ds, idx) => (
          <li key={idx} className="list-inline-item ml-1 mb-1">
            <span className="badge badge-success-lighten p-1">{ds.name}</span>
          </li>
        ))}
      </>
    );
  };

  renderuserInfo() {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      switch (this.state.user) {
        case null:
          return (
            <div className="text-center">
              <img src={notFoundImg} height="90" alt="" className="mt-4" />
              <h4 className="text-uppercase text-danger mt-3">No user found</h4>
              <p className="text-muted mt-3">There is no user found.</p>
            </div>
          );
          break;

        default:
          return (
            <Fragment>
              <Row>
                <Col sm={12}>
                  {/* User information */}
                  <Card className="">
                    <CardBody className="profile-user-box">
                      <Row>
                        <Col sm={8}>
                          <div className="media">
                            <span className="float-left m-2 mr-4">
                              <img
                                src={profileImg}
                                style={{ height: '100px' }}
                                alt=""
                                className="rounded-circle img-thumbnail"
                              />
                            </span>
                            <div className="media-body">
                              <h4 className="mt-1 mb-1">
                                {this.state.user.firstName}{' '}
                                {this.state.user.lastName}
                              </h4>
                              <p className="font-13 text-dark-50">
                                {'Positon: '}
                                <b>{this.state.user.jobTile}</b>
                                {' | Department: '}
                                <b>{this.state.user.department}</b>
                              </p>

                              <ul className="mb-0 list-inline">
                                <li className="list-inline-item mr-3">
                                  <h5 className="mb-1">
                                    {this.state.totalWorkOrders}
                                  </h5>
                                  <p className="mb-0 font-13 text-dark-50">
                                    Total Work orders
                                  </p>
                                </li>
                                <li className="list-inline-item">
                                  <h5 className="mb-1">
                                    {this.state.activeWorkOrders}
                                  </h5>
                                  <p className="mb-0 font-13 text-dark-50">
                                    My active Orders
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>

                        <Col sm={4}>
                          <div className="text-center mt-sm-0 mt-3 text-sm-right">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={this.toggleProfileChangeModal}
                            >
                              <i className="mdi mdi-account-edit mr-1"></i> Edit
                              Profile
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  {/* User's seller information */}
                  <Card>
                    <CardBody>
                      <h4 className="header-title mt-0 mb-3">My Information</h4>

                      <hr />

                      <div className="text-left">
                        <p className="text-muted">
                          <strong>Full Name :</strong>{' '}
                          <span className="ml-2">
                            {this.state.user.firstName}{' '}
                            {this.state.user.lastName}
                          </span>
                        </p>

                        <p className="text-muted">
                          <strong>Mobile :</strong>
                          <span className="ml-2">
                            {this.state.user.phoneNumber}
                          </span>
                        </p>

                        <p className="text-muted">
                          <strong>Email :</strong>{' '}
                          <span className="ml-2">{this.state.user.email}</span>
                        </p>

                        <p className="text-muted">
                          <strong>Address :</strong>{' '}
                          <span className="ml-2">
                            {this.state.user.address}
                          </span>
                        </p>
                        <p className="text-muted">
                          <strong>
                            {this.state.user.city}, {this.state.user.state}{' '}
                            {this.state.user.zipCode}
                          </strong>{' '}
                        </p>

                        <p className="text-muted">
                          <strong>Company :</strong>
                          <span className="ml-2">
                            {' '}
                            {this.state.user.company}
                          </span>
                        </p>
                        <p className="text-muted">
                          <strong>Registered date :</strong>{' '}
                          <span className="ml-2">
                            {new Date(
                              this.state.user.creationDate
                            ).toLocaleDateString()}
                          </span>
                        </p>
                      </div>
                    </CardBody>
                  </Card>

                  {/* Contact information */}
                  <Card className="text-white bg-info overflow-hidden">
                    <CardBody>
                      <div className="toll-free-box text-center">
                        <h4>
                          {' '}
                          <i className="mdi mdi-deskphone"></i> Call (614) 775-4280 with WOC Questions
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={8}>
                  <Card>
                    <CardBody>
                      <h4 className="header-title mb-3">My Assets</h4>

                      <hr />

                      {this.state.UserAssets.length < 1 && (
                        <>
                          <Alert color="danger">
                            <i className="dripicons-wrong mr-2"></i>{' '}
                            <strong>No GIS Assets - </strong>
                            You do not have any GIS Assets assigned to you at
                            this time.
                          </Alert>
                        </>
                      )}
                      {this.state.UserAssets.length > 0 && (
                        <>
                          <div className="text-wrap">
                            <ul className="list-inline font-20 mb-3">
                              {this.__renderUserAssets()}
                            </ul>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <h4 className="header-title mb-3">My Roles</h4>
                      <Table className="table-centered mb-0" hover responsive>
                        <thead>
                          <tr>
                            <th>Role Name</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.user.roles.map((role, i) => (
                            <tr key={i}>
                              <td>{role.name}</td>
                              <td>{role.description}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
{/*                  <Card>
                    <CardBody>
                      <h4 className="header-title mb-3">Notifications</h4>

                      <hr />

                      <div className="text-left">
                        <p className="text-muted">
                          <strong>Mobile phone provider :</strong>{' '}
                          <span className="ml-2">
                            {this.state.user.mobileProvider}{' '}
                          </span>
                        </p>
                        <p className="text-muted">
                          <strong>Preference :</strong>{' '}
                          <span className="ml-2">
                            {this.state.user.preferredContact}{' '}
                          </span>
                        </p>
                      </div>
                    </CardBody>
                          </Card>  DMM 6/26/2023 */}
                </Col>
              </Row>
            </Fragment>
          );
          break;
      }
    }
  }

  _renderDepartmentOptions() {
    return (
      <Fragment>
        {this.state.departments.map((d, index) => {
          return (
            <option key={`dept-${index}`} value={d.name}>
              {d.name}
            </option>
          );
        })}
      </Fragment>
    );
  }

  updateUserProfile = (event, values) => {
    const {
      department,
      phone,
      phoneProvider,
      notificationPreference,
      address,
    } = values;
    if (phoneProvider === 'Select') {
      swal('Please select your phone line provider');
      return;
    }

    this.setState({ isUpdatingProfile: true });

    const userProfileUpdateObj = {
      userID: this.state.user.id,
      mobilePhone: phone,
      phoneLineProvider: phoneProvider,
      department,
      address,
      notificationPreference,
    };

    UserAPI.UpdateUserProfile(userProfileUpdateObj)
      .then((res) => {
        swal('Account updated successfully');
        this.getUserInfo(this.state.user.id);
        this.toggleProfileChangeModal();
      })
      .catch((err) => {
        swal('Error updating your profile. Please try again later');
        console.log('Error updating profile... :: ', err);
      })
      .finally(() => {
        this.setState({ isUpdatingProfile: false });
      });
  };

  render() {
    return (
      <Fragment>
        <PageTitle
          breadCrumbItems={[
            { label: 'Map', path: '/apps/workorders' },
            { label: 'User profile', active: true },
          ]}
          title={'My Profile'}
        />
        {this.renderuserInfo()}

        <Modal isOpen={this.state.modal} toggle={this.toggleProfileChangeModal}>
          <ModalHeader toggle={this.toggleProfileChangeModal}>
            Update Contact info
          </ModalHeader>
          <ModalBody>
            <AvForm onValidSubmit={this.updateUserProfile}>
              <Row>
                <Col md="6">
                  <AvField
                    name="department"
                    label="Your Department"
                    type="select"
                    value={this.state.user?.department}
                    disabled
                    className="custom-select"
                  >
                    <option>Select</option>
                    {this._renderDepartmentOptions()}
                  </AvField>
                </Col>
                <Col md="6">
                  <AvField
                    name="email"
                    label="Your Email"
                    type="text"
                    value={this.state.user?.email}
                    required
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AvField
                    type="tel"
                    mask="(999) 999-9999"
                    maskChar="-"
                    name="phone"
                    label="Your Mobile Phone"
                    value={this.state.user?.phoneNumber}
                    placeholder="Enter your phone #"
                    tag={[Input, InputMask]}
                    required
                    //helpMessage="Valid phone number is 9999999999"
                  />
                </Col>
{/*}                <Col md={6}>
                  <AvField
                    name="phoneProvider"
                    label="Your cell phone provider"
                    type="select"
                    value={this.state.user?.mobileProvider}
                    required
                    className="custom-select"
                  >
                    <option value="Select">Select</option>
                    <option value="ATT">AT&T</option>
                    <option value="T-Mobile">T-Mobile</option>
                    <option value="Verizon">Verizon</option>
                  </AvField>
                </Col>
              </Row>
        <Row>  DMM 6/26/2023 */}
                <Col md={6}>
                  <AvField
                    name="address"
                    label="Address"
                    type="text"
                    value={this.state.user?.address}
                    required
                  />
                </Col>
{/*                <Col md={6}>
                  <AvField
                    name="notificationPreference"
                    label="Notification Preference"
                    type="select"
                    value={this.state.user?.preferredContact}
                    required
                    className="custom-select"
                  >
                    <option value="Email">Email</option>
                    <option value="TextMessage">Text Message</option>
                    <option value="EmailAndText">Email And Text</option>
                  </AvField>
                </Col> changed DMM 6/26/2023 */}
              </Row>

              <div className="text-center m-3 mb-2">
                <Button
                  color="light"
                  type="button"
                  className="mr-1"
                  onClick={this.toggleProfileChangeModal}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={this.state.isUpdatingProfile}
                  type="submit"
                >
                  {this.state.isUpdatingProfile ? 'Submitting...' : 'Update'}
                </Button>
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default UserProfile;
