import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import swal from 'sweetalert';
import { getLoggedInUser } from '../../../helpers/authUtils';
import { CrudOperations } from '../../../services/CRUDoperations.service';
import { UserAPI } from '../../../services/users.services';
import PageTitle from '../../PageTitle';
import Spinner from '../../Spinner';

class UsersManagements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedUser: getLoggedInUser(),
      users: [],
      Department: '',
      appRoles: [],
      departments: [],
      isLoading: true,
      isManager: false,
      existingModal: false,
      selectedUser: {
        id: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        roles: [],
      },
      checkboxes: [],
      checkedItems: new Map(),
      GISAssets: [],
      assetsOptions: [],
      selectedAssets: [],
      selectedDepartment: '',
      userWOCRoleID: '',
    };
    this.toggleExistingModal = this.toggleExistingModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));
  };

  toggleExistingModal = () => {
    this.setState((prevState) => ({
      existingModal: !prevState.existingModal,
    }));
  };

  componentDidMount() {
    setTimeout(() => {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('hide-footer');
      }
    }, 300);

    const managerIndex = this.state.loggedUser.roles.findIndex(
      (r) => r.id === 'ef05fa07-09f7-4789-8ab6-f89e3c02a8f1'
    );
    if (managerIndex > -1) {
      this.setState({ isManager: true });
      this.__getUsers('all');
      this.__getDepartments();
      this.fetchGIS_Services();
    } else {
      this.__getUsers(this.state.loggedUser.department);
    }
  }

  componentWillUnmount() {
    const footer = document.getElementById('footer');
    footer.classList.remove('hide-footer');

    this.setState = (state, callback) => {
      return;
    };
  }

  __getDepartments = () => {
    CrudOperations.Get('Departments')
      .then((res) => {
        this.setState({ departments: res.data });
      })
      .catch((err) => {
        console.log('Error fetching departments :: ', err);
      });
  };

  fetchGIS_Services = async () => {
    const __gisServices = await CrudOperations.Get('GisConfiguration');
    this.setState({
      GISAssets: __gisServices.data,
      assetsOptions: [],
    });
    this.__setAssetsOptions();
  };

  __setAssetsOptions = () => {
    const { assetsOptions } = this.state;
    if (this.state.GISAssets.length > 0) {
      this.state.GISAssets.map((s) => {
        assetsOptions.push({
          value: s.gisServicesConfigurationID,
          label: s.name,
        });
      });
    }

    setTimeout(() => {
      this.setState({ assetsOptions });
    }, 20);
  };

  EditUser = (userObj) => {
    this.setState({ checkedItems: new Map() });
    const selectedUser = this.state.selectedUser;
    let selectedAssets = [];

    selectedUser.id = userObj.id;
    selectedUser.firstname = userObj.firstName;
    selectedUser.lastname = userObj.lastName;
    selectedUser.email = userObj.email;
    selectedUser.phone = userObj.phoneNumber;
    selectedUser.roles = userObj.roles;
    selectedUser.department = userObj.department;

    this.IsUserInRole(selectedUser.roles);

    // fetch user assets
    if (this.state.GISAssets.length > 0) {
      this.state.GISAssets.map((a) => {
        if (a.gisServicesUsersConfigurations.length > 0) {
          a.gisServicesUsersConfigurations.map((u) => {
            switch (u.userID) {
              case this.state.selectedUser.id:
                selectedAssets.push({
                  value: a.gisServicesConfigurationID,
                  label: a.name,
                });
                break;
            }
          });
        }
      });
    }
    setTimeout(() => {
      this.setState({
        selectedUser,
        selectedAssets,
        existingModal: true,
        selectedDepartment: selectedUser.department,
      });
    }, 30);
  };

  DeleteUser = (user) => {
    swal({
      text: `Are you sure to permanently delete the user ${user.firstName} ${user.lastName}?`,
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then(async (action) => {
      if (action) {
        try {
          await UserAPI.DeleteUser('Helpers', user.id);
          if (this.state.isManager) {
            this.__getUsers('all');
          } else {
            this.__getUsers(this.state.loggedUser.department);
          }
          swal(`user deleted successfully`);
        } catch (error) {
          console.log('**ERROR** :: error deleting user :: ', error);
          swal(
            `There was an error deleting this user. Please try again later.`
          );
        }
      }
    });
  };

  __getUsers = (department) => {
    UserAPI.GetApplicationRoles()
      .then((approles) => {
        let __woc_roles = [];

        approles.data.map((r) => {
          switch (r.id) {
            case 'ef05fa07-09f7-4789-8ab6-f89e3c02a8f1':
              __woc_roles.push({ index: 1, role: r });
              break;
            case '017bf016-611d-4ddd-b5d8-f59a998c31f1':
              __woc_roles.push({ index: 2, role: r });
              break;
            case 'e217ad31-13c9-452e-a800-d2c04215396c':
              __woc_roles.push({ index: 3, role: r });
              break;
          }
        });
        __woc_roles = __woc_roles.sort(
          (a, b) => parseInt(a.index) - parseInt(b.index)
        );

        UserAPI.GetUsersByDepartment(department)
          .then((res) => {
            const __admin_user = res.data.find(
              (u) => u.id === '91681766-3787-4875-a9b3-86642fbd037d'
            );
            res.data = res.data.filter((u) => u.id !== __admin_user.id);
            this.setState({
              Department: department,
              appRoles: __woc_roles,
              users: res.data,
              isLoading: false,
            });
          })
          .catch((err) => console.log('Error fetching users :: ', err));
      })
      .catch((err) => console.log('Error fetching roles :: ', err));
  };

  dateFormatter = (id, userObj) => {
    return new Date(userObj.creationDate).toLocaleDateString();
  };

  editsFormatter = (id, userObj) => {
    return (
      <div>
        <a href="#" onClick={() => this.EditUser(userObj)}>
          Edit
        </a>
        <a className="ml-3" href="#" onClick={() => this.DeleteUser(userObj)}>
          Delete
        </a>
      </div>
    );
  };

  handleGIS_Assets_Users = async () => {
    const __bulkAssets = [];

    if (this.state.selectedAssets && this.state.selectedAssets.length > 0) {
      for (let index = 0; index < this.state.selectedAssets.length; index++) {
        const config = this.state.selectedAssets[index];
        const __config__asset = this.state.GISAssets.find(
          (a) => a.gisServicesConfigurationID === config.value
        );
        const __configObj = {
          gisServicesConfigurationID:
            __config__asset.gisServicesConfigurationID,
          name: __config__asset.name,
          serviceLink: __config__asset.serviceLink,
          departmentID: __config__asset.departmentID,
          gisServicesUsersConfigurations: [
            {
              userID: this.state.selectedUser.id,
              gisServicesConfigurationID:
                __config__asset.gisServicesConfigurationID,
            },
          ],
        };

        __bulkAssets.push(__configObj);
      }

      await CrudOperations.Create(
        'GisConfiguration/AssignUsersAssetsInBulkAsync',
        __bulkAssets
      );
    }
    if (__bulkAssets.length === 0) {
      // remove user from assets
      await CrudOperations.Delete(
        'GisConfiguration/RemoveUserFromAssets',
        this.state.selectedUser.id
      );
    }
  };

  handleEdit = async (event, values) => {
    await this.handleGIS_Assets_Users();

    this.fetchGIS_Services();

    const __userRolesObj = {
      UserID: this.state.selectedUser.id,
      department: this.state.selectedDepartment,
      RoleIDs: [this.state.userWOCRoleID], //Keys,
    };

    // console.log(__userRolesObj);

    UserAPI.UpdateUserRoles(__userRolesObj)
      .then((res) => {
        if (this.state.isManager) {
          this.__getUsers('all');
        } else {
          this.__getUsers(this.state.loggedUser.department);
        }
        this.toggleExistingModal();
        swal(`Roles and assets updated successfully.`);
      })
      .catch((Err) => {
        swal('Unable to update roles at this time');
        console.log('Error updating roles :: ', Err);
      });
  };

  IsUserInRole = (userroles) => {
    for (let index = 0; index < userroles.length; index++) {
      const userrole = userroles[index];
      const woc_role = this.state.appRoles.find(
        (r) => r.role.id === userrole.id
      );
      //console.log(woc_role);
      if (woc_role) {
        this.setState({ userWOCRoleID: woc_role.role.id });
      }
      this.setState((prevState) => ({
        checkedItems: prevState.checkedItems.set(userrole.name.id, true),
      }));
    }
  };

  __renderUsers() {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      const TableWithSearch = () => {
        const { SearchBar } = Search;
        const { ExportCSVButton } = CSVExport;
        const defaultSorted = [
          {
            dataField: 'firstName',
            order: 'asc',
          },
        ];

        const columns = [
          {
            dataField: 'id',
            text: '',
            formatter: this.editsFormatter,
          },
          {
            dataField: 'firstName',
            text: 'First name',
            sort: true,
          },
          {
            dataField: 'lastName',
            text: 'Last name',
            sort: true,
          },
          {
            dataField: 'company',
            text: 'Company',
            sort: true,
          },
          {
            dataField: 'jobTile',
            text: 'Position',
            sort: true,
          },
          {
            dataField: 'department',
            text: 'Department',
            sort: true,
          },
          {
            dataField: 'address',
            text: 'Address',
            sort: true,
          },
          {
            dataField: 'phoneNumber',
            text: 'Phone',
            sort: true,
          },
          {
            dataField: 'creationDate',
            text: 'Joined date',
            sort: true,
            formatter: this.dateFormatter,
          },
        ];

        const expandRow = {
          renderer: (row) => (
            <div>
              <h5>Role{row.roles.length > 1 ? 's' : ''}</h5>
              <ol className="list-group list-group-numbered">
                {row.roles.map((role, index) => (
                  <li
                    key={`es-${index}`}
                    className="list-group-item d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        Role name: <b>{role.name}</b>, <u>Description</u>:{' '}
                        {role.description}
                      </div>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          ),
        };

        return (
          <Card>
            <CardBody>
              {/* <h4 className="header-title">
                Department: <b>{this.state.Department}</b>
              </h4> */}
              <p className="text-muted font-14 mb-4">
                Filter or export Users data in CSV format. Click on the row to
                view Roles if any.
              </p>

              <ToolkitProvider
                bootstrap4
                keyField="id"
                data={this.state.users}
                columns={columns}
                search
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
              >
                {(props) => (
                  <React.Fragment>
                    <Row>
                      <Col>
                        <SearchBar {...props.searchProps} />
                      </Col>
                      <Col className="text-right">
                        <ExportCSVButton
                          {...props.csvProps}
                          className="btn btn-primary"
                        >
                          Export CSV
                        </ExportCSVButton>
                      </Col>
                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      keyField="id"
                      bordered={true}
                      defaultSorted={defaultSorted}
                      paging={false} // {paginationFactory({ sizePerPage: 10 })}
                      wrapperClasses="table-responsive table-sm"
                      striped={true}
                      hover={true}
                      expandRow={expandRow}
                    />
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        );
      };

      return <TableWithSearch />;
    }
  }

  __handleAssetSelectionChange = (evt) => {
    // console.log(evt);
    setTimeout(() => {
      this.setState({ selectedAssets: evt });
    }, 20);
  };

  handleRoleChange = (roleID) => {
    this.setState({ userWOCRoleID: roleID });
    //this.setState({ isRoleChecked: !this.state.isRoleChecked });
  };

  render() {
    return (
      <Fragment>
        <div>
          <div className="main-content-wrapper-scrollable">
            <div style={{ clear: 'both' }}></div>
            <div>
              <PageTitle
                breadCrumbItems={[
                  { label: 'Map', path: '/apps/workorders' },
                  { label: 'Users', active: true },
                ]}
                title={'Users Management'}
              />
              <div>{this.__renderUsers()}</div>
            </div>
          </div>
        </div>

        {/* Modal Edit */}
        <Modal
          isOpen={this.state.existingModal}
          toggle={this.toggleExistingModal}
          size="lg"
        >
          <ModalHeader toggle={this.toggleExistingModal}>
            Edit Roles and Assets
          </ModalHeader>
          <ModalBody>
            <AvForm className="p-2" onValidSubmit={this.handleEdit}>
              <Row>
                <Col md={6}>
                  <AvField
                    name="email"
                    label="Email"
                    type="email"
                    value={this.state.selectedUser.email}
                    disabled
                  />
                </Col>
                <Col md={6}>
                  <AvField
                    name="phone"
                    label="Phone"
                    type="text"
                    value={this.state.selectedUser.phone}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Label>
                    Select assets for{' '}
                    <b>
                      {this.state.selectedUser.firstname}{' '}
                      {this.state.selectedUser.lastname}
                    </b>
                  </Label>
                  <Select
                    isMulti={true}
                    options={this.state.assetsOptions}
                    value={this.state.selectedAssets}
                    className="react-select"
                    classNamePrefix="react-select"
                    onChange={(evt) => this.__handleAssetSelectionChange(evt)}
                  ></Select>
                </Col>
              </Row>
              {/* render roles */}
              <Row className="mt-3">
                <Col md={6}>
                  <h4>Roles:</h4>
                  <p className="text-muted">
                    Add or remove{' '}
                    <b>
                      {this.state.selectedUser.firstname}{' '}
                      {this.state.selectedUser.lastname}
                    </b>{' '}
                    from role
                  </p>
                  <div>
                    {this.state.appRoles.map((r, index) => (
                      <CustomInput
                        key={index}
                        type="radio"
                        id={r.role.id}
                        name="customRadio"
                        label={r.role.name}
                        checked={this.state.userWOCRoleID === r.role.id}
                        onChange={() => this.handleRoleChange(r.role.id)}
                      />
                    ))}
                  </div>
                  {/* <ul style={{ listStyle: 'none' }}>
                    {this.state.appRoles.map((role, index) => (
                      <li key={index}>
                        <div>
                          <CustomInput
                            type="checkbox"
                            //type="radio"
                            id={`role-${index}`}
                            label={role.name}
                            name={role.id}
                            //name="userRole"
                            checked={this.state.checkedItems.get(role.id)}
                            onChange={this.handleChange}
                          />
                        </div>
                      </li>
                    ))}
                  </ul> */}
                </Col>
                <Col md={6}>
                  <AvField
                    name="department"
                    label="Department:"
                    type="select"
                    className="custom-select"
                    value={this.state.selectedUser.department}
                    onChange={(e) =>
                      this.setState({ selectedDepartment: e.target.value })
                    }
                    required
                  >
                    <option>Select</option>
                    {this.state.departments.map((d, index) => (
                      <option key={index} value={d.name}>
                        {d.name}
                      </option>
                    ))}
                  </AvField>
                </Col>
              </Row>

              <div className="text-right">
                <Button
                  color="light"
                  type="button"
                  className="mr-1"
                  onClick={this.toggleExistingModal}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default UsersManagements;
