import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody
} from 'reactstrap';
import notFoundImg from '../../../../assets/images/file-searching.svg';

const ArchiveInspection = ({ inspectionFeatures, workorder, assets, renderInspectionComponent }) => {

    const [inspectionsModules, setInspectionsModules] = useState([]);

    const fetchInspectionsModules = () => {
    
        if(inspectionFeatures.length > 0) {
            const inspectionsModuleArray = [];
            const __gis__layer_name = workorder.assetName;
            const __feature_class_name = assets[0].assetName;
    
            inspectionFeatures.map((insp, idx) => {
                insp.inspectionLayers.map((layer) => {
                    if(layer.layerName === __gis__layer_name && layer.featureClass === __feature_class_name) {
                        inspectionsModuleArray.push(insp);
                    }
                })
            });
            setInspectionsModules(inspectionsModuleArray);
        }
    }

    useEffect(() => {
        fetchInspectionsModules();
    }, [])

    return (
        <>
           {inspectionFeatures.length < 1 && (
               <div className="text-center">
               <img src={notFoundImg} height="90" alt="" className="mt-4" />
               {/* <h3 className="text-error mt-4">404</h3> */}
               <h4 className="text-uppercase text-danger mt-3">Inspections not activated </h4>
               <p className="text-muted mt-3">
                 There are no active inspections at this time. Please contact  
                 <a href="mailto:dmair@emht.com?subject=WOC - Activate Inspections"> Derek Mair</a> to activate inspection feature
               </p>
             </div>
           )} 

           {inspectionsModules.length < 1 && (
               <div className="text-center">
               <img src={notFoundImg} height="90" alt="" className="mt-4" />
               {/* <h3 className="text-error mt-4">404</h3> */}
               <h4 className="text-uppercase text-danger mt-3">No Inspections data </h4>
               <p className="text-muted mt-3">
                 There are no inspections associated with this work order at this time. 
               </p>
             </div>
           )}

           {inspectionsModules.length > 0 && (
                <>
                    {inspectionsModules.map((insp, idx) => (
                        <Card key={idx} className="text-white bg-info overflow-hidden m-1">
                            <CardBody className="p-1">
                            <span className="float-left">
                                <img src={`${window.location.origin}/assets/images/inspections/${insp.thumbnail}`} style={{ height: '80px' }} alt="" className="img-thumbnail" />
                            </span>
                                <div className="media-body toll-free-box text-center">
                                    <h4>
                                        {' '}
                                       <a href="#" style={{color:'#ffeb3b'}} 
                                        onClick={() => renderInspectionComponent(insp.inspectionName)}>{insp.inspectionName}</a> 
                                    </h4>
                                    <div>
                                        {insp.description}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    ))}
                </>
           )} 
        </>
    )
}

export default ArchiveInspection
