import { loadModules } from 'esri-loader';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import { getLoggedInUser } from '../../helpers/authUtils';
import { CrudOperations } from '../../services/CRUDoperations.service';
import { UserAPI } from '../../services/users.services';
import { workorderService } from '../../services/workorder.service';
import { WorkOrderFilter } from '../../utils/filters/filter';
import StreetView from '../StreetView/StreetView';
import './map.style.css';
import UtilityToggler from './UtilityToggler';

class WorkOrdersMapAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      User: null,
      isFieldRole: true,
      mapview: null,
      modal: false,
      isFieldCrew: false,
      userType: 'field',
      WOCAssets: [],
      userApplicationSettings: null,
      selectedAsset: '',
    };
    this.toggle = this.toggle.bind(this);
    this.openModalWithHeaderClass = this.openModalWithHeaderClass.bind(this);
    this.openModalWithBodyClass = this.openModalWithBodyClass.bind(this);
  }

  /**
   * Show/hide the modal
   */
  /*:: toggle: () => void */
  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  /**
   * Opens modal with custom header
   */
  /*:: openModalWithHeaderClass: () => void */
  openModalWithHeaderClass = (className) => {
    this.setState({ headerClassName: className });
    this.toggle();
  };

  /**
   * Opens modal with custom body
   */
  /*:: openModalWithBodyClass: () => void */
  openModalWithBodyClass = (className) => {
    this.setState({ headerClassName: className, bodyClass: className });
    this.toggle();
  };

  componentDidMount() {
    const loggedUser = getLoggedInUser();
    UserAPI.GetUser(loggedUser.id).then(async (user) => {
      const __user__settings = await CrudOperations.GetByID(
        'UserSettings',
        user.data.id
      );
      //console.log(__user__settings);
      workorderService.SetUserAppSettings(__user__settings.data);
      let userType = workorderService.GetUserRole();
      this.setState({
        User: user.data,
        userType,
        userApplicationSettings: __user__settings.data,
      });
      if (this.state.userType === 'field') {
        setTimeout(() => {
          const btn = document.getElementById('btnLeftPanelToggle');
          if (btn) {
            btn.click();
          }
        }, 20);
      }
      this.initMapAction();
    });
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  __GetDepartmentByID = (departmentID) => {
  
/*    if (layerName.toLowerCase().indexOf('sanitary') > -1) {
      return 'Water Wastewater';
    }
    if (layerName.toLowerCase().indexOf('water') > -1) {
      return 'Water';
    }
    if (layerName.toLowerCase().indexOf('storm') > -1) {
      return 'Storm';
    }
    if (layerName.toLowerCase().indexOf('electric') > -1) {
      return 'Electric';
    }
    if (layerName.toLowerCase().indexOf('signs') > -1) {
      return 'General Services';
    }
    if (layerName.toLowerCase().indexOf('trees') > -1) {
      return 'Parks & Recreation';
    }

    return 'Telecommunication'; */

  };

  selectExistingWorkFeature(view) {
    view.on('click', (event) => {
      if (
        document.getElementById('mapId').style.cursor !== 'crosshair' &&
        this.state.isFieldRole
      ) {
        view.hitTest(event).then((response) => {
          // If a user clicks on an incident feature, select the feature.
          if (response.results.length === 0) {
            //this.toggleEditingDivs('block', 'none');
          } else if (
            response.results[0].graphic &&
            response.results[0].graphic.layer.id === 'workOrders'
          ) {
            // navigate to field crew work option

            this.props.renderComponent('FieldWorkUpdate', response.results[0]);

            /* this.setState({ addFeatureDivVisible: false });
                        this.selectFeature(
                            response.results[0].graphic.attributes[this.state.featureLayer.objectIdField]
                        );

                        this.state.view.popup.visible = false; */
          }
        });
      }
    });
  }

  IsIsolationTraceEnabled = () => {
    const { IswaterIsolationTraceEnabled, IsCCTVEnabled } =
      workorderService.GetAdvancedFeaturesAvailability();
    if (IswaterIsolationTraceEnabled === 'true') {
      return true;
    } else {
      return false;
    }
  };
  IsCCTVEnabled = () => {
    const { IswaterIsolationTraceEnabled, IsCCTVEnabled } =
      workorderService.GetAdvancedFeaturesAvailability();
    if (IsCCTVEnabled === 'true') {
      return true;
    } else {
      return false;
    }
  };

  initMapAction = () => {
    loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/layers/FeatureLayer',
      'esri/widgets/Legend',
      'esri/widgets/Expand',
      'esri/smartMapping/labels/clusters',
      'esri/smartMapping/popup/clusters',
      'esri/layers/GraphicsLayer',
      'esri/core/promiseUtils',
      'esri/widgets/Search',
      'esri/widgets/Home',
      'esri/widgets/Track',
      'esri/widgets/ScaleBar',
      'esri/config',
      'esri/widgets/BasemapToggle',
      'esri/Basemap',
      'esri/layers/TileLayer',
      'esri/layers/VectorTileLayer',
      'esri/core/urlUtils',
      'esri/tasks/Locator',
    ]).then(
      async ([
        Map,
        MapView,
        FeatureLayer,
        Legend,
        Expand,
        clusterLabelCreator,
        clusterPopupCreator,
        GraphicsLayer,
        promiseUtils,
        Search,
        Home,
        Track,
        ScaleBar,
        esriConfig,
        BasemapToggle,
        Basemap,
        TileLayer,
        VectorTileLayer,
        urlUtils,
        Locator,
      ]) => {
        esriConfig.request.interceptors.push({
          urls: /FeatureServer\/\d+$/,
          after: function (response) {
            response.data.supportedQueryFormats = 'JSON';
          },
        });

        if (this.props.config.proxyServer.length > 0) {
          for (
            let index = 0;
            index < this.props.config.proxyServer.length;
            index++
          ) {
            const { proxyUrl, urlPrefix } =
              this.props.config.proxyServer[index];

            urlUtils.addProxyRule({
              proxyUrl,
              urlPrefix,
            });
          }
        }

        const selectedWorkGLayer = new GraphicsLayer({
          id: 'selectedWorkGLayer',
          title: 'Selected Work',
          listMode: 'hide',
        });
        const assetHighlighterGLayer = new GraphicsLayer({
          id: 'assetHighlighterGLayer',
          listMode: 'hide',
        });
        const workCreateLocationGL = new GraphicsLayer({
          id: 'workCreateLocationGL',
          title: 'Created Work Location',
          listMode: 'hide',
        });
        const workLogLocationGL = new GraphicsLayer({
          id: 'workLogLocationGL',
          title: 'Log Work Location',
          listMode: 'hide',
        });
        const waterisolationTraceGL = new GraphicsLayer({
          id: 'waterisolationTraceGL',
          title: 'Water Isolation Trace',
          listMode: 'hide',
        });

        const updateWorkOrderAction = {
          title: 'Work Details',
          id: 'workorder-details-action',
          className: 'esri-icon-description',
        };

        const workOrdersFlayer = new FeatureLayer({
          id: 'workOrders',
          url: `${process.env.REACT_APP_WOC_BASE_SERVICE}FeatureServer/0`,
          title: 'Work orders',
          outFields: ['*'],
          popupTemplate: {
            title: '{Status}' + ' - ' + '{WOID}' + ' - ' + '{AssetName}',
            content: [
              {
                type: 'fields',
                fieldInfos: [
                  {
                    fieldName: 'DepartmentName',
                  },
                  {
                    fieldName: 'WorkType',
                  },
                  {
                    fieldName: 'AssignedToID',
                    label: 'Work Assigned To',
                  },
                  {
                    fieldName: 'LocationAddress',
                    label: 'Address',
                  },
                  {
                    fieldName: 'DateInitiated',
                    label: 'Date Initiated',
                  },
                  {
                    fieldName: 'DateCompleted',
                    label: 'Date Completed',
                  },
                ],
              },
            ],
            actions: [updateWorkOrderAction],
          },
        });

   
//        const lightBasemap = Basemap.fromId('streets-navigation-vector');  // DMM 12/13/2022
//        const nightBasemap = Basemap.fromId('streets-night-vector');  // DMM 12/13/2022
        const lightBasemap = Basemap.fromId(`${this.props.config.ESRImaps.id_base}`);  // DMM 12/13/2022
        const nightBasemap = Basemap.fromId(`${this.props.config.ESRImaps.id_alternate}`);  // DMM 12/13/2022


/*        const lightBasemap = new Basemap({
          baseLayers: [
            new VectorTileLayer({
              portalItem: {
                id: 'd216e606ad8843dda1ad8efd1dc2035c', // City streets basemap
              },
            }),
          ],
          title: 'Latest Basemap',
          id: 'mansfield-base-street',
        });
*/

/* WORKED WITH KG'S TEST BASE MAP
        const lightBasemap = new Basemap({
          baseLayers: [
            new TileLayer({
              url:'https://services1.arcgis.com/MEvzxYGCHO108ZxL/arcgis/rest/services/Mansfield_Basemap2_Tiles/MapServer',
              copyright: 'City of Mansfield, Ohio',
            }),
          ],
          title: 'Aerial Basemap',
          id: 'mansfield-base-imagery',
        });
*/

        /*
        const nightBasemap = new Basemap({
          baseLayers: [
            new TileLayer({
              url:'https://gis.mansfieldcity.com/arcgis/rest/services/Imagery/Imagery2019_NAD_83_092319_1230/MapServer',
              copyright: 'City of Mansfield, Ohio',
            }),
          ],
          title: 'Aerial Basemap',
          id: 'mansfield-base-imagery',
        });
*/

        const map = new Map({
          basemap: this.state.userApplicationSettings
            ? this.state.userApplicationSettings.themeName ===
              'LEFT_SIDEBAR_THEME_LIGHT'
              ? lightBasemap
              : nightBasemap
            : nightBasemap, //'streets-night-vector', //'topo-vector',
          layers: [
            waterisolationTraceGL,
            assetHighlighterGLayer,
            workOrdersFlayer,
            selectedWorkGLayer,
            workCreateLocationGL,
            workLogLocationGL,
          ],
        });
        // add utility Layer
        const { data } = await CrudOperations.Get('Departments');
        workorderService.SetApplicationDepartments(data);

        const woc__assets = await workorderService.__addGISAssetsToMap(
          map,
          this.state.User
        );

        //console.log(woc__assets);

        // show the map at the element
        const { center, zoom } = this.props.config.mapConfig;
        const view = new MapView({
          map,
          /* constraints: {
          lods: TileInfo.create().lods,
        }, */
          highlightOptions: {
            color: [255, 255, 0, 1],
            haloOpacity: 0.9,
            fillOpacity: 0.2,
          },
          background: { color: '#f0f0e9' },
          container: 'mapId',
          center,
          zoom,
        });

/*        view.constraints = {
          maxZoom: 18,
        };    DMM 5/26/2022 */

        view.popup = {
          dockEnabled: true,
          dockOptions: {
            buttonEnabled: true,
            breakpoint: true,
          },
        };

        workorderService.SetMapView(view);
        WorkOrderFilter.SetMapView(view);

        setTimeout(() => {
          this.setState({ mapview: view, WOCAssets: woc__assets });
        }, 50);

        view.popup.watch('selectedFeature', (feature) => {
          if (feature) {
            var featureTemplate = feature.getEffectivePopupTemplate();
            if (featureTemplate.actions) {
              if (featureTemplate.title === 'Search result') {
                view.popup.close();
                return;
              }
              if (featureTemplate.actions.items.length > 0) {
                const isAppInEditMode = workorderService.GetAppEditMode();
                const isAppInDetailMode = workorderService.GetAppDetailMode();
                const gisAssetsAddUI = workorderService.GetAppInAddingAssets();

                featureTemplate.actions.items.map((action) => {
                  switch (action.id) {
                    case 'workorder-details-action':
                      action.visible = !(isAppInDetailMode || isAppInEditMode);
                      break;
//                    case 'log-work-action':
                    case 'create-work-action':
                    case 'work-history-action':
                      action.visible = !isAppInEditMode;
                      break;
                    case 'add-work-asset-action':
                      action.visible = gisAssetsAddUI ? true : false;
                      break;
                    case 'water-isolation-action':
                      if (this.IsIsolationTraceEnabled()) {
                        action.visible =
                          featureTemplate.title === 'Water Main' ? true : false;
                      } else {
                        action.visible = false;
                      }
                      break;
                    case 'sewer-cctv-action':
                      if (this.IsCCTVEnabled()) {
                        action.visible =
                          featureTemplate.title === 'Sanitary Main'
                            ? true
                            : false;
                      } else {
                        action.visible = false;
                      }
                      break;
                  }
                });
              }
            }
          }
        });

        view.popup.on('trigger-action', (evt) => {
          // show right panel if collapsed
          const _rightpanel = document.querySelector('.right');
          if (_rightpanel.classList.contains('hide-rightside-panel'))
            _rightpanel.classList.toggle('hide-rightside-panel');

          const { selectedFeature } = view.popup;
          var mySensitiveField = this.__getCaseSensitiveAttributeName(selectedFeature.attributes, "facilityid");

          if (evt.action.id === 'workorder-details-action') {
//            const selectedWork = view.popup.features[0];
            const selectedWork = view.popup.selectedFeature;
            const _work_details_option =
              workorderService.GetUserRole() === 'field'
                ? 'WorkOrderDetails'
                : 'WorkOrderOfficeDetails';

            this.props.renderComponent(_work_details_option, selectedWork);
            view.popup.close();
          }


          if (evt.action.id === 'log-work-action') {     // some of this code for add too
          const layer_url = selectedFeature.sourceLayer.url;
          const __source__layer = layer_url.split('MapServer')[0];
          let _gisServicesConfigurationID = 0;
          let _gisServicesDepartmentID = 0;
          const __appLayers = workorderService.GetApplicationLayers();

          const __service = __appLayers.find(
            (s) => s.serviceLink === `${__source__layer}MapServer`
          );
          _gisServicesConfigurationID = parseInt(
            __service.gisServicesConfigurationID
          );
          _gisServicesDepartmentID = parseInt(
            __service.departmentID
          );

          let __TitleAndServiceName = view.popup.title.split('|');
          const __gisAsset = {
            gisAssetID: 0,
            assetName: __TitleAndServiceName[0].trim(),
            facilityID: selectedFeature.attributes[mySensitiveField],
            gisServicesConfigurationID: _gisServicesConfigurationID,  // DMM 12/6/2022
            sourceLayer: __TitleAndServiceName[1].trim(),
            departmentID: _gisServicesDepartmentID,
          };

          this.props.renderComponent('LogWork', {
            feature: selectedFeature,
            gisAsset: __gisAsset,
            department: this.__GetDepartmentByID(__gisAsset.departmentID), //__gisAsset.sourceLayer, //  DMM 10/12/2022
            view: view,
          });
        }


          if (evt.action.id === 'create-work-action') {     /* some of this code for add too */
            const layer_url = selectedFeature.sourceLayer.url;
            const __source__layer = layer_url.split('MapServer')[0];
            let _gisServicesConfigurationID = 0;
            let _gisServicesDepartmentID = 0;
            const __appLayers = workorderService.GetApplicationLayers();

            const __service = __appLayers.find(
              (s) => s.serviceLink === `${__source__layer}MapServer`
            );
            _gisServicesConfigurationID = parseInt(
              __service.gisServicesConfigurationID
            );
            _gisServicesDepartmentID = parseInt(
              __service.departmentID
            );

            let __TitleAndServiceName = view.popup.title.split('|');
            const __gisAsset = {
              gisAssetID: 0,
              assetName: __TitleAndServiceName[0].trim(),
//              facilityID: selectedFeature.attributes.FACILITYID,
              facilityID: selectedFeature.attributes[mySensitiveField],
              gisServicesConfigurationID: _gisServicesConfigurationID,  // DMM 12/6/2022
              sourceLayer: __TitleAndServiceName[1].trim(),
              departmentID: _gisServicesDepartmentID,
            };

            this.props.renderComponent('CreateWorkOrder', {
              feature: selectedFeature,
              gisAsset: __gisAsset,
              department: this.__GetDepartmentByID(__gisAsset.departmentID), //__gisAsset.sourceLayer, //  DMM 10/12/2022
              view: view,
            });
            //view.popup.close();
          }

          // work history
          if (evt.action.id === 'work-history-action') {
            // check if any history
            workorderService
              .GetWorkOrderHistory(selectedFeature.attributes[mySensitiveField])
              .then((res) => {
                if (res.data.length > 0) {
                  this.props.renderComponent('WorkOrderHistory', {
                    workhistory: res.data,
                  });
                  view.popup.close();
                } else {
                  swal(
                    'There is currently no record of work done on this feature'
                  );
                }
              });
          }

          // isolation trace
          if (evt.action.id === 'water-isolation-action') {
            this.props.renderComponent('ISOLATIONTRACE', {
              feature: selectedFeature,
              view: view,
            });
            /* swal({
              text: `Are you sure to perform water isolation trace?`,
              closeOnClickOutside: false,
              buttons: { cancel: 'NO', confirm: 'YES' },
              dangerMode: true,
            }).then((action) => {
              if (action) {
                this.props.renderComponent('ISOLATIONTRACE', {
                  feature: selectedFeature,
                  view: view,
                });
                //view.popup.close();
                console.log('Ok performing water isolation trace...');
              }
            }); */
          }

          // CCTV
          if (evt.action.id === 'sewer-cctv-action') {
            this.props.renderComponent('CCTV', {
              facilityID: selectedFeature.attributes[mySensitiveField],
            });
            view.popup.close();
          }
        });

        view.whenLayerView(workOrdersFlayer).then((layerView) => {
          workorderService.SetWorkOrderLayerView(layerView);
          WorkOrderFilter.SetWorkOrderLayerView(layerView);
          setTimeout(async () => {
            const userType = workorderService.GetUserRole();
            const loggedUser = getLoggedInUser();
            const { data } = await CrudOperations.GetByID(
              'GisConfiguration/GetGisServicesByUserID',
              loggedUser.id
            );
            /* WorkOrderFilter.FilterCompletedWork(
              this.state.User.department,
              this.state.selectedAsset,
              userType,
              loggedUser.id
            ); */
            WorkOrderFilter.FilterActiveWorkByUser(
              this.state.User.department,
              this.state.selectedAsset,
              userType,
              loggedUser.id,
              data
            );

            const btn__cctv = document.getElementById('btnCCTV');
            if (btn__cctv) {
              btn__cctv.addEventListener('click', () => {
                this.props.renderComponent('CCTV', {
                  facilityID: '',
                });
              });
            }
            const btn__waterIso = document.getElementById('btnIsolationTrace');
            if (btn__waterIso) {
              btn__waterIso.addEventListener('click', () => {
                this.props.renderComponent('ISOLATIONTRACE', {
                  view: view,
                });
              });
            }
          }, 100);
        });

        //
        // define the filter
        // only display features that belong to user department
        // by default don't show completed work in while in the field

        // basemap toggler
        const basemapToggle = new BasemapToggle({
          view: view, // The view that provides access to the map's "streets-vector" basemap
//          nextBasemap: 'hybrid',
          nextBasemap: `${this.props.config.ESRImaps.id_aerial}`,
        });

        // legend
        const legend = new Legend({
          view: view,
          container: 'legendDiv',
        });

        const sources = [   //ADDED DMM 12/13/2022
          {
//            locator: new Locator({ url: "https://gis.mansfieldcity.com/arcgis/rest/services/Miscellaneous/CityAddressLocator/GeocodeServer" }),
//            locator: new Locator({ url: this.props.config.ClientGeocoder }),
            url: this.props.config.ClientGeocoder,
            singleLineFieldName: 'SingleLine',
            name: 'City Geocoding Service',
            placeholder: 'Search Address',
            maxResults: 20,
            maxSuggestions: 20,
            suggestionsEnabled: true,
            minSuggestCharacters: 1,
          },
        ]

        const searchWidget = new Search({
          view: view,
          sources: sources, // ADDED DMM 12/13/2022
          includeDefaultSources: false,  // ADDED DMM 12/13/2022
        });
        view.ui.add(searchWidget, {
          position: 'top-right',
          //index: 2
        });

        const infoDiv = document.getElementById('infoDiv');

        const homeBtn = new Home({
          view: view,
        });
        const scaleBar = new ScaleBar({
          view: view,
        });
        const track = new Track({
          view: view,
        });

        view.ui.add(
          [
            basemapToggle,
            new Expand({
              view: view,
              content: infoDiv,
              expandIconClass: 'esri-icon-layer-list',
              expanded: false,
            }),
            scaleBar,
          ],
          'bottom-left'
        );

        view.ui.add([homeBtn, track], 'bottom-right');
        view.ui.move(['zoom'], 'bottom-right');

        //view.ui.add([track], 'bottom-right');

        view.ui.add(['utilityTogglerDiv'], 'top-left'); //map, this.state.User

/*        setTimeout(() => {
          if (this.state.mapview !== null) {
            view.ui.add(['street-view-btn'], 'bottom-right');
          }
        }, 50);  */
      }
    );
  };

  togglerMapFilterModal = () => {
    this.toggle();
  };

  __getCaseSensitiveAttributeName = (fieldAliases, fieldName) => {
    for (var key in fieldAliases) {  
      if (key.toUpperCase() === fieldName.toUpperCase()){
        return key;
        }
    }
    return "";
  };

  render() {
    return (
      <Fragment>
        <div id="mapId"></div>
{/*        <div id="street-view-btn">  // REMOVED PEGMAN - ACTING IRRACTICALLY
          {this.state.mapview && <StreetView view={this.state.mapview} />}
    </div> */}
        <div id="infoDiv" className="esri-widget">
          {/* <div style={{ paddingTop: '5px' }}>
                        <button id="toggle-cluster" className="esri-button">
                            Disable Clustering
                        </button>
                    </div> */}
          <div id="legendDiv"></div>
        </div>
        <div id="utilityTogglerDiv">
          {this.state.WOCAssets.length > 0 && (
            <UtilityToggler
              mapview={this.state.mapview}
              user={this.state.User}
              userType={this.state.userType}
              WOCAssets={this.state.WOCAssets}
            />
          )}
        </div>
        {/* Sign up Modal */}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Filter Work orders</ModalHeader>
          <ModalBody>
            <p>Map Action Filter Here</p>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    config: state.AppConfig.config,
  };
};
export default connect(mapStateToProps)(WorkOrdersMapAction);
