import React from 'react';
import ReactPlayer from 'react-player';

const CCTVItem = ({ video }) => {
    return (
        <>
            <ReactPlayer 
                url={video.url} 
                width='100%'
                height='200px'
                />
                <hr />
        </>
    )
}

export default CCTVItem
