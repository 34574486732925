import { loadModules } from 'esri-loader';
import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import notFoundImg from '../../assets/images/file-searching.svg';
import { getLoggedInUser } from '../../helpers/authUtils';
import { UserAPI } from '../../services/users.services';
import { workorderService } from '../../services/workorder.service';
import Spinner from '../Spinner';
import '../workorders/BackOffice/print-page.css';
import PrintWorkOrderInBatch from '../workorders/BackOffice/PrintWorkOrderInBatch';
import MunicipalityContactInfo from './MunicipalityContactInfo';

class PrintBatchWorkOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      mapview: null,
      User: null,
      workorders: [],
      layerView: null,
    };
  }

  componentDidMount() {
    const __batch = workorderService.GetWorkOrdersBatchList();
    const layerView = workorderService.GetWorkOrderLayerView();
    if (__batch.length > 0) {
      const loggedUser = getLoggedInUser();
      UserAPI.GetUser(loggedUser.id).then((user) => {
        this.setState({
          User: user.data,
          workorders: __batch,
          isLoading: false,
        });
        setTimeout(() => {
          this.initWorkOrderPrint();
          const rightMenu = document.querySelector('.left-side-menu');
          const btnRightMenuToggler =
            document.getElementById('btnLeftPanelToggle');
          if (rightMenu.classList.contains('hide-leftside-menu')) {
            btnRightMenuToggler.click();
            //rightMenu.classList.remove('hide-leftside-menu');
          }
        }, 200);
      });
    } else {
      this.setState({
        workorders: [],
        isLoading: false,
      });
    }
  }

  initWorkOrderPrint = () => {
    setTimeout(() => {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('hide-footer');
      }
      this.initMapBatchPrintView();
    }, 300);
  };

  componentWillUnmount() {
    const footer = document.getElementById('footer');
    footer.classList.remove('hide-footer');
    this.setState = (state, callback) => {
      return;
    };
  }

  initMapBatchPrintView = () => {
    loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/layers/GraphicsLayer',
      'esri/Graphic',
    ]).then(([Map, MapView, GraphicsLayer, Graphic]) => {
      const selectedWorkGLayer = new GraphicsLayer({
        id: 'selectedWorkGLayer',
        title: 'Selected Work',
        listMode: 'hide',
      });
      const selectedWorkTextGLayer = new GraphicsLayer({
        id: 'selectedWorkTextGLayer',
        title: 'Text ID',
        listMode: 'hide',
      });
      const map = new Map({
        basemap: `${this.props.config.ESRImaps.id_base}`, //'topo-vector' 'streets-night-vector' changed 12/13/2022 DMM
        layers: [selectedWorkGLayer, selectedWorkTextGLayer],
      });
      const { center, zoom } = this.props.config.mapConfig;
      const view = new MapView({
        container: 'map-print-view',
        map: map,
        zoom,
        center, // longitude, latitude
      });

      view.when(() => {
        // add selected work
        let marker = {
          type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
          url: `${this.props.config.ESRImaps.printmarker}`, //'https://static.arcgis.com/images/Symbols/Shapes/BlackStarLargeB.png', //'https://demogis.emht.com/assets/images/markers/BlackStarLargeB.png'
          width: '44px',
          height: '44px',
        };

        this.state.workorders.map((f) => {
          selectedWorkGLayer.add(new Graphic(f.geometry, marker, f.attributes));

          var textSym = {
            type: 'text',
            text: f.attributes.WOID,
            font: { size: 13, weight: 'bold' },
            yoffset: 10,
            color: [61, 4, 149, 1],
          };
          selectedWorkTextGLayer.add(new Graphic(f.geometry, textSym));
        });
        setTimeout(() => {
          view.goTo(this.state.workorders).then(() => {
            view.zoom -= 1;  //DMM 12/6/2022 - batch print overall work order map - zoom to them, then out.
          });
          this.setState({
            isLoading: false,
          });
        }, 50);
      });
    });
  };

  __getHumanDate = (_d) => {
    return new Date(_d).toLocaleDateString();
  };

  __getWorkStatus = () => {
    const { attributes } = this.state.WorkOrderFeature;
    if (attributes) {
      // eslint-disable-next-line default-case
      switch (attributes.Status.toLowerCase()) {
        case 'yellow':
          return 'Assigned';
        case 'red':
          return 'Unassigned';
        case 'green':
          return 'Completed';
        case 'grey':
          return 'Associated to mine';
      }
    }
  };

  renderStatus(status) {
    return workorderService.renderStatus(status);
  }

  rendertable = () => {
    const columns = [
      {
        dataField: 'attributes.WOID',
        text: 'ID',
      },
      {
        dataField: 'attributes.WorkType',
        text: 'Work Type',
        sort: true,
      },
      {
        dataField: 'attributes.DepartmentName',
        text: 'Department',
        sort: true,
      },
      {
        dataField: 'attributes.LocationAddress',
        text: 'Address',
        sort: true,
      },
      {
        dataField: 'attributes.OriginatorID',
        text: 'Originator',
        sort: true,
      },
      {
        dataField: 'attributes.AssignedToID',
        text: 'Assigned To',
        sort: true,
      },
      {
        dataField: 'attributes.PlannedDate',
        text: 'Due Date',
        formatter: (cell, row) => {
          return workorderService.GetDueDateFormatted(
            cell,
            row.attributes.Status
          );
        },
        sort: true,
      },
      {
        dataField: 'attributes.Status',
        text: 'Status',
        formatter: (cell, row, index) => {
          return (
            <>
              <small style={{ textAlign: 'center', color: '#040202' }}>
                {this.renderStatus(cell)}
              </small>
            </>
          );
        },
      },
      {
        dataField: 'attributes.DateCompleted',
        text: 'Date Completed',
        formatter: (cell) => {
          return workorderService.__getHumanDateAndTime(cell);
        },
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: 'id',
        order: 'asc',
      },
    ];

    return (
      <Card>
        <CardBody>
          <h4 className="header-title">Batch Work Orders List</h4>
          <ToolkitProvider
            bootstrap4
            keyField="attributes.WOID"
            data={this.state.workorders}
            columns={columns}
          >
            {(props) => (
              <React.Fragment>
                <BootstrapTable
                  {...props.baseProps}
                  bordered={true}
                  defaultSorted={defaultSorted}
                  keyField="attributes.WOID"
                  paging={paginationFactory({ sizePerPage: 100 })}
                  wrapperClasses="table-responsive"
                />
              </React.Fragment>
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
    );
  };

  renderBatchDetails = () => {
    return (
      <Fragment>
        {this.state.workorders.map((w, i) => {
          const { attributes, geometry } = w;
          return (
            <div key={i}>
              <PrintWorkOrderInBatch
                User={this.state.User}
                GlobalID={attributes.GlobalID}
                feature={w}
              />
              <div className="page-break-clear"></div>
              <div className="page-break">&nbsp;</div>
            </div>
          );
        })}
      </Fragment>
    );
  };

  __renderPrinting = () => {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      switch (this.state.workorders.length) {
        case 0:
          return (
            <Fragment>
              <div className="text-center">
                <img src={notFoundImg} height="90" alt="" className="mt-4" />
                <h4 className="text-uppercase text-danger mt-3">
                  No Work Order found
                </h4>
                <p className="text-muted mt-3">
                  You haven't selected any work orders batch.
                </p>
              </div>
            </Fragment>
          );
        default:
          return (
            <Fragment>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div className="clearfix">
                        <div className="float-left mb-3">
                          <MunicipalityContactInfo
                            municipalityInfo={
                              this.props.config.municipalityInfo
                            }
                          />
                        </div>
                        <div className="float-right">
                          <h4 className="m-0 d-print-none">
                            Work Orders Batch Print
                          </h4>
                        </div>
                      </div>

                      <div
                        id="map-print-view"
                        style={{
                          width: '100%',
                          height: '400px',
                          position: 'relative',
                          backgroundColor: '#ddd',
                        }}
                      ></div>

                      <div className="d-print-none mt-4">
                        <div className="text-right">
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              window.print();
                            }}
                          >
                            <i className="mdi mdi-printer"></i> Print
                          </button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {this.rendertable()}
              <div className="page-break-clear"></div>
              <div className="page-break">&nbsp;</div>
              {this.renderBatchDetails()}
            </Fragment>
          );
      }
    }
  };

  render() {
    return (
      <Fragment>
        {/* <PageTitle
          breadCrumbItems={[
            { label: 'Map', path: '/apps/workorders' },
            { label: 'Print Batch Work Orders', active: true },
          ]}
          title={'Print Batch Work Orders'}
        /> */}
        {this.__renderPrinting()}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    config: state.AppConfig.config,
  };
};
export default connect(mapStateToProps)(PrintBatchWorkOrders);
