import Axios from 'axios';
/**
 * Make a request for the App Config [public/config] with makeRequest()
 * @return { Promise}        Promise returned by makeRequest()
 */
export function getAppConfig() {
  return new Promise((resolve, reject) => {
    Axios.get('/config.json').then((resp) => resolve(resp.data));
  });
}
