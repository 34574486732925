import React, { useState, useEffect } from 'react';
import {
    Label
} from 'reactstrap';

const DepartmentMembers = ({members, GISAssets}) => {
    //console.log(members);
    const [userAndAsset, setUserAndAsset] = useState([]);

    const fetchUser = () => {

      if(members.length > 0) {
        let __membersAndAssets = [];
        for (let index = 0; index < members.length; index++) {
            const user = members[index];
            let __membersAssets = [];
            GISAssets.map((asset) => {
                if(asset.gisServicesUsersConfigurations.length > 0) {
                    const userServices = asset.gisServicesUsersConfigurations.find((s) => s.userID === user.id);
                    if(userServices) {
                        __membersAssets.push(asset);
                    }
                }
            })
            __membersAndAssets.push({user, assets: __membersAssets});
        }
        
        setTimeout(() => {
            setUserAndAsset(__membersAndAssets);
        }, 200);
      }
    }

    useEffect(() => {
        fetchUser();
    }, [])

    return (
        <>
{/*                <Label for="members">Members</Label>    COMMENTED OUT DMM 9/18/2023*
                    <div className="text-wrap" id="members">  */}
            <Label id="members">Members</Label>
            <div className="text-wrap" role="listbox" aria-labelledby="members">
                <ul className="list-inline font-15 mb-3">
                    {
                        userAndAsset.length > 0 && (
                            <>
                                {userAndAsset.map((u, idx) => (
                                    <li key={idx} className="list-inline-item ml-1 mb-1">
                                        <div>
                                            <span className="badge badge-success-lighten p-1">
                                                {u.user.firstName} {u.user.lastName}
                                            </span>
                                            {
                                                u.assets.length > 0 && (
                                                    <>
                                                        <ul>
                                                        {
                                                            u.assets.map((asset, index) => (
                                                                <li key={index}>
                                                                    {asset.name}
                                                                </li>
                                                            ))
                                                        }
                                                        </ul>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </li>
                                ))}
                            </>
                        )
                    }
                    
                </ul>
            </div>
        </>
    )
}

export default DepartmentMembers;
