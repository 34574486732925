import {
  AvField, AvForm
} from 'availity-reactstrap-validation';
import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  CSVExport, Search
} from 'react-bootstrap-table2-toolkit';
import {
  Button, Card,
  CardBody, Col, Row
} from 'reactstrap';
import PageTitle from '../../components/PageTitle';
import { workorderService } from '../../services/workorder.service';

const AssetWorkHistory = () => {
    const [workHistory, setWorkHistory] = useState([]);
    const [isFetchingHistoryData, setIsFetchingHistoryData] = useState(false);
    const [isSearchComplete, setIsSearchComplete] = useState(false);

    const __findWorkHistory = async(event, values) => {
        // call the back end api for work history by facilityID
        setIsSearchComplete(false);
        setIsFetchingHistoryData(true);
        const { facilityID } = values;
        const { data } = await workorderService.GetWorkOrderHistory(facilityID);
        setWorkHistory(data);
        setIsFetchingHistoryData(false);
        setIsSearchComplete(true);
    }

    const __getGlobalID = (woid, row) => {
        return (
          <>
            <a href={`/apps/workorders/?id=${row.workOrder.globalID}`}>
              {woid}
            </a>
          </>
        );
      };

    const columns = [
        {
          dataField: 'workOrder.workOrderID',
          text: 'WOID',
          formatter: (cell, row) => {
            return __getGlobalID(cell, row);
          },
        },
        {
          dataField: 'assetName',
          text: 'Asset',
          sort: true,
        },
        {
          dataField: 'facilityID',
          text: 'Facility ID',
          sort: true,
        },
        {
          dataField: 'workOrder.workOrderType',
          text: 'Work Type',
          sort: true,
        },
        {
          dataField: 'workOrder.workCreatedBy',
          text: 'Created By',
          sort: true,
        },
        {
          dataField: 'workOrder.dateCreated',
          text: 'Created Date',
          formatter: (cell) => {
            return workorderService.__getHumanDateAndTime(cell);
          },
          sort: true,
        },
        {
          dataField: 'workOrder.workAssignedTo',
          text: 'Assigned To',
          sort: true,
        },
        {
          dataField: 'workOrder.status',
          text: 'Status',
          formatter: (cell, row, index) => {
            return (
              <>
                <small style={{ textAlign: 'center', color: '#040202' }}>
                  {workorderService.renderStatus(cell)}
                </small>
              </>
            );
          },
          sort: true,
        }
      ];

      const defaultSorted = [
        {
          dataField: 'id',
          order: 'asc',
        },
      ];
  
      const { SearchBar } = Search;
      const { ExportCSVButton } = CSVExport;

    return (
        <>
            <div>
                <PageTitle
                    breadCrumbItems={[
                    { label: 'Map', path: '/apps/workorders' },
                    { label: 'Assets History', active: true },
                    ]}
                    title={'Assets Work History'}
                />
            </div>

            {/** Input field for requesting FacilityID */}
            <AvForm className="p-2" onValidSubmit={__findWorkHistory}>
                <AvField name="facilityID" label="Enter Facility ID" type="text" required />
 
                <Button color="primary"
                  disabled={isFetchingHistoryData} 
                  type="submit"
                  >
                    {isFetchingHistoryData && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        ></span> Finding...
                      </>
                    )}
                    {!isFetchingHistoryData && (
                      <>Find</>
                    )}
                 
                </Button>
            </AvForm>

            {
                workHistory.length > 0 && (
                    <>
                        <Card>
                            <CardBody>
                            <h4 className="header-title">Search or Export</h4>
                            <p className="text-muted font-14 mb-4">
                                Search or export work order history data in a CSV format
                            </p>

                            <ToolkitProvider
                                bootstrap4
                                keyField="workOrder.workOrderID"
                                data={workHistory}
                                columns={columns}
                                search
                                exportCSV={{ onlyExportFiltered: false, exportAll: true }}
                            >
                                {(props) => (
                                <React.Fragment>
                                    <Row>
                                    <Col>
                                        <SearchBar {...props.searchProps} />
                                    </Col>
                                    <Col className="text-right">
                                        <ExportCSVButton
                                        {...props.csvProps}
                                        className="btn btn-primary"
                                        >
                                        Export CSV
                                        </ExportCSVButton>
                                    </Col>
                                    </Row>

                                    <BootstrapTable
                                    {...props.baseProps}
                                    bordered={false}
                                    defaultSorted={defaultSorted}
                                    keyField="workOrder.workOrderID"
                                    paging={false} // {paginationFactory({ sizePerPage: 10 })}
                                    wrapperClasses="table-responsive"
                                    />
                                </React.Fragment>
                                )}
                            </ToolkitProvider>
                            </CardBody>
                        </Card>
                    </>
                )
            }

            {isSearchComplete && workHistory.length < 1 && (
              <div className="text-center">
                <p className="text-danger mt-3">
                  There are no work history data available for that asset at this time.
                </p>
              </div>
            )}

        </>
    )
}

export default AssetWorkHistory;
