import React from 'react';
import { Col, Row } from 'reactstrap';

const MunicipalityContactInfo = ({ municipalityInfo }) => {
    const { address, city, state, zipCode, phone, logoUrl } = municipalityInfo;
    return (
        <>
            <Row>
                <Col sm={4}>
                <img
                    src={logoUrl}
                    alt="logo"
                    height="100"
                    className="mb-1"
                />
                </Col>
            </Row>
            <Row>
                <Col>
                <address>
                    {address}, {city}, {state} {zipCode}
                    <br />
                    <abbr title="Phone">P:</abbr> {phone}
                </address>
                </Col>
            </Row>
        </>
    )
}

export default MunicipalityContactInfo
