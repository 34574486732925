import React, { useEffect, useState } from 'react';
import {
    FormGroup, Input, Label
} from 'reactstrap';
import { workorderService } from '../../../services/workorder.service';

const TopLayerSelector = ({ 
    layers, 
    onSelectionChangeSave, 
    isDisabled, 
    selectedOption,
    userSettings,
    userID
}) => {

const [selectedLayerID, setSelectedLayerID] = useState();

const __setSelectionChange = (val) => {
    setSelectedLayerID(val);
    onSelectionChangeSave(val);
}

const __saveMyTopLayerChoice = async() => {
    //console.log(selectedLayerID);
    let __settingsObj;
    if(userSettings === null) {
        __settingsObj = {
            userID,
            themeName: "LEFT_SIDEBAR_THEME_DARK",
            notificationType: "Email",
            mostTopLayerID: parseInt(selectedLayerID)
          };
    }
    else {
        __settingsObj = userSettings;
        __settingsObj.mostTopLayerID = parseInt(selectedLayerID)
    }

    await workorderService.SaveUserSettings(__settingsObj);
}

useEffect(() => { 
    //console.log(userSettings);
    if(userSettings === null) {
        setSelectedLayerID(selectedOption);
    } else {
        setSelectedLayerID(userSettings.mostTopLayerID);
    }
}, [])

    return (
        <div className="p-2 text-left">
            <FormGroup>
                <Label for="exampleSelect">Select top most layer</Label>
                <Input 
                    type="select" 
                    name="select" 
                    id="exampleSelect"
                    value={selectedLayerID}
                    onChange={(e) => __setSelectionChange(e.target.value)}
                    disabled={isDisabled}
                    >
                    {
                        layers && (
                            <>
                                {
                                    layers.map((l, idx) => (
                                        <option key={idx} value={l.gisServicesConfigurationID}>{l.name}</option>
                                    ))
                                }
                            </>
                        )
                    }
                </Input>
            </FormGroup>

            <div className="text-center">
              <button
                className="btn btn-primary mt-2"
                onClick={() => __saveMyTopLayerChoice()}
                disabled={isDisabled}
              >
                Save my choice
              </button>
            </div>
        </div>
    )
}

export default TopLayerSelector;
