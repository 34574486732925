import React, { Fragment } from 'react';
import notFoundImg from '../../assets/images/file-searching.svg';

const NoDataAvailable = () => {
  return (
    <Fragment>
      {/* <Row className="justify-content-center">
                <Col lg={4}>
                    
                </Col>
            </Row> */}
      <div className="text-center">
        <img src={notFoundImg} height="90" alt="" className="mt-4" />
        {/* <h3 className="text-error mt-4">404</h3> */}
        <h4 className="text-uppercase text-danger mt-3">No Data available</h4>
        <p className="text-muted mt-3">
          There is no data available to report on at this time.
        </p>

        {/* <Link className="btn btn-info mt-3" to="/">
                    <i className="mdi mdi-reply"></i> Return Home
                </Link> */}
      </div>
    </Fragment>
  );
};
export default NoDataAvailable;
