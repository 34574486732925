import { AvField, AvRadioGroup, AvRadio, AvForm } from 'availity-reactstrap-validation';
import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import swal from 'sweetalert';
import { CrudOperations } from '../../services/CRUDoperations.service';

class WoProblems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Department: '',
      problems: [],
      departments: [],
      isLoading: true,
      newModal: false,
      existingModal: false,
      name: '',
      description: '',
      problemID: 0,
      resolutionID: 0,
      departmentID: 0,
      GISAssets: this.props.GISAssets,
      assetsOptions: this.props.assetsOptions,
      selectedAsset: 0,
      selectedProblem: null,
      requireAsset: '',
      requireResource: '',
    };
    this.toggleNewModal = this.toggleNewModal.bind(this);
    this.toggleExistingModal = this.toggleExistingModal.bind(this);
  }

  toggleNewModal = () => {
    this.setState((prevState) => ({
      newModal: !prevState.newModal,
    }));
  };

  toggleExistingModal = () => {
    this.setState((prevState) => ({
      existingModal: !prevState.existingModal,
    }));
  };

  componentDidMount() {}

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  booleanFormatter = (cell, row, rowIndex, formatExtraData) => {
      return cell ? 'Yes' : 'No'
  }

  editsFormatter = (departmentID, problemObj) => {
    return (
      <div>
        <a href="#" onClick={() => this.EditProblem(problemObj)}>
          Edit
        </a>
        <a
          className="ml-3"
          href="#"
          onClick={() => this.DeleteProblem(problemObj)}
        >
          Delete
        </a>
      </div>
    );
  };

  EditProblem = (problemObj) => {
    // console.log(problemObj);
    const { problemID, name, description, requireAsset, requireResource, problemAsset } = problemObj;

    this.setState({
      problemID,
      name,
      description,
      requireAsset,
      requireResource,
      selectedAsset: problemAsset.getGISService.gisServicesConfigurationID,
      selectedProblem: problemObj,
      existingModal: true,
    });
  };

  handleEdit = (event, values) => {
    const { assetID, name, description, requireAsset, requireResource } = values;
    let _ProblemObj = {
      problemID: this.state.problemID,
      name,
      description,
      requireAsset,
      requireResource,
      problemAsset: this.state.selectedProblem.problemAsset,
    };
    CrudOperations.Update('ProblemResolutions/UpdateProblem', _ProblemObj)
      .then((res) => {
        this.props.__getProblemResolutions(this.props.departmentID);
        this.toggleExistingModal();
        swal(`Problem ${name} updated successfully.`);
      })
      .catch((err) => {
        swal('Unable to update problem at this time');
        console.log('Error updating Problem :: ', err);
      });
  };

  DeleteProblem = (problemObj) => {
    swal({
      text: `You are about to delete Problem [${problemObj.name}]. Please note if this Problem has been linked to a work order, it will not be deleted. Are you sure to delete? `,
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then((action) => {
      if (action) {
        CrudOperations.VerifyItemUsedForDeletion(
          problemObj.problemID,
          'problems'
        ).then((result) => {
          if (result.data) {
            swal(
              `Problem [${problemObj.name}] has been assigned to work order and cannot be deleted.`
            );
          } else {
            CrudOperations.Delete(
              `ProblemResolutions/DeleteProblem`,
              problemObj.problemID
            )
              .then((res) => {
                this.props.__getProblemResolutions(this.props.departmentID);
                swal(`Problem [${problemObj.name}] deleted successfully.`);
              })
              .catch((err) => {
                swal(
                  `Unable to delete Problem [${problemObj.name}]. Unexpected result.`
                );
                console.log('Error :: ', err);
              });
          }
        });
      }
    });
  };

  handleNew = (event, values) => {
    //console.log(values);
    const { assetID, name, description,requireAsset, requireResource } = values;
    let NewProblemObj = {
      name,
      description,
      requireAsset,
      requireResource,
      problemAsset: {
        gisServicesConfigurationID: assetID,
      },
    };
    CrudOperations.Create('ProblemResolutions/CreateProblem', NewProblemObj)
      .then((res) => {
        this.props.__getProblemResolutions(this.props.departmentID);
        this.toggleNewModal();
        swal('Problem created successfully!');
      })
      .catch((err) => {
        swal('Something went wrong!');
        console.log('Error :: ', err);
      });
  };

  __renderProblemsTable = () => {
    const TableWithSearch = () => {
      const { SearchBar } = Search;
      const { ExportCSVButton } = CSVExport;
      const defaultSorted = [
        {
          dataField: 'name',
          order: 'asc',
        },
      ];

      const columns = [
        {
          dataField: 'problemID',
          text: '',
          formatter: this.editsFormatter,
        },
        {
          dataField: 'name',
          text: 'Problem',
          sort: true,
        },
        {
          dataField: 'description',
          text: 'Description',
        },
        {
          dataField: 'problemAsset.getGISService.name',
          text: 'Asset',
          sort: true,
        },
        {
          dataField: 'requireAsset',
          text: 'Asset Required?',
        },
        {
          dataField: 'requireResource',
          text: 'Resource Required?',
        },
      ];

      return (
        <Card>
          <CardBody>
            <h3>
              <i className="dripicons-question"></i> Work Order Problems
            </h3>
            <Button
              color="danger"
              className="btn-rounded mb-3 mt-3"
              onClick={this.toggleNewModal}
            >
              <i className="mdi mdi-plus"></i> Create Problem
            </Button>
            <ToolkitProvider
              bootstrap4
              keyField="problemID"
              data={this.props.problems}
              columns={columns}
              search
              exportCSV={{ onlyExportFiltered: true, exportAll: false }}
            >
              {(props) => (
                <React.Fragment>
                  <Row>
                    <Col>
                      <SearchBar id="problemSearchBarID" {...props.searchProps} />
                    </Col>
                    <Col className="text-right">
                      <ExportCSVButton
                        {...props.csvProps}
                        className="btn btn-primary"
                      >
                        Export CSV
                      </ExportCSVButton>
                    </Col>
                  </Row>

                  <BootstrapTable
                    {...props.baseProps}
                    bordered={true}
                    defaultSorted={defaultSorted}
                    paging={false} // {paginationFactory({ sizePerPage: 10 })}
                    wrapperClasses="table-responsive table-sm"
                    striped={true}
                    hover={true}
                    keyField="problemID"
                  />
                </React.Fragment>
              )}
            </ToolkitProvider>
          </CardBody>
        </Card>
      );
    };

    return <TableWithSearch />;
  };

  render() {
    return (
      <Fragment>
        {this.__renderProblemsTable()}

        <Modal
          isOpen={this.state.newModal}
          toggle={this.toggleNewModal}
          /* size="lg" */
        >
          <ModalHeader toggle={this.toggleNewModal}>
            Create New Problem
          </ModalHeader>
          <ModalBody>
            {this.props.GISAssets.length < 1 && (
              <>
                <Alert color="danger">
                  <i className="dripicons-wrong mr-2"></i>{' '}
                  <strong>Missing GIS Assets - </strong>
                  Please consider adding GIS Assets first
                </Alert>
              </>
            )}
            <AvForm className="p-2" onValidSubmit={this.handleNew}>
              <AvField
                name="assetID"
                label="Asset"
                type="select"
                className="custom-select"
                required
              >
                <option value="0">Select</option>
                {this.props.GISAssets.map((a, idx) => (
                  <option key={idx} value={a.gisServicesConfigurationID}>
                    {a.name}
                  </option>
                ))}
              </AvField>
              <AvField name="name" label="Problem" type="text" required />
              <AvField
                name="description"
                label="Description"
                type="textarea"
                required
              />
              <AvRadioGroup inline label="Asset Required?" name="requireAsset" value="No">
                  <AvRadio label="Yes" value="Yes" id="cprequireasset_yes"/>
                  <AvRadio label="No" value="No" id="cpresrequireasset_no"/>
              </AvRadioGroup>

              <AvRadioGroup inline label="Resource Required?" name="requireResource" value="No">
                  <AvRadio label="Yes" value="Yes" id="cpresrequireresource_yes"/>
                  <AvRadio label="No" value="No" id="cpresrequireresource_no"/>
              </AvRadioGroup>

              <div className="text-right">
                <Button
                  color="light"
                  type="button"
                  className="mr-1"
                  onClick={this.toggleNewModal}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Create
                </Button>
              </div>
            </AvForm>
          </ModalBody>
        </Modal>

        {/* Modal Edit */}
        <Modal
          isOpen={this.state.existingModal}
          toggle={this.toggleExistingModal}
          /* size="lg" */
        >
          <ModalHeader toggle={this.toggleExistingModal}>
            Edit Problem
          </ModalHeader>
          <ModalBody>
            <AvForm className="p-2" onValidSubmit={this.handleEdit}>
              <AvField
                name="name"
                label="Problem"
                type="text"
                value={this.state.name}
                required
              />
              <AvField
                name="description"
                label="Description"
                type="textarea"
                value={this.state.description}
                required
              />
              <AvRadioGroup inline label="Asset Required?" name="requireAsset" value={this.state.requireAsset}>
                  <AvRadio label="Yes" value="Yes" id="eprequireasset_yes"/>
                  <AvRadio label="No" value="No" id="eprequireasset_no"/>
              </AvRadioGroup>

              <AvRadioGroup inline label="Resource Required?" name="requireResource" value={this.state.requireResource}>
                  <AvRadio label="Yes" value="Yes" id="eprequireresource_yes"/>
                  <AvRadio label="No" value="No" id="eprequireresource_no"/>
              </AvRadioGroup>

              <AvField
                name="assetID"
                label="Asset"
                type="select"
                className="custom-select"
                value={this.state.selectedAsset}
                disabled
              >
                <option value="0">Select</option>
                {this.state.GISAssets.map((a, idx) => (
                  <option key={idx} value={a.gisServicesConfigurationID}>
                    {a.name}
                  </option>
                ))}
              </AvField>
              <small
                className="form-text text-muted"
                style={{ marginTop: '-10px' }}
              >
                The Asset cannot be changed.
              </small>
              <br />

              <div className="text-right">
                <Button
                  color="light"
                  type="button"
                  className="mr-1"
                  onClick={this.toggleExistingModal}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default WoProblems;
