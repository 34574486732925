import React, { Component, Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import PageTitle from '../PageTitle';
import appMenu from './img/app-menu.png';
import appSettings from './img/app-settings.PNG';
import searchResult from './img/search-result.PNG';
import userProfile from './img/user-profile.PNG';
import utilityToggler from './img/utility-toggler.PNG';
import workListPanel from './img/work-list-panel.PNG';

class Documentation extends Component {
  componentDidMount() {
    setTimeout(() => {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('hide-footer');
      }
    }, 300);
  }
  componentWillUnmount() {
    const footer = document.getElementById('footer');
    footer.classList.remove('hide-footer');
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    return (
      <Fragment>
        <PageTitle
          breadCrumbItems={[
            { label: 'Map', path: '/apps/workorders' },
            { label: 'Documentation', active: true },
          ]}
          title={'Documentation'}
        />

        <Row>
          <Col lg={9}>
            <div className="bd-cheatsheet container-fluid bg-body">
              <section id="getting-started">
                <article className="my-3" id="typography">
                  <div className="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2">
                    <h3>Getting Started</h3>

                    <p className="display-5">Application user interface</p>

                    <Row>
                      <Col md={3}>
                        <img
                          src={appMenu}
                          className="img-fluid"
                          style={{ minHeight: '300px' }}
                        />
                      </Col>
                      <Col md={9}>
                        <ol>
                          <li>
                            <div>
                              <h4 className="font-15">Dashboard</h4>
                              <p>
                                A Dashboard presenting a handful statistical data
                                for work orders throughout the city.
                              </p>
                              <p>
                                It is important to notice that this option is
                                only available to Managers.
                              </p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <h4 className="font-15">Map</h4>
                              <p>
                                A map view of the work orders within the WOC.
                                Work orders will be displayed on the map, as
                                well as within a listing on the right side of
                                the webpage
                              </p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <h4 className="font-15">Listing</h4>
                              <p>
                                Listing or Work Orders Table is a table view of
                                the work orders. Work orders in table are
                                displayed as follow:
                              </p>
                              <ul>
                                <li>
                                  <b>For Managers: </b> the table shows all work
                                  orders for all departments, completed and
                                  active
                                </li>
                                <li>
                                  <b>For Supervisors: </b> the table shows
                                  completed and active work orders for their
                                  department
                                </li>
                                <li>
                                  <b>For Field User: </b> the table only shows
                                  assigned, unassigned and completed work orders
                                  within his department
                                </li>
                              </ul>
                              <p>
                                Work orders from the table view can be exported
                                out to a CSV file for viewing in Microsoft Excel
                              </p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <h4 className="font-15">Report</h4>
                              <p>
                                Provides reporting capabilities to the Work
                                Order Center such as yearly work cost.
                              </p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <h4 className="font-15">Documentation</h4>
                              <p>
                                Contains help documents on using the Work Order
                                Center
                              </p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <h4 className="font-15">System Configuration</h4>
                              <p>
                                Contains access to updating the information
                                contained within the Work Order Center such as
                                Work Types, Resources, Problems and Resolutions,
                                Departments & Users. Only users with specific
                                levels of access will be able to see this
                                information.
                              </p>
                            </div>
                          </li>
                        </ol>
                      </Col>
                    </Row>
                  </div>
                </article>
              </section>

              <section id="woc-search">
                <article className="my-3" id="typography">
                  <div className="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2">
                    <h3>WOC Search</h3>

                    <p>
                      Using the search bar at the top of the WOC page, users can
                      search for any key word, a term that may match a work
                      order description, an address or a work order ID. Please
                      note that the search will only be performed on items
                      related to the user’s assigned department, except for
                      Managers.
                    </p>
                    <img
                      src={searchResult}
                      className="img-fluid"
                      style={{ minWidth: '350px' }}
                    />
                  </div>
                </article>
              </section>
              <section id="map-layers">
                <article className="my-3" id="typography">
                  <div className="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2">
                    <h3>Map Layers</h3>

                    <p>
                      By default, the department that the logged in user is
                      assigned to will be listed at the top of the layers menu.
                      Layers that have been assigned to that department can be
                      easily turned on or off by clicking the button next to
                      this group.
                    </p>
                    <p>
                      By expanding View More users can see a listing of other
                      department layers such as Sewer, Water, Storm, Electric,
                      etc. and turn those on.
                    </p>
                    <img
                      src={utilityToggler}
                      className="img-fluid"
                      style={{ minHeight: '250px' }}
                    />
                  </div>
                </article>
              </section>
              <section id="app-settings" className="mt-4">
                <article className="my-3" id="typography">
                  <div className="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2">
                    <h3>Settings</h3>

                    <Row>
                      <Col md={9}>
                        <p>
                          <b>Settings</b> – allows users to customize the layout
                          theme based on their login. Options include a light
                          and dark setting. The dark setting will darken the
                          webpage panels as well as the map background. The
                          light setting will brighten the map webpage panels and
                          display a lighter map background. Choose the version
                          you prefer and click the Save theme button. These
                          settings will remain attached to your login
                          credentials.
                        </p>
                        <p>
                          Users can also update their notification setting
                          preferences when work is assigned to them.
                          Notification options include Email, Text Message or
                          Both. The notification settings can also be updated
                          under the user profile. Click on "My Account" under
                          User initials then click on "Edit Profile"
                        </p>

                        <img
                          src={userProfile}
                          className="img-fluid"
                          style={{ minHeight: '200px' }}
                        />
                      </Col>
                      <Col md={3}>
                        <img
                          src={appSettings}
                          className="img-fluid"
                          style={{ minHeight: '250px' }}
                        />
                      </Col>
                    </Row>
                  </div>
                </article>
              </section>

              <section className="mt-4">
                <article className="my-3" id="work-list-panel">
                  <div className="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2">
                    <h3>Active work list panel</h3>

                    <Row>
                      <Col md={9}>
                        <p>
                          <b>Active work list panel</b> – This panel only show
                          active work orders (assigned) and unassigned
                        </p>
                        <p>
                          Completed work can only be viewed on the map. Work
                          orders in the list will disapear as the user completes
                          them.
                        </p>
                      </Col>
                      <Col md={3}>
                        <img
                          src={workListPanel}
                          className="img-fluid"
                          style={{ minHeight: '250px' }}
                        />
                      </Col>
                    </Row>
                  </div>
                </article>
              </section>
            </div>
          </Col>
          <Col lg={3}>
            <aside
              className="bd-aside sticky-xl-top text-muted align-self-start mb-3 mb-xl-5 px-2"
              style={{ position: 'fixed', minWidth: '300px' }}
            >
              <h2 className="h6 pt-4 pb-3 mb-4 border-bottom">On this page</h2>
              <nav className="small" id="toc">
                <ul className="list-unstyled">
                  <li className="mb-1 font-15">
                    <a
                      className="d-inline-flex align-items-center rounded"
                      href="#getting-started"
                    >
                      Getting Started
                    </a>
                  </li>
                  <li className="mb-1 font-15">
                    <a
                      className="d-inline-flex align-items-center rounded"
                      href="#woc-search"
                    >
                      WOC Search
                    </a>
                  </li>
                  <li className="mb-1 font-15">
                    <a
                      className="d-inline-flex align-items-center rounded"
                      href="#map-layers"
                    >
                      Map Layers
                    </a>
                  </li>
                  <li className="mb-1 font-15">
                    <a
                      className="d-inline-flex align-items-center rounded"
                      href="#app-settings"
                    >
                      Application settings
                    </a>
                  </li>
                  <li className="mb-1 font-15">
                    <a
                      className="d-inline-flex align-items-center rounded"
                      href="#work-list-panel"
                    >
                      Active Work Orders list panel
                    </a>
                  </li>
                </ul>
              </nav>
            </aside>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default Documentation;
