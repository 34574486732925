 import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import { loadModules } from 'esri-loader';
import { getLoggedInUser, isUserInSupervisorRole } from '../helpers/authUtils';
import { appConfiguration } from '../components/AppMenu';
import { CrudOperations } from './CRUDoperations.service';
import swal from 'sweetalert';

const __SAVED__THEME = '__my__theme';

const request_options = {
  query: {
    f: 'json',
  },
  responseType: 'json',
};

const workOrderBaserestEndPoint = process.env.REACT_APP_WOC_BASE_SERVICE;

const WorkOrderFeatureLayerUrl = `${workOrderBaserestEndPoint}FeatureServer/0`;

const WorkOrderMapServerUrl = `${workOrderBaserestEndPoint}MapServer`;
const WorkOrderFeatureServerUrl = `${workOrderBaserestEndPoint}FeatureServer`;

const queryDomainURL = `${WorkOrderFeatureServerUrl}/queryDomains?layers=0&f=pjson`;
var mapview;
var workOrderLayerView;
var userRole;
var userApplicationSettings = null;

var appConfig;

var printableWorkOrderObj;
var isAppInEditMode = false;
var isAppInAddingAssetsMode = false;
var isAppInDetailMode = false;
var workOrdersBatchList = [];
var _ListWorkOrders = [];
var _applicationLayers = [];
var __ApplicationDepartments = [];

const requestOptions = {
  query: {
    f: 'json',
  },
  responseType: 'json',
};

// SRC API http request config
export const _src_service = Axios.create({
  baseURL: process.env.REACT_APP_WOC_API_URL,
});

export const workorderService = {
  GetAdvancedFeaturesAvailability: () => {
    return {
      IswaterIsolationTraceEnabled:
        process.env.REACT_APP_IsWaterIsolationTrace_Enabled,
      IsCCTVEnabled: process.env.REACT_APP_IsCCTV_Enabled,
    };
  },
  GetApplicationDepartments: () => {
    return __ApplicationDepartments;
  },
  SetApplicationDepartments: (departments) => {
    __ApplicationDepartments = departments;
  },
  HostedServiceURL: () => {
    return WorkOrderFeatureLayerUrl;
  },
  SetMapView: (view) => {
    mapview = view;
  },
  mapView: () => {
    return mapview;
  },
  SetUserRole: (role) => {
    userRole = role;
  },
  SetUserAppSettings: (settings) => {
    userApplicationSettings = settings;
  },
  GetUserAppSettings: () => {
    return userApplicationSettings;
  },
  SetApplicationLayers: (layers) => {
    _applicationLayers = layers;
  },
  GetApplicationLayers: () => {
    return _applicationLayers;
  },
  SetListWorkOrders: (workorders) => {
    _ListWorkOrders = workorders;
  },
  GetListWorkOrders: () => {
    return _ListWorkOrders;
  },
  SetWorkOrdersBatchList: (workorders) => {
    workOrdersBatchList = workorders;
  },
  GetWorkOrdersBatchList: () => {
    return workOrdersBatchList;
  },
  GetUserRole: () => {
    return userRole;
  },
  SetPrintableOrderObj: (wodetails) => {
    printableWorkOrderObj = wodetails;
  },
  SetAppDetailMode: (isDetailMode) => {
    isAppInDetailMode = isDetailMode;
  },
  GetAppDetailMode: () => {
    return isAppInDetailMode;
  },
  SetAppEditMode: (isEditMode) => {
    isAppInEditMode = isEditMode;
  },
  GetAppEditMode: () => {
    return isAppInEditMode;
  },
  SetAppInAddingAssets: (isAddingAssetsMode) => {
    isAppInAddingAssetsMode = isAddingAssetsMode;
  },
  GetAppInAddingAssets: () => {
    return isAppInAddingAssetsMode;
  },
  GetPrintableOrderObj: () => {
//    console.log(printableWorkOrderObj);
    return printableWorkOrderObj;
  },
  GetDepartment: () => {
    return _src_service.get(`/Departments`);
  },
  GetConnectedUserAndAssets: (uid) => {
    return _src_service.get(`/Users/${uid}`);
  },
  SetWorkOrderLayerView: (layerView) => {
    workOrderLayerView = layerView;
  },
  GetWorkOrderLayerView: () => {
    return workOrderLayerView;
  },
  GetUserTheme: () => {
    const _theme = localStorage.getItem(__SAVED__THEME);
    if (_theme) {
      const __theme = JSON.parse(_theme);
      return __theme.theme;
    } else return '';
  },

/*  GetFeature: (config) => {
    appConfig = config;
    loadModules(["esri/request"]).then(([esriRequest]) => {
      // First, define the attribute you want to search for and the value you are looking for
      const attribute = "WOID";
      const value = "164";
    
      // Next, create the query parameters
      const queryParams = {
        where: `${attribute} = '${value}'`,
        outFields: "*"
      };

      const requestOptions = {
        responseType: "json"
      };
    
      // Use the esriRequest function to send the query to the map service
      esriRequest(`https://gis.reynoldsburg.gov/arcgis/rest/services/WorkOrderCenter/WOC_WorkOrders/FeatureServer/0`,
      {
        query: queryParams,
        requestOptions: requestOptions,
      }).then(response => {
          const feature = response.features[0];
          return feature;
      }).catch(error => {
          console.error(error);
      });
    });
  },  */

  GetFeature: (attribute, value) => {
//    appConfig = config;
    return loadModules([
      'esri/request',
      'esri/layers/FeatureLayer',
      'esri/geometry/SpatialReference',
      'esri/config',
      'esri/core/urlUtils',
    ]).then(
      ([esriRequest, FeatureLayer, SpatialReference, esriConfig, urlUtils]) => {
        esriConfig.request.interceptors.push({
          urls: /FeatureServer\/\d+$/,
          after: function (response) {
            response.data.supportedQueryFormats = 'JSON';
          },
        });

/*        if (config.proxyServer.length > 0) {
          for (let index = 0; index < config.proxyServer.length; index++) {
            const { proxyUrl, urlPrefix } = config.proxyServer[index];

            urlUtils.addProxyRule({
              proxyUrl,
              urlPrefix,
            });
          }
        } */

        const fLayer = new FeatureLayer(
          `https://gis.reynoldsburg.gov/arcgis/rest/services/WorkOrderCenter/WOC_Signs/MapServer/1`
        );

        var query = fLayer.createQuery();
        query.where = `${attribute} = '${value}'`;
        query.outFields = ['*'];
        query.outSpatialReference = new SpatialReference({ wkid: 102100 });
        return fLayer.queryFeatures(query);
      }
    );
  },

  GetAnnualYears: () => {
    //    appConfig = config;
        return loadModules([
          'esri/request',
          'esri/layers/FeatureLayer',
          'esri/geometry/SpatialReference',
          'esri/config',
          'esri/core/urlUtils',
        ]).then(
          ([esriRequest, FeatureLayer, SpatialReference, esriConfig, urlUtils]) => {
            esriConfig.request.interceptors.push({
              urls: /FeatureServer\/\d+$/,
              after: function (response) {
                response.data.supportedQueryFormats = 'JSON';
              },
            });
    
    /*        if (config.proxyServer.length > 0) {
              for (let index = 0; index < config.proxyServer.length; index++) {
                const { proxyUrl, urlPrefix } = config.proxyServer[index];
    
                urlUtils.addProxyRule({
                  proxyUrl,
                  urlPrefix,
                });
              }
            } */
    
            const fLayer = new FeatureLayer(
              `https://gis.reynoldsburg.gov/arcgis/rest/services/WorkOrderCenter/WOC_Signs/FeatureServer/1`
            );
    
            var query = fLayer.createQuery();
            query.where = 'YRREPLACE > 0';
//            query.outFields = ['STREET','YRREPLACE'];
            query.outFields = ['YRREPLACE'];
            query.outSpatialReference = new SpatialReference({ wkid: 102100 });
            fLayer.queryFeatures(query).then(function(results) {
              const qryFeatures=results.features;
              const unique = qryFeatures
              .map((item) => item.attributes.YRREPLACE)
              .filter((value, index, self) => self.indexOf(value) === index)
              .sort();
              return unique;
            });
          }
        );
      },
    

  GetAnnualWork: (attribute, value) => {
    //    appConfig = config;
        return loadModules([
          'esri/request',
          'esri/layers/FeatureLayer',
          'esri/geometry/SpatialReference',
          'esri/config',
          'esri/core/urlUtils',
        ]).then(
          ([esriRequest, FeatureLayer, SpatialReference, esriConfig, urlUtils]) => {
            esriConfig.request.interceptors.push({
              urls: /FeatureServer\/\d+$/,
              after: function (response) {
                response.data.supportedQueryFormats = 'JSON';
              },
            });
    
    /*        if (config.proxyServer.length > 0) {
              for (let index = 0; index < config.proxyServer.length; index++) {
                const { proxyUrl, urlPrefix } = config.proxyServer[index];
    
                urlUtils.addProxyRule({
                  proxyUrl,
                  urlPrefix,
                });
              }
            } */
    
            const fLayer = new FeatureLayer(
              `https://gis.reynoldsburg.gov/arcgis/rest/services/WorkOrderCenter/WOC_Signs/MapServer/1`
            );
    
            var query = fLayer.createQuery();
            query.where = `${attribute} = '${value}'`;
//            query.outFields = ['STREET','YRREPLACE'];
            query.outFields = ['*'];
            query.outSpatialReference = new SpatialReference({ wkid: 102100 });
            return fLayer.queryFeatures(query);
          }
        );
      },
    

  GetWorkOrders: (config) => {
    appConfig = config;
    return loadModules([
      'esri/request',
      'esri/layers/FeatureLayer',
      'esri/geometry/SpatialReference',
      'esri/config',
      'esri/core/urlUtils',
    ]).then(
      ([esriRequest, FeatureLayer, SpatialReference, esriConfig, urlUtils]) => {
        esriConfig.request.interceptors.push({
          urls: /FeatureServer\/\d+$/,
          after: function (response) {
            response.data.supportedQueryFormats = 'JSON';
          },
        });

        if (config.proxyServer.length > 0) {
          for (let index = 0; index < config.proxyServer.length; index++) {
            const { proxyUrl, urlPrefix } = config.proxyServer[index];

            urlUtils.addProxyRule({
              proxyUrl,
              urlPrefix,
            });
          }
        }

        const fLayer = new FeatureLayer(
          `${process.env.REACT_APP_WOC_BASE_SERVICE}FeatureServer/0`
        );

        var query = fLayer.createQuery();
        query.where = `1 = 1`;
        query.outFields = ['*'];
        query.outSpatialReference = new SpatialReference({ wkid: 102100 });
        return fLayer.queryFeatures(query);
      }
    );
  },
  GetWorkOrderHistory: (facilityID) => {
    return _src_service.get(`/WorkOrders/GetWorkHistory/${facilityID}`);
  },
  GetManagedOrderDetails: (globalID) => {
    return _src_service.get(`/WorkOrders/GetByGlobalID/${globalID}`);
  },
  GetDashboardData: () => {
    return _src_service.get(`/WorkOrders/GetDashboard`);
  },
  GetProblemResolutionReport: (year) => {
    return _src_service.get(`/WorkOrders/GetProblemResolutionReport/${year}`);  //THE VIEW WAS REFERENCING A SPECIFIC  DATABASE, WHICH HAD CHANGED 11/2/2022 DMM
  },

  GetResourceInventoryCriticalReport: () => {
    return _src_service.get(`/WorkOrders/GetResourceInventoryCriticalReport`);  //THE VIEW WAS REFERENCING A SPECIFIC  DATABASE, WHICH HAD CHANGED 11/2/2022 DMM
  },

  __getEpochTime: (humanDate) => {  
    // Returns date in the form of 1659352786000 where the last three are zeros due to the substraction of ms
    // Input of new date in the default form of: Mon Aug 01 2022 07:21:37 GMT-0400 (Eastern Daylight Time)
    const d = new Date(humanDate);
    return d.getTime() - d.getMilliseconds();
  },

  __getISODate: (humanDate) => {
    // Returns date in the form of 1659352786000 where the last three are zeros due to the substraction of ms
    // Input of new date in the default form of: Mon Aug 01 2022 07:21:37 GMT-0400 (Eastern Daylight Time)
    const d = new Date(humanDate);
    return d.toISOString().split('T')[0];
  },


  __getHumanDateAndTime: (humanDate) => {
    // Returns date in the form of 8/1/2022 - 7:19:46 AM
    // Input of new date in the default form of: Mon Aug 01 2022 07:21:37 GMT-0400 (Eastern Daylight Time)
    if (humanDate) {
      const d = new Date(humanDate);
      return `${d.toLocaleDateString()} - ${d.toLocaleTimeString()}`;
    } else {
      return 'N/A';
    }
  },

  __getNextDueDate: (occurrenceNumber, workOrderDueDate, OccurrenceDelay) => {
    var someDate = new Date(workOrderDueDate);
    switch (OccurrenceDelay) {
      case 'Weeks':
        someDate.setDate(someDate.getDate() + parseInt(occurrenceNumber * 7));
        break;
      case 'Months':
        someDate.setMonth(someDate.getMonth() + parseInt(occurrenceNumber));
        break;
      case 'Years':
        break;
      default:
        someDate.setDate(someDate.getDate() + parseInt(occurrenceNumber));
        break;
    }
    var dd = someDate.getDate();
    var mm = someDate.getMonth() + 1;
    var y =
      OccurrenceDelay === 'Years'
        ? someDate.getFullYear() + parseInt(occurrenceNumber)
        : someDate.getFullYear();

    var someFormattedDate = mm + '/' + dd + '/' + y;

    return someFormattedDate;
  },

  renderStatus: (status) => {
    switch (status) {
      case 'Unassigned':
        return (
          <span className="badge bg-danger rounded-pill work-status-text-color">
            {status}
          </span>
        );
        break;
      case 'Assigned':
        return (
          <span className="badge bg-warning rounded-pill work-status-text-color">
            {status}
          </span>
        );
        break;
      case 'Archived':
        return (
          <span className="badge bg-danger rounded-pill work-status-text-color">
            {status}
          </span>
        );
        break;

      case 'Completed':
        return (
          <span className="badge bg-success rounded-pill work-status-text-color">
            {status}
          </span>
        );
        break;
    }
  },

  NotifyCrossedDepartmentUnassignedWork: (unassignedWorkAlertObj) => {
    return _src_service.post(
      `/Notifications/NotifyCrossedDepartmentUnassignedWork`,
      unassignedWorkAlertObj
    );
  },
  UpdateWorkRelatedDetails: (workOrderObj) => {
    return _src_service.put(`/WorkOrders`, workOrderObj);
  },
  UpdateWorkRecurrence: (recurrenceObj) => {
    return _src_service.put(`/WorkOrders/UpdateRecurrence`, recurrenceObj);
  },
  UpdateWorkorderAssigned: (workOrderObj) => {
    return _src_service.post(`/WorkOrders/UpdateAssigned`, workOrderObj);
  },
  CreateServerWorkOrder: (workOrderObj) => {
    return _src_service.post(`/WorkOrders`, workOrderObj);
  },

  DeleteWorkOrder: (workorderID) => {
    return _src_service.delete(`/WorkOrders/${workorderID}`);
  },

  IsDeleteWorkOptionAvailable: (initiatedDate) => {
    const initDate = new Date(initiatedDate);
    const _todayDate = new Date();
    var hours = Math.abs(_todayDate - initDate) / 36e5;
    return hours < parseInt(process.env.REACT_APP_WORK_DELETE_DELAY_IN_HOURS)
      ? true
      : false;
  },

  GetResourcesByDepartment: (departmentID) => {
    return _src_service.get(
      `/Resources/GetResourcesByDepartment/${departmentID}`
    );
  },
  GetResourcesByGISAsset: (gisAssetID) => {
    return _src_service.get(`/Resources/GetResourcesByAsset/${gisAssetID}`);
  },
  GetEssentialQuestions: (workServiceID) => {
    return _src_service.get(`/Services/${workServiceID}`);
  },
  GetServices: () => {
    return _src_service.get(`/Services`);
  },
  GetUserWorkSummary: (userID) => {
    return _src_service.get(`/WorkOrders/GetUserWorkSummary/${userID}`);
  },

  GetWorkOrderAttachments: (ObjectID) => {
    return loadModules(['esri/request']).then(([esriRequest]) => {
      const _queryAttachmentsURL = `${WorkOrderFeatureLayerUrl}/${ObjectID}/attachments`;
      return esriRequest(_queryAttachmentsURL, requestOptions);
    });
  },

  GetFirstAttachment: (objectId) => {
    loadModules(['esri/request']).then(([esriRequest]) => {
      const attachmentsUrl = `https://gis.reynoldsburg.gov/arcgis/rest/services/WorkOrderCenter/WOC_Signs/MapServer/1/${objectId}/attachments`;
      esriRequest(attachmentsUrl,requestOptions).then((attachmentsResponse) => {
          const attachment = attachmentsResponse.data.attachmentInfos[0];
          if (attachment) {
              const attachmentUrl = `https://gis.reynoldsburg.gov/arcgis/rest/services/WorkOrderCenter/WOC_Signs/MapServer/1/${objectId}/attachments/${attachment.id}`;
              console.log(objectId,attachment.id)
              return attachmentUrl;
            } else {
              console.log("No attachments found for objectId: ", objectId);
          }
      }).catch(error => {
          console.log("Error getting attachment URL: ", error);
      });
    });
  },

  getDomains: () => {
    return loadModules(['esri/request']).then(([esriRequest]) => {
      return esriRequest(queryDomainURL);
    });
  },
  __getEpochTime: (humanDate) => {
    // Returns date in the form of 1659352786000 where the last three are zeros due to the substraction of ms
    // Input of new date in the default form of: Mon Aug 01 2022 07:21:37 GMT-0400 (Eastern Daylight Time)
    const d = new Date(humanDate);
    return d.getTime() - d.getMilliseconds();
  },

  __getISODate: (humanDate) => {
    // Returns date in the form of 1659352786000 where the last three are zeros due to the substraction of ms
    // Input of new date in the default form of: Mon Aug 01 2022 07:21:37 GMT-0400 (Eastern Daylight Time)
    const d = new Date(humanDate);
    return d.toISOString().split('T')[0];
  },

  __getHumanDateAndTime: (humanDate) => {
    // Returns date in the form of 8/1/2022 - 7:19:46 AM
    // Input of new date in the default form of: Mon Aug 01 2022 07:21:37 GMT-0400 (Eastern Daylight Time)
    if (humanDate) {
      const d = new Date(humanDate);
      return `${d.toLocaleDateString()} - ${d.toLocaleTimeString()}`;
    } else {
      return 'N/A';
    }
  },

  __getHumanDate: (humanDate) => {
    // Returns date in the form of 8/1/2022 - 7:19:46 AM
    // Input of new date in the default form of: Mon Aug 01 2022 07:21:37 GMT-0400 (Eastern Daylight Time)
    if (humanDate) {
      const d = new Date(humanDate);
      return `${d.toLocaleDateString()}`;
    } else {
      return 'N/A';
    }
  },

  ClaimUnassignedWork: (edits) => {
    return workorderService.applyAttributeUpdates(edits);
  },
  RefreshWorkOrders: () => {
    if (mapview) {
      const fLayer = mapview.map.layers.find((layer) => {
        return layer.id === 'workOrders';
      });
      fLayer.refresh();
    }
  },

  ApplyWorkOrderEdits: (editedAttributesObj) => {
    return workorderService.applyAttributeUpdates(editedAttributesObj);
  },

  AddAttachmentToWorkOrder: (attachmentFile, ObjectID) => {
    return loadModules(['esri/request']).then(([esriRequest]) => {
      const url = `${WorkOrderFeatureLayerUrl}/${ObjectID}/addAttachment`;
      const form = new FormData();
      form.set('attachment', attachmentFile);
      form.append('f', 'json');
      const options = {
        method: 'post',
        body: form,
        query: {
          f: 'json',
        },
      };
      return esriRequest(url, options);
    });
  },

  /* __addUtilityMapService: (
    map,
    user_department,
    utilityMapService,
    streetSignsLayer,
    treesLayer
  ) => {
    const user_role = workorderService.GetUserRole();

    let layerSubLayers = [];

    loadModules(['esri/request', 'esri/layers/MapImageLayer'])
      .then(([esriRequest, MapImageLayer]) => {
        esriRequest(utilityMapService, request_options).then((res) => {
          const addWorkAssetAction = {
            title: 'Add Work Asset',
            id: 'add-work-asset-action',
            className: 'esri-icon-plus-circled',
          };
          const CreateWorkOrderFromAsset = {
            title: 'Create Work',
            id: 'create-work-action',
            className: 'esri-icon-add-attachment',
          };
          const workOrderHistory = {
            title: 'Work History',
            id: 'work-history-action',
            className: 'esri-icon-rotate-back',
          };
          const waterIsolationTrace = {
            title: 'Isolation Trace',
            id: 'water-isolation-action',
            className: 'esri-icon-polyline',
          };
          const cctv = {
            title: 'CCTV',
            id: 'sewer-cctv-action',
            className: 'esri-icon-play-circled',
          };

          const __getFeatureActions = (isActionAllowed) => {
            //const isInEditMode = true; //document.getElementById('gis-assets-add-ui') ? true : false;
            if (isActionAllowed) {
              return user_role === 'supervisor' || user_role === 'manager'
                ? [
                    addWorkAssetAction,
                    workOrderHistory,
                    CreateWorkOrderFromAsset,
                    waterIsolationTrace,
                    cctv,
                  ]
                : [
                    addWorkAssetAction,
                    workOrderHistory,
                    waterIsolationTrace,
                    cctv,
                  ];
            } else {
              if (user_role === 'manager') {
                return [
                  addWorkAssetAction,
                  workOrderHistory,
                  CreateWorkOrderFromAsset,
                  waterIsolationTrace,
                  cctv,
                ];
              }
            }
          };

          res.data.layers.map((layer) => {
            if (layer.parentLayerId > -1) {
              var isLayerInUserDepartment =
                user_role === 'manager'
                  ? true
                  : layer.name
                      .toLowerCase()
                      .indexOf(user_department.toLowerCase()) > -1;

              layerSubLayers.push({
                id: layer.id,
                visible: false,
                popupTemplate: {
                  title: layer.name.trim(),
                  content: [
                    {
                      type: 'fields',
                      fieldInfos: [
                        {
                          fieldName: 'FACILITYID',
                          visible: true,
                          label: 'FACILITYID',
                        },
                      ],
                    },
                  ],
                  actions: __getFeatureActions(isLayerInUserDepartment),
                },
              });
            }
          });

          // add layer to map
          layerSubLayers = layerSubLayers.reverse();
          const UtilityLayer = new MapImageLayer({
            url: workorderService.UtilityMapService,
            id: '1703a0495d2-util',
            title: 'UTILITY',
            sublayers: layerSubLayers,
          });

          map.add(UtilityLayer, 0);

          const StreetSignsLayer = new MapImageLayer({
            url: streetSignsLayer,
            id: 'streetSigns',
            title: 'Street Signs',
            sublayers: [
              {
                id: 0,
                visible: false,
                popupTemplate: {
                  title: 'Street Signs',
                  content: [
                    {
                      type: 'fields',
                      fieldInfos: [
                        {
                          fieldName: 'FACILITYID',
                          visible: true,
                          label: 'FACILITYID',
                        },
                      ],
                    },
                  ],
                  actions: __getFeatureActions(
                    user_department === 'General Services' ? true : false
                  ),
                },
              },
            ],
          });

          map.add(StreetSignsLayer, 1);

          const TreesLayer = new MapImageLayer({
            url: treesLayer,
            id: 'trees',
            title: 'Trees',
            sublayers: [
              {
                id: 0,
                visible: false,
                popupTemplate: {
                  title: 'Trees',
                  content: [
                    {
                      type: 'fields',
                      fieldInfos: [
                        {
                          fieldName: 'FACILITYID',
                          visible: true,
                          label: 'FACILITYID',
                        },
                      ],
                    },
                  ],
                  actions: __getFeatureActions(
                    user_department === 'Parks & Recreation' ? true : false
                  ),
                },
              },
            ],
          });

          map.add(TreesLayer, 2);
        });
      })
      .catch((err) => console.log(`Something went wrong :: ${err}`));
  }, */

  __GetLayerActionButtons: async (serviceName, user, gisAssets) => {
    const addWorkAssetAction = {
      title: 'Add Work Asset',
      id: 'add-work-asset-action',
      className: 'esri-icon-plus-circled',
    };
    const CreateWorkOrderFromAsset = {
      title: 'Create Work',
      id: 'create-work-action',
      className: 'esri-icon-add-attachment',
    };
    const workOrderHistory = {
      title: 'Work History',
      id: 'work-history-action',
      className: 'esri-icon-rotate-back',
    };
    const logWorkFromAsset = {
      title: 'Log Completed',
      id: 'log-work-action',
      className: 'esri-icon-resend-invitation',
    };  
    const waterIsolationTrace = {
      title: 'Isolation Trace',
      id: 'water-isolation-action',
      className: 'esri-icon-polyline',
    };
    const cctv = {
      title: 'CCTV',
      id: 'sewer-cctv-action',
      className: 'esri-icon-play-circled',
    };

    let actions = [];

    //console.log('user role :: ', userRole);
    switch (userRole) {
      case 'manager':
        actions = [
          workOrderHistory,
          CreateWorkOrderFromAsset,
          addWorkAssetAction,      //LET MANAGER ADD ASSETS FROM THE MAP WHILE EDITING WORK ORDER 11/1/2022 DMM
          logWorkFromAsset
        ];
        break;

      case 'supervisor':
        // get supervisor department
        const department = __ApplicationDepartments.find(
          ({ name }) => name === user.department
        );
        if (department.getDepartmentGISAssets.length > 0) {
          department.getDepartmentGISAssets.map((a) => {
            if (a.gisService.name === serviceName) {
              actions = [
                workOrderHistory,
                CreateWorkOrderFromAsset,
                addWorkAssetAction,  //LET SUPERVISOR ADD ASSETS FROM THE MAP WHILE EDITING WORK ORDER 11/1/2022 DMM
                logWorkFromAsset
              ];
            }
          });
        }
        break;
      case 'field':
        //console.log(gisAssets);
        if (gisAssets.length > 0) {
          gisAssets.map((a) => {
            if (a.gisServicesUsersConfigurations.length > 0) {
              a.gisServicesUsersConfigurations.map((us) => {
//                if (us.user.id === user.id) {
                  if (us.userID === user.id) {
                    actions = [
                      workOrderHistory,
                       addWorkAssetAction,
                      logWorkFromAsset
                    ];
                }
              });
            }
          });
        }
        break;
    }
    return actions;
  },

  __GetSubLayers: (sublayers, serviceName, user, gisAsset) => {       /* sets up the asset sublayers for actions */
    const __promise = new Promise((resolve, reject) => {
      let layerSubLayers = [];
      sublayers.map(async (layer) => {
        if (layer.parentLayerId > -1) {
          layerSubLayers.push({
            id: layer.id,
            visible: false,
            popupTemplate: {
              title: `${layer.name.trim()} | ${serviceName}`,
              content: [
                {
                  type: 'fields',
                  fieldInfos: [
                    {
                      fieldName: 'FACILITYID',
                      visible: true,
                      label: 'FACILITYID',
                    },
                    {
                      fieldName: 'FACILITYDETAIL',
                      visible: true,
                      label: 'FACILITYDETAIL',
                    }, 
                  ],
                },
              ],
              actions: await workorderService.__GetLayerActionButtons(
                serviceName,
                user,
                gisAsset
              ),
            },
          });
        }
      });
      layerSubLayers = layerSubLayers.reverse();
      resolve(layerSubLayers);
    });
    return __promise;
  },

  __addGISAssetsToMap: (map, user) => {
    const _promise = new Promise((resolve, reject) => {
      loadModules(['esri/request', 'esri/layers/MapImageLayer']).then(
        async ([esriRequest, MapImageLayer]) => {
          const { data } = await CrudOperations.Get('GisConfiguration');
          //console.log(data);
          workorderService.SetApplicationLayers(data);

          if (data.length > 0) {
            // add services to map
            data.map(async (d, idx) => {
              //console.log(d);
              const __layerInfo = await esriRequest(
                d.serviceLink,
                request_options
              );
              const layerSubLayers = await workorderService.__GetSubLayers(
                __layerInfo.data.layers,
                d.name,
                user,
                data
              );
              const __layerId = d.name.replace(/ +/g, '');
              const mapImageLayer = new MapImageLayer({
                url: d.serviceLink,
                id: `${__layerId.toLowerCase().trim()}`,
                title: d.name,
                sublayers: layerSubLayers.reverse(),  //DMM 10/12/2022
              });
              map.add(mapImageLayer, idx);
            });
          }
          resolve(data);
        }
      );
    });

    return _promise;
  },

  SaveUserSettings: async (userSettingsObj) => {
    var result = await CrudOperations.Create('UserSettings', userSettingsObj);

    swal('Settings saved successfully.  You must restart the application to get the updated base map.');
    //this.props.hideRightSidebar();
  },

  selectFeatureByObjectID: (featureLayer, objectId) => {
    // query feature from the server
    return featureLayer.queryFeatures({
      objectIds: [objectId],
      outFields: ['*'],
      returnGeometry: true,
    });
  },

  SelectFeatureByGlobalID: (globalID) => {
    // query feature from the server
    return loadModules([
      'esri/layers/FeatureLayer',
      'esri/geometry/SpatialReference',
      'esri/config',
    ]).then(([FeatureLayer, SpatialReference, esriConfig]) => {
      esriConfig.request.interceptors.push({
        urls: /FeatureServer\/\d+$/,
        after: function (response) {
          response.data.supportedQueryFormats = 'JSON';
        },
      });

      const view = workorderService.mapView();
      const featureLayer = view.map.layers.find((layer) => {
        return layer.id === 'workOrders';
      });

      const query = featureLayer.createQuery();
      query.where = `GlobalID = '${globalID}'`;
      query.outFields = ['*'];
      query.outSpatialReference = new SpatialReference({ wkid: 102100 });
      query.returnGeometry = true;

      return featureLayer.queryFeatures(query);
    });
  },

  applyAttributeUpdates: (edits) => {
    if (mapview) {
      const fLayer = mapview.map.layers.find((layer) => {
        return layer.id === 'workOrders';
      });
      return fLayer.applyEdits(edits);
    } else {
      return null;
    }
  },

  GetAddressFromLocation: (view, locationPoint = null) => {
    const { location } = view.popup;
    const _workLocation = locationPoint ? locationPoint : location;
    return loadModules(['esri/request']).then(([esriRequest]) => {
      var url = process.env.REACT_APP_REVERSE_GEOCODE_SERVICE +
        '?f=pjson&featureTypes=&location=' +
        _workLocation.longitude + ',' +
        _workLocation.latitude +
        '';    //DMM 12/13/2022
/*      var url = 'https://gis.mansfieldcity.com/arcgis/rest/services/Miscellaneous/CityAddressLocator/GeocodeServer'
        + '/reverseGeocode?f=pjson&featureTypes=&location=' +
        _workLocation.longitude +
        ',' +
        _workLocation.latitude +
        '';  */
      return esriRequest(url);
    });
  },

  __flushSelectedWork: (geometry, view) => {
    loadModules(['esri/Graphic']).then(([Graphic]) => {
      const selectedWorkGLayer = view.map.findLayerById('selectedWorkGLayer');
      if (selectedWorkGLayer) {
        selectedWorkGLayer.graphics.removeAll();

        /* const flushingSymbol = {
          type: 'picture-marker',
          url:
            'https://arcgis.github.io/arcgis-samples-javascript/sample-data/cat3.png',
          width: 56,
          height: 56,
        }; */
        const flushingSymbol = {
          type: 'simple-marker',
          outline: { width: 1.25, color: [64, 186, 226, 1] },
          size: 30,
        };
        const selectWorkGraphic = new Graphic({
          geometry: geometry,
          symbol: flushingSymbol,
        });

        selectedWorkGLayer.graphics.add(selectWorkGraphic);
      }
    });
  },

  __clearFlushSelectedWork: (view) => {
    if(view && view.map) {
      const selectedWorkGLayer = view.map.findLayerById('selectedWorkGLayer');
      const assetHighlighterGLayer = view.map.findLayerById('assetHighlighterGLayer');
      if (selectedWorkGLayer && assetHighlighterGLayer) {
        selectedWorkGLayer.graphics.removeAll();
        assetHighlighterGLayer.graphics.removeAll();
      }
    }
  },
  
/*
  __clearFlushSelectedWork: (view) => {   // commented out 9/27/2023
    const selectedWorkGLayer = view.map.findLayerById('selectedWorkGLayer');
    const assetHighlighterGLayer = view.map.findLayerById(
      'assetHighlighterGLayer'
    );
    if (selectedWorkGLayer) {
      selectedWorkGLayer.graphics.removeAll();
      assetHighlighterGLayer.graphics.removeAll();
    }
  },

  */

  ZoomToSelectedWork: (geometry, view, zoom = 20) => {
    view.goTo({
      target: geometry,
      zoom,   // DMM 12/6/2022 - maintains the zoom, but changes the center point
    });
  },

  GoToSelectedWork: (geometry, view, zoom = 20) => {   // ADDED DMM 12/29/2022
    view.goTo({
      target: geometry,
//      zoom,   // DMM 12/6/2022 - maintains the zoom, but changes the center point
    });
  },

  reclassifyWork: (edits) => {
    return workorderService.applyAttributeUpdates(edits);
  },
  __addRemoveSelectionGraphics: (isAdd = false) => {
    loadModules(['esri/Graphic']).then(([Graphic]) => {
      const selectedWorkGLayer =
        mapview.map.findLayerById('selectedWorkGLayer');
      if (selectedWorkGLayer) {
        selectedWorkGLayer.graphics.removeAll();
        if (isAdd) {
          workOrdersBatchList.map((f) => {
            var marker = {
              type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
              url:
                f.attributes.Status === 'Unassigned'
                  ? 'https://static.arcgis.com/images/Symbols/Shapes/RedPin2LargeB.png'
                  : 'https://static.arcgis.com/images/Symbols/Shapes/YellowPin2LargeB.png',
              width: '44px',
              height: '44px',
            };
            selectedWorkGLayer.add(new Graphic(f.geometry, marker));
          });
        }
      }
    });
  },

  DoSRCSearch: (searchTerm) => {
    return loadModules(['esri/request']).then(([esriRequest]) => {
      const _searchURL = `${WorkOrderMapServerUrl}/find?searchText=${searchTerm}&contains=true&searchFields=%5B*%5D&layers=0&returnGeometry=false&f=pjson`;
      return esriRequest(_searchURL);
    });
  },
  GetDueDateTextColor: (date, status = 'Completed') => {
    const todaysDate = new Date().getTime();
    const dueDate = new Date(date).getTime();
    var dateColor = 'text-success';
    switch (status) {
      case 'Completed':
        break;

      default:
        if (dueDate <= todaysDate) {
          dateColor = 'text-danger';
        } else {
          dateColor = 'text-primary';
        }
        break;
    }
    return dateColor;
  },
  GetDueDateFormatted: (d, status, isRecurring = 'No') => {
    const textColor = workorderService.GetDueDateTextColor(d, status);
    const dueDate = new Date(d).toISOString().substr(0, 10);
    return (
      <>
        {isRecurring === 'Yes' && (
          <>
            <i className="uil uil-sync" style={{ fontSize: '0.9rem' }}></i>{' '}
          </>
        )}
        <span className={textColor}>{dueDate}</span>
      </>
    );
  },
  IsAdmin: () => {
    const user = getLoggedInUser();
    const { roles } = user;
    // returns trufy or falsy based on user roles
    return (
      roles.findIndex((r) => r.id === 'd4463c2c-ae7e-4ea6-86cc-7e24a1d2bbd5') >
      -1
    );
  },
};

export const AppThemeService = {
  SetMapDarkMode: () => {
    if (mapview) {
//      mapview.map.basemap = 'streets-night-vector';
//      mapview.map.basemap = `${this.props.config.ESRImaps.id_alternate}`;
      //AppThemeService.CloseSettings();
    }
  },
  SetMapLightMode: () => {
    if (mapview) {
//      mapview.map.basemap = 'streets-navigation-vector';
//      mapview.map.basemap = `${this.props.config.ESRImaps.id_base}`;
      //AppThemeService.CloseSettings();
    }
  },
  CloseSettings: () => {
    const btnClose = document.getElementById('btn-close-settings');
    if (btnClose) {
      btnClose.click();
    }
  },
};
