import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useState } from 'react';
import {
  Button, Col, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip
} from 'reactstrap';
import swal from 'sweetalert';
import { workorderService } from '../../../../services/workorder.service';

const RecurrenceUpdate = ({ feature, reccurenceNber, reccurenceDelay, goBack }) => {
    //console.log(feature);
    const [modal, setModal] = useState(false);
    const [isChangingRecurrence, setIsChangingRecurrence] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
      };
    
    const cancelRecurrence = () => {
        swal({
            text: 'Are you sure to cancel this recurrence?',
            closeOnClickOutside: false,
            buttons: { cancel: 'NO', confirm: 'YES' },
            dangerMode: true,
          }).then((action) => {
            if (action) {
              //[1] update feature from service
              Object.keys(feature.attributes).forEach((fieldName) => {
                switch (fieldName) {
                  case 'IsRecurring':
                    feature.attributes[fieldName] = 'No';
                    break;
                  case 'Recurrence': case 'NextDueDate':
                    feature.attributes[fieldName] = null;
                    break; 
                  default:
                    break;
                }
              });

              const edits = {
                updateFeatures: [feature],
              };
              workorderService.ApplyWorkOrderEdits(edits)
                .then((result) => {
                  if (result.updateFeatureResults.length > 0) {
                      //[2] update record in backend
                      const __cancelRecurrence = {
                        globalID: feature.attributes.GlobalID,
                        isRecurring:'No',
                        recurrence: null,
                        nextDueDate: new Date()
                      };
                      workorderService
                        .UpdateWorkRecurrence(__cancelRecurrence)
                        .then((updateDataResponse) => {
                            swal('Recurrence cancelled successfully');
                            goBack();
                        })
                  }
                })
              
            }
          });
    }

    const changeWorkWorkOrderRecurrence = (event, values) => {
        const { reccurenceNber, recurrenceDelay } = values;

        setIsChangingRecurrence(true);

        const Recurrence = `Occurs every ${reccurenceNber} ${recurrenceDelay}`;
        const _nextDueDate = workorderService.__getNextDueDate(reccurenceNber, feature.attributes.PlannedDate, recurrenceDelay);
   
        Object.keys(feature.attributes).forEach((fieldName) => {
            switch (fieldName) {
              case 'Recurrence': 
                feature.attributes[fieldName] = Recurrence;
                break;
              case 'NextDueDate':
                feature.attributes[fieldName] = _nextDueDate;
                break;
              default:
                break;
            }
          });

          const edits = {
            updateFeatures: [feature],
          };
          workorderService.ApplyWorkOrderEdits(edits)
            .then((result) => {
                //console.log(result);
                if (result.updateFeatureResults.length > 0){
                    const __updaterecurrence = {
                        globalID: feature.attributes.GlobalID,
                        isRecurring:'Yes',
                        recurrence: Recurrence,
                        nextDueDate: new Date(_nextDueDate)
                      };
                      workorderService
                        .UpdateWorkRecurrence(__updaterecurrence)
                        .then((updateDataResponse) => {
                            swal('Recurrence updated successfully');
                            goBack();
                        })
                }
            })

    }

    return (
        <>
          <Button 
            className="p-0 text-danger"
            id="cancelRecurrence" 
            color="link"
            style={{marginTop:'-10px'}} 
            onClick={() => cancelRecurrence()}>
            <i className="uil uil-times-square" style={{fontSize:'1.5rem'}}></i>
          </Button>

          <Button 
            className="p-0 ml-3"
            id="updateRecurrence" 
            color="link"
            style={{marginTop:'-10px'}} 
            onClick={() => toggleModal()}>
            <i className="uil uil-edit" style={{fontSize:'1.5rem'}}></i>
          </Button>

          <UncontrolledTooltip placement="top" target="cancelRecurrence">
            Cancel Recurrence
          </UncontrolledTooltip>
          <UncontrolledTooltip placement="top" target="updateRecurrence">
            Update Recurrence
          </UncontrolledTooltip>


            <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            Change Work order Recurrence
          </ModalHeader>
          <ModalBody>
            <AvForm onValidSubmit={changeWorkWorkOrderRecurrence}>

                <Row>
                 <Col md={6}>
                  <AvField
                    name="reccurenceNber"
                    type="number"
                    min={1}
                    label="Occurs every"
                    value={reccurenceNber}
                    required
                  />
                </Col>
                <Col md={6}>
                    <AvField
                        name="recurrenceDelay"
                        label="Delay"
                        type="select"
                        value={reccurenceDelay}
                        required
                        className="custom-select"
                    >
                    <option value="Days">Days</option>
                    <option value="Weeks">Weeks</option>
                    <option value="Months">Months</option>
                    <option value="Years">Years</option>
                    </AvField>
                </Col>
              </Row>

              <div className="text-center m-3 mb-2">
                <Button
                  color="light"
                  type="button"
                  className="mr-1"
                  onClick={toggleModal}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={isChangingRecurrence}
                  type="submit"
                >
                  {isChangingRecurrence
                    ? 'Updating...'
                    : 'Update'}
                </Button>
              </div>
            </AvForm>
          </ModalBody>
        </Modal>

        </>
    )
}

export default RecurrenceUpdate


