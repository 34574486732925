import React, { Component } from 'react';
import Axios from 'axios';

var LoggedInUser;

// users management https request config
export const _users_api_baseurl = Axios.create({
  baseURL: process.env.REACT_APP_USERS_API_URL, //`${window.location.protocol}//${window.location.host}/usersapi/api`, //'http://localhost:51259/api', //'https://demogis.emht.com/usersapi/api', //
});

export const UserAPI = {
  SignIn: (signinObj) => {
    return _users_api_baseurl.post(`/helpers/SignIn`, signinObj);
  },
  SetLoggedInUser: (user) => {
    LoggedInUser = user;
  },
  GetLoggedInUser: () => {
    return LoggedInUser;
  },
  GetUsers: () => {
    return _users_api_baseurl.get(`/users`);
  },
  GetUsersByDepartment: (department) => {
    return _users_api_baseurl.get(`/users/GetUsersByDepartment/${department}`);
  },
  GetUser: (userID) => {
    return _users_api_baseurl.get(`/users/${userID}`);
  },
  GetApplicationRoles: () => {
    return _users_api_baseurl.get(`/helpers/getroles`);
  },
  UpdateUserRoles: (userRolesObj) => {
    return _users_api_baseurl.post(`/users/UpdateUserRolesAsync`, userRolesObj);
  },
  UpdateNotificationPreference: (userPreferenceObj) => {
    return _users_api_baseurl.post(
      `/users/UpdateNotificationPreference`,
      userPreferenceObj
    );
  },
  DeleteUser: (Entity, EntityObjID) => {
    return _users_api_baseurl.delete(`/${Entity}/${EntityObjID}`);
  },
  UpdateUser: (Entity, EntityObj) => {
    return _users_api_baseurl.put(`/${Entity}`, EntityObj);
  },
  ForgotPassword: (accountResetHelperObj) => {
    return _users_api_baseurl.post(
      `/Helpers/ForgotPassword`,
      accountResetHelperObj
    );
  },
  ResetPassword: (accountResetHelperObj) => {
    return _users_api_baseurl.post(
      `/Helpers/ResetPassword`,
      accountResetHelperObj
    );
  },
  CreateUser: (newAccountUserObj) => {
    return _users_api_baseurl.post(`/Helpers/CreateUser`, newAccountUserObj);
  },
  ConfirmAccount: (accountResetHelperObj) => {
    return _users_api_baseurl.post(
      `/Helpers/ConfirmAccount`,
      accountResetHelperObj
    );
  },
  UpdateUserProfile: (userProfileUpdateObj) => {
    return _users_api_baseurl.post(
      `/Helpers/UpdateUserProfile`,
      userProfileUpdateObj
    );
  },
};
