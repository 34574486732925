import React, { useState } from 'react';
import { Card, CardBody, 
    TabContent, TabPane, Nav, 
    NavItem, NavLink
} from 'reactstrap';

import ReportFiles from '../Reports/ReportFiles';

const ReportRenderer = ({ videos, photos, reports }) => {
    const [activeTab, setActiveTab] = useState('1');



    return (
        <>
            <Nav tabs className="nav-bordered">
                        <NavItem>
                            <NavLink
                                href="#"
                                className={`${activeTab === '1'? 'active': ''}`}
                                onClick={() => {
                                    setActiveTab('1');
                                }}>
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
                                <span className="d-none d-lg-block">Videos</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={`${activeTab === '2'? 'active': ''}`}
                                onClick={() => {
                                    setActiveTab('2');
                                }}>
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
                                <span className="d-none d-lg-block">Photos</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={`${activeTab === '3'? 'active': ''}`}
                                onClick={() => {
                                    setActiveTab('3');
                                }}>
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
                                <span className="d-none d-lg-block">Files</span>
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={'1'} className="mt-2">
                            <ReportFiles reportFiles={videos} />
                        </TabPane>
                        <TabPane tabId={'2'} className="mt-2">
                            <ReportFiles reportFiles={photos} />
                        </TabPane>
                        <TabPane tabId={'3'} className="mt-2">
                            <ReportFiles reportFiles={reports} />
                        </TabPane>
                    </TabContent>
        </>
    )
}

export default ReportRenderer
