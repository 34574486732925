import React from 'react';
import { Row, Col, Card } from 'reactstrap';
import NoDataMessage from '../NoDataMessage/NoDataMessage';

const reportFiles = ({ reportFiles }) => {

    const __troncateLongtext = (str) => {
        return str.length > 15 ? str.substring(0, 15) + '...' : str;
    }

    return (
        <>
            {reportFiles.length > 0 && (
                <>
                    {reportFiles.map((f, idx) => (
                        <Card className="mb-1 shadow-none border" key={idx}>
                            <div className="p-2">
                                
                                    <Row className="align-items-center" >
                                        <div className="col-auto">
                                            <div className="avatar-sm">
                                                <span className="avatar-title bg-secondary rounded">{f.reportExtension}</span>
                                            </div>
                                        </div>
                                        <div className="col pl-0">
                                            <a href={f.reportUrl} 
                                                target='_blank'
                                                className="text-muted font-weight-bold text-wrap">
                                                {__troncateLongtext(f.reportName)}
                                                {/* {f.reportName} */}
                                            </a>
                                            <p className="mb-0">{f.fileSize}</p>
                                        </div>
                                        <div className="col-auto">
                                            <a 
                                                title='Open file'
                                                href={f.reportUrl}
                                                target='_blank'
                                                className="btn btn-link btn-lg text-muted">
                                                <i className="dripicons-folder-open"></i>
                                            </a>
                                        </div>
                                    </Row>
                                
                            </div>
                        </Card>
                    ))}
                </>
            )}
            {reportFiles.length < 1 && (
                <>
                    {/**If No files */}
                    <NoDataMessage message={'No files found at this time'} />
                </>
            )}
        </>
    )
}

export default reportFiles
