import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import notFoundImg from '../../../assets/images/file-searching.svg';
import {
  getLoggedInUser,
  isUserInSupervisorRole,
} from '../../../helpers/authUtils';
import { workorderService } from '../../../services/workorder.service';
import PageTitle from '../../PageTitle';
import Spinner from '../../Spinner';
import './print-page.css';

class SrcSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
      loggedUser: null,
      isUserSupervisor: false,
      isLoading: true,
      searchResults: [],
    };
  }

  componentDidMount() {
    const loggedUser = getLoggedInUser();
    const isUserSupervisor = isUserInSupervisorRole();
    const searchTerm = this.props.match.params.q;

    workorderService.DoSRCSearch(searchTerm).then((res) => {
      var departmentResultsFilter = res.data.results;
      if (workorderService.GetUserRole() === 'manager') {
      } else {
        departmentResultsFilter = departmentResultsFilter.filter(
          (r) => r.attributes.DepartmentName === loggedUser.department
        );
      }

      this.setState({
        isLoading: false,
        loggedUser,
        isUserSupervisor,
        searchTerm: searchTerm,
        searchResults: departmentResultsFilter,
      });
    });

    setTimeout(() => {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('hide-footer');
      }
    }, 200);
  }

  componentWillUnmount() {
    const footer = document.getElementById('footer');
    footer.classList.remove('hide-footer');
    this.setState = (state, callback) => {
      return;
    };
  }

  rnderViewDetail = (attr) => {
    // manager
    if (workorderService.GetUserRole() === 'manager') {
      switch (attr.Status) {
        case 'Completed':
          break;
        default:
          return (
            <Link
              className="text-info"
              to={`/apps/workorders/?id=${attr.GlobalID}`}
            >
              <span className="ml-3 pl-2">
                <i className="dripicons-information"></i> View Details
              </span>
            </Link>
          );
      }
    }
    if (
      workorderService.GetUserRole() === 'supervisor' &&
      this.state.loggedUser.department === attr.DepartmentName
    ) {
      switch (attr.Status) {
        case 'Completed':
          break;
        default:
          return (
            <Link
              className="text-info"
              to={`/apps/workorders/?id=${attr.GlobalID}`}
            >
              <span className="ml-3 pl-2">
                <i className="dripicons-information"></i> View Details
              </span>
            </Link>
          );
      }
    }

    // field crew case
    if (
      workorderService.GetUserRole() === 'field' &&
      this.state.loggedUser.department === attr.DepartmentName
    ) {
      switch (attr.Status) {
        case 'Completed':
          break;
        default:
          if (
            attr.AssignedToUID === this.state.loggedUser.id ||
            attr.Status === 'Unassigned'
          ) {
            return (
              <Link
                className="text-info"
                to={`/apps/workorders/?id=${attr.GlobalID}`}
              >
                <span className="ml-3 pl-2">
                  <i className="dripicons-information"></i> View Details
                </span>
              </Link>
            );
          }
          break;
      }
    }
  };

  __renderSearchResult() {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      switch (this.state.searchResults.length) {
        case 0:
          return (
            <div className="text-center">
              <img src={notFoundImg} height="90" alt="" className="mt-4" />
              <h4 className="text-uppercase text-danger mt-3">
                No result found
              </h4>
              <p className="text-muted mt-3">
                There are no result found for search term: "
                <b>{this.state.searchTerm}</b>"
              </p>
              {workorderService.GetUserRole() !== 'manager' && (
                <p className="text-muted font-14">
                  Please note search is being perfomed within the{' '}
                  <b>{this.state.loggedUser.department}</b> department.
                </p>
              )}
            </div>
          );
          break;

        default:
          return (
            <Card>
              <CardBody>
                <h5>
                  Showing {this.state.searchResults.length} result
                  {this.state.searchResults.length > 1 ? 's' : ''}{' '}
                  {/* (0.45 seconds) */}
                  for "{this.state.searchTerm}"{' '}
                  {workorderService.GetUserRole() !== 'manager' && (
                    <span>
                      within the <u>{this.state.loggedUser.department}</u>{' '}
                      department
                    </span>
                  )}
                </h5>
                {/* <p className="text-muted font-14">
                  Add nearly any HTML within, even for linked list groups like
                  the one below, with the help of flexbox utilities.
                </p> */}
                <ListGroup>
                  {this.state.searchResults.map((r, index) => (
                    <ListGroupItem key={index}>
                      <ListGroupItemHeading>
                        {r.attributes['Work Type']} - WOID: {r.attributes.WOID}{' '}
                        {workorderService.GetUserRole() === 'manager' && (
                          <span>
                            in <u>{r.attributes.DepartmentName}</u> department
                          </span>
                        )}
                      </ListGroupItemHeading>
                      <ListGroupItemText>
                        {r.attributes.Description}
                      </ListGroupItemText>
                      <div>
                        <p className="text-muted font-14">
                          Work order created on {r.attributes['Date Initiated']}
                        </p>
                        <p>
                          Created by: <b>{r.attributes.Originator}</b>
                        </p>
                        <p>
                          Assigned to: <b>{r.attributes['Work Assigned to']}</b>
                        </p>
                        <p>
                          Location: <b>{r.attributes['Location Address']}</b> |
                          Field notes:{' '}
                          <span className="text-danger">
                            {r.attributes['Field Notes'] === 'Null' ||
                            r.attributes['Field Notes'] === ''
                              ? 'N/A'
                              : r.attributes['Field Notes']}
                          </span>
                        </p>
                      </div>
                      Status:{' '}
                      {workorderService.renderStatus(r.attributes.Status)}{' '}
                      {r.attributes.Status === 'Completed' ? (
                        <span className="text-muted font-14">
                          {'  '} Work completed on:{' '}
                          {r.attributes['Date Completed']}
                        </span>
                      ) : (
                        ''
                      )}
                      <Link
                        className="text-primary"
                        to={`/print/${r.attributes.GlobalID}`}
                      >
                        <span className="ml-3 pl-2">
                          <i className="dripicons-print"></i> Print
                        </span>
                      </Link>
                      {this.rnderViewDetail(r.attributes)}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          );
          break;
      }
    }
  }

  render() {
    return (
      <Fragment>
        {!this.state.isLoading && (
          <PageTitle
            breadCrumbItems={[
              { label: 'Map', path: '/apps/workorders' },
              { label: 'WOC Search', active: true },
            ]}
            title={'WOC Search results'}
          />
        )}
        {this.__renderSearchResult()}
      </Fragment>
    );
  }
}

export default SrcSearch;
