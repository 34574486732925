import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit';
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import swal from 'sweetalert';
import { getLoggedInUser } from '../../../helpers/authUtils';
import {
  CrudOperations,
  GlobalFormatter,
} from '../../../services/CRUDoperations.service';
import { workorderService } from '../../../services/workorder.service';
import PageTitle from '../../PageTitle';
import Spinner from '../../Spinner';

const Entity = 'Resources';

class ResourcesManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Department: '',
      resources: [],
      departments: [],
      isLoading: true,
      newModal: false,
      existingModal: false,
      name: '',
      type: '',
      description: '',
      rate: 0,
      resourceID: 0,
      departmentID: 0,
      GISAssets: [],
      assetsOptions: [],
      selectedAsset: 0,
      selectedResource: null,
      currentLevel:0,
      criticalLevel: 0,
    };
    this.toggleNewModal = this.toggleNewModal.bind(this);
    this.toggleExistingModal = this.toggleExistingModal.bind(this);
  }

  /**
   * Toggles the new department modal
   */
  toggleNewModal = () => {
    this.setState((prevState) => ({
      newModal: !prevState.newModal,
    }));
  };

  toggleExistingModal = () => {
    this.setState((prevState) => ({
      existingModal: !prevState.existingModal,
    }));
  };

  __getGISAssets = async (department) => {
    const user_role = workorderService.GetUserRole();
    const { data } = await CrudOperations.Get('GisConfiguration');
    var __gis__assets = data;
    if (user_role === 'supervisor') {
      __gis__assets = __gis__assets.filter(
        (s) => s.departmentID === department.departmentID
      );
    }
    //console.log(__gis__assets);
    this.setState({ GISAssets: __gis__assets });
  };

  __setAssetsOptions = () => {
    const { assetsOptions } = this.state;
    if (this.state.GISAssets.length > 0) {
      this.state.GISAssets.map((s) => {
        assetsOptions.push({
          value: s.gisServicesConfigurationID,
          label: s.name,
        });
      });
    }

    setTimeout(() => {
      this.setState({ assetsOptions });
    }, 20);
  };

  componentDidMount() {
    setTimeout(() => {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('hide-footer');
      }
    }, 300);
    const loggedUser = getLoggedInUser();
    CrudOperations.Get('Departments').then((res) => {
      const department = res.data.find((d) => d.name === loggedUser.department);

      this.__getGISAssets(department);

      this.setState({
        departments: res.data,
        Department: department.name,
        departmentID: department.departmentID,
      });
      setTimeout(() => {
        this.__getResources();
      }, 50);
    });
  }

  componentWillUnmount() {
    const footer = document.getElementById('footer');
    footer.classList.remove('hide-footer');

    this.setState = (state, callback) => {
      return;
    };
  }
  __getResources = () => {
    const user_role = workorderService.GetUserRole();
    CrudOperations.Get(Entity)
      .then((res) => {
        var __resources = res.data;
        if (user_role === 'supervisor') {
          __resources = __resources.filter(
            (r) =>
              r.resourceAsset.getGISService.departmentID ===
              this.state.departmentID
          );
        }
        //console.log(__resources);
        this.__setResources(__resources);
      })
      .catch((err) => {
        console.log('Error fetching resources for manager :: ', err);
      });
  };

  __setResources = (resources) => {
    let newResources = [];
    resources.map((r) => {
      const { name, rate, resourceID, type, resourceAsset, currentLevel, criticalLevel } = r;
      newResources.push({
        name,
        rate,
        resourceID,
        type,
        resourceAsset,
        currentLevel,
        criticalLevel,
      });
    });
    this.setState({ resources: newResources, isLoading: false });
  };

  newModel = () => {
    this.setState({
      newModal: true,
    });
  };

  EditResource = (resObj) => {
    const { resourceID, resourceAsset, name, type, rate, currentLevel, criticalLevel } = resObj;
    // console.log(resourceAsset);
    this.setState({
      resourceID,
      //departmentID: 0,
      name,
      type,
      rate,
      currentLevel,
      criticalLevel,
      selectedAsset: resourceAsset.gisServicesConfigurationID,
      selectedResource: resObj,
      existingModal: true,
    });
  };

  DeleteResource = (resObj) => {
    swal({
      text: `You are about to delete Resource [${resObj.name}]. Please note if this Resource has been linked to a work order, it will not be deleted. Are you sure to delete?`,
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then((action) => {
      if (action) {
        CrudOperations.VerifyItemUsedForDeletion(
          resObj.resourceID,
          'resources'
        ).then((result) => {
          if (result.data) {
            swal(
              `Resource [${resObj.name}] has been assigned to work order and cannot be deleted.`
            );
          } else {
            CrudOperations.Delete(Entity, resObj.resourceID)
              .then((res) => {
                this.__getResources(this.state.departmentID);
                swal(`Resource [${resObj.name}] deleted successfully.`);
              })
              .catch((err) => {
                swal(
                  `Unable to delete Resource [${resObj.name}]. Unexpected result.`
                );
                console.log('Error :: ', err);
              });
          }
        });
      }
    });
  };

  handleEdit = (event, values) => {
    //console.log(values);
    const { name, type, rate, currentLevel, criticalLevel } = values;

    const { resourceAsset } = this.state.selectedResource;

    let _ResourceObj = {
      resourceID: resourceAsset.resourceID,
      name,
      type,
      rate,
      currentLevel,
      criticalLevel,
      resourceAsset,
    };
    CrudOperations.Update(Entity, _ResourceObj)
      .then((res) => {
        this.__getResources();
        this.toggleExistingModal();
        swal(`Resource ${name} updated successfully.`);
      })
      .catch((err) => {
        swal('Unable to update resource at this time');
        console.log('Error updating resource :: ', err);
      });
  };

  handleNew = (event, values) => {
    //console.log(values);
    const { assetID, name, type, rate, currentLevel, criticalLevel } = values;
    let NewResourceObj = {
      name,
      type,
      rate,
      currentLevel,
      criticalLevel,
      resourceAsset: {
        gisServicesConfigurationID: assetID,
      },
    };

    CrudOperations.Create(Entity, NewResourceObj)
      .then((res) => {
        if (res.data) {
          this.__getResources();
          this.toggleNewModal();
          swal('Resource created successfully!');
        } else {
          swal(
            `This resource is being already used in the system and cannot be deleted`
          );
        }
      })
      .catch((err) => {
        swal('Something went wrong!');
        console.log('Error :: ', err);
      });
  };

  _renderDepartmentOptions() {
    return (
      <Fragment>
        {this.state.departments.map((d, index) => {
          return (
            <option
              key={`dept-${index}`}
              value={d.departmentID}
              key={index + 1}
            >
              {d.name}
            </option>
          );
        })}
      </Fragment>
    );
  }

  __getDepartmentName = (departmentID) => {
    const dept = this.state.departments.find(
      (d) => d.departmentID === departmentID
    );
    return dept.name;
  };

  departmentFormatter = (resourceID, resourceObj) => {
    return this.__getDepartmentName(resourceObj.departmentID);
  };
  
  editsFormatter = (resourceID, resourceObj) => {
    return (
      <div>
        <a href="#" onClick={() => this.EditResource(resourceObj)}>
          Edit
        </a>
        <a
          className="ml-3"
          href="#"
          onClick={() => this.DeleteResource(resourceObj)}
        >
          Delete
        </a>
      </div>
    );
  };

  __renderResources() {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      const TableWithSearch = () => {
        const { SearchBar } = Search;
        const { ExportCSVButton } = CSVExport;
        const defaultSorted = [
          {
            dataField: 'name',
            order: 'asc',
          },
        ];

        const columns = [
          {
            dataField: 'resourceID',
            text: '',
            formatter: this.editsFormatter,
          },
          {
            dataField: 'name',
            text: 'Resource',
            sort: true,
          },
          {
            dataField: 'type',
            text: 'Type',
            sort: true,
          },
          {
            dataField: 'resourceAsset.getGISService.name',
            text: 'Asset',
            sort: true,
          },
          {
            dataField: 'rate',
            text: 'Rate',
            sort: true,
            formatter: (cell) => {
              return GlobalFormatter.FormatUSD(cell);
            },
          },
          {
            dataField: 'currentLevel',
            text: 'Current Level',
            sort: true,
          },
          {
            dataField: 'criticalLevel',
            text: 'Critical Level',
            sort: true,
          },
        ];

        return (
          <Card>
            <CardBody>
              {/* <h4 className="header-title">
                Department: <b>{this.state.Department}</b>
              </h4> */}
              <p className="text-muted font-14 mb-4">
                Filter or export resources data in CSV format
              </p>

              <ToolkitProvider
                bootstrap4
                keyField="resourceID"
                data={this.state.resources}
                columns={columns}
                search
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
              >
                {(props) => (
                  <React.Fragment>
                    <Row>
                      <Col>
                        <SearchBar {...props.searchProps} />
                      </Col>
                      <Col className="text-right">
                        <ExportCSVButton
                          {...props.csvProps}
                          className="btn btn-primary"
                        >
                          Export CSV
                        </ExportCSVButton>
                      </Col>
                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      bordered={true}
                      defaultSorted={defaultSorted}
                      paging={false} // {paginationFactory({ sizePerPage: 10 })}
                      wrapperClasses="table-responsive table-sm"
                      striped={true}
                      hover={true}
                      keyField="resourceID"
                    />
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        );
      };

      return <TableWithSearch />;
    }
  }

  render() {
    return (
      <Fragment>
        <div>
          <div className="main-content-wrapper-scrollable">
            <div style={{ clear: 'both' }}></div>
            <div>
              <PageTitle
                breadCrumbItems={[
                  { label: 'Map', path: '/apps/workorders' },
                  { label: 'Resources', active: true },
                ]}
                title={'Resources Management'}
              />
              <Button
                color="danger"
                className="btn-rounded mb-3"
                onClick={this.newModel}
              >
                <i className="mdi mdi-plus"></i> Create Resource
              </Button>
              <div>{this.__renderResources()}</div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.newModal}
          toggle={this.toggleNewModal}
          /* size="lg" */
        >
          <ModalHeader toggle={this.toggleNewModal}>
            Create New Resource
          </ModalHeader>
          <ModalBody>
            <AvForm className="p-2" onValidSubmit={this.handleNew}>
              <AvField
                name="assetID"
                label="Asset"
                type="select"
                className="custom-select"
                required
              >
                <option value="0">Select</option>
                {this.state.GISAssets.map((a, idx) => (
                  <option key={idx} value={a.gisServicesConfigurationID}>
                    {a.name}
                  </option>
                ))}
              </AvField>
              <Row>
                <Col md={8}>
                  <AvField
                    name="name"
                    label="Resource name"
                    type="text"
                    required
                  />
                </Col>
                <Col md={4}>
                  <AvField name="rate" label="Rate" type="text" required />
                </Col>
              </Row>

              <AvField
                name="type"
                label="Resource type"
                type="select"
                value={'Equipment'}
                required
                className="custom-select"
              >
                <option value="Equipment">Equipment</option>
                <option value="Material">Material</option>
                <option value="Labor">Labor</option>
                <option value="Other">Other</option>
              </AvField>
              <Row>
                <Col md={4}>
                <AvField name="currentLevel" label="Current Level" type="text" value = "-1" required />
                </Col>
                <Col md={4}>
                  <AvField name="criticalLevel" label="Critical Level*" type="text" value = "-1" required />
                </Col>
              </Row>

              {/* <AvField
                name="departmentID"
                label="Department"
                type="select"
                value={this.state.departmentID}
                required
                className="custom-select"
                disabled={workorderService.GetUserRole() !== 'manager'}
              >
                <option>Select</option>
                {this._renderDepartmentOptions()}
              </AvField> */}

              <div className="text-right">
                <Button
                  color="light"
                  type="button"
                  className="mr-1"
                  onClick={this.toggleNewModal}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Create
                </Button>
              </div>
            </AvForm>
          </ModalBody>
            * - Keep at '-1' to ignore inventory tracking
        </Modal>

        {/* Modal Edit */}
        <Modal
          isOpen={this.state.existingModal}
          toggle={this.toggleExistingModal}
          /* size="lg" */
        >
          <ModalHeader toggle={this.toggleExistingModal}>
            Edit Resource
          </ModalHeader>
          <ModalBody>
            <AvForm className="p-2" onValidSubmit={this.handleEdit}>
              <Row>
                <Col md={8}>
                  <AvField
                    name="name"
                    label="Resource name"
                    type="text"
                    value={this.state.name}
                    required
                  />
                </Col>
                <Col md={4}>
                  <AvField
                    name="rate"
                    label="Rate"
                    type="text"
                    value={this.state.rate}
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <AvField
                    name="currentLevel"
                    label="Current Level"
                    type="text"
                    value={this.state.currentLevel}
                  />
                </Col>
                <Col md={4}>
                  <AvField
                    name="criticalLevel"
                    label="Critical Level"
                    type="text"
                    value={this.state.criticalLevel}
                  />
                </Col>
              </Row>

              <AvField
                name="assetID"
                label="Asset"
                type="select"
                className="custom-select"
                value={this.state.selectedAsset}
                disabled
              >
                <option value="0">Select</option>
                {this.state.GISAssets.map((a, idx) => (
                  <option key={idx} value={a.gisServicesConfigurationID}>
                    {a.name}
                  </option>
                ))}
              </AvField>
              <small
                className="form-text text-muted"
                style={{ marginTop: '-10px' }}
              >
                The Asset cannot be changed.
              </small>
              <br />
              <AvField
                className="mt-2"
                name="type"
                label="Resource type"
                type="select"
                value={this.state.type}
                required
                disabled
                className="custom-select"
              >
                <option value="Equipment">Equipment</option>
                <option value="Material">Material</option>
                <option value="Labor">Labor</option>
                <option value="Other">Other</option>
              </AvField>
              <small
                className="form-text text-muted"
                style={{ marginTop: '-10px', marginBottom: '10px' }}
              >
                The Resource type cannot be changed.
              </small>

              <div className="text-right">
                <Button
                  color="light"
                  type="button"
                  className="mr-1"
                  onClick={this.toggleExistingModal}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default ResourcesManagement;
