import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Row,
} from 'reactstrap';
import logo from '../../assets/images/auth-logo.png';
import errorImg from '../../assets/images/icon-error.png';
import LoaderWidget from '../../components/Loader';
import { isUserAuthenticated } from '../../helpers/authUtils';
import { resetPassword } from '../../redux/actions';

class ResetPassword extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.state = { code: '', isValidToken: false };
  }

  componentDidMount() {
    this._isMounted = true;
    const _pw_reset_code = new URLSearchParams(window.location.search).get(
      'code'
    );
    if (_pw_reset_code) {
      this.setState({ code: _pw_reset_code, isValidToken: true });
    } else {
      // redirect to login
    }

//    console.log(this.props);  CHANGED 10/28/2022 DMM
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Handles the submit
   */
  handleValidSubmit = (event, values) => {
    //Update password;
    this.props.resetPassword(values.email, values.password, this.state.code);
  };

  render() {
    const isAuthTokenValid = isUserAuthenticated();
    return (
      <React.Fragment>
        {(this._isMounted || !isAuthTokenValid) && (
          <div className="account-pages mt-5 mb-5">
            <Container>
              <Row className="justify-content-center">
                <Col lg={5}>
                  <Card>
                    <div className="card-header pt-4 pb-4 text-center bg-primary">
                      <Link to="/">
                        <span>
                          <img src={logo} alt="" height="60" />
                        </span>
                      </Link>
                    </div>

                    <CardBody className="p-4 position-relative">
                      {/* preloader */}
                      {this.props.loading && <LoaderWidget />}

                      {this.state.isValidToken && (
                        <Fragment>
                          <div className="text-center w-75 m-auto">
                            <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                              Reset Password
                            </h4>
                            <p className="text-muted mb-4">
                              Enter your email address and provide your new
                              password
                            </p>
                          </div>

                          {this.props.passwordResetStatus && (
                            <Alert
                              color="success"
                              isOpen={
                                this.props.passwordResetStatus ? true : false
                              }
                            >
                              {this.props.passwordResetStatus}
                            </Alert>
                          )}

                          {this.props.error && (
                            <Alert
                              color="danger"
                              isOpen={this.props.error ? true : false}
                            >
                              {this.props.error}
                            </Alert>
                          )}
                          <AvForm onValidSubmit={this.handleValidSubmit}>
                            <AvField
                              type="email"
                              name="email"
                              label="Your Email"
                              placeholder="Enter your email"
                              required
                              autocomplete="email"
                            />
                            <AvField
                              type="password"
                              name="password"
                              label="Your new password"
                              placeholder="Enter your password"
                              required
                            />
                            <AvField
                              type="password"
                              name="confirmPassword"
                              label="Confirm password"
                              placeholder="Confirm password"
                              required
                              validate={{ match: { value: 'password' } }}
                            />
                            <FormGroup>
                              <Button color="success">Submit</Button>
                            </FormGroup>
                          </AvForm>
                        </Fragment>
                      )}

                      {!this.state.isValidToken && (
                        <Fragment>
                          <div className="text-center">
                            <img src={errorImg} alt="" height="54" />
                          </div>
                          <h4 className="text-dark-50 text-center mt-4 font-weight-bold">
                            Invalid Token
                          </h4>
                          <p className="text-muted mb-4">
                            Please check your inbox for password reset email
                            instructions
                          </p>
                        </Fragment>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row className="mt-1">
                <Col className="col-12 text-center">
                  <p className="text-muted">
                    Back to{' '}
                    <Link to="/account/login" className="text-muted ml-1">
                      <b>Sign In</b>
                    </Link>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { passwordResetStatus, loading, error } = state.Auth;
  return { passwordResetStatus, loading, error };
};

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
