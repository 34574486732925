import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation';
import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit';
import {
  Button,
  Card,
  CardBody,
  Col,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import swal from 'sweetalert';
import { getLoggedInUser } from '../../../helpers/authUtils';
import { CrudOperations } from '../../../services/CRUDoperations.service';
import { workorderService } from '../../../services/workorder.service';
import PageTitle from '../../PageTitle';
import Spinner from '../../Spinner';

const Entity = 'Services';

class ServicesManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      departments: [],
      isLoading: true,
      newModal: false,
      existingModal: false,
      name: '',
      description: '',
      workServiceID: 0,
      Department: '',
      departmentID: 0,
      essentialQuestions: [],
      GISAssets: [],
      assetsOptions: [],
      selectedAsset: 0,
      selectedAssetObj: null,
    };
    this.toggleNewModal = this.toggleNewModal.bind(this);
    this.toggleExistingModal = this.toggleExistingModal.bind(this);
  }

  /**
   * Toggles the new department modal
   */
  toggleNewModal = () => {
    this.setState((prevState) => ({
      newModal: !prevState.newModal,
    }));
  };

  toggleExistingModal = () => {
    this.setState((prevState) => ({
      existingModal: !prevState.existingModal,
    }));
  };

  __getGISAssets = async (department) => {
    const user_role = workorderService.GetUserRole();
    const { data } = await CrudOperations.Get('GisConfiguration');
    var __gis__assets = data;
    if (user_role === 'supervisor') {
      __gis__assets = __gis__assets.filter(
        (s) => s.departmentID === department.departmentID
      );
    }
    //console.log(__gis__assets);
    this.setState({ GISAssets: __gis__assets });
  };

  __setAssetsOptions = () => {
    const { assetsOptions } = this.state;
    if (this.state.GISAssets.length > 0) {
      this.state.GISAssets.map((s) => {
        assetsOptions.push({
          value: s.gisServicesConfigurationID,
          label: s.name,
        });
      });
    }

    setTimeout(() => {
      this.setState({ assetsOptions });
    }, 20);
  };

  componentDidMount() {
    setTimeout(() => {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('hide-footer');
      }
    }, 300);
    
    const loggedUser = getLoggedInUser();

    CrudOperations.Get('Departments').then((res) => {
      const userDepartment = res.data.find(
        (d) => d.name === loggedUser.department
      );

      this.__getGISAssets(userDepartment);

      this.setState({
        departments: res.data,
        Department: userDepartment.name,
        departmentID: userDepartment.departmentID,
      });
      setTimeout(() => {
        this.__getServices();
      }, 50);
    });
  }

  componentWillUnmount() {
    const footer = document.getElementById('footer');
    footer.classList.remove('hide-footer');

    this.setState = (state, callback) => {
      return;
    };
  }

  __getServices = () => {
    const user_role = workorderService.GetUserRole();
    CrudOperations.Get(Entity)
      .then((res) => {
        var __services = res.data;
        if (user_role === 'supervisor') {
          __services = __services.filter(
            (s) =>
              s.gisService.getGISService.departmentID ===
              this.state.departmentID
          );
        }
        //console.log(__services);
        this.setState({ services: __services, isLoading: false });
      })
      .catch((err) => {
        console.log('Error fetching services :: ', err);
        this.setState({ isLoading: false });
      });
  };

  updateResourcesArrayWithDepartmentName = (servicesArray) => {
    let services = [];
    servicesArray.map((s) => {
      const {
        departmentID,
        name,
        workServiceID,
        description,
        essentialQuestions,
      } = s;
      services.push({
        departmentID,
        name,
        workServiceID,
        description,
        department: this.__getDepartmentName(departmentID),  //maybe here
        essentialQuestions,
      });
    });
    this.setState({ services, isLoading: false });
  };

  newModel = () => {
    this.setState({
      essentialQuestions: [],
      newModal: true,
    });
  };

  EditService = (resObj) => {
    const { workServiceID, name, description, essentialQuestions } = resObj;
    this.setState({
      workServiceID,
      name,
      description,
      essentialQuestions,
      existingModal: true,
      selectedAsset: resObj.gisService.gisServicesConfigurationID,
      selectedAssetObj: resObj,
    });
  };
  DeleteService = (servObj) => {
    swal({
      text: `You are about to delete Service [${servObj.name}]. Please note if this Service has been linked to a work order, it will not be deleted. Are you sure to delete? `,
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then((action) => {
      if (action) {
        CrudOperations.VerifyItemUsedForDeletion(
          servObj.workServiceID,
          'services'
        ).then((result) => {
          if (result.data) {
            swal(
              `Service [${servObj.name}] has been assigned to work order and cannot be deleted.`
            );
          } else {
            CrudOperations.Delete(Entity, servObj.workServiceID)
              .then((res) => {
                this.__getServices(this.state.departmentID);
                swal(`Service [${servObj.name}] deleted successfully.`);
              })
              .catch((err) => {
                swal(
                  `Unable to delete Service [${servObj.name}]. Unexpected result.`
                );
                console.log('Error :: ', err);
              });
          }
        });
      }
    });
  };

  handleEdit = (event, values) => {
    const { name, description } = values;
    //console.log(this.state.selectedAssetObj);

    let _updatedObj = {
      workServiceID: this.state.workServiceID,
      departmentID:
        this.state.selectedAssetObj.gisService.getGISService.departmentID,
      name,
      description,
      gisService: this.state.selectedAssetObj.gisService,
      essentialQuestions: this.__getEssentialQuestions(values),
    };

    //console.log(_updatedObj);

    CrudOperations.Update(Entity, _updatedObj)
      .then((res) => {
        this.__getServices(this.state.departmentID);
        this.toggleExistingModal();
        swal(`Service ${name} updated successfully.`);
      })
      .catch((err) => {
        swal('Unable to update service at this time');
        console.log('Error updating resource :: ', err);
      });
  };

  __getEssentialQuestions = (values) => {
    let essentialQuestions = [];
    if (this.state.essentialQuestions.length > 0) {
      for (
        let index = 0;
        index < this.state.essentialQuestions.length;
        index++
      ) {
        Object.keys(values).forEach((e) => {
          switch (e) {
            case `question-${index}`:
              // check for prop essentialQuestionID for existing question
              if (
                this.state.essentialQuestions[index].hasOwnProperty(
                  'essentialQuestionID'
                )
              ) {
                const essentialQuestionObj =
                  this.state.essentialQuestions[index];
                essentialQuestions.push({
                  essentialQuestionID: essentialQuestionObj.essentialQuestionID,
                  question: values[e],
                });
              } else {
                essentialQuestions.push({
                  question: values[e],
                });
              }

              break;
            default:
              break;
          }
        });
      }
    }
    return essentialQuestions;
  };

  handleNew = (event, values) => {
    //console.log(values);
    const { assetID, name, description } = values;
    let _newService = {
      //assetID: parseInt(assetID),
      name,
      description,
      essentialQuestions: this.__getEssentialQuestions(values),
      gisService: {
        //workTypeAssetID: 0,
        //workServiceID: 0,
        gisServicesConfigurationID: parseInt(assetID),
      },
    };
    CrudOperations.Create(Entity, _newService)
      .then((res) => {
        this.__getServices(this.state.departmentID);
        this.toggleNewModal();
        swal('Service created successfully!');
      })
      .catch((err) => {
        swal('Something went wrong!');
        console.log('Error :: ', err);
      });
  };

  _renderDepartmentOptions() {
    return (
      <Fragment>
        {this.state.departments.map((d, index) => {
          return (
            <option
              key={`dept-${index}`}
              value={d.departmentID}
              key={index + 1}
            >
              {d.name}
            </option>
          );
        })}
      </Fragment>
    );
  }

  __getDepartmentName = (departmentID) => {
    const dept = this.state.departments.find(
      (d) => d.departmentID === departmentID
    );
    return dept.name;
  };

  departmentFormatter = (resourceID, resourceObj) => {
    return this.__getDepartmentName(resourceObj.departmentID);
  };
  editsFormatter = (resourceID, resourceObj) => {
    return (
      <div>
        <a href="#" onClick={() => this.EditService(resourceObj)}>
          Edit
        </a>
        <a
          className="ml-3"
          href="#"
          onClick={() => this.DeleteService(resourceObj)}
        >
          Delete
        </a>
      </div>
    );
  };

  AddNewEssentialQuestion = () => {
    const essentialQuestions = this.state.essentialQuestions;
    /* const _question = {
      question: ''
    }; */
    essentialQuestions.push(`question-${essentialQuestions.length}`);
    this.setState({ essentialQuestions });
  };

  RemoveFromAddedQuestions = (index) => {
    const essentialQuestions = this.state.essentialQuestions;
    essentialQuestions.splice(index, 1);
    this.setState({ essentialQuestions });
  };

  renderEssentialQuestions() {
    return (
      <Fragment>
        {this.state.essentialQuestions.map((q, index) => (
          <AvGroup key={`av-${index}`}>
            <Label for={`question-${index}`}>Detail {index + 1}</Label>
            <div className="input-group">
              <InputGroupAddon
                style={{ cursor: 'pointer' }}
                title={'delete'}
                addonType="prepend"
                onClick={() => this.RemoveFromAddedQuestions(index)}
              >
                X
              </InputGroupAddon>
              <AvInput
                placeholder="enter question"
                name={`question-${index}`}
                value={q.question}
                required
              />
              <AvFeedback>Please provide required details.</AvFeedback>
            </div>
          </AvGroup>
        ))}
      </Fragment>
    );
  }

  __renderServices() {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      const TableWithSearch = () => {
        const { SearchBar } = Search;
        const { ExportCSVButton } = CSVExport;
        const defaultSorted = [
          {
            dataField: 'name',
            order: 'asc',
          },
        ];

        const columns = [
          {
            dataField: 'workServiceID',
            text: '',
            formatter: this.editsFormatter,
          },
          {
            dataField: 'name',
            text: 'Type',
            sort: true,
          },
          {
            dataField: 'gisService.getGISService.name',
            text: 'Asset',
            sort: true,
          },
          {
            dataField: 'description',
            text: 'Description',
            sort: false,
          },
        ];

        const expandRow = {
          renderer: (row) => (
            <div>
              <h5>
                Essential Detail{row.essentialQuestions.length > 1 ? 's' : ''}
              </h5>
              <ol className="list-group list-group-numbered">
                {row.essentialQuestions.map((e, index) => (
                  <li
                    key={`es-${index}`}
                    className="list-group-item d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">{e.question}</div>
                    </div>
                    {/* <span className="badge bg-primary rounded-pill">14</span> */}
                  </li>
                ))}
              </ol>
            </div>
          ),
        };

        return (
          <Card>
            <CardBody>
              {/* <h4 className="header-title">
                Department: <b>{this.state.Department}</b>
              </h4> */}
              <p className="text-muted font-14 mb-4">
                Filter or export services data in CSV format. Click on the row
                to view Essential Details if any.
              </p>

              <ToolkitProvider
                bootstrap4
                keyField="workServiceID"
                data={this.state.services}
                columns={columns}
                search
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
              >
                {(props) => (
                  <React.Fragment>
                    <Row>
                      <Col>
                        <SearchBar {...props.searchProps} />
                      </Col>
                      <Col className="text-right">
                        <ExportCSVButton
                          {...props.csvProps}
                          className="btn btn-primary"
                        >
                          Export CSV
                        </ExportCSVButton>
                      </Col>
                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      keyField="workServiceID"
                      bordered={true}
                      defaultSorted={defaultSorted}
                      paging={false} // {paginationFactory({ sizePerPage: 10 })}
                      wrapperClasses="table-responsive table-sm"
                      striped={true}
                      hover={true}
                      expandRow={expandRow}
                    />
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        );
      };

      return <TableWithSearch />;
    }
  }

  render() {
    return (
      <Fragment>
        <div>
          <div className="main-content-wrapper-scrollable1">
            <div style={{ clear: 'both' }}></div>
            <div>
              <PageTitle
                breadCrumbItems={[
                  { label: 'Map', path: '/apps/workorders' },
                  { label: 'Work Types', active: true },
                ]}
                title={'Work types Management'}
              />
              <Button
                color="danger"
                className="btn-rounded mb-3"
                onClick={this.newModel}
              >
                <i className="mdi mdi-plus"></i> Create Work Type
              </Button>
              <div>{this.__renderServices()}</div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.newModal}
          toggle={this.toggleNewModal}
          size="lg"
        >
          <ModalHeader toggle={this.toggleNewModal}>
            Create New Work type
          </ModalHeader>
          <ModalBody>
            <AvForm className="p-2" onValidSubmit={this.handleNew}>
              <Row>
                <Col md={4}>
                  <AvField
                    name="assetID"
                    label="Asset"
                    type="select"
                    className="custom-select"
                    required
                  >
                    <option value="0">Select</option>
                    {this.state.GISAssets.map((a, idx) => (
                      <option key={idx} value={a.gisServicesConfigurationID}>
                        {a.name}
                      </option>
                    ))}
                  </AvField>
                </Col>
                <Col md={8}>
                  <AvField name="name" label="Type" type="text" required />
                </Col>
              </Row>
              <AvField
                name="description"
                label="Description"
                type="textarea"
                required
              />

              {this.renderEssentialQuestions()}

              <Button
                color="info"
                type="button"
                className="mr-1"
                onClick={this.AddNewEssentialQuestion}
              >
                Add Essential Detail
              </Button>

              <div className="text-right">
                <Button
                  color="light"
                  type="button"
                  className="mr-1"
                  onClick={this.toggleNewModal}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Create
                </Button>
              </div>
            </AvForm>
          </ModalBody>
        </Modal>

        {/* Modal Edit */}
        <Modal
          isOpen={this.state.existingModal}
          toggle={this.toggleExistingModal}
          size="lg"
        >
          <ModalHeader toggle={this.toggleExistingModal}>
            Edit Work Type
          </ModalHeader>
          <ModalBody>
            <AvForm className="p-2" onValidSubmit={this.handleEdit}>
              <Row>
                <Col md={4}>
                  <AvField
                    name="assetID"
                    label="Asset"
                    type="select"
                    className="custom-select"
                    value={this.state.selectedAsset}
                    disabled
                  >
                    <option value="0">Select</option>
                    {this.state.GISAssets.map((a, idx) => (
                      <option key={idx} value={a.gisServicesConfigurationID}>
                        {a.name}
                      </option>
                    ))}
                  </AvField>
                  <small
                    className="form-text text-muted"
                    style={{ marginTop: '-10px' }}
                  >
                    The Asset cannot be changed.
                  </small>
                </Col>
                <Col md={8}>
                  <AvField
                    name="name"
                    label="Type"
                    type="text"
                    value={this.state.name}
                    required
                  />
                </Col>
              </Row>
              <AvField
                name="description"
                label="Description"
                type="textarea"
                value={this.state.description}
                required
              />

              {this.renderEssentialQuestions()}

              <Button
                color="info"
                type="button"
                className="mr-1"
                onClick={this.AddNewEssentialQuestion}
              >
                Add Essential Detail
              </Button>
              <div className="text-right">
                <Button
                  color="light"
                  type="button"
                  className="mr-1"
                  onClick={this.toggleExistingModal}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default ServicesManagement;
