import React, { Fragment } from 'react';
import notFoundImg from '../../assets/images/file-searching.svg';

const NoWorkOrders = () => {
  return (
    <Fragment>
      <div className="text-center">
        <img src={notFoundImg} height="90" alt="" className="mt-4" />
        {/* <h3 className="text-error mt-4">404</h3> */}
        <h4 className="text-uppercase text-danger mt-3">
          No Work Orders found
        </h4>
        <p className="text-muted mt-3">
          There are no work orders at this time.
        </p>
      </div>
    </Fragment>
  );
};
export default NoWorkOrders;
