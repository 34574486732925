import { SET_CONFIG, CONFIG_FETCH } from './constants';

type Action = { type: string, payload: {} };

export const initialState = {
  loaded: false,
};

const AppConfig = (state = initialState, action) => {
  const { config } = action;
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        config,
      };
    default:
      return state;
  }
};

export default AppConfig;
