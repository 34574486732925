import { getLoggedInUser } from '../../helpers/authUtils';
import { workorderService } from '../../services/workorder.service';

var mapview;
var workOrderLayerView;
var workOrdersBatchList = [];
var userAssets = [];
var userRole;

export const WorkOrderFilter = {
  SetUserRole: (role) => {
    userRole = role;
  },
  SetMapView: (view) => {
    mapview = view;
  },
  SetWorkOrderLayerView: (layerView) => {
    workOrderLayerView = layerView;
  },
  SetWorkOrdersBatchList: (workorders) => {
    workOrdersBatchList = workorders;
  },
  SetUserAssets: (assets) => {
    userAssets = assets;
  },
  FilterActiveWorkByUser: (
    department,
    selectedAsset,
    userType,
    userUID,
    userAssets
  ) => {
    if (workOrderLayerView) {  //FILTER WORKORDERS BY USER ROLE FOR MAP
      switch (userType) {
        case 'field':  // SHOW NON-COMPLETE WORK ORDERS WHICH ARE MINE OR ARE UNASSIGNED IN MY DEPARTMENT
//        let __where = `(AssignedToUID in ('${userUID}') OR ((Status = 'Unassigned' and DepartmentName in ('${department}'))))`;

/*          let __where = `(Status <> 'Completed') AND (AssignedToUID in ('${userUID}') OR ((Status = 'Unassigned' and DepartmentName in ('${department}'))))`;
          workOrderLayerView.filter = {
            where: __where.trim(),
          };  */
        if (selectedAsset === '') {
          if (userAssets.length > 0) {
//            let __where = `(Status <> 'Completed') AND (`;
//            let __where = `(Status <> 'Completed') AND (AssignedToUID in ('${userUID}') OR `;
              let __where = `(Status <> 'Completed') AND ((AssignedToUID = '${userUID}') OR `;
              userAssets.map((a, idx) => {
              const __user__asset = a.gisServicesUsersConfigurations.find(
                (u) => u.userID === userUID
              );
              if (__user__asset) {
                __where = __where + `((Status = 'Unassigned') AND (AssetName in ('${a.name}'))) OR `;
              }
            });

            __where = __where.substring(0, __where.lastIndexOf('OR '));
            __where = __where + `)`;

//            __where = __where + ` AND AssignedToUID in ('${userUID}')`;

            workOrderLayerView.filter = {
              where: __where.trim(),
            };
          } else {
            // don't show
//            let __where = `(Status <> 'Completed') AND (AssignedToUID in ('${userUID}') OR ((Status = 'Unassigned' and Department in ('${department}'))))`;
            let __where = `(Status <> 'Completed') AND (AssignedToUID = '${userUID}')`;
            workOrderLayerView.filter = {
              where: __where.trim(),
            };
          }
         } else {
            let __where = `(Status <> 'Completed') AND (AssignedToUID in ('${userUID}') OR (Status = 'Unassigned' and AssetName in ('${selectedAsset}')))`;
            workOrderLayerView.filter = {
              where: __where.trim(),
            };
          }
          break;
        case 'supervisor':  //SHOW NON-COMPLETE WORK ORDERS IN MY DEPARTMENT
          if (selectedAsset === '') {
            workOrderLayerView.filter = {
              where: `DepartmentName in ('${department}') AND Status <> 'Completed'`,
            };
          } else {
            workOrderLayerView.filter = {
              where: `DepartmentName in ('${department}') AND AssetName in ('${selectedAsset}') AND Status <> 'Completed'`,
            };
          }
          break;
        default:
          if (selectedAsset === '') {
            workOrderLayerView.filter = {
              where: `Status <> 'Completed'`,
            };
          } else {
            workOrderLayerView.filter = {
              where: `AssetName in ('${selectedAsset}') AND Status <> 'Completed'`,
            };
          }
          break;
      }
    }
  },
  
  FilterCompletedWork: async (department, selectedAsset, userType, userUID) => {
    if (workOrderLayerView) {
      switch (userType) {
        case 'field':
          if (selectedAsset === '') {
            workOrderLayerView.filter = {
              where: `Status <> 'Completed' AND AssignedToUID in ('${userUID}') OR Status = 'Unassigned'`,
            };
          } else {
            workOrderLayerView.filter = {
              where: `Status <> 'Completed' AND AssignedToUID in ('${userUID}') OR Status = 'Unassigned' AND AssetName in ('${selectedAsset}')`,
            };
          }
          break;
        case 'supervisor':
          if (selectedAsset === '') {
            workOrderLayerView.filter = {
              where: `DepartmentName in ('${department}') AND Status <> 'Completed'`,
            };
          } else {
            workOrderLayerView.filter = {
              where: `DepartmentName in ('${department}') AND AssetName in ('${selectedAsset}') AND Status <> 'Completed'`,
            };
          }
          break;
        default:
          if (selectedAsset === '') {
            workOrderLayerView.filter = {
              where: `Status <> 'Completed'`,
            };
          } else {
            workOrderLayerView.filter = {
              where: `AssetName in ('${selectedAsset}') AND Status <> 'Completed'`,
            };
          }
          break;
      }
    }
  },
  FilterUserWorkAndShowCompletedFieldUser: (
    department,
    selectedAsset,
    workType,
    userUID
  ) => {
    if (workOrderLayerView) {
      const filterObj = {
        AssetName: selectedAsset,
        WorkType: workType,
        //AssignedToUID: userUID,
      };
      WorkOrderFilter.__buildShowHideFilterWhereClauseForFieldUser(
        department,
        userUID,
        filterObj,
        workOrderLayerView,
        true
      );
    }
  },
  FilterUserWorkAndHideCompletedFieldUser: (
    department,
    selectedAsset,
    workType,
    userUID
  ) => {
    if (workOrderLayerView) {
      const filterObj = {
        AssetName: selectedAsset,
        WorkType: workType,
        //AssignedToUID: userUID,
      };
      WorkOrderFilter.__buildShowHideFilterWhereClauseForFieldUser(
        department,
        userUID,
        filterObj,
        workOrderLayerView,
        false
      );
      /* switch (workType) {
        case '':
          if (department === '') {
            // show all
            workOrderLayerView.filter = [];
          } else {
            workOrderLayerView.filter = {
              where: `Status <> 'Completed' AND AssignedToUID in ('${userUID}') OR Status = 'Unassigned' AND Department in ('${department}')`,
            };
          }
          break;
        default:
          if (department === '') {
            workOrderLayerView.filter = {
              where: `WorkType in ('${workType}')`,
            };
          } else {
            workOrderLayerView.filter = {
              where: `WorkType in ('${workType}') AND Status <> 'Completed' AND AssignedToUID in ('${userUID}') OR Status = 'Unassigned' AND WorkType in ('${workType}') AND Department in ('${department}')`,
            };
          }
          break;
      } */
    }
  },
  __buildShowHideFilterWhereClauseForFieldUser: (  
//For Field Users - most complicated - filter by worktype and / or asset class while preserving the ability to limit to mine or unassigned in my asset classes
    department,
    userUID,
    filterObj,
    workOrderLayerView,
    isHide // = false  //HMMMM
  ) => {

    let __where = '';
    let __assetName = '';
    let __workType ='';


    if (isHide) {
      __where = "Status <> 'Completed' ";
    } else {
      __where = "Status > '' ";
    }

    const fieldValues = [];
    Object.keys(filterObj).map((key, idx) => {
      const fieldValue = filterObj[key];
      if (fieldValue.trim() === '') {
      } else {
        fieldValues.push({ [key]: fieldValue.trim() });
      }
    });

    if (fieldValues.length > 0) {
      for (let index = 0; index < fieldValues.length; index++) {
        const element = fieldValues[index];
        Object.keys(element).map((field, idx) => {
          if (field === "AssetName"){
            __assetName = element[field]
          } else {
            __workType = element[field]
          }
/*        if (element[field] === '') {
          } else {
            __where = __where + ` AND ${field} in ('${element[field]}') `;
          } */
        });
      }
//      __where = __where.substring(0, __where.lastIndexOf('AND '));
    }

    if (userAssets.length > 0) {
/*      if (__where != '') {
        __where = __where + ` OR (Status = 'Unassigned' AND (`;
        }
      else
        {
          __where = '(';
        }; */
      if (__workType !=='') {
        __where = __where + ` AND (WorkType IN ('${__workType}'))`;
      }
      if (__assetName !=='') {
        const __user__asset = userAssets.find(
          (u) => u.name === __assetName);
/*        userAssets.map((a, idx) => {
          const __user__asset = a.gisServicesUsersConfigurations.find(
            (u) => u.userID === userUID
          );  */
          if (__user__asset){
            if (__user__asset.name === __assetName) {
              __where = __where + ` AND (((AssignedToUID = '${userUID}') OR (Status='Unassigned')) AND (AssetName in ('${__assetName}'))) `;
            }
          } else {
            __where = __where + ` AND ((AssignedToUID = '${userUID}') AND (AssetName in ('${__assetName}'))) `;
          }
//        });
      } else {
        __where = __where + ` AND ((AssignedToUID = '${userUID}') OR `;
        userAssets.map((a, idx) => {
          const __user__asset = a.gisServicesUsersConfigurations.find(
            (u) => u.userID === userUID
          );
          if (__user__asset) {
            __where = __where + ` ((Status = 'Unassigned') AND (AssetName in ('${a.name}'))) OR `;
          }
        });
        __where = __where.substring(0, __where.lastIndexOf('OR '));
        __where = __where + ")";
      }
//      __where = __where + ')';

//      __where = __where.substring(0, __where.lastIndexOf('OR '));
//      __where = __where + '))';
    } else {
      __where = __where + ` AND AssignedToUID = '${userUID}'`;
      if (__assetName !== '') {
        __where = __where + ` AND AssetName IN ('${__assetName}')`;
      }
      if (__workType !=='') {
        __where = __where + ` AND WorkType IN ('${__workType}')`;
      }
    }

    setTimeout(() => {
      workOrderLayerView.filter = {
        where: __where.trim(),
      };
    }, 50);
  
/*    workOrderLayerView.filter = {
        where: __where.trim(),
      };
    console.log(workOrderLayerView); */
  },
  __buildShowHideFilterWhereClauseForSupervisor: (  //For Supervisors
    department,
    filterObj,
    workOrderLayerView,
    isHide = false
  ) => {
    const fieldValues = [];
    Object.keys(filterObj).map((key, idx) => {
      const fieldValue = filterObj[key];
      if (fieldValue === '') {
      } else {
        fieldValues.push({ [key]: fieldValue });
      }
    });

    if (fieldValues.length > 0) {
      // filter department out
      let __where = `DepartmentName in ('${department}') AND `;
      for (let index = 0; index < fieldValues.length; index++) {
        const element = fieldValues[index];
        Object.keys(element).map((field, idx) => {
          if (element[field] === '') {
          } else {
            __where = __where + `${field} in ('${element[field]}') AND `;
          }
        });
      }

      __where = __where.substring(0, __where.lastIndexOf('AND '));

      if (isHide) {
        __where = __where + ` AND Status <> 'Completed'`;
      }

      workOrderLayerView.filter = {
        where: __where.trim(), //`AssetName in ('${selectedAsset}') AND WorkType in ('${workType}') AND AssignedToID in ('${selectedUser}')`,
      };
    } else {
      let __where = `DepartmentName in ('${department}')`;
      workOrderLayerView.filter = {
        where: __where.trim(),
      };
      if (isHide) {
        let __where = `DepartmentName in ('${department}') AND Status <> 'Completed'`;
        workOrderLayerView.filter = {
          where: __where.trim(),
        };
      }
    }
  },

  __buildShowHideFilterWhereClause: (   //for Managers
    filterObj,
    workOrderLayerView,
    isHide = false
  ) => {
    const fieldValues = [];
    Object.keys(filterObj).map((key, idx) => {
      const fieldValue = filterObj[key];
      if (fieldValue === '') {
      } else {
        fieldValues.push({ [key]: fieldValue });
      }
    });

    if (fieldValues.length > 0) {
      let __where = '';
      for (let index = 0; index < fieldValues.length; index++) {
        const element = fieldValues[index];
        Object.keys(element).map((field, idx) => {
          if (element[field] === '') {
          } else {
            __where = __where + `${field} in ('${element[field]}') AND `;
          }
        });
      }

      __where = __where.substring(0, __where.lastIndexOf('AND '));

      if (isHide) {
        __where = __where + ` AND Status <> 'Completed'`;
      }

      workOrderLayerView.filter = {
        where: __where.trim(), //`AssetName in ('${selectedAsset}') AND WorkType in ('${workType}') AND AssignedToID in ('${selectedUser}')`,
      };
    } else {
      workOrderLayerView.filter = [];
      if (isHide) {
        workOrderLayerView.filter = {
          where: `Status <> 'Completed'`,
        };
      }
    }
  },
  FilterUserWorkAndShowCompleted: (
    department,
    selectedAsset,
    workType,
    selectedUser,
    userRole
  ) => {
    if (workOrderLayerView) {
      const filterObj = {
        AssetName: selectedAsset,
        WorkType: workType,
        AssignedToID: selectedUser,
      };
      switch (userRole) {
        case 'supervisor':
          WorkOrderFilter.__buildShowHideFilterWhereClauseForSupervisor(
            department,
            filterObj,
            workOrderLayerView
          );

          break;
        case 'manager':
          WorkOrderFilter.__buildShowHideFilterWhereClause(
            filterObj,
            workOrderLayerView
          );
          break;
      }
    }
  },

  FilterUserWorkAndHideCompleted: (
    department,
    selectedAsset,
    workType,
    selectedUser,
    userRole
  ) => {
    if (workOrderLayerView) {
      const filterObj = {
        AssetName: selectedAsset,
        WorkType: workType,
        AssignedToID: selectedUser,
      };
      switch (userRole) {
        case 'supervisor':
          WorkOrderFilter.__buildShowHideFilterWhereClauseForSupervisor(
            department,
            filterObj,
            workOrderLayerView,
            true
          );
          /* if ((department === '') & (selectedUser === '')) {
            // hide completed
            workOrderLayerView.filter = {
              where: `Status <> 'Completed'`,
            };
          } else if ((department !== '') & (selectedUser === '')) {
            workOrderLayerView.filter = {
              where: `Department in ('${department}') AND Status <> 'Completed'`,
            };
          } else if ((department !== '') & (selectedUser !== '')) {
            workOrderLayerView.filter = {
              where: `Department in ('${department}') AND Status <> 'Completed' AND AssignedToID in ('${selectedUser}')`,
            };
          } */
          break;
        case 'manager':
          WorkOrderFilter.__buildShowHideFilterWhereClause(
            filterObj,
            workOrderLayerView,
            true
          );
          break;
      }
    }
  },

  FilterMapWorkOrders: (
    department,
    selectedAsset,
    workOrderType,
    selectedUser,
    filteredFeatures,
    isCompletedVisible
  ) => {
    const filterObj = {
      AssetName: selectedAsset,
      WorkType: workOrderType,
      AssignedToID: selectedUser,
    };
    switch (userRole) {
      case 'field':
        const loggedUser = getLoggedInUser();
        const filterObjField = {
          AssetName: selectedAsset,
          WorkType: workOrderType,
          //AssignedToUID: loggedUser.id,
        };
        WorkOrderFilter.__buildShowHideFilterWhereClauseForFieldUser(
          department,
          loggedUser.id,
          filterObjField,
          workOrderLayerView,
          isCompletedVisible
        );
        break;
      case 'supervisor':
        WorkOrderFilter.__buildShowHideFilterWhereClauseForSupervisor(
          department,
          filterObj,
          workOrderLayerView,
          isCompletedVisible
        );

        break;
      case 'manager':
        WorkOrderFilter.__buildShowHideFilterWhereClause(
          filterObj,
          workOrderLayerView,
          isCompletedVisible
        );
        break;
    }

/*    setTimeout(() => {   // REMOVED 12/6/2022 DMM
      // zoom to filter
      WorkOrderFilter.__zoomToFilteredFeatures(filteredFeatures, mapview);
    }, 200);  */
  },

  FilterMapWorkOrderByDepartment: (
    assetName,
    workOrderType,
    filteredFeatures,
    selectedUser
  ) => {
    if (workOrderLayerView) {
      switch (selectedUser) {
        case '':
          if (assetName === '' && workOrderType === '') {
            workOrderLayerView.filter = {
              where: `Status <> 'Completed'`,
            };
            return;
          }
          if (workOrderType === '' && assetName !== '') {
            workOrderLayerView.filter = {
              where: `AssetName in ('${assetName}') AND Status <> 'Completed'`,
            };
            WorkOrderFilter.__zoomToFilteredFeatures(filteredFeatures, mapview);
            return;
          }
          if (workOrderType !== '' && assetName === '') {
            workOrderLayerView.filter = {
              where: `WorkType in ('${workOrderType}') AND Status <> 'Completed'`,
            };
            WorkOrderFilter.__zoomToFilteredFeatures(filteredFeatures, mapview);
            return;
          }

          workOrderLayerView.filter = {
            where: `AssetName in ('${assetName}') AND WorkType in ('${workOrderType}') AND Status <> 'Completed'`,
          };
          break;

        default:
          if (assetName === '' && workOrderType === '') {
            workOrderLayerView.filter = {
              where: `Status <> 'Completed' AND AssignedToID in ('${selectedUser}')`,
            };
            return;
          }
          if (workOrderType === '' && assetName !== '') {
            workOrderLayerView.filter = {
              where: `AssetName in ('${assetName}') AND Status <> 'Completed' AND AssignedToID in ('${selectedUser}')`,
            };
            WorkOrderFilter.__zoomToFilteredFeatures(filteredFeatures, mapview);
            return;
          }
          if (workOrderType !== '' && assetName === '') {
            workOrderLayerView.filter = {
              where: `WorkType in ('${workOrderType}') AND Status <> 'Completed' AND AssignedToID in ('${selectedUser}')`,
            };
            WorkOrderFilter.__zoomToFilteredFeatures(filteredFeatures, mapview);
            return;
          }

          workOrderLayerView.filter = {
            where: `AssetName in ('${assetName}') AND WorkType in ('${workOrderType}') AND Status <> 'Completed' AND AssignedToID in ('${selectedUser}')`,
          };
          break;
      }
      WorkOrderFilter.__zoomToFilteredFeatures(filteredFeatures, mapview);
    }
  },
  FilterMapWorkOrderByType: (
    workOrderType,
    assetName,
    role,
    userUID,
    filteredFeatures,
    selectedUser
  ) => {
    //console.log(filteredFeatures);
    if (workOrderLayerView) {
      //workOrderLayerView.filter = null;
      switch (role) {
        case 'field':
          if (assetName !== '' && workOrderType === '') {
            workOrderLayerView.filter = {
              where: `Status <> 'Completed' AND AssignedToUID in ('${userUID}') OR Status = 'Unassigned' AND AssetName in ('${assetName}')`,
            };
          }
          if (assetName !== '' && workOrderType !== '') {
            workOrderLayerView.filter = {
              where: `WorkType in ('${workOrderType}') AND Status <> 'Completed' AND AssignedToUID in ('${userUID}') OR Status = 'Unassigned' AND WorkType in ('${workOrderType}') AND AssetName in ('${assetName}')`,
            };
          }
          break;

        default:
          switch (selectedUser) {
            case '':
              if (assetName === '' && workOrderType === '') {
                workOrderLayerView.filter = {
                  where: `Status <> 'Completed'`,
                };
              }
              if (assetName === '' && workOrderType !== '') {
                workOrderLayerView.filter = {
                  where: `WorkType in ('${workOrderType}') AND Status <> 'Completed'`,
                };
              }
              if (assetName !== '' && workOrderType === '') {
                workOrderLayerView.filter = {
                  where: `AssetName in ('${assetName}') AND Status <> 'Completed'`,
                };
              }
              if (assetName !== '' && workOrderType !== '') {
                workOrderLayerView.filter = {
                  where: `AssetName in ('${assetName}') AND WorkType in ('${workOrderType}') AND Status <> 'Completed'`,
                };
              }

              break;

            default:
              if (assetName === '' && workOrderType === '') {
                workOrderLayerView.filter = {
                  where: `Status <> 'Completed' AND AssignedToID in ('${selectedUser}')`,
                };
              }
              if (assetName === '' && workOrderType !== '') {
                workOrderLayerView.filter = {
                  where: `WorkType in ('${workOrderType}') AND Status <> 'Completed' AND AssignedToID in ('${selectedUser}')`,
                };
              }
              if (assetName !== '' && workOrderType === '') {
                workOrderLayerView.filter = {
                  where: `AssetName in ('${assetName}') AND Status <> 'Completed' AND AssignedToID in ('${selectedUser}')`,
                };
              }
              if (assetName !== '' && workOrderType !== '') {
                workOrderLayerView.filter = {
                  where: `AssetName in ('${assetName}') AND WorkType in ('${workOrderType}') AND Status <> 'Completed' AND AssignedToID in ('${selectedUser}')`,
                };
              }
              break;
          }

          break;
      }
      WorkOrderFilter.__zoomToFilteredFeatures(filteredFeatures, mapview);
    }
  },
  FilterMapWorkOrderByUser: (
    selectedUser,
    assetName,
    role,
    userUID,
    filteredFeatures
  ) => {
    //console.log(filteredFeatures);
    if (workOrderLayerView) {
      //workOrderLayerView.filter = null;
      switch (role) {
        case 'field':
          if (assetName !== '' && selectedUser === '') {
            workOrderLayerView.filter = {
              where: `Status <> 'Completed' AND AssignedToUID in ('${userUID}') OR Status = 'Unassigned' AND AssetName in ('${assetName}')`,
            };
          }
          if (assetName !== '' && selectedUser !== '') {
            workOrderLayerView.filter = {
              where: `AssignedToID in ('${selectedUser}') AND Status <> 'Completed' AND AssignedToUID in ('${userUID}') OR Status = 'Unassigned' AND AssignedToID in ('${selectedUser}') AND AssetName in ('${assetName}')`,
            };
          }
          break;

        default:
          if (assetName === '' && selectedUser === '') {
            workOrderLayerView.filter = {
              where: `Status <> 'Completed'`,
            };
          }
          if (assetName === '' && selectedUser !== '') {
            workOrderLayerView.filter = {
              where: `AssignedToID in ('${selectedUser}') AND Status <> 'Completed'`,
            };
          }
          if (assetName !== '' && selectedUser === '') {
            workOrderLayerView.filter = {
              where: `AssetName in ('${assetName}') AND Status <> 'Completed'`,
            };
          }
          if (assetName !== '' && selectedUser !== '') {
            workOrderLayerView.filter = {
              where: `AssetName in ('${assetName}') AND AssignedToID in ('${selectedUser}') AND Status <> 'Completed'`,
            };
          }
          break;
      }
      WorkOrderFilter.__zoomToFilteredFeatures(filteredFeatures, mapview);
    }
  },
  __filterWorkOrdersSelection: () => {
    //console.log(workOrdersBatchList);
    if (workOrdersBatchList.length > 0) {
      /* let GlobalIDs = '';
          workOrdersBatchList.map((w) => {
            GlobalIDs += `${w.attributes.GlobalID},`;
          });
          GlobalIDs = GlobalIDs.substring(0, GlobalIDs.length - 1); */
      //this will not work as the filter only works on a sigle field attribute
      /* workOrderLayerView.filter = {
            where: `GlobalID in ('${GlobalIDs}') AND Status <> 'Completed'`,
          }; */
      workOrderLayerView.filter = {
        where: `GlobalID in ('0') AND Status <> 'Completed'`,
      };
      workorderService.__addRemoveSelectionGraphics(true);
      //workorderService.__zoomToFilteredFeatures(workOrderLayerView, mapview);
    }
  },

  __zoomToFilteredFeatures: (filteredFeatures, view) => {
    view.goTo(filteredFeatures).then(() => {
      //console.log('Done zooming...');
    });
  },
};
