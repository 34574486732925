// MODULE TO PRINT THE WORK RECURRENCE REPORT.  ONLY SHOWS WORK ORDERS THAT ARE RECURRING AND NOT COMPLETED (LINE #37)
import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  CSVExport, Search
} from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import {
  Card,
  CardBody, Col, Row
} from 'reactstrap';
import { getLoggedInUser } from '../../../helpers/authUtils';
import { workorderService } from '../../../services/workorder.service';
import PageTitle from '../../PageTitle';
import Spinner from '../../Spinner';
import NoWorkOrders from '../../workorders/NoWorkOrders';

class WorkOrdersRecurrence extends Component {
    constructor(props){
        super(props)
        this.state = {
            workorders: [],
            isLoading: true
        }
    }
    componentDidMount() {
        // load work orders with recurrece - only reports back those that are not completed.
        const loggedUser = getLoggedInUser();
        
        if(this.props.config){
            workorderService.GetWorkOrders(this.props.config)
            .then(({features}) => {
                if (features.length > 0) {
                    const userRole = workorderService.GetUserRole();
                    features = features.filter(
                        (f) => f.attributes.Status !== 'Completed' && f.attributes.IsRecurring === 'Yes'
                      );

                    switch (userRole) {
                      case 'supervisor':
                        features = features.filter(
                          (f) => f.attributes.DepartmentName === loggedUser.department
                        );
                        break;
                      default:
                        break;
                    }
                  }
                  setTimeout(() => {
                    this.setState({
                        workorders: features,
                        isLoading: false,
                      });
                  }, 100);
            })
        } else{
            this.setState({isLoading: false});
        }
    }
    componentWillUnmount () {
        this.setState = (state, callback) => {
            return;
          };
    }

    __getHumanDate = (_d) => {
        return new Date(_d).toLocaleDateString();
      };

    __getGlobalID = (WOID) => {
        const wo = this.state.workorders.find(
          (w) => w.attributes.WOID === WOID
        );
        return (
          <>
            <a href={`/apps/workorders/?id=${wo.attributes.GlobalID}`}>
              {WOID}
            </a>
          </>
        );
      };
    
    renderStatus(status) {
      switch (status) {
        case 'Unassigned':
          return (
            <span className="badge bg-danger rounded-pill work-status-text-color">
              {status}
            </span>
          );
          break;
        case 'Assigned':
          return (
            <span className="badge bg-warning rounded-pill work-status-text-color">
              {status}
            </span>
          );
          break;
        case 'Mine':
          return (
            <span className="badge bg-secondary rounded-pill work-status-text-color">
              {status}
            </span>
          );
          break;
  
        case 'Completed':
          return (
            <span className="badge bg-success rounded-pill work-status-text-color">
              {status}
            </span>
          );
          break;
      }
    }

    __renderTableComponent = () => {
        const columns = [
            {
              dataField: 'attributes.WOID',
              text: 'ID',
              formatter: (cell) => {
                return this.__getGlobalID(cell);
              },
            },
            {
              dataField: 'attributes.WorkType',
              text: 'Work Type',
              sort: true,
            },
            {
              dataField: 'attributes.AssetName',
              text: 'Asset',
              sort: true,
            },
            {
              dataField: 'attributes.LocationAddress',
              text: 'Address',
              sort: true,
            },
            /* {
              dataField: 'attributes.OriginatorID',
              text: 'Originator',
              sort: true,
            }, */
            {
              dataField: 'attributes.AssignedToID',
              text: 'Assigned To',
              sort: true,
            },
            {
              dataField: 'attributes.Recurrence',
              text: 'Recurrence'
            },
            {
              dataField: 'attributes.Status',
              text: 'Status',
              formatter: (cell, row, index) => {
                return (
                  <>
                    <small style={{ textAlign: 'center', color: '#040202' }}>
                      {this.renderStatus(cell)}
                    </small>
                  </>
                );
              },
              sort: true,
            },
            {
              dataField: 'attributes.NextDueDate',
              text: 'Next Due Date',
              /* formatter: (cell) => {
                return workorderService.__getHumanDateAndTime(cell);
              }, */
              sort: true,
            },
          ];
      
          const defaultSorted = [
            {
              dataField: 'id',
              order: 'asc',
            },
          ];
      
          const { SearchBar } = Search;
          const { ExportCSVButton } = CSVExport;

        if (this.state.isLoading) {
        return (
            <div style={{ textAlign: 'center', marginTop: '45px' }}>
            <Spinner className="m-2" color={'info'} size={'sm'} />
            </div>
        );
        }
        else{
            if (this.state.workorders.length > 0) {
                return (
                  <Card>
                    <CardBody>
                      <h4 className="header-title">Search or Export</h4>
                      <p className="text-muted font-14 mb-4">
                        Search or export work orders data in a CSV format
                      </p>
        
                      <ToolkitProvider
                        bootstrap4
                        keyField="attributes.OBJECTID"
                        data={this.state.workorders}
                        columns={columns}
                        search
                        exportCSV={{ onlyExportFiltered: false, exportAll: true }}
                      >
                        {(props) => (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <SearchBar {...props.searchProps} />
                              </Col>
                              <Col className="text-right">
                                <ExportCSVButton
                                  {...props.csvProps}
                                  className="btn btn-primary"
                                >
                                  Export CSV
                                </ExportCSVButton>
                              </Col>
                            </Row>
        
                            <BootstrapTable
                              {...props.baseProps}
                              bordered={false}
                              defaultSorted={defaultSorted}
                              keyField="attributes.OBJECTID"
                              paging={false} // {paginationFactory({ sizePerPage: 10 })}
                              wrapperClasses="table-responsive"
                            />
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                );
              } else {
                return <NoWorkOrders />;
              }
        }
    }

    render() {
        return (
            <Fragment>
                <div>
                    <div className="main-content-wrapper-scrollable">
                        <div style={{ clear: 'both' }}></div>
                        <div>
                            <PageTitle
                                breadCrumbItems={[
                                { label: 'Map', path: '/apps/workorders' },
                                { label: 'Work orders recurrence', active: true },
                                ]}
                                title={'Recurring Work Orders'}
                            />
                            {this.__renderTableComponent()}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      config: state.AppConfig.config,
    };
  };
export default connect(mapStateToProps)(WorkOrdersRecurrence);

