import React from 'react';
import { Alert } from 'reactstrap';

const NoDataMessage = ( {message} ) => {
    return (
        <>
            <Alert color="info" className="mt-3">
                <i className="dripicons-wrong mr-2"></i>{' '}
                <strong>Missing Data - </strong>
                {message}
            </Alert>
        </>
    )
}

export default NoDataMessage;
