import { loadModules } from 'esri-loader';

const __isolation__trace__service =
  process.env.REACT_APP_ISOLATION_TRACE_SERVICE;

export const IsolationTraceService = {
  AddStartPointAndBarrier: (
    waterisolationTraceGL,
    startPointGeometry,
    barrierGeometry = null
  ) => {
    loadModules(['esri/Graphic']).then(([Graphic]) => {
      const startingPointMarker = {
        type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
        url: 'https://static.arcgis.com/images/Symbols/Shapes/YellowPin2LargeB.png',
        width: '64px',
        height: '64px',
      };
      const barrierMarker = {
        type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
        url: 'https://static.arcgis.com/images/Symbols/Shapes/RedPin2LargeB.png',
        width: '64px',
        height: '64px',
      };

      waterisolationTraceGL.add(
        new Graphic(startPointGeometry, startingPointMarker)
      );
      if (barrierGeometry) {
        waterisolationTraceGL.add(new Graphic(barrierGeometry, barrierMarker));
      }
    });
  },
  GetStartingPointGraphic: (location) => {
    return loadModules(['esri/Graphic']).then(([Graphic]) => {
      const startingPointMarker = {
        type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
        url: 'https://static.arcgis.com/images/Symbols/Shapes/YellowPin2LargeB.png',
        width: '64px',
        height: '64px',
      };
      return new Graphic(location, startingPointMarker);
    });
  },
  /**
   * Trace parameters
   * @param {*} view
   * @param {[]} traceLocations // The list of starting points and barriers that will define where the trace starts and stops
   * @returns
   */
  performTrace: (view, traceLocations) => {
    return loadModules([
      'esri/rest/networks/trace',
      'esri/rest/networks/support/TraceParameters',
      'esri/rest/geoprocessor',
    ]).then(([trace, TraceParameters, geoprocessor]) => {
      let traceInputDetails = [];

      /* var __config = {
        geoprocessing: {
          url: gis_services.utilityTraceGP,
          inputs: [
            {
              paramName: 'flags',
              displayName: 'Flags',
              toolTip: 'Input locations from which to start the trace.',
              type: 'Flag',
              symbol: {
                color: [0, 0, 128, 128],
                size: 18,
                angle: 0,
                xoffset: 0,
                yoffset: 0,
                type: 'esriSMS',
                style: 'esriSMSCircle',
                outline: {
                  color: [0, 0, 128, 255],
                  width: 0.75,
                  type: 'esriSLS',
                  style: 'esriSLSSolid',
                },
              },
            },
            {
              paramName: 'barriers',
              displayName: 'Barriers',
              toolTip: 'Input locations from which to block the trace.',
              type: 'Barrier',
              symbol: {
                color: [0, 0, 128, 128],
                size: 18,
                angle: 0,
                xoffset: 0,
                yoffset: 0,
                type: 'esriSMS',
                style: 'esriSMSCircle',
                outline: {
                  color: [0, 0, 128, 255],
                  width: 0.75,
                  type: 'esriSLS',
                  style: 'esriSLSSolid',
                },
              },
            },
            {
              paramName: 'skip_locations',
              displayName: 'Skip Locations',
              toolTip:
                'Locations of disabling features to "skip" in the trace.',
              type: 'Skip',
              symbol: {
                color: [0, 0, 128, 128],
                size: 18,
                angle: 0,
                xoffset: 0,
                yoffset: 0,
                type: 'esriSMS',
                style: 'esriSMSCircle',
                outline: {
                  color: [0, 0, 128, 255],
                  width: 0.75,
                  type: 'esriSLS',
                  style: 'esriSLSSolid',
                },
              },
            },
          ],
          outputs: [
            {
              visibility: true,
              paramName: 'result_area',
              type: 'Result',
              panelText: 'Result Area',
              toolTip: '',
              displayName: 'Result Area',
              displayText: 'Affected Area',
              MinScale: 0,
              MaxScale: 0,
              exportToCSV: false,
              saveToLayer: '',
              guidField: '',
              parameternameField: '',
              symbol: {
                color: [155, 187, 89, 129],
                outline: {
                  color: [115, 140, 61, 255],
                  width: 1.5,
                  type: 'esriSLS',
                  style: 'esriSLSSolid',
                },
                type: 'esriSFS',
                style: 'esriSFSSolid',
              },
              bypassDetails: {
                skipable: null,
              },
            },
            {
              visibility: true,
              paramName: 'wHydrantLateral',
              type: 'Result',
              panelText: 'wHydrantLateral',
              toolTip: '',
              displayName: 'wHydrantLateral',
              displayText: 'Affected Hydrant Laterals',
              MinScale: 0,
              MaxScale: 0,
              exportToCSV: false,
              saveToLayer: '',
              guidField: '',
              parameternameField: '',
              symbol: {
                color: [155, 187, 89, 255],
                width: 2.25,
                type: 'esriSLS',
                style: 'esriSLSSolid',
              },
              bypassDetails: {
                skipable: null,
              },
            },
            {
              visibility: true,
              paramName: 'wLateralLine',
              type: 'Result',
              panelText: 'wLateralLine',
              toolTip: '',
              displayName: 'wLateralLine',
              displayText: 'Affected Lateral Lines',
              MinScale: 0,
              MaxScale: 0,
              exportToCSV: false,
              saveToLayer: '',
              guidField: '',
              parameternameField: '',
              symbol: {
                color: [155, 187, 89, 255],
                width: 2.25,
                type: 'esriSLS',
                style: 'esriSLSSolid',
              },
              bypassDetails: {
                skipable: null,
              },
            },
            {
              visibility: true,
              paramName: 'wMain',
              type: 'Result',
              panelText: 'wMain',
              toolTip: '',
              displayName: 'wMain',
              displayText: 'Affected Mains',
              MinScale: 0,
              MaxScale: 0,
              exportToCSV: false,
              saveToLayer: '',
              guidField: '',
              parameternameField: '',
              symbol: {
                color: [155, 187, 89, 255],
                width: 2.25,
                type: 'esriSLS',
                style: 'esriSLSSolid',
              },
              bypassDetails: {
                skipable: null,
              },
            },
            {
              visibility: true,
              paramName: 'snapped_inputs',
              type: 'Result',
              panelText: 'Snapped Inputs',
              toolTip: '',
              displayName: 'Snapped Inputs',
              displayText: 'Snapped Inputs',
              MinScale: 0,
              MaxScale: 0,
              exportToCSV: false,
              saveToLayer: '',
              guidField: '',
              parameternameField: '',
              symbol: {
                color: [0, 0, 128, 128],
                size: 18,
                angle: 0,
                xoffset: 0,
                yoffset: 0,
                type: 'esriSMS',
                style: 'esriSMSCircle',
                outline: {
                  color: [0, 0, 128, 255],
                  width: 0.75,
                  type: 'esriSLS',
                  style: 'esriSLSSolid',
                },
              },
              bypassDetails: {
                skipable: false,
              },
            },
            {
              visibility: true,
              paramName: 'disabling_wValve',
              type: 'Result',
              panelText: 'disabling_wValve',
              toolTip: '',
              displayName: 'disabling_wValve',
              displayText: 'Affected Valves',
              MinScale: 0,
              MaxScale: 0,
              exportToCSV: false,
              saveToLayer: '',
              guidField: '',
              parameternameField: '',
              symbol: {
                color: [0, 0, 128, 128],
                size: 18,
                angle: 0,
                xoffset: 0,
                yoffset: 0,
                type: 'esriSMS',
                style: 'esriSMSCircle',
                outline: {
                  color: [0, 0, 128, 255],
                  width: 0.75,
                  type: 'esriSLS',
                  style: 'esriSLSSolid',
                },
              },
              bypassDetails: {
                skipable: false,
              },
            },
            {
              visibility: true,
              paramName: 'wCurbStopValve',
              type: 'Result',
              panelText: 'wCurbStopValve',
              toolTip: '',
              displayName: 'wCurbStopValve',
              displayText: 'Affected Curb Stop Valves',
              MinScale: 0,
              MaxScale: 0,
              exportToCSV: false,
              saveToLayer: '',
              guidField: '',
              parameternameField: '',
              symbol: {
                color: [0, 0, 128, 128],
                size: 18,
                angle: 0,
                xoffset: 0,
                yoffset: 0,
                type: 'esriSMS',
                style: 'esriSMSCircle',
                outline: {
                  color: [0, 0, 128, 255],
                  width: 0.75,
                  type: 'esriSLS',
                  style: 'esriSLSSolid',
                },
              },
              bypassDetails: {
                skipable: false,
              },
            },
            {
              visibility: true,
              paramName: 'wHydrant',
              type: 'Result',
              panelText: 'wHydrant',
              toolTip: '',
              displayName: 'wHydrant',
              displayText: 'Affected Hydrants',
              MinScale: 0,
              MaxScale: 0,
              exportToCSV: false,
              saveToLayer: '',
              guidField: '',
              parameternameField: '',
              symbol: {
                color: [0, 0, 128, 128],
                size: 18,
                angle: 0,
                xoffset: 0,
                yoffset: 0,
                type: 'esriSMS',
                style: 'esriSMSCircle',
                outline: {
                  color: [0, 0, 128, 255],
                  width: 0.75,
                  type: 'esriSLS',
                  style: 'esriSLSSolid',
                },
              },
              bypassDetails: {
                skipable: false,
              },
            },
          ],
        },
        projectSettings: {
          polygonLayerId: 'Water_Outage_System_7440',
          pointLayerId: 'Water_Outage_System_2569',
          outputParamName: 'result_area',
        },
        summaryExpression: {
          summaryExpressionValueArr: [],
          summaryExpressionValue: '',
          summaryExpressionTrimmedValue: '',
          summaryExpressionNLS: {
            title: 'General Settings',
            summaryFieldsetText: 'Summary settings',
            inputOutput: 'Input/Output',
            field: 'Field',
            operator: 'Operator',
            inputOperatorCountOption: 'Count',
            outputOperatorCountOption: 'Count',
            outputOperatorSkipCountOption: 'SkipCount',
            fieldOperatorSumOption: 'Sum',
            fieldOperatorMinOption: 'Min',
            fieldOperatorMaxOption: 'Max',
            fieldOperatorMeanOption: 'Mean',
            expressionAddButtonText: 'Add',
            expressionVerifyButtonText: 'Verify',
            summaryEditorText: 'Summary Text',
            autoZoomAfterTrace: 'Additional Options',
            zoomText: 'Auto zoom after trace',
            summarSettingTooltipText: 'Add input/output count',
          },
          autoZoomAfterTrace: false,
        },
        autoZoomAfterTrace: false,

        watermanagement: {
          shutdownService: function (_layer) {
            console.log(LayersController);

            LayersController.shutDownService(_layer).then(function (res) {
              console.log('The response from the layer controller: ', res);
            });

            //console.log("Shuting down service: ", _layer);
          },
          saveOutageArea: function (serviceAreaGraphic) {},
          notifyResidents: function () {},
        },
      }; */

      const params = new TraceParameters({
        traceLocations,
        outSpatialReference: view.spatialReference,
        traceType: 'isolation',
      });
      return geoprocessor.submitJob(__isolation__trace__service, params);
    });
  },
};
