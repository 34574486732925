import { loadModules } from 'esri-loader';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import notFoundImg from '../../../assets/images/file-searching.svg';
import { getLoggedInUser } from '../../../helpers/authUtils';
import { GlobalFormatter } from '../../../services/CRUDoperations.service';
import { UserAPI } from '../../../services/users.services';
import { workorderService } from '../../../services/workorder.service';
import PageTitle from '../../PageTitle';
import MunicipalityContactInfo from '../../Shared/MunicipalityContactInfo';
import Spinner from '../../Spinner';
import UtilityToggler from '../UtilityToggler';
import './print-page.css';

class PrintWorkOrder extends Component {   /* completed at 589 */
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      AssignedUser: null,
      WorkOrderFeature: null,
      GISAssets: [],
      GISResources: [],
      workOrderQuestionAnswers: [],
//      workOrderProblemResolutions: [],  //removed when changed from multi to single storage
      workorders: [],
      mapview: null,
      User: null,
      userType: '',
      WOCAssets: [],
    };
  }

  componentDidMount() {
    this.setState({ User: this.props.user });
    this.initWorkOrderPrint();
  }

  initWorkOrderPrint = () => {  /* completed at 120 */
    const _globalID = this.props.match.params.globalid;
    //console.log(_globalID);
    workorderService
      .SelectFeatureByGlobalID(_globalID)
      .then((featureResult) => {
        workorderService
          .GetManagedOrderDetails(_globalID)
          .then((results) => {
            if (results.data === '') {
              this.setState({ isLoading: false });
            } else {
              const {
                gisAssets,
                workOrderResources,
                workOrderQuestionAnswers,
//                workOrderProblemResolutions,  //removed when changed from multi to single storage
                assignedToUID,
              } = results.data;
              if (featureResult.features.length > 0) {
                // get assigned to work
                if (assignedToUID) {
                  UserAPI.GetUser(assignedToUID).then((user) => {
                    this.setState({
                      AssignedUser: user.data,
                      GISAssets: gisAssets,
                      GISResources: workOrderResources,
                      WorkOrderFeature: featureResult.features[0],
                      workOrderQuestionAnswers,
//                      workOrderProblemResolutions,  //removed when changed from multi to single storage
                      workorders: featureResult.features,
                      isLoading: false,
                    });
                  });
                } else {
                  this.setState({
                    GISAssets: gisAssets,
                    GISResources: workOrderResources,
                    WorkOrderFeature: featureResult.features[0],
                    workorders: featureResult.features,
                    workOrderQuestionAnswers,
//                    workOrderProblemResolutions,  // removed when changed from multi to single storage
                    isLoading: false,
                  });
                }

                setTimeout(() => {
                  this.initMapView();  /* only call to initMapView */
                }, 500);
              } else {
                this.setState({
                  GISAssets: gisAssets,
                  GISResources: workOrderResources,
                  workorders: [],
                  isLoading: false,
                });
              }
            }  /* end of initial Else */
          })
          .catch((error) =>
            console.log(`Error fetching order details :: ${error}`)
          );
      })
      .catch((err) => {
        console.log(`Error featching feature from GIS Server :: `, err);
      });

    setTimeout(() => {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('hide-footer');
      }
    }, 300);
  };  /* initWorkOrderPrint */

  componentWillUnmount() {
    const footer = document.getElementById('footer');
    footer.classList.remove('hide-footer');
    this.setState = (state, callback) => {
      return;
    };
  }

  initMapView = () => {   /* called only from initWorkOrderPrint - completed @ #216 */
    loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/layers/GraphicsLayer',
      'esri/Graphic',
    ]).then(async ([Map, MapView, GraphicsLayer, Graphic]) => {
      const selectedWorkGLayer = new GraphicsLayer({
        id: 'selectedWorkGLayer',
        title: 'Selected Work',
        listMode: 'hide',
      });
      const selectedWorkTextGLayer = new GraphicsLayer({
        id: 'selectedWorkTextGLayer',
        title: 'Text ID',
        listMode: 'hide',
      });
      const map = new Map({
        basemap: `${this.props.config.ESRImaps.id_base}`, //'topo-vector', 'streets-night-vector', was 'topo' which has been deprecated CHANGED 12/13/2022 DMM
        layers: [selectedWorkGLayer, selectedWorkTextGLayer],
      });

{/*      // add utility Layer
      const woc__assets = await workorderService.__addGISAssetsToMap(
        map,
        this.state.User
      );  */}

      const { center, zoom } = this.props.config.mapConfig;
      const view = new MapView({
        container: 'viewDiv',
        map: map,
        zoom,
        center, // longitude, latitude
      });
      view.ui.move(['zoom'], 'bottom-right');
//      view.ui.add(['utilityTogglerDiv'], 'top-left');  //

      setTimeout(() => {
        this.setState({ mapview: view });
      }, 50);

      view.when(() => {
        // add selected work
        let marker = {
          type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
          url: `${this.props.config.ESRImaps.printmarker}`, //'https://static.arcgis.com/images/Symbols/Shapes/BlackStarLargeB.png', //'https://demogis.emht.com/assets/images/markers/BlackStarLargeB.png'
          width: '44px',
          height: '44px',
        };

        this.state.workorders.map((f) => {
          selectedWorkGLayer.add(new Graphic(f.geometry, marker, f.attributes));

          var textSym = {
            type: 'text',
            text: f.attributes.WOID,
            font: { size: 13, weight: 'bold' },
            yoffset: 10,
            color: [61, 4, 149, 1],
          };
          selectedWorkTextGLayer.add(new Graphic(f.geometry, textSym));
        });

        //console.log(selectedWorkGLayer);

        const { geometry } = this.state.WorkOrderFeature;
        if (geometry) {
          view.center = geometry;
          view.zoom = 16;
        }
      });
      /* setTimeout(() => {
        const { geometry } = this.state.WorkOrderFeature;
        if (geometry) {
          view.center = geometry;
          view.zoom = 16;
        }
      }, 200); */
    });
  }; /* end of initMapView from #130 */

  __getHumanDate = (_d) => {
    return new Date(_d).toLocaleDateString();
  };

  __getWorkStatus = () => {
    const { attributes } = this.state.WorkOrderFeature;
    if (attributes) {
      switch (attributes.Status.toLowerCase()) {
        case 'yellow':
          return 'Assigned';
          break;
        case 'red':
          return 'Unassigned';
          break;
        case 'green':
          return 'Completed';
          break;
        case 'grey':
          return 'Associated to mine';
          break;
      }
    }
  };

  __getResourcesTotalCost = () => {
    let cost = 0;
    this.state.GISResources.map((r) => {
//      cost = cost + r.unit * r.resource.rate;  // DMM 12/9/2022
      cost = cost + r.unit * r.rate;
    });

    return GlobalFormatter.FormatUSD(cost);
  };

  renderStatus(status) {
    return workorderService.renderStatus(status);
  }

  __renderEssentialQuestion() {
    if (this.state.workOrderQuestionAnswers.length > 0) {
      return (
        <div className="clearfix">
          <hr />
          <h6 className="text-muted font-18">Essential Details</h6>
          <ol>
            {this.state.workOrderQuestionAnswers.map((q, index) => (
              <li key={index}>
                <div>
                  <p style={{ fontSize: '1.2rem', margin: '0' }}>
                    {q.essentialQuestion.question}
                  </p>
                  <p
                    style={{ fontSize: '1.2rem', margin: '0' }}
                    className="text-success mb-2"
                  >
                    {q.answer}
                  </p>
                </div>
              </li>
            ))}
          </ol>
        </div>
      );
    }
  }

  __renderPrinting() {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      if (this.state.WorkOrderFeature) {
        const { attributes } = this.state.WorkOrderFeature;
        return (
          <Fragment>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div className="clearfix">
                      <div className="float-left mb-3">
                        <MunicipalityContactInfo
                          municipalityInfo={this.props.config.municipalityInfo}
                        />
                      </div>
                      <div className="float-right">
                        <h4 className="m-0 d-print-none">
                          Work Order - {attributes.WOID}
                        </h4>
                      </div>
                    </div>
                    <Row>
                      <Col sm={6}>
                        <div className="float-left mt-3">
                          <p className="font-20">
                            <b>Created by: {attributes.OriginatorID}</b>
                          </p>
                          <p className="font-20">
                            Asset: <b>{attributes.AssetName}</b>
                          </p>
                          <p className="font-20">
                            Work Type: <b>{attributes.WorkType}</b>
                          </p>
                          <p className="text-muted font-20">
                            <u>Description</u>: {attributes.Description}
                          </p>
                        </div>
                      </Col>

                      <Col sm={6}>
                        <div className="mt-3 float-sm-right">
                          <p className="font-20">
                            <strong>Due Date: </strong> &nbsp;&nbsp;&nbsp;{' '}
                            <b
                              className={`${workorderService.GetDueDateTextColor(
                                attributes.PlannedDate,
                                attributes.Status
                              )} float-right`}
                            >
                              {new Date(
                                attributes.PlannedDate
                              ).toISOString().substr(0, 10)}
                            </b>
                          </p>
                          <p className="font-20">
                            <strong>Order Status: </strong>{' '}
                            <span style={{ float: 'right' }}>
                              {this.renderStatus(attributes.Status)}
                            </span>
                          </p>
                          {attributes.Status === 'Completed' && (
                            <p className="font-20">
                              <strong>Date Completed: </strong>{' '}
                              <strong>
                                <span
                                  className="ml-2"
                                  style={{ float: 'right' }}
                                >
                                  {new Date(
                                    attributes.DateCompleted
                                  ).toLocaleDateString()}
                                </span>
                              </strong>{' '}
                            </p>
                          )}
                          <p className="font-20">
                            <strong>WO ID: </strong>{' '}
                            <span className="float-right">
                              #{attributes.WOID}
                            </span>
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col sm={3}>
                        <h6 className="font-18">Location</h6>
                        <address style={{ fontSize: '1.2rem' }}>
                          <b>{attributes.LocationAddress}</b>
                          <br />
                          Work Assigned to:
                          <br />
                          <b>{attributes.AssignedToID}</b>
                          <br />
                          {this.state.AssignedUser
                            ? this.state.AssignedUser.email
                            : ''}
                          <br />
                        </address>
                      </Col>
                      <Col sm={3}>
                        <div className="panel-gis-list">
                          <h5>GIS Assets</h5>
                          {this.state.GISAssets.length === 0 && <p>None</p>}
                          <ul className="gis-assets-list">
                            {this.state.GISAssets.map((a, index) => (
                              <li key={index} className="gis-assets font-16">
                                {a.assetName} - {a.facilityID}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Col>
                      <Col
                        sm={6}
                        style={{ height: '250px' }}
                        className="text-right"
                      >
                        <div
                          id="viewDiv"
                          style={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#ddd',
                          }}
                        >
{/*                          <div id="utilityTogglerDiv" className="text-left">
                            {this.state.WOCAssets.length > 0 && (  
                              <UtilityToggler
                                mapview={this.state.mapview}
                                user={this.state.User}
                                userType={this.state.userType}
                                WOCAssets={this.state.WOCAssets}
                              />
                            )}
                          </div>  */}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="table-responsive">
                          <table className="table mt-4">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Resource</th>
                                <th>Quantity</th>
                                <th>Unit Cost</th>
                                <th className="text-right">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.GISResources.map((item, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>
{/*                                    <b>{item.resource.name}</b>  */}
                                      <b>{item.name}</b>
                                    </td>
                                    <td>{item.unit}</td>
{/*                                    <td>{item.resource.rate}</td>  */}
                                    <td>{item.rate}</td> 
                                    <td className="text-right">
                                      {GlobalFormatter.FormatUSD(
                                        item.unit * item.rate  // Changed from item.resource.rate 
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={9}>
                        <div className="clearfix pt-3">
                          <h6 className="text-muted font-18">Field Notes:</h6>
                          <small style={{ fontSize: '1.2rem' }}>
                            {attributes.FieldNotes === '' ||
                            attributes.FieldNotes === null
                              ? 'No field note provided at this time'
                              : attributes.FieldNotes}
                          </small>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="float-right mt-3 mt-sm-0">
                          <h3>{this.__getResourcesTotalCost()}</h3>
                        </div>
                        <div className="clearfix"></div>
                      </Col>
                    </Row>
                    <div className="clearfix"></div>

                    <Row>
                      <Col sm={6}>
                        <div className="clearfix pt-3">
                          <p className="text-muted font-18">
                          Problem: <b>
                            {attributes.ProblemName === '' ||
                            attributes.ProblemName === null
                              ? '(No Problem chosen)'
                              : attributes.ProblemName}
                          </b>
                          </p>
                          <p className="text-muted font-18">
                          Resolution: <b>
                            {attributes.ResolutionName === '' ||
                            attributes.ResolutionName === null
                              ? '(No Resolution chosen)'
                              : attributes.ResolutionName}
                          </b>
                          </p>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div>{this.__renderEssentialQuestion()}</div>
                        <div className="clearfix"></div>
                      </Col>

                    </Row>

                    <div className="d-print-none mt-4">
                      <div className="text-right">
                        <button
                          className="btn btn-primary"
                          onClick={(e) => {
                            window.print();
                          }}
                        >
                          <i className="mdi mdi-printer"></i> Print
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Fragment>
        );
      } else {
        return (
          <div className="text-center">
            <img src={notFoundImg} height="90" alt="" className="mt-4" />
            <h4 className="text-uppercase text-danger mt-3">
              No Work Order found
            </h4>
            <p className="text-muted mt-3">
              There is no work order associate with given id.
            </p>
          </div>
        );
      }
    }
  }

  render() {
    return (
      <Fragment>
        <PageTitle
          breadCrumbItems={[
            { label: 'Map', path: '/apps/workorders' },
            { label: 'Print Work Order', active: true },
          ]}
          title={'Print Work Order'}
        />
        {this.__renderPrinting()}
      </Fragment>
    );
  }
} /* PrintWorkOrder component @ #017 */

const mapStateToProps = (state) => {
  return {
    config: state.AppConfig.config,
  };
};

export default connect(mapStateToProps)(PrintWorkOrder);
