import React, { Component, Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import PageTitle from '../PageTitle';
import assetBasedCreateWork from './img/asset-based-create-work.PNG';
import btnEdit from './img/btn-edit.PNG';
import createWork from './img/create-work.PNG';
import workDetailsBtn from './img/work-details-btn.PNG';

class Faq extends Component {
  componentDidMount() {
    setTimeout(() => {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('hide-footer');
      }
    }, 300);
  }
  componentWillUnmount() {
    const footer = document.getElementById('footer');
    footer.classList.remove('hide-footer');
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    return (
      <Fragment>
        <PageTitle
          breadCrumbItems={[
            { label: 'Map', path: '/apps/workorders' },
            { label: 'FAQ', active: true },
          ]}
          title={'FAQ'}
        />

        <Row>
          <Col>
            <div className="text-center">
              <h3 className="">Frequently Asked Questions</h3>
              <p className="text-muted mt-3">
                {' '}
                Below is a list of frequently asked questions.
{/*                Below is a list of frequently asked questions. If you cannot
                find what you're looking for, try to look into the{' '}
        <a href="/apps/docs/help">user guide</a>  DMM 12/12/2022 */}
                <br />
              </p>

              {/* <button type="button" className="btn btn-success btn-sm mt-2">
                <i className="mdi mdi-email-outline mr-1"></i> Email us your
                question
              </button>
              <button type="button" className="btn btn-info btn-sm mt-2 ml-1">
                <i className="mdi mdi-twitter mr-1"></i> Send us a tweet
              </button> */}
            </div>
          </Col>
        </Row>

        <Row className="pt-5">
          <Col lg={5} className="offset-lg-1">
            <div>
              <div className="faq-question-q-box">Q.</div>
              <h4 className="faq-question" data-wow-delay=".1s">
                How do I create a work order?
              </h4>
              <p className="faq-answer mb-2">
                If you are a supervisor or a manager, go to the "Map" and click
                on "Create New Work Order" button.
              </p>
              <img
                src={createWork}
                className="img-fluid faq-answer"
                style={{ Height: '20px' }}
              />
              <p className="faq-answer mb-2 mt-1">
                You can either pick a location or enter an address, and provide
                necessary information to the work.
              </p>
              <p className="faq-answer mb-2">
                You can also create an asset-based work order by selecting an
                asset, then click on "Create Work Order".
              </p>
              <img
                src={assetBasedCreateWork}
                className="img-fluid faq-answer"
                style={{ minWidth: '200px' }}
              />
            </div>
            <div className="mt-5">
              <div className="faq-question-q-box">Q.</div>
              <h4 className="faq-question" data-wow-delay=".1s">
                How do I re-assign a work order to someone else?
              </h4>
              <p className="faq-answer mb-2">
                If you are a supervisor or a manager, Go to the work order
                details and click on "Change" button.
              </p>
            </div>
          </Col>
          <Col lg={5}>
            <div>
              <div className="faq-question-q-box">Q.</div>
              <h4 className="faq-question" data-wow-delay=".1s">
                How do I complete a work order?
              </h4>
              <p className="faq-answer mb-4">
                If you are a field user, or a supervisor, or a manager, you can
                complete by going to the work details from the list. Select the
                work you wish to complete, the work will be then highlighted
                then click on "Details" button
              </p>
              <img
                src={workDetailsBtn}
                className="img-fluid faq-answer"
                style={{ minWidth: '200px' }}
              />
              <p className="faq-answer mt-2">
                From the details window, click the "Edit" button as shown Below
              </p>

              <img
                src={btnEdit}
                className="img-fluid faq-answer"
                style={{ minWidth: '200px' }}
              />
            </div>

            {/* <div className="mt-5">
                <div className="faq-question-q-box">Q.</div>
                <h4 className="faq-question" data-wow-delay=".1s">
                  How do I delete existing work?
                </h4>
                <p className="faq-answer mb-2">
                  An exisiting work order can be deleted until 24h after work creation. It is not possible to delete a work after 24
                </p>
              </div> */}
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default Faq;
