import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import notFoundImg from '../../../assets/images/file-searching.svg';
import { GlobalFormatter } from '../../../services/CRUDoperations.service';
import { UserAPI } from '../../../services/users.services';
import { workorderService } from '../../../services/workorder.service';
import MunicipalityContactInfo from '../../Shared/MunicipalityContactInfo';
import Spinner from '../../Spinner';
import './print-page.css';

const _questionStyle = {
  fontSize: '0.75rem',
  margin: '0 !important',
};

class PrintWorkOrderInBatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      AssignedUser: null,
      WorkOrderFeature: null,
      GISAssets: [],
      GISResources: [],
      workOrderQuestionAnswers: [],
//      workOrderProblemResolutions: [],  // removed when changed from multi to single storage
      userType: '',
      mapview: null,
      User: null,
    };
  }

  componentDidMount() {
    this.setState({ User: this.props.user });
    this.initWorkOrderPrint();
  }

  initWorkOrderPrint = () => {
    const _globalID = this.props.GlobalID;
    workorderService
      .SelectFeatureByGlobalID(_globalID)
      .then((featureResult) => {
        workorderService
          .GetManagedOrderDetails(_globalID)
          .then((results) => {
            if (results.data === '') {
              this.setState({ isLoading: false });
            } else {
              const {
                gisAssets,
                workOrderResources,
                workOrderQuestionAnswers,
//                workOrderProblemResolutions,  // removed when changed from multi to single storage
                assignedToUID,
              } = results.data;
              if (featureResult.features.length > 0) {
                // get assigned to work
                if (assignedToUID) {
                  UserAPI.GetUser(assignedToUID).then((user) => {
                    this.setState({
                      AssignedUser: user.data,
                      GISAssets: gisAssets,
                      GISResources: workOrderResources,
                      WorkOrderFeature: featureResult.features[0],
                      workOrderQuestionAnswers,
//                      workOrderProblemResolutions,  // removed when changed from multi to single storage
                      isLoading: false,
                    });
                  });
                } else {
                  this.setState({
                    GISAssets: gisAssets,
                    GISResources: workOrderResources,
                    WorkOrderFeature: featureResult.features[0],
                    workOrderQuestionAnswers,
//                    workOrderProblemResolutions,  // removed when changed from multi to single storage
                    isLoading: false,
                  });
                }
              } else {
                this.setState({
                  GISAssets: gisAssets,
                  GISResources: workOrderResources,
                  isLoading: false,
                });
              }
            }
          })
          .catch((error) =>
            console.log(`Error fetching order details :: ${error}`)
          );
      })
      .catch((err) => {
        console.log(`Error featching feature from GIS Server :: `, err);
      });

    setTimeout(() => {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('hide-footer');
      }
    }, 300);
  };

  componentWillUnmount() {
    const footer = document.getElementById('footer');
    footer.classList.remove('hide-footer');
    this.setState = (state, callback) => {
      return;
    };
  }

  /* initMapPrintView = () => {
    loadModules(['esri/Map', 'esri/views/MapView']).then(([Map, MapView]) => {
      const { attributes, geometry } = this.props.feature;
    });
  }; */

  __getHumanDate = (_d) => {
    return new Date(_d).toLocaleDateString();
  };

  __getWorkStatus = () => {
    const { attributes } = this.state.WorkOrderFeature;
    if (attributes) {
      // eslint-disable-next-line default-case
      switch (attributes.Status.toLowerCase()) {
        case 'yellow':
          return 'Assigned';
        case 'red':
          return 'Unassigned';
        case 'green':
          return 'Completed';
        case 'grey':
          return 'Associated to mine';
      }
    }
  };

  __getResourcesTotalCost = () => {
    let cost = 0;
    this.state.GISResources.map((r) => {
//      cost = cost + r.unit * r.resource.rate;  // DMM 12/9/2022
      cost = cost + r.unit * r.rate;
    });

    return GlobalFormatter.FormatUSD(cost);
  };

  renderStatus(status) {
    return workorderService.renderStatus(status);
  }

  __renderEssentialQuestion() {
    if (this.state.workOrderQuestionAnswers.length > 0) {
      return (
        <div className="clearfix">
          <hr />
          <h6 className="text-muted font-18">Essential Details</h6>
          <ol>
            {this.state.workOrderQuestionAnswers.map((q, index) => (
              <li key={index}>
                <div>
                  <p style={{ fontSize: '1.2rem', margin: '0' }}>
                    {q.essentialQuestion.question}
                  </p>
                  <p
                    style={{ fontSize: '1.2rem', margin: '0' }}
                    className="text-success mb-2"
                  >
                    {q.answer}
                  </p>
                </div>
              </li>
            ))}
          </ol>
        </div>
      );
    }
  }

  __renderPrinting() {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '45px' }}>
          <Spinner className="m-2" color={'info'} size={'sm'} />
        </div>
      );
    } else {
      if (this.state.WorkOrderFeature) {
        const { attributes } = this.state.WorkOrderFeature;
        const { latitude, longitude } = this.props.feature.geometry;

/*        const mapImgUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=16&size=600x250&maptype=roadmap
        &markers=color:green%7Clabel:W%7C${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;  */
        const mapImgUrl = `${this.props.config.GoogleStaticMap}?center=${latitude},${longitude}&zoom=16&size=600x250&maptype=roadmap
        &markers=color:green%7Clabel:W%7C${latitude},${longitude}&key=${this.props.config.ClientGoogleMapsAPIKey}`;
        return (
          <Fragment>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div className="clearfix">
                      <div className="float-left mb-3">
                        <MunicipalityContactInfo
                          municipalityInfo={this.props.config.municipalityInfo}
                        />
                      </div>
                      <div className="float-right">
                        <h4 className="m-0 d-print-none">
                          Work Order - {attributes.WOID}
                        </h4>
                      </div>
                    </div>
                    <Row>
                      <Col sm={6}>
                        <div className="float-left mt-3">
                          <p className="font-20">
                            <b>Created by: {attributes.OriginatorID}</b>
                          </p>
                          <p className="font-20">
                            Asset: <b>{attributes.AssetName}</b>
                          </p>
                          <p className="font-20">
                            Work Type: <b>{attributes.WorkType}</b>
                          </p>
                          <p className="text-muted font-20">
                            <u>Description</u>: {attributes.Description}
                          </p>
                        </div>
                      </Col>

                      <Col sm={6}>
                        <div className="mt-3 float-sm-right">
                          <p className="font-20">
                            <strong>Due Date: </strong> &nbsp;&nbsp;&nbsp;{' '}
                            <b
                              className={workorderService.GetDueDateTextColor(
                                attributes.PlannedDate,
                                attributes.Status
                              )}
                            >
                              {new Date(attributes.PlannedDate).toISOString().split('T')[0]}
{/*                              {new Date(
                                attributes.PlannedDate
                                ).toLocaleDateString()}  DMM 12/12/2022 */}
                            </b>
                          </p>
                          <p className="font-20">
                            <strong>WO Status: </strong>{' '}
                            <span style={{ float: 'right' }}>
                              {this.renderStatus(attributes.Status)}
                            </span>
                          </p>
                          <p className="font-20">
                            <strong>WO ID: </strong>{' '}
                            <span className="float-right">
                              #{attributes.WOID}
                            </span>
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col sm={3}>
                        <h6 className="font-18">Location</h6>
                        <address style={{ fontSize: '1.2rem' }}>
                          <b>{attributes.LocationAddress}</b>
                          <br />
                          Work Assigned to:
                          <br />
                          <b>{attributes.AssignedToID}</b>
                          <br />
                          {this.state.AssignedUser
                            ? this.state.AssignedUser.email
                            : ''}
                          <br />
                          <abbr title="Phone">P:</abbr>{' '}
                          {this.state.AssignedUser
                            ? this.state.AssignedUser.phoneNumber
                            : ''}
                        </address>
                      </Col>
                      <Col sm={3}>
                        <div className="panel-gis-list">
                          <h5>GIS Assets</h5>
                          {this.state.GISAssets.length === 0 && <p>None</p>}
                          <ul className="gis-assets-list">
                            {this.state.GISAssets.map((a, index) => (
                              <li key={index} className="gis-assets font-16">
                                {a.assetName} - {a.facilityID}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Col>
                      <Col
                        sm={6}
                        style={{ height: '250px' }}
                        className="text-right"
                      >
                        <img src={mapImgUrl} />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="table-responsive">
                          <table className="table mt-4">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Resource</th>
                                <th>Quantity</th>
                                <th>Unit Cost</th>
                                <th className="text-right">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.GISResources.map((item, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>
{/*                                      <b>{item.resource.name}</b>  *DMM 12/12/2022 */}
                                      <b>{item.name}</b>
                                    </td>
                                    <td>{item.unit}</td>
                                    <td>{item.rate}</td>
                                    <td className="text-right">
                                      {GlobalFormatter.FormatUSD(
                                        item.unit * item.rate
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="clearfix pt-3">
                          <h6 className="text-muted font-18">Field Notes:</h6>
                          <small style={{ fontSize: '1.2rem' }}>
                            {attributes.FieldNotes === '' ||
                            attributes.FieldNotes === null
                              ? 'No field note provided at this time'
                              : attributes.FieldNotes}
                          </small>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="float-right mt-3 mt-sm-0">
                          <h3>{this.__getResourcesTotalCost()}</h3>
                        </div>
                        <div className="clearfix"></div>
                      </Col>
                    </Row>
                    <div className="clearfix" style={{ clear: 'both' }}></div>
                    <Row>
                      <Col sm={6}>
                        <div className="clearfix pt-3">
                          <p className="text-muted font-18">
                          Problem: <b>
                            {attributes.ProblemName === '' ||
                            attributes.ProblemName === null
                              ? '(No Problem chosen)'
                              : attributes.ProblemName}
                          </b>
                          </p>
                          <p className="text-muted font-18">
                          Resolution: <b>
                            {attributes.ResolutionName === '' ||
                            attributes.ResolutionName === null
                              ? '(No Resolution chosen)'
                              : attributes.ResolutionName}
                          </b>
                          </p>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div>{this.__renderEssentialQuestion()}</div>
                        <div className="clearfix"></div>
                      </Col>

                    </Row>


                    {/* <div className="d-print-none mt-4">
                      <div className="text-right">
                        <button
                          className="btn btn-primary"
                          onClick={(e) => {
                            window.print();
                          }}
                        >
                          <i className="mdi mdi-printer"></i> Print
                        </button>
                      </div>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="clearfix" style={{ clear: 'both' }}></div>
          </Fragment>
        );
      } else {
        return (
          <div className="text-center">
            <img src={notFoundImg} height="90" alt="" className="mt-4" />
            <h4 className="text-uppercase text-danger mt-3">
              No Work Order found
            </h4>
            <p className="text-muted mt-3">
              There is no work order associate with given id.
            </p>
          </div>
        );
      }
    }
  }

  render() {
    return <Fragment>{this.__renderPrinting()}</Fragment>;
  }
}
const mapStateToProps = (state) => {
  return {
    config: state.AppConfig.config,
  };
};
export default connect(mapStateToProps)(PrintWorkOrderInBatch);
