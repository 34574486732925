// @flow
import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  // console.log(user);
  if (!user) {
    return false;
  }
  if (user.token) {
    return true;
  } else {
    return false;
  }
};
/**
 * Checks if user is supervisor
 */
const isUserInSupervisorRole = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }
  if (user.roles.length > 0) {
    var supervisorID;

    const index = user.roles.findIndex(
      (r) => r.id === '017bf016-611d-4ddd-b5d8-f59a998c31f1'
    );
    return index > -1 ? true : false;
  } else {
    return false;
  }
};

/**
 * Returns the logged in user
 */

const getLoggedInUser = () => {
  const cookies = new Cookies();
  const user = cookies.get('user');
  return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export { isUserAuthenticated, isUserInSupervisorRole, getLoggedInUser };
