import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { workorderService } from '../../services/workorder.service';

const __listPanelContentScroller = {
  position: 'absolute',
  top: '70px',
  left: '0',
  right: '0',
  bottom: '65px',
  overflow: 'auto',
  overflowX: 'hidden',
};
const _btnFooter = {
  backgroundColor: '#a4a3a4',
  color: '#fff',
};

class WorkOrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workorders: [],
    };
  }
  componentDidMount() {
    this.setState({ workorders: this.props.workhistory.workhistory });
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    return (
      <Fragment>
        <Card style={{ height: '100%' }}>
          <CardHeader style={{ padding: '0' }}>
            <div className="flex-row">
              <div className="box box--start">
                <i className="dripicons-clock mr-1"></i>
                Work Order History
              </div>
              <div className="box box--end">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ marginRight: '-45px' }}
                  onClick={() => this.props.renderComponent('')}
                >
                  Return
                </button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div
              id="layerInfoContentDisplay"
              style={__listPanelContentScroller}
            >
              <div style={{ margin: '5px 10px' }}>
                <h4 className="header-title mt-2 mb-2">
                  Showing {this.state.workorders.length} performed
                </h4>

                <div className="inbox-widget">
                  {this.state.workorders.map((w, idx) => {
                    const { workOrder } = w;
//                    console.log(workOrder);
                    if ((workOrder.status === 'Archived') || (workOrder.status === 'Completed')) {  // HISTORY ONLY FOR COMPLETED AND ARCHVED
                      return (
                        <a
                          href={`/apps/workorders/?id=${workOrder.globalID}`}
                          className="list-group-item list-group-item-action workorder-item pl-4"
                          style={{ padding: '0 1.25rem' }}
                          key={idx}
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{workOrder.workOrderType}</h5>
                          </div>
                          <p className="inbox-item-text mb-0">
                            Created by: {workOrder.workCreatedBy}
                          </p>
                          <p className="inbox-item-text mb-0">
                            Created on:{' '}
                            {new Date(workOrder.dateCreated).toLocaleDateString()}
                          </p>
                          <p className="inbox-item-text mb-0">
                            Work assigned to: {workOrder.workAssignedTo}
                          </p>
                          <p className="inbox-item-date mb-0">
                            Status:
                            <small
                              className="px-1 py-0"
                              style={{ float: 'right', color: '#040202' }}
                            >
                              {workorderService.renderStatus(workOrder.status)}
                            </small>
                          </p>
                        </a>
                      );
                    }
                   })}
                </div>
              </div>
            </div>
          </CardBody>
          <CardFooter style={_btnFooter}>
            You are viewing work order history
          </CardFooter>
        </Card>
      </Fragment>
    );
  }
}
export default withRouter(WorkOrderHistory);
