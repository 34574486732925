import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Button, Card,
    CardBody, CardFooter, CardHeader
} from 'reactstrap';
import Spinner from '../Spinner';
import { IsolationTraceService } from './service/isolationTrace.service';

const __stylePanelHeader = {
    position: 'relative',
    height: '70px',
    backgroundColor: '#313a46',
  };
  
  const __listPanelContentScroller = {
    position: 'absolute',
    top: '70px',
    left: '0',
    right: '0',
    bottom: '65px',
    overflow: 'auto',
  };

const btnOfficeWrapper = {
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
  };
  
  const _btnFooter = {
    backgroundColor: '#a4a3a4',
    textAlign: 'center',
    padding: '0.75rem 0',
  };


class WaterIsolationTrace extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          isPerformingTrace: false,
          areBothPointsAvailable: false,
          view: this.props.feature.view,
          feature: this.props.feature.feature,
          location: null,
          results: []
        };
      }

    componentDidMount () {
        const waterisolationTraceGL = this.state.view.map.findLayerById(
            'waterisolationTraceGL'
          );
        waterisolationTraceGL.graphics.removeAll();
        setTimeout(() => {
            const { location } = this.state.view.popup;
            if(location) {
                this.setState({location});
                this.state.view.popup.close();
                //IsolationTraceService.AddStartPointAndBarrier(waterisolationTraceGL, location);
            }
        }, 50);
    }

    componentWillUnmount () {
        this.setState = (state, callback) => {
            return;
          };
    }

    getLineLastPoint = (feature) => {
        var lastIdx = feature.geometry.paths[0].length - 1;
        var lastPnt = feature.geometry.getPoint(0, lastIdx);
        return lastPnt;
    }

    performTrace = async() => {
        if(this.state.feature) {
            
            try {
                const waterisolationTraceGL = this.state.view.map.findLayerById(
                    'waterisolationTraceGL'
                );
                waterisolationTraceGL.type = "Flag";
                waterisolationTraceGL.add(await IsolationTraceService.GetStartingPointGraphic(this.state.location));

                IsolationTraceService.performTrace(this.state.view, waterisolationTraceGL)
                .then((res) => {
                    console.log(res);
                })
            } catch (error) {
                console.log("Error :: ", error);
            }
        }
    }

    __renderComponent () {
        //const { attributes } = this.props.feature.feature;
        if (this.state.isLoading) {
            return (
              <div style={{ textAlign: 'center', marginTop: '45px' }}>
                <Spinner className="m-2" color={'info'} size={'sm'} />
                <label className="text-danger">Performing water isolation trace on main</label>
                <label className="text-danger">Please wait...</label>
              </div>
            );
        }
        else {

            if(this.props.feature.feature) {
                return (
                    <>
                        <div className="video-player-wrapper text-center m-3">
                            <div id="sketch1"></div>
                            <p>You may move the starting point or barrier if necessary</p>
                            <Button
                                color="primary"
                                disabled={this.state.isPerformingTrace}
                                type="button"
                                onClick={this.performTrace}
                                >
                                {this.state.isPerformingTrace
                                    ? 'Tracing... please wait'
                                    : 'Perform Trace'}
                            </Button>
                        </div>   
                    </>
                )
            }
            else {
                return (
                    <div className="text-center m-3">
                        <div id="sketch2"></div>
                        <p>
                            Add starting and barrier points on the map that will define 
                            where the trace starts and stops
                        </p>

                        <Button
                            color="primary"
                            disabled={!this.state.areBothPointsAvailable}
                            type="button"
                            onClick={this.performTrace}
                            >
                            {this.state.isPerformingTrace
                                ? 'Tracing... please wait'
                                : 'Perform Trace'}
                        </Button>

                    </div>
                )
            }
        }
    }

    render() {
        return (
            <>
                <Card style={{ height: '100%' }}>
                        <CardHeader style={__stylePanelHeader}>
                            <div style={btnOfficeWrapper}>
                                <div className="cctv-title">
                                    Isolation Trace
                                </div>
                                <div className="workOrders-total" style={{ marginTop: '15px' }}>
                                {''}
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div
                                id="layerInfoContentDisplay"
                                style={__listPanelContentScroller}
                            >
                                <div>{this.__renderComponent()}</div>
                            </div>
                        </CardBody>
                        <CardFooter style={_btnFooter}>
                            <Link className="btn btn-info" to="/" disabled={this.state.isLoading}>
                                <i className="mdi mdi-reply"></i> Return Home
                            </Link> 
                        </CardFooter>
                </Card>
            </>
        )
    }
}


export default WaterIsolationTrace;
