import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import CCTV from '../cctv/CCTV';
import WorkOrderHistory from '../Reports/WorkOrderHistory';
import WaterIsolationTrace from '../WaterIsolationTrace/WaterIsolationTrace';
import WorkOrderOfficeDetails from './BackOffice/WorkOrderOfficeDetails';
import WorkOrderOfficeArchiveDetails from './BackOffice/WorkOrderOfficeArchiveDetails';
import CreateWorkOrder from './CreateWorkOrder';
import LogWork from './LogWork';
import './map.style.css';
import WorkFieldUpdate from './WorkFieldUpdate';
import WorkOrderDetails from './WorkOrderDetails';
import WorkOrdersList from './WorkOrdersList';
import WorkOrdersMapAction from './WorkOrdersMapAction';

class WorkOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeComponent: 'WorkOrdersList',
      activeProps: null,
      facilityID: '',
      user: null,
      view: null,
    };
  }
  componentDidMount() {
    //this.__getWorkOrders();
  }

  __renderRightStateComponent = (
    component,
    props = null,
    user = null,
    view = null
  ) => {
    this.setState({
      activeComponent: component,
      activeProps: props,
      user,
      view,
    });
  };

  renderComponent() {
    switch (this.state.activeComponent) {
      case 'CreateWorkOrder':
        return (
          <CreateWorkOrder
            renderComponent={this.__renderRightStateComponent}
            feature={this.state.activeProps}
            view={this.state.view}
          />
        );
        break;
      case 'LogWork':
          return (
            <LogWork
              renderComponent={this.__renderRightStateComponent}
              feature={this.state.activeProps}
              view={this.state.view}
            />
          );
          break;   
      case 'WorkOrderHistory':
        return (
          <WorkOrderHistory
            renderComponent={this.__renderRightStateComponent}
            workhistory={this.state.activeProps}
          />
        );
        break;
      case 'WorkOrderDetails':
        return (
          <WorkOrderDetails
            renderComponent={this.__renderRightStateComponent}
            workorder={this.state.activeProps}
            user={this.state.user}
          />
        );
        break;
      case 'WorkOrderOfficeDetails':
        return (
          <WorkOrderOfficeDetails
            renderComponent={this.__renderRightStateComponent}
            workorder={this.state.activeProps}
            user={this.state.user}
          />
        );
        break;
        case 'WorkOrderOfficeArchiveDetails':
          return (
            <WorkOrderOfficeArchiveDetails
              renderComponent={this.__renderRightStateComponent}
              workorder={this.state.activeProps}
              user={this.state.user}
            />
          );
          break;
        case 'FieldWorkUpdate':
        return (
          <WorkFieldUpdate
            renderComponent={this.__renderRightStateComponent}
            workorder={this.state.activeProps}
            user={this.state.user}
          />
        );
        break;
      case 'CCTV':
        return (
          <CCTV
            renderComponent={this.__renderRightStateComponent}
            facilityID={this.state.activeProps}
            user={this.state.user}
          />
        );
        break;
      case 'ISOLATIONTRACE':
        return (
          <WaterIsolationTrace
            renderComponent={this.__renderRightStateComponent}
            feature={this.state.activeProps}
            user={this.state.user}
          />
        );
        break;

      default:
        return (
          <WorkOrdersList renderComponent={this.__renderRightStateComponent} />
        );
        break;
    }
  }

  render() {
    return (
      <Fragment>
        <div className="map-wrapper">
          <div className="main-container">
            {/* <div className="work-create-loading work-create-loader work-creation-loader"></div>
            <div className="work-create-loading work-create-loader-text work-creation-loader">
              Mapping work, please wait...
            </div> */}
            <div className="left">
              <div className="map-display-container">
                <WorkOrdersMapAction
                  renderComponent={this.__renderRightStateComponent}
                />
              </div>
            </div>
            <div className="right" id="sidebar-component-wrapper">
              {this.renderComponent()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(WorkOrders);
